import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO } from '../../modal/DAO';
import { MessageService } from 'primeng/api';
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { NgForm } from '@angular/forms';
import { DateService } from 'src/app/services/date.service';
import { UserInputService } from 'src/app/services/userInput.service';
import { StateSettings } from 'src/app/modal/state.models';
import { StateDriverLicenseSettings } from 'src/app/modal/state.driversLicense.models';
import { DatePipe } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { IBasePage } from '../../modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { DriverConstants, DriverDetails, DriverModel } from 'src/app/modal/DriversModel.models';
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { Constants } from 'src/app/modal/ProjConstants.models';
declare var jQuery: any;

@Component({
    selector: 'app-driver',
    templateUrl: './driver.component.html',
})
export class DriverComponent implements OnInit, IBasePage {

    @ViewChild('driverForm') driverForm: NgForm;
    @Input() RollbackDriverData: EventEmitter<string>;

    private type: string = '';
    public yesterday: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
    private MaximumDriver: number = 30;
    private ExcelData: any;
    private areLicensesValid: boolean = false;
    private ExcelEvent: any;
    private isLicenseNumberValidatedAtSave: boolean = false;
    protected unsubscriber = new Subject();
    private meunuClickSubscription: Subscription;
    private apiCallSubscription: Subscription;
    private RollbackDriverDataSubscription: Subscription;
    public driverModel: DriverModel = new DriverModel();
    public initialdriverModel: DriverModel = new DriverModel();
    private toastValidation: string = 'Please fill required fields highlighted in red';
    private dateService: DateService = new DateService();
    public nameSuffixDropdownValues = DriverConstants.NameSuffix;
    private actionType: string;
    private uploadedExcelWorkBook: any;

    constructor(
        public serviceLayer: ServiceLayer,
        public dao: DAO,
        private messageService: MessageService,
        private menuComponent: menuComponent,
        private userInputService: UserInputService,
        public stateSettings: StateSettings,
        private stateDriverLicenseSettings: StateDriverLicenseSettings,
        private datepipe: DatePipe
    ) { }

    ngOnInit(): void {
        var that = this;
        setTimeout(function () {
            that.dateControlChange();
        }, 10);
        this.meunuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
            (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
        );
        this.RollbackDriverDataSubscription = this.RollbackDriverData.subscribe(
            (page) => this.onRollback(page)
        )
        this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
            (dotSubmissionDetails) => {
                this.initializeData(dotSubmissionDetails);
            });
    }
    public menuClicked(menuClickContext: MenuClickContext): void {
        if (menuClickContext != null && menuClickContext.page != "driver") {
            menuClickContext.currentPageIsValid = this.isPageValid();
            menuClickContext.currentPageDataChanged = !_.isEqual(this.driverModel, this.initialdriverModel)
        }
    }
    public isPageValid(): boolean {
        let result = this.ValidatingDriverPage(this.driverForm);
        return result == '';
    }
    public onRollback(currentTempage: string): void {
        if (currentTempage == "driver") {
            this.driverModel = _.cloneDeep(this.initialdriverModel);
            this.setDriversDataToDOTList();
        }
    }
    public ngOnDestroy(): void {
        if (!!this.meunuClickSubscription)
            this.meunuClickSubscription.unsubscribe();

        if (!!this.apiCallSubscription) {
            this.apiCallSubscription.unsubscribe();
            this.apiCallSubscription = null;
        }
        if (!!this.RollbackDriverDataSubscription) {
            this.RollbackDriverDataSubscription.unsubscribe();
            this.RollbackDriverDataSubscription = null;
        }
    }
    private ValidatingDriverPage(driverForm: NgForm): string {
        this.dao.NotAllowedToSubmit = false;
        for (let i = 0; i < this.driverModel.driverList.length; i++) {
            this.driverModel.driverList[i].isValidDriver = true;
        }
        this.validatingDriverList();
        if (!!driverForm && driverForm.invalid) {
            return this.toastValidation;
        }
        if (!this.validateAllLicensePermitNumbers('save')) {
            return 'License number format is invalid for the state. Please review the drivers highlighted in red and correct the invalid license number to continue.';
        }
        if (this.isDriverListValid()) {
            return 'Must review and complete each driver details highlighted in red';
        }
        if (this.checkDuplicateDriverExists('save')) {
            return 'License State and License Number is same for highlighted drivers. Please review the drivers and update with correct data to continue.';
        }
        return '';
    }
    private async saveDriver(): Promise<void> {
        this.setDriversDataToDOTList();
        await this.serviceLayer.save();
        if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
            if (!this.dao.IsSavedFromMenu) {
                document.body.style.pointerEvents = "none";
                this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission' });
            } else {
                this.dao.NotAllowedToSubmit = true;
            }
        }
    }

    public confirmRemove(): void {
        if (this.type == 'confirmUpload') {
            this.validateTemplateVersion();
        }
        this.messageService.clear('remove')
        document.body.style.pointerEvents = "visible";
    }

    // validate the current driver and then allowed to add new driver
    public async addDriver(driverForm: NgForm): Promise<void> {
        let form = this.serviceLayer.formValidation(driverForm);
        if (form.valid) {
            await this.addingDriver();
            form.submitted = false;
        }
        else {
            this.openToast(this.toastValidation);
        }
    }
    //====Change Dob =======
    public selectDob() {
        this.driverModel.currentDriver.originalLicenseDate.answer = this.driverModel.currentDriver.dob.answer ? new Date(new Date(this.driverModel.currentDriver.dob.answer).setFullYear(new Date(this.driverModel.currentDriver.dob.answer).getFullYear() + 16)) : null;
        this.driverModel.minOriginalLicenseDate = new Date(this.driverModel.currentDriver.originalLicenseDate.answer);
    }
    public CancelmySubmission() {
        return this.serviceLayer.CancelmySubmission();
    }
    public cancelMySubmissionPopUp() {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
    }
    public onReject(type) {
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
        if (this.actionType == 'validateTemplateVersion') {
            this.validateAndUploadExcel();
        }
    }
    public openToast(body) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({
            key: 'ok', sticky: true, severity: 'warn',
            summary: body
        });
        this.dao.isClickFromMenu = false;
    }
    //  change Date control with mask
    public dateControlChange() {
        var that = this;
        jQuery("#calendarIcon1").inputmask("mm/dd/yyyy", {
            isComplete: function (event) {
            const value = jQuery("#calendarIcon1").val();
                const numericNewEffectiveDate = new Date(value).getTime();  
                if (!isNaN(numericNewEffectiveDate)) {                        
                    if (new Date(that.driverModel.currentDriver.dob.answer).getTime() != numericNewEffectiveDate) {
                        that.driverModel.currentDriver.dob.answer = new Date(numericNewEffectiveDate);
                        }
                }
                
            }, onKeyValidation: function (key, result) {
                $("div").remove(".p-datepicker");
            }
        });
        jQuery("#calendarIcon1").blur(function () {
            if (new Date(that.driverModel.currentDriver.dob.answer) > that.yesterday) {
                that.driverModel.currentDriver.dob.answer = that.yesterday;
            }
            that.selectDob();
        })
        jQuery("#calendarIcon2").inputmask("mm/dd/yyyy", {
            isComplete: function (event) {
                const value = jQuery("#calendarIcon2").val();
                const numericNewEffectiveDate = new Date(value).getTime();  
                if (!isNaN(numericNewEffectiveDate)) {                        
                        if (new Date(that.driverModel.currentDriver.originalLicenseDate.answer).getTime() != numericNewEffectiveDate) {
                            that.driverModel.currentDriver.originalLicenseDate.answer = new Date(numericNewEffectiveDate);
                        }
                }
            }, onKeyValidation: function (key, result) {
                $("div").remove(".p-datepicker");
            }
        });
        jQuery("#calendarIcon2").blur(function () {
            if (new Date(that.driverModel.currentDriver.originalLicenseDate.answer) < that.driverModel?.minOriginalLicenseDate) {
                that.driverModel.currentDriver.originalLicenseDate.answer = that.driverModel?.minOriginalLicenseDate;
            }
        })
        jQuery("#calendarIcon3").inputmask("mm/dd/yyyy", {
            isComplete: function (event) {
                const value = jQuery("#calendarIcon3").val();
                const numericNewEffectiveDate = new Date(value).getTime();  
                if (!isNaN(numericNewEffectiveDate))  {                        
                        if (new Date(that.driverModel.currentDriver.dateOfHire.answer).getTime() != numericNewEffectiveDate) {
                            that.driverModel.currentDriver.dateOfHire.answer = new Date(numericNewEffectiveDate);                        
                    }
                }
            }, onKeyValidation: function (key, result) {
                $("div").remove(".p-datepicker");
            }
        });
        jQuery("#calendarIcon3").blur(function () {
            if (new Date(that.driverModel.currentDriver.dateOfHire.answer).getTime() < new Date(that.driverModel.currentDriver.dob.answer).getTime()) {
                that.driverModel.currentDriver.dateOfHire.answer = null;
            }
        })
    }

    public onBack(page: string) {
        this.dao.IsBackButtonClicked = true;
        return this.menuComponent.menuClick(page);
    }

    public clickUpload(form, event) {
        if (event && event.target && event.target.className == 'p-fileupload-content') {
            this.fileUpload(form);
        }
    }

    private fileUpload(form) {
        if (form.valid || (this.driverModel.driverList.length == 1 && form.invalid &&
            !(form.controls['txtfirstName'].hasError('alphaCharactersOnly') ||
                form.controls['txtmiddleName'].hasError('alphaCharactersOnly') ||
                form.controls['txtlastName'].hasError('alphaCharactersOnly')))) {
            jQuery('.p-fileupload-choose').click();
        }
        else {
            this.openToast(this.toastValidation);
        }
    }
    private validateTemplateVersion(): void {
        let file = this.ExcelEvent;
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            this.uploadedExcelWorkBook = XLSX.read(fileReader.result, { type: 'binary', cellText: false, cellDates: true });
            this.ExcelData = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['Drivers'], { header: 0, raw: false, dateNF: 'MM/dd/yyyy' });
            if (!!!this.uploadedExcelWorkBook.Custprops['version'] || (!!this.uploadedExcelWorkBook.Custprops['version'] && this.uploadedExcelWorkBook.Custprops['version'] != Constants.CurrentDriverExcelTemplateVersion)) {
                this.openToast('A new version of the driver template is now available.  You may continue with the old version, but the new version will provide a superior experience.');
                this.actionType = "validateTemplateVersion";
                return;
            }
            this.validateAndUploadExcel();
        }
    }

    private validateAndUploadExcel(): boolean {
        this.actionType = '';
        let driverLength: number;
        if (!this.validatingTemplateFormat()) {
            this.openToast('The file you uploaded is not in the correct format.  Please upload the drivers list using the BSB template only.');
            return true; 
        }

        var currentSizeofDriverList = (this.driverModel.driverList.length == 1 && !this.driverForm.valid) ? 0 : this.driverModel.driverList.length;;
        if (currentSizeofDriverList + this.ExcelData.length <= this.MaximumDriver) {
            if (this.ExcelData.length > 0) {
                let i = 1;
                for (var key in this.ExcelData) {
                    if (this.ExcelData[key]["First Name"] != undefined && this.ExcelData[key]["Last Name"] != undefined
                        && this.ExcelData[key]["Date of Birth"] != undefined && this.ExcelData[key]["Driver License State"] != undefined
                        && this.ExcelData[key]["Driver License Number"] != undefined && this.ExcelData[key]["Date of Hire"] != undefined) {
                        let tempDriver = _.cloneDeep(this.driverModel.currentDriver);
                        if (this.driverModel.driverList.length == 1 &&
                            (this.driverModel.driverList[0].firstName.answer == '' && this.driverModel.driverList[0].lastName.answer == ''
                                && this.driverModel.driverList[0].dob.answer == '' && this.driverModel.driverList[0].licenseStateCode.answer == ''
                                && this.driverModel.driverList[0].licensePermitNumber.answer == '' && this.driverModel.driverList[0].dateOfHire.answer == '')) {
                            this.driverModel.driverList.splice(0, 1);
                            currentSizeofDriverList = this.driverModel.driverList.length;
                        }
                        let recentIndex = Math.max.apply(Math, this.driverModel.driverList.map(function (o) { return o.rowIndex; }));
                        recentIndex = recentIndex == -Infinity ? 0 : recentIndex;
                        tempDriver.rowIndex = recentIndex + 1;
                        tempDriver.firstName.answer = this.getValidName(this.ExcelData[key]["First Name"]);
                        tempDriver.middleName.answer = this.ExcelData[key]["Middle Name"] != undefined ? this.getValidName(this.ExcelData[key]["Middle Name"]) : "";
                        tempDriver.lastName.answer = this.getValidName(this.ExcelData[key]["Last Name"]);
                        tempDriver.suffix.answer = this.getValidNamesuffix(this.ExcelData[key]["Name Suffix"]);
                        tempDriver.dob.answer = this.getValidDob(this.ExcelData[key]["Date of Birth"]);
                        tempDriver.licenseStateCode.answer = this.getValidLicenseState(this.ExcelData[key]["Driver License State"]);
                        tempDriver.licensePermitNumber.answer = this.ExcelData[key]["Driver License Number"].trim();
                        tempDriver.dateOfHire.answer = this.getValidHiredDate(this.ExcelData[key]["Date of Hire"]);
                        tempDriver.originalLicenseDate.answer = this.ExcelData[key]["Date of Birth"] == "" ? "" : new Date(new Date(tempDriver.dob.answer).setFullYear(new Date(tempDriver.dob.answer).getFullYear() + 16));
                        this.driverModel.driverIndex = this.driverModel.driverList.length == 0 ? 0 : this.driverModel.driverIndex;
                        if (i == 1)
                            driverLength = this.driverModel.driverList.length;
                        this.driverModel.driverList.push(tempDriver);
                        tempDriver.isReviewed = false;
                        tempDriver.isValidDriver = this.getValidDriverFromExcel(tempDriver);
                        tempDriver.isDeleteCheckBoxChecked = false;
                    }
                    else {
                        this.driverModel.driverIndex = driverLength == undefined || driverLength == 0 ? 0 : driverLength;
                        this.driverModel.currentDriver = i == 1 && currentSizeofDriverList > 0 ? this.driverModel.driverList[currentSizeofDriverList - 1] : this.driverModel.driverList[currentSizeofDriverList];
                        this.openToast('Driver list cannot be uploaded, as the driver ' + i + ' record has an error');
                        return false;
                    }
                    i++;
                }
            }
            else {
                this.openToast('Driver list is empty, cannot be uploaded');
                return false;
            }
        }
        else {
            this.openToast('You cannot exceed ' + this.MaximumDriver + ' drivers');
            return false;
        }
        this.driverModel.driverIndex = driverLength == 0 ? 0 : driverLength;
        this.driverModel.currentDriver = this.driverModel.driverList[currentSizeofDriverList];
        this.openToast('Driver list has been successfully uploaded');
        return true;
    }
    public clickedAdjacentDriver(driverForm: NgForm, index: number, toWhich: string): void {
        if (driverForm.invalid) {
            this.openToast(this.toastValidation);
        }
        let areLicensesValid = true;
        areLicensesValid = this.validateAllLicensePermitNumbers('AdjacentDriver');
        if (driverForm.valid && areLicensesValid) {
            this.removingHighlightionOfInvalidDriver();
            if (toWhich == 'NextDriver' && index != this.driverModel.driverList.length - 1) {
                this.driverModel.currentDriver = this.driverModel.driverList[index + 1];
                this.driverModel.driverIndex = this.driverModel.driverIndex + 1;
            }
            else if (toWhich == 'PreviousDriver' && index != 0) {
                this.driverModel.currentDriver = this.driverModel.driverList[index - 1];
                this.driverModel.driverIndex = this.driverModel.driverIndex - 1;
            }
        }
        else if (driverForm.valid) {
            this.openToast('License number format is invalid for the state ' + this.driverModel.currentDriver.licenseStateCode.answer);
        }
    }
    private checkDuplicateDriverExists(type: string): boolean {
        let isDuplicateDriverExists = false;
        if (type == 'save' || type == 'RemovingHighlightionOfDuplicatesWithWhom' || type == 'delete') {
            for (let i = 0; i < this.driverModel.driverList.length; i++) {
                this.driverModel.driverList[i].isValidDriver = true;
            }
            for (let i = 0; i < this.driverModel.driverList.length; i++) {
                for (let j = i + 1; j < this.driverModel.driverList.length; j++) {
                    if (_.isEqual(this.driverModel.driverList[i].licenseStateCode.answer, this.driverModel.driverList[j].licenseStateCode.answer)) {
                        if (_.isEqual(this.driverModel.driverList[i].licensePermitNumber.answer, this.driverModel.driverList[j].licensePermitNumber.answer)) {
                            this.driverModel.driverList[i].isValidDriver = false;
                            this.driverModel.driverList[j].isValidDriver = false;
                            isDuplicateDriverExists = true;
                        }
                    }
                }
            }
        }
        else {
            for (let i = 0; i < this.driverModel.driverList.length; i++) {
                if (this.driverModel.driverIndex != i) {
                    if (_.isEqual(this.driverModel.currentDriver.licenseStateCode.answer, this.driverModel.driverList[i].licenseStateCode.answer)) {
                        if (_.isEqual(this.driverModel.currentDriver.licensePermitNumber.answer, this.driverModel.driverList[i].licensePermitNumber.answer)) {
                            isDuplicateDriverExists = true;
                        }
                    }
                }
            }
        }
        return isDuplicateDriverExists;
    }
    
    private async addingDriver(from: string = ''): Promise<void> {
        let areLicensesValid = true;
        if (from == '') {
            areLicensesValid = this.validateAllLicensePermitNumbers('add');
        }
        if (areLicensesValid) {
            this.validatingDriverList();
            let isInvalidDriverExists = this.isDriverListValid();
            if (!isInvalidDriverExists || from == 'Delete') {
                this.removingHighlightionOfInvalidDriver();
                if (from != 'Delete' && !isInvalidDriverExists) {
                    await this.DriverSaveDraft();
                }

                let tempDriver = _.cloneDeep(this.driverModel.currentDriver);
                let recentIndex = Math.max.apply(Math, this.driverModel.driverList.map(function (o) { return o.rowIndex; }));
                recentIndex = recentIndex == -Infinity ? 0 : recentIndex;
                tempDriver.rowIndex = recentIndex + 1;
                tempDriver.firstName.answer = "";
                tempDriver.lastName.answer = ""
                tempDriver.middleName.answer = ""
                tempDriver.suffix.answer = ""
                tempDriver.dob.answer = ""
                tempDriver.licenseStateCode.answer = ""
                tempDriver.licensePermitNumber.answer = ""
                tempDriver.originalLicenseDate.answer = ""
                tempDriver.dateOfHire.answer = ""
                tempDriver.isReviewed = false;
                tempDriver.isValidDriver = true;
                tempDriver.yearsOfExperience.answer = ""
                tempDriver.isDeleteCheckBoxChecked = false;
                this.driverModel.driverIndex = this.driverModel.driverList.length == 0 ? 0 : this.driverModel.driverIndex;
                this.driverModel.driverList.push(tempDriver)
                if (from != '') {
                    this.driverModel.currentDriver = _.cloneDeep(tempDriver);
                }
                else {
                    this.DriverContinue();
                }
            }
            else {
                this.openToast('Must review and complete each driver details highlighted in red');
            }
        }
        if (!areLicensesValid) {
            this.openToast('License number format is invalid for the state ' + this.driverModel.currentDriver.licenseStateCode.answer);
        }
    }
    private DriverContinue(): void {
        this.driverModel.driverIndex = this.driverModel.driverList.length - 1;
        this.driverModel.currentDriver = this.driverModel.driverList[this.driverModel.driverIndex];
    }
    private validateAllLicensePermitNumbers(type: string): boolean {
        let areLicensesValid: boolean = true;
        if (type == 'save') {
            this.isLicenseNumberValidatedAtSave = false;
            for (let i = 0; i < this.driverModel.driverList.length; i++) {
                let xpressionData = this.stateDriverLicenseSettings.DriverLicenseQuestionOptions.filter(d => d.code == this.driverModel?.driverList[i]?.licenseStateCode?.answer);
                if (xpressionData && xpressionData?.length > 0) {
                    let pattern = new RegExp(xpressionData[0].expression);
                    let isValidDriver = pattern.test(this.driverModel.driverList[i].licensePermitNumber.answer);
                    if (!isValidDriver) {
                        areLicensesValid = false;
                        this.driverModel.driverList[i].isValidDriver = false;
                        this.isLicenseNumberValidatedAtSave = true;
                    }
                }
            }
        }
        else {
            let xpressionData = this.stateDriverLicenseSettings.DriverLicenseQuestionOptions.filter(d => d.code == this.driverModel?.currentDriver?.licenseStateCode?.answer);
            if (xpressionData && xpressionData?.length > 0) {
                let pattern = new RegExp(xpressionData[0].expression);
                areLicensesValid = pattern.test(this.driverModel.currentDriver.licensePermitNumber.answer);
            }
        }
        return areLicensesValid;
    }

    public onItemClick(driverForm: NgForm, index: number): void {
        this.clickedItem(driverForm, this.driverModel.driverList[index], index);
    }

    public onRemoveButtonClicked(index: number): void {
        if (this.driverModel.driverList == 0) {
            this.addingDriver('Delete');
        }
        this.driverModel.currentDriver = this.driverModel.driverList[index];
        this.driverModel.driverIndex = index;
    }

    public downloadSampleDriverTemplate(id: any, fileName: any): void {
        this.serviceLayer.downloadSampleTemplate(id, fileName);
    }
    public clickedItem(driverForm: NgForm, driver: DriverDetails, index: number): void {
        if (driverForm.invalid) {
            this.openToast(this.toastValidation);
        }
        this.areLicensesValid = true;
        this.areLicensesValid = this.validateAllLicensePermitNumbers('NavigationThroughList')
        if (!this.areLicensesValid && driverForm.valid) {
            this.openToast('License number format is invalid for the state ' + this.driverModel.currentDriver.licenseStateCode.answer);
        }
        else if (this.areLicensesValid && driverForm.valid) {
            this.driverModel.currentDriver = driver;
            this.driverModel.driverIndex = index;
            this.removingHighlightionOfInvalidDriver();
        }
    }
    private removingHighlightionOfInvalidDriver(): void {
        if (!this.driverModel.currentDriver.isValidDriver) {
            if (this.isLicenseNumberValidatedAtSave) {
                this.driverModel.currentDriver.isValidDriver = true;
            }
            else {
                let isDuplicateDriverExists = false;
                isDuplicateDriverExists = this.checkDuplicateDriverExists('RemovingHighlightion');
                this.driverModel.currentDriver.isValidDriver = isDuplicateDriverExists == false;
                this.checkDuplicateDriverExists('RemovingHighlightionOfDuplicatesWithWhom');
            }
        }
    }
    public confirmUpload(event: { files: any[]; }): void {
        this.ExcelEvent = event.files[0];
        this.type = 'confirmUpload';
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: 'Do you want to upload this driver list?' });
    }
    private validatingTemplateFormat(): boolean {
        let templateHeaders: any;
        if (!!!this.uploadedExcelWorkBook.Sheets['Drivers']) {
            return false;
        }
        templateHeaders = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['Drivers'], { header: 1 })[0];
        let isTemplateFormatValid = true;
        for (var i = 0; i < templateHeaders.length; i++) {
            if (templateHeaders[i] == 'First Name' || templateHeaders[i] == 'Last Name'
                || templateHeaders[i] == 'Middle Name' || templateHeaders[i] == 'Name Suffix'
                || templateHeaders[i] == 'Date of Birth' || templateHeaders[i] == 'Driver License Number'
                || templateHeaders[i] == 'Driver License State' || templateHeaders[i] == 'Date of Hire') {

            }
            else {
                isTemplateFormatValid = false;
                return isTemplateFormatValid;
            }
        }
        return isTemplateFormatValid;
    }
    private validatingDriverList(): void {
        this.isLicenseNumberValidatedAtSave = false;
        this.driverModel.driverList.forEach(driver => {
            Object.keys(driver).forEach(key => {
                if (driver[key].required && (driver[key].answer == '' || driver[key].answer == undefined) && key != 'yearsOfExperience') {
                    this.isLicenseNumberValidatedAtSave = true;
                    driver.isValidDriver = false;
                }
            });
        });
    }
    private getValidNamesuffix(nameSuffix: string): string {
        if (nameSuffix == undefined) {
            return "";
        }
        nameSuffix = nameSuffix.toUpperCase().replace(/[^a-zA-Z ]/g, "").trim();
        if (this.driverModel.driverDefault.suffix.questionOptions.some(x => x.code == nameSuffix)) {
            return nameSuffix;
        }
        return "";
    }
    private getValidLicenseState(licenseState: string): string {
        licenseState = licenseState.toUpperCase().trim();
        if (this.stateSettings.StateQuestionOptions.some(x => x.code == licenseState)) {
            return licenseState;
        }
        return "";
    }
    private getValidDob(dob: string): string {
        if (!this.dateService.isValidDate(dob)) {
            return '';
        }
        const date = new Date(dob.trim());
        date.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (date >= today) {
            return '';
        }
        return this.dateService.getDateString(date);
    }
    private getValidHiredDate(hiredDate: string): string {
        return this.dateService.getDateString(hiredDate).trim();
    }
    private isDriverListValid(): boolean {
        return !this.driverModel.driverList.every(driver => driver.isValidDriver);
    }
    private getValidDriverFromExcel(driver) {
        let isValidDriver = true;
        Object.keys(driver).forEach(key => {
            if (driver[key].required && (driver[key].answer == '' || driver[key].answer == undefined) && key != 'yearsOfExperience') {
                isValidDriver = false;
            }
        });
        return isValidDriver;
    }
    private getValidName(name: string): string {
        return this.userInputService.valueContainsNonAlphaCharacters(name) ? '' : name.trim();
    }
    public saveAndContinue(driverFrom: NgForm): void {
        let result = this.ValidatingDriverPage(driverFrom);
        if (result == '') {
            this.saveDriver();
        }
        else {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true
            }
            else {
                this.openToast(result);
            }
        }
    }
    private setDriversDataToDOTList(): void {
        let driverJSON = [];
        if (this.driverModel.driverList && this.driverModel.driverList.length > 0) {
            this.driverModel.driverList.forEach((driver, i) => {
                if (!isNaN(new Date(this.datepipe.transform((driver.originalLicenseDate.answer), 'MM-dd-yyyy')).getTime())) {
                    driver.yearsOfExperience.answer = (new Date().getFullYear() - new Date(this.datepipe.transform((driver.originalLicenseDate.answer), 'MM-dd-yyyy')).getFullYear()).toString();
                }
                Object.keys(driver).forEach(key => {
                    if (key == 'licensePermitNumber') {
                        driver[key].questionOptions = [];
                    }
                    if (key != 'group' && typeof (driver[key]) == 'object') {
                        driver[key].rowIndex = i + 1;
                        driverJSON.push(driver[key]);
                    }
                    if (key == 'firstName' || key == 'middleName' || key == 'lastName' || key == 'licensePermitNumber') {
                        driver[key].answer = driver[key].answer.trim();
                    }
                });
            })
        }
        this.dao.DOTList.questionnaire.filter(q => q.key == 'driver')[0].questionAnswer = _.cloneDeep(driverJSON);
    }
    get isValidToShowDriverAddButton(): boolean {
        return this.driverModel.driverList.length >= this.MaximumDriver ? false : true;
    }
    public initializeData(apiResponse: any): Promise<void> {
        if (!!!apiResponse)
            return;
        let getDriverDataIndex = apiResponse.driversQsnr.findQuestions('firstName').length;
        let getDriversData = apiResponse.driversQsnr.questionAnswer;
        this.driverModel.driverList = [];
        this.driverModel.currentDriver = new DriverDetails();
        this.driverModel.driverIndex = 0;
        if (getDriverDataIndex >= 0) {
            for (var j = 1; j <= getDriverDataIndex; j++) {
                let tempDriverModel = new DriverDetails();
                if (getDriversData && getDriversData.length > 0) {
                    Object.keys(tempDriverModel).forEach(key => {
                        if (key == 'isReviewed' || key == 'isValidDriver') {
                            tempDriverModel[key] = true;
                        }
                        else if (key != 'rowIndex') {
                            tempDriverModel[key] = _.cloneDeep(this.serviceLayer.getObjUsingJpath(getDriversData, '$..[?(@.key =="' + key + '" && @.rowIndex == "' + j + '")]')[0]);
                            if (tempDriverModel[key] && tempDriverModel[key].answer && tempDriverModel[key].answer != '' && (key == 'dob' || key == 'originalLicenseDate' || key == 'dateOfHire')) {
                                tempDriverModel[key].answer = this.serviceLayer.getCorrectDate(tempDriverModel[key].answer);
                            }
                        }
                        else {
                            tempDriverModel[key] = j + 1;
                        }
                    });
                }
                this.driverModel.driverList.push(tempDriverModel);
            }
        }
        if (this.driverModel.driverList && this.driverModel.driverList.length > 0) {
            this.driverModel.currentDriver = this.driverModel.driverList[this.driverModel.driverIndex];
            this.driverModel.driverDefault = this.driverModel.driverList[0];
            this.driverModel.minOriginalLicenseDate = this.serviceLayer.getCorrectDate(this.driverModel.currentDriver.originalLicenseDate.answer);
        }
        if (this.dao.finalSubmitPage == 'vehicles') {
            this.driverModel.currentDriver.firstName.answer = "";
            this.driverModel.currentDriver.lastName.answer = ""
            this.driverModel.currentDriver.middleName.answer = ""
            this.driverModel.currentDriver.suffix.answer = ""
            this.driverModel.currentDriver.dob.answer = ""
            this.driverModel.currentDriver.licenseStateCode.answer = ""
            this.driverModel.currentDriver.licensePermitNumber.answer = ""
            this.driverModel.currentDriver.originalLicenseDate.answer = ""
            this.driverModel.currentDriver.dateOfHire.answer = ""
            this.driverModel.currentDriver.yearsOfExperience.answer = ""
            this.driverModel.currentDriver.rowIndex = 1;
            this.driverModel.currentDriver.isReviewed = false;
            this.driverModel.currentDriver.isValidDriver = true;
        }
        this.initialdriverModel = _.cloneDeep(this.driverModel);
    }
    // ===================saving driver details in DB  while AddDriver btn is clicked
    private async DriverSaveDraft(): Promise<void> {
        this.setDriversDataToDOTList();
        if (this.driverModel.driverList.length > 0) {
            this.driverModel.driverList[this.driverModel.driverIndex] = _.cloneDeep(this.driverModel.currentDriver);
        }
        let getTemplate = this.serviceLayer.saveDatas.filter(s => s.template == this.dao.PolicyTemplate);
        let getFinalTemplate = this.serviceLayer.saveDatas.filter(s => s.template == this.dao.finalSubmitPage);
        let getTemplateOrder = (!!getTemplate && getTemplate.length > 0) ? getTemplate[0].order : 0;
        let getFinalTemplateOrder = (!!getFinalTemplate && getFinalTemplate.length > 0) ? getFinalTemplate[0].order : 0;
        let DotExists: boolean = false;
        if (getTemplateOrder <= getFinalTemplateOrder) {
            DotExists = true;
        }
        this.initialdriverModel = _.cloneDeep(this.driverModel);
        let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, DotExists, this.dao.PolicyTemplate, 'dot', false], "payloadData": this.dao.DOTList }
        await this.serviceLayer.SavePayloadToAPI(payload).then((data: any) => { });
        if (this.dao.finalSubmitPage == 'vehicles') {
            this.dao.finalSubmitPage = 'driver';
            this.dao.navigatePage = 'driver';
            this.dao.DOTList.finalSubmitPage = 'driver'
        }
    }
}
