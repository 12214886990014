import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ServiceLayer } from '../../../services/serviceLayer';
import { DAO, dropdown } from '../../../modal/DAO'
import { AuthenticationService } from '@rss/ng-auth-service';
import { Router } from '@angular/router';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { takeUntil, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/commonService';
import { workInProgress } from 'src/app/modal/routing.models';
import { MessageService } from 'primeng/api';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-new-submission',
  templateUrl: './new-submission.component.html'
})
export class NewSubmissionComponent extends AuthenticatedUserComponent implements OnInit, OnDestroy {
  searchWIPShowNoResults: boolean = false;
  public WIPSearchModal: WIPSearchModal = new WIPSearchModal();
  public AgencyWIPSearchModal: WIPSearchModal = new WIPSearchModal();
  public activeIndex: number;

  constructor(
    private router: Router,
    public serviceLayer: ServiceLayer,
    protected authenticationService: AuthenticationService,
    public dao: DAO, public appConfig: AppConfig,
    private commonService: CommonService,
    private messageService: MessageService,
    private auth0UserService: Auth0UserService) {
    super(authenticationService);
  }

  // ========= Page Load =====================
  ngOnInit(): void {

    let pageView: string = history.state?.data;
    if (!!pageView && pageView === 'endorsement') {
      this.activeIndex = 1;
      history.state.data = '';
    }
    // ============= AD login invalid change ==========
    if ((location.href.split("#") && location.href.split("#").length > 1 && location.href.split("#")[1].split("&").some(data => data == 'error=server_error'))) {
      this.router.navigateByUrl('/unAuthorized');
    }

    if (this.appConfig.auth0Enabled)
    {
      this.auth0UserService.userLoaded$.subscribe(
        auth0User => {
          if(!!auth0User?.accessToken)
          {            
            if (!!auth0User?.agencyCode)
            {
              this.serviceLayer.getWorkInProgress();
              this.serviceLayer.GetAgencyWIPData();
              this.setStatusDropdown();
            }
          }
        });   
    }
    else
    {
      this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(async () => {
        await this.serviceLayer.CheckUserClaims(this.userEmail);
        if (Number(this.dao.userAgencyCode) > 10000)
        {  
          this.serviceLayer.getWorkInProgress();
          this.serviceLayer.GetAgencyWIPData();
          this.setStatusDropdown();
        }
      });
    }
  }
  //============ On table row click ============
  public async onRowSelect(event) {
    if (event.status == "Incomplete") {
      let event1: any = {}
      event1.data = event;
      await this.serviceLayer.bindDatafromWIP(event1);
    }
  }

  public async setStatusDropdown() {
    this.dao.WIPstatusDropdown = new Array<dropdown>();
    let payload = { "urlData": [this.dao.userCompany] }
    await this.commonService.get('workInProgress', 'GetAllStatus', payload).subscribe((event: any) => {
      if (event) {
        this.dao.WIPstatusDropdown = event;
      }
    });
  }

  public searchWIPFilterData(isAgencyView: boolean, isReset: boolean): void {
    if (isReset) {
      if (isAgencyView) {
        this.AgencyWIPSearchModal = new WIPSearchModal();
      } else {
        this.WIPSearchModal = new WIPSearchModal();
      }
    }
    this.getWIPFilteredData(isAgencyView, isReset).then(
      data => {
        if (isAgencyView && this.dao.AgencySubmissionList?.length > 0) {
          this.searchWIPShowNoResults = false;
        } else if (!isAgencyView && this.dao.SubmissionList?.length > 0) {
          this.searchWIPShowNoResults = false;
        } else {
          this.searchWIPShowNoResults = true;
        }
      }
    );
  }

  openOtherTabs(pageName) {

    if (pageName == 'documentLibrary') {
      this.router.navigateByUrl("/documentLibrary");
    } else if (pageName == 'endorsement') {
      this.router.navigateByUrl('/endorsement');
    }
  }
  // WIP Filter
  public async getWIPFilteredData(isAgencyView = false, isReset = false) {
    if (isAgencyView == false) {
      if (this.WIPSearchModal.namedInsured == "") {
        this.WIPSearchModal.namedInsured = null;
      }
      if (this.WIPSearchModal.dot == "") {
        this.WIPSearchModal.dot = null;
      }
      if (this.WIPSearchModal.beginningEffectiveDate == null || this.WIPSearchModal.beginningEffectiveDate == undefined) {
        this.WIPSearchModal.beginningEffectiveDateString = null;
      } else {
        this.WIPSearchModal.beginningEffectiveDateString = ((new Date(this.WIPSearchModal.beginningEffectiveDate).getMonth() + 1) + '/' + new Date(this.WIPSearchModal.beginningEffectiveDate).getDate() + '/' + new Date(this.WIPSearchModal.beginningEffectiveDate).getFullYear()).toString();
      }
      if (this.WIPSearchModal.endingEffectiveDate == null || this.WIPSearchModal.endingEffectiveDate == undefined) {
        this.WIPSearchModal.endingEffectiveDateString = null;
      } else {
        this.WIPSearchModal.endingEffectiveDateString = ((new Date(this.WIPSearchModal.endingEffectiveDate).getMonth() + 1) + '/' + new Date(this.WIPSearchModal.endingEffectiveDate).getDate() + '/' + new Date(this.WIPSearchModal.endingEffectiveDate).getFullYear()).toString();
      }
      if (this.WIPSearchModal.status == "") {
        this.WIPSearchModal.status = null;
      }

      let searchCritieria = {
        NamedInsured: this.WIPSearchModal.namedInsured,
        Dot: this.WIPSearchModal.dot,
        FromEffectiveDate: this.WIPSearchModal.beginningEffectiveDateString,
        ToEffectiveDate: this.WIPSearchModal.endingEffectiveDateString,
        Status: this.WIPSearchModal.status,
        Email: this.dao.userMail,
        IsAgencyView: isAgencyView,
        Company: this.dao.userCompany,
        IsReset: isReset
      };

      let payload = { "urlData": [], "payloadData": searchCritieria };

      await this.commonService.post('workInProgress', 'postWIPFilter', payload).pipe(
        tap(event => {
          if (event) {
            this.dao.SubmissionList = event;
          }
        }
        )).toPromise();
      if (this.WIPSearchModal.beginningEffectiveDate == null || this.WIPSearchModal.beginningEffectiveDate == undefined) {
        this.WIPSearchModal.beginningEffectiveDateString = "";
      }
      if (this.WIPSearchModal.endingEffectiveDate == null || this.WIPSearchModal.endingEffectiveDate == undefined) {
        this.WIPSearchModal.endingEffectiveDateString = "";
      }
    } else {
      if (this.AgencyWIPSearchModal.namedInsured == "") {
        this.AgencyWIPSearchModal.namedInsured = null;
      }
      if (this.AgencyWIPSearchModal.dot == "") {
        this.AgencyWIPSearchModal.dot = null;
      }
      if (this.AgencyWIPSearchModal.beginningEffectiveDate == null || this.AgencyWIPSearchModal.beginningEffectiveDate == undefined) {
        this.AgencyWIPSearchModal.beginningEffectiveDateString = null;
      } else {
        this.AgencyWIPSearchModal.beginningEffectiveDateString = ((new Date(this.AgencyWIPSearchModal.beginningEffectiveDate).getMonth() + 1) + '/' + new Date(this.AgencyWIPSearchModal.beginningEffectiveDate).getDate() + '/' + new Date(this.AgencyWIPSearchModal.beginningEffectiveDate).getFullYear()).toString();
      }
      if (this.AgencyWIPSearchModal.endingEffectiveDate == null || this.AgencyWIPSearchModal.endingEffectiveDate == undefined) {
        this.AgencyWIPSearchModal.endingEffectiveDateString = null;
      } else {
        this.AgencyWIPSearchModal.endingEffectiveDateString = ((new Date(this.AgencyWIPSearchModal.endingEffectiveDate).getMonth() + 1) + '/' + new Date(this.AgencyWIPSearchModal.endingEffectiveDate).getDate() + '/' + new Date(this.AgencyWIPSearchModal.endingEffectiveDate).getFullYear()).toString();
      }
      if (this.AgencyWIPSearchModal.status == "") {
        this.AgencyWIPSearchModal.status = null;
      }
      let searchCritieria = {
        NamedInsured: this.AgencyWIPSearchModal.namedInsured,
        Dot: this.AgencyWIPSearchModal.dot,
        FromEffectiveDate: this.AgencyWIPSearchModal.beginningEffectiveDateString,
        ToEffectiveDate: this.AgencyWIPSearchModal.endingEffectiveDateString,
        Status: this.AgencyWIPSearchModal.status,
        Email: this.dao.userMail,
        IsAgencyView: isAgencyView,
        Company: this.dao.userCompany,
        IsReset: isReset
      };
      let payload = { "urlData": [], "payloadData": searchCritieria };

      await this.commonService.post('workInProgress', 'postWIPFilter', payload).pipe(
        tap(event => {
          if (event) {
            this.dao.AgencySubmissionList = event;
          }
        }
        )).toPromise();
      if (this.AgencyWIPSearchModal.beginningEffectiveDateString == null || this.AgencyWIPSearchModal.beginningEffectiveDateString == undefined) {
        this.AgencyWIPSearchModal.beginningEffectiveDateString = "";
      }
      if (this.AgencyWIPSearchModal.endingEffectiveDateString == null || this.AgencyWIPSearchModal.endingEffectiveDateString == undefined) {
        this.AgencyWIPSearchModal.endingEffectiveDateString = "";
      }

    }

  }
}
export class WIPSearchModal {
  public namedInsured: string = '';
  public dot: string = '';
  public policyNumber: string = '';
  public beginningEffectiveDate: Date;
  public endingEffectiveDate: Date;
  public endorsementEffectiveDate: string = '';
  public beginningEffectiveDateString: string = '';
  public endingEffectiveDateString: string = '';
  public status: string = '';
  public agentName: string = '';
}
