import { Injectable } from '@angular/core';

@Injectable()

export class DocumentService {

    public openStreamPDF(streamString) {
        var arrrayBuffer = this.base64ToArrayBuffer(streamString);
        var blob = new Blob([arrrayBuffer], { type: "application/pdf" });
        var link = window.URL.createObjectURL(blob);
        window.open(link, '', 'height=650,width=840');
    }

    public base64ToArrayBuffer(streamString) {
        var binaryString = window.atob(streamString);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
}
