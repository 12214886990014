<div class="row">
   <div class="col-lg-12 mb-1">
      <div class="float-right text-right mb-2">
         <button type="button" class="btnsuccess mr-2" name="btnEndorsement" id="btnEndorsement"
            title="Request a Endorsement" [routerLink]="['/createEndorsement']">
            <i class="fa fa-files-o" aria-hidden="true"></i>
            Request an Endorsement
         </button>
      </div>
   </div>
</div>
<work-in-progress-template [workInProgressSearchModal]="endorsementWIPSearchModal" [isEndorsementView]="true"
   [workInProgressData]="endorsementList" (onSearchButtonClicked)="searchWIPFilterData($event)"
   (onEndorsementItemSelected)="routeToCorrespondingEndorsementPage($event)"
   [hasNoWorkInProgressResults]="hasNoWorkInProgressResults" [workInProgressStatusDropdown]="endorsementStatuses">
</work-in-progress-template>