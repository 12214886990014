<div class="container" [class.wider-container]="dao?.PolicyTemplate === 'losshistory' && serviceLayer.lossHistoryVersion === 2">
    <div class="row mt-2 mb-2">
        <div class="col-md-12 mt-3 d-flex justify-content-center">
            <div class="header_menu_cls">

                <a title="Policy Holder" (click)="menuClick('AccountInfo');" class="menu_list  mb-2 {{dao?.MenuList[0]?.cssClass}}">
                    <i class="fa fa-file-text mb-2" aria-hidden="true"></i> <br />
                    Policy Holder
                </a>
               <span
                    class="arrow_next {{dao?.MenuList[1]?.arrowCss}}"></span>

                <a title="Underwriting" (click)="dao?.MenuList[1]?.cssClass == 'active' && menuClick('uw')"
                    class="menu_list  mb-2 {{dao?.MenuList[1]?.cssClass}}">
                    <i class="fa fa-list-alt mb-2" aria-hidden="true"></i><br />
                    Underwriting
                </a>
                <span
                    class="arrow_next {{dao?.MenuList[2]?.arrowCss}}"></span>

                <a title="Loss History" (click)="dao?.MenuList[2]?.cssClass == 'active' && menuClick('losshistory')"
                    class="menu_list  mb-2 {{dao?.MenuList[2]?.cssClass}}">
                    <i class="fa fa-files-o mb-2" aria-hidden="true"></i><br />
                    Loss History
                </a>

                <span
                    class="arrow_next {{dao?.MenuList[3]?.arrowCss}}"></span>

                <a title="Unit Info" (click)="dao?.MenuList[3]?.cssClass == 'active' && menuClick('vehicles')"
                    class="menu_list  mb-2 {{dao?.MenuList[3]?.cssClass}}">
                    <i class="fa fa-truck mb-2" aria-hidden="true"></i><br />
                    Unit Info
                </a>

                <span
                    class="arrow_next {{dao?.MenuList[4]?.arrowCss}}"></span>
                <a title="Driver Info" (click)="dao?.MenuList[4]?.cssClass == 'active' && menuClick('driver')"
                    class="menu_list  mb-2 {{dao?.MenuList[4]?.cssClass}}">
                    <i class="fa fa-id-card mb-2" aria-hidden="true"></i><br />
                    Driver Info
                </a>

                <span
                    class="arrow_next {{dao?.MenuList[5]?.arrowCss}}"></span>

                <a title="Product Info" (click)="dao?.MenuList[5]?.cssClass == 'active' && menuClick('product')"
                    class="menu_list  mb-2 {{dao?.MenuList[5]?.cssClass}}">
                    <i class="fa fa-tasks mb-2" aria-hidden="true"></i><br />
                    Product Info
                </a>

                <span
                    class="arrow_next {{dao?.MenuList[6]?.arrowCss}}"></span>

                <a title="Application Info" (click)="dao?.MenuList[6]?.cssClass == 'active' && menuClick('application')"
                    class="menu_list  mb-2 {{dao?.MenuList[6]?.cssClass}}">
                    <i class="fa fa-file mb-2" aria-hidden="true"></i><br />
                    Application Info
                </a>
            </div>
        </div>
        <div class="row hdng_value_cls">
            <div class="col-lg-3 text-left">
                <label><b>DOT #:</b></label>
                <label class="ml-2 value_label"
                    title="{{dotNumber}}">{{dotNumber}}</label>
            </div>
            <div class="col-lg-5">
                <label><b>Named Insured:</b></label>
                <label class="ml-2 value_label nameInsurcls" title="{{accountName}}"
                    name="NamedInsured">{{accountName}}</label>
            </div>
            <div class="col-lg-4 text-right">
                <label><b>Eff Date:</b></label>
                <label *ngIf="!dao.isDuplicateDOT" class="ml-2 value_label" dateFormat="mm/dd/yy" name="EffectiveDate"
                    title="EffectiveDate">{{!!!policyEffectiveDate ? '':(policyEffectiveDate | date:'MM-dd-yyyy')}}</label>
                <label><b> &nbsp;/ Exp Date:</b></label>
                <label *ngIf="!dao.isDuplicateDOT" class="ml-2 value_label" dateFormat="mm/dd/yy" name="ExpirationDate"
                    title="ExpirationDate">{{!!policyExpirationDate ? (policyExpirationDate | date:'MM-dd-yyyy') : ''}}</label>
            </div>
        </div>
    </div>
</div>
<p-toast position="center" key="madeChange" (onClose)="onReject('madeChange')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">

                    <button type="button" class="warnBtnSuccess float-right" title="OK" name="btnLHPopUpYes"
                        id="btnLHPopYesID" (click)="confirmChange()">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        OK
                    </button>
                </div>
                <div class="p-col-6">

                    <button type="button" class="warnBtnerror" title="Cancel" name="btnLHPopUpNo" id="btnLHPopNoID"
                        (click)="onReject('madeChange')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        CANCEL
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
