import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { ChangeDetectionService } from '../../services/changeDetectionService';
import { DAO } from '../../modal/DAO'
import { MessageService } from 'primeng/api';
import _ from 'lodash';
import { Router } from '@angular/router';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { takeUntil } from 'rxjs/operators';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-policy-container',
  templateUrl: './policy-container.component.html',
})
export class PolicyContainerComponent extends AuthenticatedUserComponent implements OnInit {
  public hasDataChanged:boolean = false;    
  @Output() notifyChidrenToRollbackPageData:EventEmitter<string>  = new EventEmitter<string>();
  constructor(
    private router: Router,
    public serviceLayer: ServiceLayer,
    public dao: DAO, public appConfig: AppConfig,
    private messageService: MessageService,
    protected authenticationService: AuthenticationService,
    public dataChangeService: ChangeDetectionService,
    private auth0UserService: Auth0UserService) {
        super(authenticationService);
    }

    ngOnInit(): void {
      if (this.appConfig.auth0Enabled)
        {
          this.auth0UserService.userLoaded$.subscribe(
            auth0User => {
              if(!!auth0User?.accessToken)
              {
                this.dao.PolicySubTemplate = 'newsubmission';
              }
            });   
        }
        else
        {
          this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe((inital:boolean) => {
            if (!!inital)
            {
              this.dao.userMail = this.userEmail;
              if(!!this.userInfo && this.router?.url?.indexOf("access_token=") < 0)
                {
                  this.dao.PolicySubTemplate = 'newsubmission';
                }  
            }
            
        });
             
        }
            
    }

  public async Ok(type, data) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
    if(data == "declineSaved" || data =="cancelSubmission"){
       window.location.href = '/home';
    }
  }
  // ===== find any change occurs in model =====
  public ngDoCheck() {
    this.hasDataChanged =this.dataChangeService.HasCurrentPolicyTemplateDataChanged();
    this.serviceLayer.restrictAutoFill();
  }

  public receiveRollbackEvent(keyword){    
    this.notifyChidrenToRollbackPageData.emit(keyword);
  }
}
