import { Component, OnInit } from '@angular/core';
import { ServiceLayer } from '../../../services/serviceLayer';
import { DAO } from '../../../modal/DAO'
import * as $ from "jquery";
declare var jQuery: any;
import {MessageService} from 'primeng/api';
import { NewSubmissionComponent } from '../../agentregistration/new-submission/new-submission.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {

  constructor(public serviceLayer: ServiceLayer, public dao: DAO,private messageService: MessageService,private newSubmissionComponent: NewSubmissionComponent) { }
  // ========= Page Load =====================
  ngOnInit(): void {    
    setTimeout(function () { (<any>$('#FirstNameID')).focus(); }, 100);
  }
  // ======== Register the User Data
  public async postRegistration(form){
    let response:any = await this.serviceLayer.postRegistration();
    form != null ? form.submitted = false : null;
    // if(response != true){
    //   this.openPopup(response == false ? 'You are not allowed to register.' : response);
    // }    
    return response;
  }
  // ========= Validate mail id =====================
  public validateEmails(){
    return this.serviceLayer.validateEmails(this.dao.RegistrationModal.email);
  }
  // ========= Open popup =====================
  public openPopup(msg:string = ''){
    this.messageService.add({key: 'myKey2', severity:'error', summary: 'Agent Registration', detail: msg != '' ? msg : this.dao.toastValidation});  
  }
  // ============ Complete auto complete ===========
  public async completeMethod(event){
    if(event.query && event.query.length > 2){
      await this.serviceLayer.GetAgencyList(event.query);
    }    
  }
}
