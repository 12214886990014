
export class MenuClickContext {
    page: string;    
    currentPageIsValid: boolean;
    currentPageDataChanged: boolean = false;
}

export interface HttpResults
 {
    isSuccess: boolean;
    responseText: string;
    isInternalUser: boolean;
    role: string
    agencyCode: string
    isEndorsementActive: boolean;
    userData: any;
}

export interface workInProgress
{
    id: string;
    nameInsured: string;  
    dotNumber: number;
    referenceNumber: string;
    effectiveDate: string;
    page: string;
    status: string;
    submissionTimeStamp: string;
    agentName: string;
    aAgencyCode: string;
    clientId: string;
    policyNumber: string;
    email: string;
    proceedAs: string;
}