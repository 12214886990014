import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FourYearLossHistoryFormGroup, LossDetailsRecord } from './FourYearLossHistoryFormGroup';
import { MessageService } from 'primeng/api';
import { dropDownOptions } from 'src/app/modal/DAO';
import { LossHistoryConstants } from 'src/app/modal/LossHistoryConstants.models';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'four-year-loss-history',
  templateUrl: './four-year-loss-history.component.html',
  styleUrls: ['./four-year-loss-history.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FourYearLossHistoryComponent implements OnInit, OnDestroy {
  @Input() fourYearLossHistoryFormGroup: FourYearLossHistoryFormGroup;
  @Input() isEdit: boolean = false;
  @Input() isSubmitted: boolean = false;
  @Output() resetLastFourYearsLosses: EventEmitter<boolean> = new EventEmitter();

  public isAddEditButtonClicked: boolean = false;
  public lossHistoryPriorCarrier: Array<dropDownOptions> = LossHistoryConstants.LossHistoryPriorCarrier;
  public typeOfLoss: Array<dropDownOptions> = LossHistoryConstants.TypeOfLosses;
  public typeOfLossStatus: Array<dropDownOptions> = LossHistoryConstants.TypeOfLossStatus;
  public maxDate: Date = new Date();

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.resetNewLossHistory();  
  }

  public validateAndAddLossesHistory(): void {
    this.isAddEditButtonClicked = true;
    if (this.fourYearLossHistoryFormGroup.valid) {
         this.fourYearLossHistoryFormGroup.moveNewLossToArray();
         this.isAddEditButtonClicked = false;
    } else {
      this.displayRequiredMessage();
    }
  }

  public validateAndUpdateLossHistory(rowIndex: number, lossRowInEdit: LossDetailsRecord, currentFG: FormGroup): void {
    this.isAddEditButtonClicked = true;
    if (currentFG.valid) {
        this.fourYearLossHistoryFormGroup.setValuesToList(rowIndex);
        this.isAddEditButtonClicked = false;
        lossRowInEdit.isEdit = false;
    } else {
      this.displayRequiredMessage();
    }
  }

  /**
   * Reset the values of the form controls for adding a new loss history row.
   */
  public resetNewLossHistory(): void {
    this.fourYearLossHistoryFormGroup.resetLossFormGroup();
  }

  public deleteLossHistory(rowIndex: number): void {
    this.fourYearLossHistoryFormGroup.deleteLoss(rowIndex);
    if(this.fourYearLossHistoryFormGroup.lossHistoryFormArray.length === 0) {
        this.resetLastFourYearsLosses.emit(true);
    }
  }

  private displayRequiredMessage() {
    this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'Loss History #', detail: 'Please fill required fields highlighted in red.' });
  }
}