<div class="col-mg-6 p-0 mt-3" *ngIf="!isLastPage">
  <span class="text-lg-right float-lg-right">
    <button type="submit" name="continue" id="continue" (click)="saveAndContinue()" class="btnsuccess" title="Continue"
      data-toggle="modal">
      <i class="fa fa-arrow-right" aria-hidden="true"></i>
      Save & Continue
    </button>
  </span>
</div>
<div class="col-mg-6 p-0 mt-3" *ngIf="isLastPage">
  <span class="text-lg-right float-lg-right">
    <button name="showPDF" id="showPDF" type="submit" class="btnsuccess mr-1" (click)="viewPdf()"
      title="View Application">
      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
      View Application
    </button>
    <button type="submit" name="continue" id="continue" (click)="saveAndContinue('finalSubmit')" class="btnsuccess"
      title="Continue" data-toggle="modal">
      <i class="fa fa-check" aria-hidden="true"></i>
      Save & Submit
    </button>
    <button type="submit" class="btnsuccess ml-1" (click)="saveAndContinue('draft')" id="saveAsDraft"
      title="Save as Draft & Close" data-toggle="modal">
      <i class="fa fa-save" aria-hidden="true"></i>
      Save as Draft & Close
    </button>
  </span>
</div>