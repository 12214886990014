import { dropdown } from './DAO';
import { Address} from './address.models'; 
import { Questionnaire} from './questionnaire.models'; 

export class DAODetailList {
  public accountNameModal: modalValues = new modalValues();
  public accountName2Modal: modalValues = new modalValues();
  public accountStreetModal: modalValues = new modalValues();
  public accountCityModal: modalValues = new modalValues();
  public accountStateModal: modalValues = new modalValues();
  public accountHeadquarterStateModal: modalValues = new modalValues();
  public accountPostalCodeModal: modalValues = new modalValues();
  public currentCarrierNameModal: modalValues = new modalValues();
  public currentCarrierPolicyNumberModal: modalValues = new modalValues();
  public mailStreetModal: modalValues = new modalValues();
  public mailCityModal: modalValues = new modalValues();
  public mailStateModal: modalValues = new modalValues();
  public mailPostalCodeModal: modalValues = new modalValues();
  public naicsCodeModal: modalValues = new modalValues();
  public billStreetModal: modalValues = new modalValues();
  public billCityModal: modalValues = new modalValues();
  public billStateModal: modalValues = new modalValues();
  public billPostalCodeModal: modalValues = new modalValues();
  public legalEntityModal: modalValues = new modalValues();
  public businessOwnerNameModal: modalValues = new modalValues();
  public businessOwnerBirthDateModal: modalValues = new modalValues();
  public businessOwnerFeinModal: modalValues = new modalValues();
  public businessClassModal: modalValues = new modalValues();
  public commodityType1Modal: modalValues = new modalValues();
  public commodityPercent1Modal: modalValues = new modalValues();
  public commodityType2Modal: modalValues = new modalValues();
  public commodityPercent2Modal: modalValues = new modalValues();
  public commodityType3Modal: modalValues = new modalValues();
  public commodityPercent3Modal: modalValues = new modalValues();
  public commodityType4Modal: modalValues = new modalValues();
  public commodityPercent4Modal: modalValues = new modalValues();
  public commodityType5Modal: modalValues = new modalValues();
  public commodityPercent5Modal: modalValues = new modalValues();
  public tempbillStateModal: modalValues = new modalValues();
  public policyEffectiveDateModal: modalValues = new modalValues();
  public policyExpirationDateModal: modalValues = new modalValues();
  public contactTypeModal: modalValues = new modalValues();
  public contactDetailModal: modalValues = new modalValues();
  public mailAddressIndicatorModal: modalValues = new modalValues();
  public trailerInterchangeIndicatorModal: modalValues = new modalValues();
  public vehicleGarageStateIndicatorModal: modalValues = new modalValues();
  public cargoIndicatorModal: modalValues = new modalValues();
  public iftaVehiclesMileageModal: modalValues = new modalValues();
  public iftaFleetSizeModal: modalValues = new modalValues();
  public selectedFleetSizeModal: modalValues = new modalValues();
  public vehicleTravelModal: modalValues = new modalValues();
  public vehicleMileModal: modalValues = new modalValues();
  public currentCarrierMCNumberModal: modalValues = new modalValues();
  public currentCarrierInsuredInYearsModal: modalValues = new modalValues();
  public currentCarrierInsuredInMonthsModal: modalValues = new modalValues();
  public hasContinuousAutoLiabilityModal: modalValues = new modalValues();
  public doesFileIftaModal: modalValues = new modalValues();
}


export class modalValues {
  public modal: any;
  public IsHidden: boolean = false;
  public IsRequired: boolean = false;
  public placeHolder: string = '';
  public dropdown: Array<dropdown> = new Array<dropdown>();
  public isShow: boolean = false;
  public IsExistsData: boolean = false;
  public minDate: Date;
  public maxDate: Date;
  public IsOverride: boolean = false;
  public status: string = 'true';
}


export type Contact = {
  id: number, 
  type: string, 
  details: string, 
  IsOverridecontactTypeModal:boolean,
  IsOverridecontactDetailModal: boolean 
};


export class PolicyHolderValueParser {

    private myAddress: Address | null = null;
    constructor(public myQuestionnaire: Questionnaire)
    {
    }

    get accountAddress(): Address
    {
      if (this.myAddress == null) {
        this.myAddress = new Address()
        this.myAddress.street = this.myQuestionnaire.findQuestion("accountStreet")?.answer;
        this.myAddress.city = this.myQuestionnaire.findQuestion("accountCity")?.answer;
        this.myAddress.state = this.myQuestionnaire.findQuestion("accountState")?.answer;
        this.myAddress.postalCode = this.myQuestionnaire.findQuestion("accountPostalCode")?.answer;
      }
      return this.myAddress;
    }

    get mailAddress(): Address
    {
        return new Address()
        {
          Street:  this.myQuestionnaire.findQuestion("mailState")?.answer;
          City:  this.myQuestionnaire.findQuestion("mailCity")?.answer;
          State:  this.myQuestionnaire.findQuestion("mailState")?.answer;          
          PostalCode:  this.myQuestionnaire.findQuestion("mailPostalCode")?.answer; 
        };    
    }

    get billAddress(): Address
    {
        return new Address()
        {
          Street:  this.myQuestionnaire.findQuestion("billState")?.answer;
          City:  this.myQuestionnaire.findQuestion("billCity")?.answer;
          State:  this.myQuestionnaire.findQuestion("billState")?.answer;          
          PostalCode:  this.myQuestionnaire.findQuestion("billPostalCode")?.answer; 
        };    
    }
 
    get dotNumber(): string {
      return this.myQuestionnaire.findQuestion("accountDot").answer;
    }

    get referenceNumber(): string {
      return this.myQuestionnaire.findQuestion("number").answer;          
    }

    get accountName(): string {
      return this.myQuestionnaire.findQuestion("accountName").answer;          
    }

    get accountName2(): string {
      return this.myQuestionnaire.findQuestion("accountName2").answer;          
    }

    get commodityPercent1(): number {
      return this.myQuestionnaire.findQuestion("commodityPercent1").answer;          
    }

    get commodityType1(): string {
      return this.myQuestionnaire.findQuestion("commodityType1").answer;          
    }

    get businessOwnerBirthDate(): string {
      return this.myQuestionnaire.findQuestion("businessOwnerBirthDate").answer;          
    }
}
