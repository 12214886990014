<div class="row mb-1"
*ngIf="municipalLocation?.enabled || municipalLimit.enabled">
    <div class="col-lg-12">
        <label><b><u>Kentucky Municipal Tax/Surcharge</u></b></label>
    </div>
</div>
<dropdown-question
    *ngIf="municipalLocation?.enabled"
    #municipalLocationdropdown
    [name]="name"
    [question]="municipalLocation"
    [disabled]="defaultDisable"
    [requiredAmtOrDecline]="municipalLocation.required"
    [isFormSubmitted]="isFormSubmitted"
    (changed)="onValueChanged()">
</dropdown-question>

<radiobutton-question
    *ngIf="municipalLimit?.enabled"
    #municipalCityLimitRadiobutton
    [name]="name"
    [question]="municipalLimit"
    [required]="municipalLimit.required"
    [disabled]="defaultDisable"
    [radiovalue1]="'Y'"
    [radiovalue2]="'N'"
    (clicked)="onValueChanged()"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>