import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs-compat';
import { AppConfig } from '../app.config';

@Injectable()
export class Settings {

    public BSBPayloadDetails: any;
    public serviceUrl: string = "not set";//<-- for Local https://localhost:44387/, For Test --> http://usilg01-ist109:65007/
    public authToken: string = 'not set';  //<-- is Local 7BdQ_CiAje7v1UcAwZTOQ2TbJHoa  is Test -->//'1PAUP9dqvfiqtcxDB8k_of5rWF0a'
    public redirectUrl: string = "not set"; //<-- for Local http://localhost:4200/home, For Test --> http://usilg01-ist109:65007/home
    public configId: string = "SIT";
    public providerUrl: string = "not set";
    public apiBaseUrl: string = "";
    public unauthenticatedUrl: string = "/unauthenticated";
    public unauthorizedRedirect: string = "/unauthorized";

    constructor(public appConfig: AppConfig) {
        this.getConfig();
    }
    getConfig() {
        const auth0Enabled = this.appConfig.auth0Enabled;
        if (auth0Enabled)
        {
            this.serviceUrl = this.appConfig.serviceUrlKong;
            this.authToken = "";
            this.redirectUrl = "";
            this.providerUrl = "";
        }
        else
        {
            this.serviceUrl = this.appConfig.config["serviceUrl"];
            this.authToken = this.appConfig.config["authToken"];
            this.redirectUrl = this.appConfig.config["redirectUrl"];
            this.providerUrl = this.appConfig.config["providerUrl"]
        }     
    }

    initializeCommonData() {
        return this.BSBPayloadDetails = [
            {
                componentName: "policy-holder",
                functionality: "getDOTNumber",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "dot/questionnaire/by/company/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "endorsement",
                functionality: "postEndorsementWIPFilter",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "endorsementWIP",
                    method: "POST"
                }
            },
            {
                componentName: "endorsement",
                functionality: "submissionByPolicyNumber",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/submissionByPolicyNumber/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "endorsement",
                functionality: "getQuestionAnswers",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "questionAnswers/endorsementID/{0}/groupKey/{1}",
                    method: "GET"
                }
            },
            {
                componentName: "endorsement",
                functionality: "getEndorsementQuestionGroup",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "endorsementQuestionGroups/endorsementID/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "endorsement",
                functionality: "updateEndorsementStatus",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "endorsement/endorsementId/{0}/status/{1}",
                    method: "PUT"
                }
            },
            {
                componentName: "endorsement",
                functionality: "postQuestionAnswers",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "endorsement/questionAnswers",
                    method: "POST"
                }
            },
            {
                componentName: "endorsement",
                functionality: "upsertEndorsementDetail",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "upsert/endorsement",
                    method: "POST"
                }
            },
            {
                componentName: "endorsement",
                functionality: "getAllEndorsementQuestionAnswerGroups",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "endorsementQuestionAnswerGroups/endorsementId/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "endorsement",
                functionality: "endorsementPDF",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "endorsement/PDF",
                    method: "POST"
                }
            },
            {
                componentName: "policy-holder",
                functionality: "getDOTDetails",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "dot/questionnaire/by/company/{0}/email/{1}/isDOTExists/{2}/page/{3}/actionDOT/{4}/isWIPCall/{5}/savePayload/{6}",
                    method: "POST"
                }
            },
            {
                componentName: "documentLibrary",
                functionality: "GetFAQuestions",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/GetFAQuestions",
                    method: "GET"
                }
            },
            {
                componentName: "common",
                functionality: "getMGBInfo",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "account/post/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "policy-holder",
                functionality: "savePolicyHolder",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "policyholder/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "under-writer",
                functionality: "saveUnderwriter",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "uw/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "loss-history",
                functionality: "getLossHistory",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/loss/history",
                    method: "GET"
                }
            },
            {
                componentName: "Policy",
                functionality: "CancelPolicy",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "cancel/policy/{0}/email/{1}/status/{2}",
                    method: "GET"
                }
            },
            {
                componentName: "upload",
                functionality: "FileUpload",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "upload/file/referenceNumber/{0}",
                    method: "POST"
                }
            },
            {
                componentName: "loss-history",
                functionality: "saveLossHistory",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "loss/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "vehicle",
                functionality: "saveVehicle",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "vehicle/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "driver",
                functionality: "saveDriver",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "driver/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "delete",
                functionality: "FileUpload",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "delete/file/guid/{0}/",
                    method: "GET"
                }
            },
            {
                componentName: "workInProgress",
                functionality: "getWorkInProgress",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/workInProgress/by/email/{0}/",
                    method: "GET"
                }
            },
            {
                componentName: "primaryDOT",
                functionality: "getPrimaryData",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "check/clearance/by/dot",
                    method: "POST"
                }
            },
            {
                componentName: "primaryDOT",
                functionality: "getFleetSize",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/dropdown/fleetsize",
                    method: "GET"
                }
            },
            {
                componentName: "login",
                functionality: "getLoginModel",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/login",
                    method: "GET"
                }
            },
            {
                componentName: "login",
                functionality: "postLoginModel",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "post/login",
                    method: "POST"
                }
            }, {
                componentName: "Registration",
                functionality: "GetAuthToken",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/authtoken",
                    method: "GET"
                }
            }, {
                componentName: "Registration",
                functionality: "PostRegistration",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "post/registration/company/{0}/agencyCode/{1}",
                    method: "POST"
                }
            }, {
                componentName: "Login",
                functionality: "CheckUser",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/checkuserclaims/by/company/{0}/email/{1}/",
                    method: "GET"
                }
            }, {
                componentName: "Registration",
                functionality: "GetAgencyName",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/agency/by/{0}",
                    method: "GET"
                }
            }, {
                componentName: "Clearance",
                functionality: "CheckSubmissionModel",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "dot/questionnaire/by/company/{0}/actionCode/{1}",
                    method: "GET"
                }
            }, {
                componentName: "DOT",
                functionality: "Submission",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "post/submission/by/company/{0}/email/{1}/actionCode/{2}",
                    method: "POST"
                }
            }, {
                componentName: "loss-history",
                functionality: "getFileDetails",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/file/guid/{0}/referenceNumber/{1}",
                    method: "GET"
                }
            }, {
                componentName: "product",
                functionality: "saveProduct",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "product/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            }, {
                componentName: "application",
                functionality: "saveAppInfo",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "application/post/json/by/company/{0}/email/{1}/",
                    method: "POST"
                }
            }, {
                componentName: "application",
                functionality: "getHTML",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "Stream/Html/To/PDF/company/{0}/email/{1}/",
                    method: "POST"
                }
            },
            {
                componentName: "dot",
                functionality: "getReferenceNo",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "getReferenceNumber/dot/{0}/email/{1}/company/{2}/retailerSubCode/{3}",
                    method: "GET"
                }
            },
            {
                componentName: "dot",
                functionality: "checkIsExistsDOT",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "check/isdot/submitted/dot/{0}/email/{1}/agencyCode/{2}",
                    method: "GET"
                }
            },
            {
                componentName: "application",
                functionality: "getAgencyandBranchCode",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/agency/details/by/company/{0}/email/{1}",
                    method: "GET"
                }
            }, {
                componentName: "workInProgress",
                functionality: "getWIPFilter",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/wip/filter/namedInsured/{0}/dot/{1}/fromEffectiveDate/{2}/toEffectiveDate/{3}/status/{4}/email/{5}/isAgencyView/{6}/company/{7}/isReset/{8}",
                    method: "GET"
                }
            }, {
                componentName: "workInProgress",
                functionality: "postWIPFilter",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "post/wip/filter",
                    method: "POST"
                }
            },
            {
                componentName: "workInProgress",
                functionality: "GetAgencyWIPData",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/agencyWIP/by/company/{0}/email/{1}",
                    method: "GET"
                }
            },
            {
                componentName: "DataAgreement",
                functionality: "GetDataAgreementDocument",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/data/agreement/document/id/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "Agent",
                functionality: "GetAllUsers",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/all/users/by/company/{0}/email/{1}",
                    method: "GET"
                }
            },
            {
                componentName: "Agent",
                functionality: "DeleteUser",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "delete/user/by/claimId/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "Agent",
                functionality: "RolesDropdown",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/roles",
                    method: "GET"
                }
            },
            {
                componentName: "Agent",
                functionality: "UpdateUserProfile",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "update/user/by/company/{0}/securityId/{1}/firstName/{2}/lastName/{3}/email/{4}",
                    method: "PUT"
                }
            },
            {
                componentName: "Agent",
                functionality: "getClaimEmptyModel",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/claim/model",
                    method: "GET"
                }
            },
            {
                componentName: "Agent",
                functionality: "GetAllAgencies",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/all/agencies/by/company/{0}/email/{1}",
                    method: "GET"
                }
            },
            {
                componentName: "Agent",
                functionality: "postAgencyRights",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "post/agency/rights/by/company/{0}/email/{1}",
                    method: "POST"
                }
            },
            {
                componentName: "workInProgress",
                functionality: "GetAllStatus",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/all/status",
                    method: "GET"
                }
            },
            {
                componentName: "workInProgress",
                functionality: "GetSiteBanner",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/sitebanner",
                    method: "GET"
                }
            },
            {
                componentName: "InternalAdmin",
                functionality: "GetAndUpdateBannerList",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get-and-update/bannerList",
                    method: "GET"
                }
            },
            {
                componentName: "InternalAdmin",
                functionality: "UpsertBannerInfo",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "upsert/bannerInfo/id/{0}",
                    method: "POST"
                }
            },
            {
                componentName: "InternalAdmin",
                functionality: "DeleteBannerInfo",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "delete/bannerInfo/id/{0}",
                    method: "DELETE"
                }
            },
            {
                componentName: "documentLibrary",
                functionality: "GetDocumentStates",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/documentStates",
                    method: "GET"
                }
            },
            {
                componentName: "documentLibrary",
                functionality: "GetSpecificStateDocument",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/specific/state/documents/state/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "vehicle",
                functionality: "saveVehicleDraft",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "vehicle/draft/json/by/email/{0}/",
                    method: "POST"
                }
            },
            {
                componentName: "SampleExcelTemplate",
                functionality: "GetSampleExcelTemplate",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/sampleExcel/template/id/{0}",
                    method: "GET"
                }
            },
            {
                componentName: "DocumentLibrary",
                functionality: "GetStateMapImage",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "get/stateMap/image",
                    method: "GET"
                }
            },
            {
                componentName: "upload",
                functionality: "ImageUpload",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "upload/image",
                    method: "POST"
                }
            },
            {
                componentName: "workInProgress",
                functionality: "changePassword",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "send/change/password/mail",
                    method: "POST"
                }
            },
            {
                componentName: "GlobalEventHandler",
                functionality: "logWebServiceCallEvents",
                Header: "",
                endPointInfo: {
                    endPointUrl: this.serviceUrl + "log/events",
                    method: "POST"
                }
            }
        ]
    }
    getBSBPayloadDetails(componentName, functionality, payload) {
        let result = this.initializeCommonData().filter(data => data.componentName == componentName && data.functionality == functionality);
        //=====set value into url for GET call
        if (result != null && result.length > 0 && (result[0].endPointInfo.method == "GET" || result[0].endPointInfo.method == "POST" || result[0].endPointInfo.method == "PUT" || result[0].endPointInfo.method == "DELETE") && payload != null && payload.length) {
            payload.forEach((data, index) => {
                result[0].endPointInfo.endPointUrl = result[0].endPointInfo.endPointUrl.replace("{" + index + "}", data);
            })
        }

        return (result != null && result.length > 0) ? result[0] : null;
    }

}