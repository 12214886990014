<div class="container">
    <div class="row justify-content-center" >
        <div class="col-lg-4 ">
            <div class="login_cls">
                <form #f="ngForm" style="width:100%" (ngSubmit)="f.valid ? postLogin() : openPopup()">
                    <h2 class="text-center">Welcome</h2>
                    <div class="my-4 pt-2">
                        <input
                            class="{{f.submitted && !UserID?.valid ? 'form-control validate_error_cls' : 'form-control'}}"
                            [(ngModel)]="dao.LoginModal.userID" placeholder="User ID" type="text" name="UserID"
                            #UserID="ngModel" id="UserID" required title="User ID">
                    </div>
                    <div class="my-4 pt-2">
                        <input
                            class="{{f.submitted && !Password?.valid ? 'form-control validate_error_cls' : 'form-control'}}"
                            [(ngModel)]="dao.LoginModal.password" placeholder="Password" type="Password" name="Password"
                            #Password="ngModel" required title="Password" id="PasswordID">
                    </div>
                    <div class="my-4 pt-2">
                        <button class="login_button" title="Log In" type="submit" name="LogIn" id="LogInID">Log In ></button>
                    </div>
                    <div class="my-4 pt-2">
                        <span class="regis_label">New user? <a title="Register"
                            name="Newuser" id="NewuserID" (click)="dao.PolicySubTemplate = 'registration'"><b class="addcomm" title="Register">Register</b></a> for an
                            account.</span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <p-toast key="myKey1" position="top-center"></p-toast>
</div>