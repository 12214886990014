import { Validator, FormGroup, AbstractControl, NgModelGroup, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit, forwardRef, Input } from '@angular/core';

@Directive({
  selector: '[uniqueValuedControls]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: UniqueValueValidatorDirective, multi: true }
  ]
})
export class UniqueValueValidatorDirective {

  @Input("uniqueValuedControls") controlNameKeyword: string;
  constructor() { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    if (this.controlNameKeyword == undefined || this.controlNameKeyword == "")
      return null;
    let invalidValues = [];
    var allDropDowns = Object.keys(c.value).filter(x => x.indexOf(this.controlNameKeyword) >= 0);
    for (let i = 0; i < allDropDowns.length; i++) {
      let curVal = c.value[allDropDowns[i]];
      for (let j = i + 1; j < allDropDowns.length; j++) {
        if (curVal == c.value[allDropDowns[j]]) {          
          if (invalidValues.indexOf(curVal)<0)
            invalidValues.push(curVal);          
        }
      }
    }
    if(invalidValues.length>0)
      return { 'uniqueValue': true, 
      'errmsg': 'A dropdown value can only be used once', 
      'invalidUniqueValues': invalidValues }
    return null;
  }
}
