import { Component, OnInit, ViewChild, OnDestroy, Input, EventEmitter } from '@angular/core';
import { ApplicationInfoModel, DAO } from '../../modal/DAO'
import { ServiceLayer } from '../../services/serviceLayer';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../../app.component';
import { menuComponent } from '../../sharedComponents/menu/menu.component';
declare var jQuery: any;
import _ from 'lodash';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/services/commonService';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appinfo',
  templateUrl: './application-info.component.html'
})
export class ApplicationInfoComponent implements OnInit, OnDestroy, IBasePage {

  constructor(public serviceLayer: ServiceLayer,
    public dao: DAO,
    private messageService: MessageService,
    public appComponent: AppComponent,
    public menuComponent: menuComponent,
    public fileuploadService: FileuploadService,
    private commonService: CommonService,
    private router: Router
  ) { }
  public text: string = '';
  public currentFile;
  public currentFileIndex;
  public currentDocType: string = '';
  public displayIftaFileUpload: boolean = false;
  public PDFURL: any = {};
  private menuClickSubscription: Subscription;
  private apiCallSubscription: Subscription;
  private RollbackUwDataSubscription: Subscription;
  @Input() RollbackData: EventEmitter<string>;
  public applicationInfoModel: ApplicationInfoModel = new ApplicationInfoModel();
  public initialapplicationInfoModel: ApplicationInfoModel = new ApplicationInfoModel();
  public fileNetAllowedFileExtensions = Constants.FileNetAllowedFileExtensions.toString();
  @ViewChild('applicationForm') applicationForm: NgForm;  
  ngOnInit(): void {
    jQuery('.p-fileupload-content').on('click', function (event) {
      this.fileUpload();
    });
    setTimeout(() => {
      this.menuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
        (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
      );
      this.RollbackUwDataSubscription = this.RollbackData?.subscribe(
        (page) => this.onRollback(page)
      )
      this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
        (dotSubmissionDetails) => {
          if (!!dotSubmissionDetails) {
            this.initializeData(dotSubmissionDetails);
          }
        });
    }, 100);
  }

  menuClicked(menuClickContext: MenuClickContext) {
    if (menuClickContext != null && menuClickContext.page != "application") {
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = !_.isEqual(this.applicationInfoModel, this.initialapplicationInfoModel)
    }
  }

  isPageValid(): boolean {
    return (!!this.applicationForm?.valid) && this.serviceLayer.checkDateValidation();
  }

  onRollback(currentTempage: string) {
    if (currentTempage == "application") {
      this.applicationInfoModel = _.cloneDeep(this.initialapplicationInfoModel);
      this.setApplicationData();
    }
  }

  // Initialize Data
  public async initializeData(apiResponse: any) {
    let applicationQsnr = apiResponse.applicationQsnr;
    if (applicationQsnr != null) {
      this.applicationInfoModel = new ApplicationInfoModel();
      Object.keys(this.applicationInfoModel.applicationDetails).forEach(key => {
        this.applicationInfoModel.applicationDetails[key] = applicationQsnr.findQuestion(key);
      });
      
      var lossRunFiles = apiResponse.losssHistoryQuestionnaire?.findQuestions("lossRunsUpload");
      if(lossRunFiles != null && lossRunFiles.length>0){
        this.applicationInfoModel.lossRunDocumentFiles = [];
        lossRunFiles.forEach(item => {
          if(!!item.answer)
          {
            let file = {"guid":item.answer};
            this.applicationInfoModel.lossRunDocumentFiles.push(file);
          }
        });
      }
      
      var iftaDocuments = applicationQsnr.questionAnswer.filter(x => x.key == "iftaDocumentId");
      if (iftaDocuments != null && iftaDocuments.length > 0) {
        this.applicationInfoModel.iftaDocumentFiles = [];
        let referenceNumber = this.dao.DOTList.dotQsnr.findQuestion('number').answer;
        await Promise.all(iftaDocuments.map(async (file) => {
          if (!!file.answer) {
            let fileToUpload = await this.fileuploadService.getFileDetails(file.answer, referenceNumber);
            fileToUpload.progressValue = 100;
            fileToUpload.size = fileToUpload.fileSize;
            fileToUpload.filesize = this.fileuploadService.bytesToSize(fileToUpload.fileSize);
            fileToUpload.guid = fileToUpload.id;
            this.applicationInfoModel.iftaDocumentFiles.push(fileToUpload);
          }
        }));
        this.initialapplicationInfoModel = _.cloneDeep(this.applicationInfoModel);
        if (this.dao.IsBackButtonClicked == true) {
          this.dao.IsBackButtonClicked = false;
        }
      }

      this.displayIftaFileUpload = this.shouldIftaFileUploadDisplay();
    }
  }
  // ========= Delete File =====================
  deleteFile(item, i) {
    this.currentFile = item;
    this.currentFileIndex = i;
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'Delete', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
  }
  // ========= Confirm Delete =====================
  confirmDelete() {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
    this.deletingDocuments();
  }
  deletingDocuments() {
    let uploadArray = this.applicationInfoModel.iftaDocumentFiles;
    let payload = { "urlData": [this.currentFile.guid] }
    this.commonService.get('delete', 'FileUpload', payload).subscribe((event: any) => {
      uploadArray.splice(this.currentFileIndex, 1);
    });
  }
  // ========= Upload the file=====================
  async onUpload(event) {
    let referenceNumber : string = this.dao.DOTList.dotQsnr?.findQuestion('number')?.answer;
    let response = this.fileuploadService.onUploadingFiles(event.files, this.applicationInfoModel.iftaDocumentFiles, 'iftaDocument',referenceNumber);
    if (!!response) this.openValidPopup(response);
  }
  // ========= Click upload =====================
  clickUpload(event, docType) {
    if (event && event.target && event.target.className == 'p-fileupload-content' && event.currentTarget.className.includes(docType)) {
      this.fileUpload(docType);
    }
  }
  // ========= Open Validation Popup =====================
  public openValidPopup(msg: string = '') {
    this.messageService.add({ key: 'applicationToastKey', severity: 'error', summary: 'Application info', detail: msg != '' ? msg : this.dao.toastValidation, sticky: true });
    this.dao.isClickFromMenu = false;
  }
  // ========= File Upload =====================
  fileUpload(docType) {
    this.currentDocType = docType;
    jQuery('.' + docType + ' .p-fileupload-choose').click();
  }
  public async saveAndContinue(type: string = '') {
    if (this.applicationForm.form.valid && this.iftaDocsSatisfied) {
      if (type == 'Draft') {
        this.saveData(type);
      }
      else if (type == 'OpenPdf') {
        this.openPDF();
      }
      else {
        let isPolicyDateRangeValid = this.serviceLayer.checkDateValidation();
        if (!!!isPolicyDateRangeValid) {
          document.body.style.pointerEvents = "none";
          this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission' });
        }
        else {
          document.body.style.pointerEvents = "none";          
          let iftaDocWarning = this.applicationInfoModel.iftaDocumentFiles.length == 0 && this.displayIftaFileUpload;
          let lossRunDocWarning = this.dao.DOTList.losssHistoryQuestionnaire.questionAnswer.filter(c => c.key == "lossRunsUpload" && !!c.answer && c.answer.length >= 32).length == 0;
          let warningData = {"iftaDocWarning": iftaDocWarning, "lossRunDocWarning": lossRunDocWarning };
          this.messageService.add({ key: 'confirmModal', sticky: true, severity: 'warn', summary: 'Application info', data: warningData});          
        }
      }
    }
    else {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        this.appComponent.addToast('error', 'Application Info', 'Please fill the required fields');
      }
      this.dao.isClickFromMenu = false;
    }
  }
  // ===== Open Application PDF=============
  public async openPDF() {
    await this.setApplicationData();
    await this.serviceLayer.setDropdownOptions();
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, false, this.dao.PolicyTemplate], "payloadData": this.dao.DOTList }
    let response = await this.commonService.post('application', 'getHTML', payload).toPromise();
    this.applicationInfoModel.pdfDatas = response
    this.serviceLayer.openStreamPDF(this.applicationInfoModel.pdfDatas.pdfStream);
  }

  // ==============set App info data ==========
  public setApplicationData(): void {
    let applicationQsnr = this.dao.DOTList.applicationQsnr;
    let tempApplicationQnnr = [];
    let applicationKeys = [];
    if (applicationQsnr != null && applicationQsnr.questionAnswer != null) {
      applicationKeys = _.uniq(applicationQsnr.questionAnswer.map(c => c.key));
    }
    if (applicationKeys != null && applicationKeys.length > 0 && !!this.applicationInfoModel?.applicationDetails) {
      applicationKeys.forEach(key => {
        if (!!this.applicationInfoModel.applicationDetails[key]) {
          if (key == 'iftaDocumentId' || key == 'lossRunDocumentId') {
            let attachmentFiles = this.applicationInfoModel[key == 'iftaDocumentId' ? 'iftaDocumentFiles' : 'lossRunDocumentFiles'];
            if (attachmentFiles != null && attachmentFiles.length > 0) {
              attachmentFiles.forEach((doc, i) => {
                this.applicationInfoModel.applicationDetails[key].answer = doc.guid;
                this.applicationInfoModel.applicationDetails[key].fileName = doc.fileName;
                this.applicationInfoModel.applicationDetails[key].rowIndex = i + 1;
                tempApplicationQnnr.push(_.cloneDeep(this.applicationInfoModel.applicationDetails[key]));
              });
            }
          }
          else {
            tempApplicationQnnr.push(this.applicationInfoModel.applicationDetails[key]);
          }
        }
        else {
          let questionAnswer = applicationQsnr.findQuestion(key);
          tempApplicationQnnr.push(questionAnswer);
        }
      });
      applicationQsnr.questionAnswer = tempApplicationQnnr;
    }
  }

  public async saveData(type: string = '') {    
      this.dao.DOTList.finalSubmitPage = 'application';
      let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, false, this.dao.PolicyTemplate], "payloadData": this.dao.DOTList }
      this.setApplicationData();
      this.serviceLayer.modifyDateObject(payload);
      if (type == 'Draft') {
        this.savePayloadForDraft();
      } else {
        this.savePayloadForPostSubmission();
      }    
  }

  private async savePayloadForDraft() {
    let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, false, this.dao.PolicyTemplate], "payloadData": this.dao.DOTList }
    await this.serviceLayer.SavePayloadToAPI(payload).then((data: any) => { });
    if (this.dao.isClickFromMenu) {
      this.dao.navigatePage = 'application';
      this.dao.isSubmitForm = false;
      this.dao.isClickFromMenu = false;
      this.serviceLayer.setActiveMenu();
    }
    else {
      this.appComponent.addToast('success', 'Application Info', 'Saved Successfully');
      setTimeout(() => {
        this.dao.PolicyTemplate = '';
        this.dao.PolicySubTemplate = 'newsubmission';
        this.router.navigateByUrl('/home');  
      }, 1000);
    }
  }
  private async savePayloadForPostSubmission() {
    let payloadforAgencyCode = { "urlData": [this.dao.userCompany, this.dao.userMail], "payloadData": this.dao.DOTList };
    return await this.serviceLayer.getAgencyBranchCode(payloadforAgencyCode).then(async (agencydata: any) => {
      if (!!agencydata) {

        let agencyCode = this.dao.DOTList.applicationQsnr?.findQuestion('agencyCode');
        let branchCode = this.dao.DOTList.applicationQsnr?.findQuestion('branchCode');
        if (!!agencyCode) agencyCode.answer = this.dao.DOTList.dotQsnr?.findQuestion('agencyCode')?.answer;
        if (!!branchCode) branchCode.answer = agencydata.branchCode

        let payloadData = { "urlData": [this.dao.userCompany, this.dao.userMail, false, this.dao.PolicyTemplate, 'POST_SUBMISSION', this.dao.IsWIPCall, true], "payloadData": this.dao.DOTList }
        await this.serviceLayer.SavePayloadToAPI(payloadData).then((data: any) => { });
        return this.serviceLayer.getDOTDetailsService(payloadData).then(async (data: any) => {
          if (!data) {
            return;
          }
          this.serviceLayer.checkApplicationResult(data);
        });
      }
    });
  }
  // ========= On Reject =====================
  public onReject(type) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
    if (type == 'confirmation') {
      this.dao.PolicyTemplate = '';
      this.dao.PolicySubTemplate = 'newsubmission';
      this.router.navigateByUrl('/home');
    }
  }
  // ========= Cancel my submission =====================
  public CancelmySubmission() {
    return this.serviceLayer.CancelmySubmission();
  }
  // ========= Cancel my submission popup =====================
  public cancelMySubmissionPopUp() {
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
  }
  // confirm Save============
  public confirmSave() {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
    this.saveData();
  }
  // ========= On Back =====================
  public onBack(page) {
    this.dao.IsBackButtonClicked = true;
    return this.menuComponent.menuClick(page);
  }
  
  ok() {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
  }

  /**
   * Determines if IFTA file upload should display. 
   * If not New Trucking, then always display file upload
   * If New Trucking, then do not display file upload if doesFileIfta is 'No'
   */
  public shouldIftaFileUploadDisplay(): boolean {
    const policyEffectiveDate = this.dao.DOTList.accountInfoQsnr.findQuestion('policyEffectiveDate').answer;
    const accountState = this.dao.DOTList.accountInfoQsnr.findQuestion('accountState').answer;
    if(this.serviceLayer.getPolicyHolderVersionNTM(policyEffectiveDate, accountState) &&
      this.dao.DOTList.accountInfoQsnr.findQuestion('doesFileIfta').answer === 'No') {
      return false;
    }

    return true;
  }

  public get iftaDocsSatisfied():boolean {
    if (!!this.applicationInfoModel.applicationDetails.iftaDocumentId
            && this.applicationInfoModel.applicationDetails.iftaDocumentId.required){            
        if (this.applicationInfoModel.iftaDocumentFiles?.length > 0)
            return true;
        else
            return false;
    }
    return true;
  }

  public ngOnDestroy(): void {
    if (!!this.menuClickSubscription)
      this.menuClickSubscription.unsubscribe();
    if (!!this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.apiCallSubscription = null;
    }
    if (!!this.RollbackUwDataSubscription) {
      this.RollbackUwDataSubscription.unsubscribe();
      this.RollbackUwDataSubscription = null;
    }
  }
}
