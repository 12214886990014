<div class="row mb-1">
    <div class="col-lg-12">
        <a class="drag_and_drop" (click)="fileUpload()" name="fileUpload" id="fileUploadID">
            <h3 class="mb-0">Drag & Drop Files Here</h3>
            <p class="mb-0">or</p>
            <h6>Browse Files</h6>
        </a>
        <p-fileUpload class="UploadCls"  id="edsLossLetter" name="edsLossLetter"
            (onSelect)="onUpload($event)" (click)="clickUpload($event)"
            [accept]="fileNetAllowedFileExtensions" multiple="multiple" maxFileSize="15728640"
            invalidFileTypeMessageSummary="{0}: Invalid file type.">
        </p-fileUpload>
        <div class="row" *ngFor="let item of uploadedDocuments; let i = index;">
            <div class="col-lg-12 mt-3">
                <div class="upload_file_icon mr-3">
                    <i class="fa fa-file-o" aria-hidden="true"></i>
                </div>
                <div class="upload_file_details">
                    <div class="upload_file_name">{{ item?.fileName }}</div>
                    <span class="row text-right float-right upload_size_details">
                        <b class="color_dark">({{ item?.filesize }})</b>
                        <i class="fa fa-times-circle-o ml-2 fa-lg color_green pointerCur"
                            title="DeleteFile" (click)="deleteFile(item, i)"
                            aria-hidden="true"></i>
                    </span><br>
                    <p-progressBar [value]="item?.progressValue"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="small color_grey">The combined file size limit is 15 MB.</div>
    </div>
</div>
<p-toast position="center" key="remove" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle"></i>
                <h4 class="toast-summary">{{ message.summary }}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnonRejectYesNo"
                        id="btnonRejectYesID" (click)="confirmDelete(message.data)">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="btnVonRejectNo"
                        id="btnVonRejectNoID" (click)="onReject('remove')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>