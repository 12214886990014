import { emptyModal } from "./DAO";
import { QuestionOption } from "./questionnaire.models";

export class DriverModel {
  public currentDriver: DriverDetails = new DriverDetails();
  public driverDefault: DriverDetails = new DriverDetails();
  public driverIndex: number = 0;
  public driverPage: number = 1;
  public driverList: any = [];
  public minOriginalLicenseDate: Date = new Date();
}

export class DriverDetails {
  public firstName: emptyModal = new emptyModal();
  public middleName: emptyModal = new emptyModal();
  public lastName: emptyModal = new emptyModal();
  public dob: emptyModal = new emptyModal();
  public licenseStateCode: emptyModal = new emptyModal();
  public licensePermitNumber: emptyModal = new emptyModal();
  public originalLicenseDate: emptyModal = new emptyModal();
  public dateOfHire: emptyModal = new emptyModal();
  public suffix: emptyModal = new emptyModal();
  public yearsOfExperience: emptyModal = new emptyModal();
  public rowIndex: number = 1;
  public isReviewed: boolean = false;
  public isValidDriver: boolean = true;
}

export class DriverConstants {
  public static NameSuffix: QuestionOption[] = [
    {
      'code': '',
      'helpText': '',
      'label': 'Select Suffix',
      'sequence': 1,
      'value': '',
      'isEligible': false
    },
    {
      'code': 'JR',
      'helpText': '',
      'label': 'Jr.',
      'sequence': 2,
      'value': 'JR',
      'isEligible': false
    },
    {
      'code': 'SR',
      'helpText': '',
      'label': 'Sr.',
      'sequence': 3,
      'value': 'SR',
      'isEligible': false
    },
    {
      'code': 'II',
      'helpText': '',
      'label': 'II',
      'sequence': 4,
      'value': 'II',
      'isEligible': false
    },
    {
      'code': 'III',
      'helpText': '',
      'label': 'III',
      'sequence': 5,
      'value': 'III',
      'isEligible': false
    },
    {
      'code': 'IV',
      'helpText': '',
      'label': 'IV',
      'sequence': 6,
      'value': 'IV',
      'isEligible': false
    }
  ];
}

