<div class="vehicles_list mb-4" *ngIf="!!itemList">
    <h5 class="float-left pr-4 mb-3">{{ header + " " + itemList.items.length }}</h5>

    <p-checkbox name="masterDeleteCheckBox_{{ listName }}" id="masterDeleteCheckBox_{{ listName }}" type="checkbox"
        binary="true" [(ngModel)]="isMasterDeleteCheckBoxChecked" (onChange)="onChangeMasterCheckBox($event)">
    </p-checkbox>

    <i class="fa fa-trash remove_icon" style="padding-left: 10px;" title="Delete" id="masterDeleteButton_{{ listName }}"
        type="button" (click)="onMasterDeleteButtonClick()"></i>

    <i class="fa fa-plus-circle add_icon" *ngIf="isValidToShowAddButton" title="Add New {{ listName }}"
        id="add_{{ listName }}" (click)="itemList.add()" type="button"></i>

    <div class="clearfix"></div>
    <div class="menulen" [class.ovrflvsbl]="isDataExpanded">
        <div *ngFor="let item of itemList.items; let i = index;" [class.active-item]="i == itemList.currentPosition">
            <h6 title="{{ getFormattedLabelName(item) }}" [class.highlight-invalid-item]="!isItemValid(item)">
                <label class="txtellipsis m-0" [style.width.%]="70">
                    <a *ngIf="hasBlankDataInProperties(item)" (click)="onItemClick(i)">
                        {{ getFormattedLabelName(item) }}
                    </a>
                    <a *ngIf="!hasBlankDataInProperties(item)" (click)="onItemClick(i)">
                        New {{ listName }} {{ i + 1 }}
                    </a>
                </label>
                <a class="float-right">
                    <i class="fa fa-trash remove_icon" title="Delete" id="btnRemove_{{ listName + i }}"
                        (click)="remove(item, listName)"></i>
                </a>
                <p-checkbox class="delete-checkbox" name="deleteCheckBox_{{ listName + i }}"
                    id="deleteCheckBox_{{ listName + i }}" type="checkbox" binary="true"
                    [(ngModel)]="item.isDeleteCheckBoxChecked" #deleteCheckBox="ngModel">
                </p-checkbox>
            </h6>
        </div>
    </div>
    <a class="float-right viewmorecls" *ngIf="itemList.items.length > 10" name="linkViewMsg" id="linkViewMsgID"
        (click)="isDataExpanded = !isDataExpanded">
        {{ isDataExpanded ? 'view less &lt;&lt;' : 'view more &gt;&gt;' }}
    </a>
</div>

<p-toast position="center" key="removeItem" (onClose)="closePopup('removeItem')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{ message.summary }}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" name="btnConfirmRemoveYes"
                        id="btnConfirmRemoveYesID" title="Yes" (click)="confirmRemove('removeItem', message.data);">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="btnConfirmRemoveNo"
                        id="btnConfirmRemoveNoID" (click)="closePopup('removeItem')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" key="confirmation" (onClose)="closePopup('confirmation')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1; padding: 3px;">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <div [innerHTML]="message.summary">{{message.summary}}</div><br />
                <div class="p-grid p-fluid">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <button type="button" class="warnBtnSuccess" title="OK" name="btnok" id="btnokID"
                            (click)="closePopup('confirmation')">
                            <i class="fa fa-thumbs-up" aria-hidden="true" tooltip="OK"></i>
                            OK
                        </button>
                    </div>
                    <div class="col-sm-3"></div>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>