import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes, PredefinedOrder } from 'src/app/modal/Endorsements/Endorsement.Model';
import { CommonService } from 'src/app/services/commonService';
import { DocumentService } from 'src/app/services/document.service';
import { IAttachmentModel } from 'src/app/modal/IAttachmentModel';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { EndorsementValidator } from 'src/app/modal/Endorsements/Endorsement.Validator';

@Component({
  selector: 'endorsement-footer',
  templateUrl: './endorsement-footer.component.html'
})

export class EndorsementFooterComponent implements OnInit {

  @Input() parentPage: any;
  @Output() clickedViewApplication = new EventEmitter();

  private readonly toastValidation: string = 'Please fill the required fields highlighted in red';

  constructor
    (
      public router: Router,
      private documentService: DocumentService,
      private commonService: CommonService,
      private messageService: MessageService
    ) { }

  public ngOnInit(): void { }

  public get isLastPage(): boolean {
    if (!!!this.parentPage.endorsement || !!!this.parentPage.endorsementQuestionGroup)
      return false;
    return this.ifItLastPage(this.parentPage.endorsementQuestionGroup.groupKey, this.parentPage.endorsement.selectedEndorsementGroups);
  }

  public ifItLastPage(groupKey: EndorsementTypes, selectedEndorsementGroups: Array<EndorsementQuestionGroup>): boolean {
    // Filter the selected items
    const selectedItems = selectedEndorsementGroups.filter(item => item.selected);

    // Sort the selected items based on the predefined order
    const sortedSelectedItems = selectedItems.sort((a, b) => {
      return PredefinedOrder.indexOf(a.groupKey as EndorsementTypes) - PredefinedOrder.indexOf(b.groupKey as EndorsementTypes);
    });

    // Check if the provided groupKey is the last in the sorted selected items
    return sortedSelectedItems[sortedSelectedItems.length - 1]?.groupKey === groupKey;
  }

  public saveAndContinue(type: string = "") {
    if (type === 'finalSubmit') {
      if (this.parentPage.isSubmitted !== undefined)
        this.parentPage.isSubmitted = true;
      if (this.isLastPage) {
        if (this.parentPage.isPageValid()) {
          this.parentPage.endorsement.isFinalSubmit = true;
          this.isAllPageValid().then(isValid => {
            if (isValid) {
              this.parentPage.saveAndContinue(type);
            }
          }).catch(error => {
            console.error(error);
          });
        }
        else {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements', detail: this.toastValidation });
        }
      } 
    }
    else 
      this.parentPage.saveAndContinue(type);
  }

  public viewPdf(): void {
    if (this.parentPage.isPageValid()) {
      this.clickedViewApplication.emit();
      this.getEndorsementPDF()
        .then((attachmentModel: IAttachmentModel) => {
          if (!!attachmentModel) {
            this.documentService.openStreamPDF(attachmentModel.pdfStream);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements', detail: this.toastValidation });
    }
  }

  private getEndorsementPDF(): Promise<IAttachmentModel> {
    let payload = { "payloadData": this.parentPage.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'endorsementPDF', payload).toPromise()
      .then(result => {
        return result;
      })
  }

  private async isAllPageValid(): Promise<boolean> {
    let isAllPageValid: boolean = true;
    await this.getAllEndorsementQuestionAnswerGroups().then((questionAnswergroups) => {
      if (!!questionAnswergroups) {
        isAllPageValid = EndorsementValidator.isSelectedEndorsementsAreValid(this.parentPage.endorsementQuestionGroup.groupKey, this.parentPage.endorsement, questionAnswergroups);
        if (!isAllPageValid) {
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'All Endorsements', detail: "Please review the highlighted page and fill the mandatory fields." });
        }
      }
    }).catch(error => {
      console.error(error);
    });
    return isAllPageValid;
  }

  private async getAllEndorsementQuestionAnswerGroups(): Promise<Array<EndorsementQuestionGroup>> {
    let payload = { "urlData": [this.parentPage.endorsement.endorsementId] };
    return await this.commonService.get('endorsement', 'getAllEndorsementQuestionAnswerGroups', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }
}
