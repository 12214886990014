import { AbstractControl, NG_VALIDATORS } from '@angular/forms'
import { Directive, Input } from '@angular/core';
import { VehiclesComponent } from 'src/app/component/vehicles/vehicles.component';

@Directive({
  selector: '[validatingVehicleList]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: VehicleListValidatorDirective, multi: true }
  ]
})
export class VehicleListValidatorDirective {
  @Input("validatingVehicleList") name: string;
  constructor(private vehicleComponent: VehiclesComponent) { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    if (c.value != null) {
      this.vehicleComponent.validatingVehicleList();
    }
  }
}

