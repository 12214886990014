import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { StateSettings } from 'src/app/modal/state.models';
import { DriverConstants } from 'src/app/modal/DriversModel.models';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { CommonService } from 'src/app/services/commonService';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { DateService } from 'src/app/services/date.service';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import * as $ from "jquery";
import { Router } from '@angular/router';
import { Driver } from 'src/app/modal/Endorsements/Driver.Model';
import { ListViewModel } from 'src/app/modal/Endorsements/ListView.Model';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { Subscription } from 'rxjs';
import { FileUploadFormComponent } from '../file-upload-form/file-upload-form.component';
declare var jQuery: any;
import _ from 'lodash';

@Component({
  selector: 'add-driver',
  templateUrl: './add-driver.component.html'
})
export class AddDriverComponent implements OnInit, OnDestroy, IBasePage {

  @ViewChild('activeDriver') activeDriver: NgForm;
  @ViewChild('fileUploadForm') fileUploadForm: FileUploadFormComponent;

  public yesterday: Date = new Date(new Date().setDate(new Date().getDate() - 1));
  private readonly toastValidation: string = 'Please fill the required fields highlighted in red';
  private menuClickSubscription: Subscription;
  private menuClickContext: MenuClickContext;
  public nameSuffixDropdownValues = DriverConstants.NameSuffix;
  public endorsementQuestionGroup: EndorsementQuestionGroup;
  public endorsement: Endorsement = new Endorsement();
  public minOriginalLicenseDate: Date = new Date();
  public driverList: ListViewModel<Driver>;

  constructor
    (
      public stateSettings: StateSettings,
      private messageService: MessageService,
      private commonService: CommonService,
      private dateService: DateService,
      public router: Router,
      private endorsementService: EndorsementService
    ) {
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
  }

  ngOnInit(): void {
    this.initializeData();
    this.menuClickSubscription = this.endorsementService.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
  }

  private async getQuestionAnswers(): Promise<QuestionAnswer[]> {
    let payload = { "urlData": [this.endorsementQuestionGroup.endorsementId, this.endorsementQuestionGroup.groupKey] };
    try {
      const result = await this.commonService.get('endorsement', 'getQuestionAnswers', payload).toPromise();
      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  // Initialize the Data
  public async initializeData(): Promise<void> {
    await this.endorsementService.reloadQuestionGroups(this.endorsement);
    this.endorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.AddDriver);
    this.getQuestionAnswers().then(questionAnswers => {
      if (!!questionAnswers && questionAnswers.length > 0) {
        this.driverList = new ListViewModel<Driver>(
          Driver.instantiateArrayFrom(questionAnswers),
          this.beforeAdd,
          null,
          this.afterDelete,
          this.initializeNewItem
        )
        this.endorsementQuestionGroup.questionAnswers = questionAnswers;
        this.minOriginalLicenseDate = this.dateService.getCorrectDate(this.driverList.currentItem.originalLicenseDate.answer);
        setTimeout(() => {
          this.dateControlChange();
        },
          3000);
      }
    }).catch(error => {
      console.error(error);
    });
  }

  public menuClicked(menuClickContext: MenuClickContext): void {
    this.menuClickContext = menuClickContext;
    if (menuClickContext != null && menuClickContext.page != EndorsementTypes.AddDriver) {
      if (!this.activeDriver || !this.fileUploadForm) {
        this.menuClickContext.page = EndorsementTypes.AddDriver;
        return;
      }
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = this.activeDriver.touched || !_.isEqual(this.driverList.initialItems, this.driverList.items) || this.fileUploadForm.isFormDataChanged();
      if (this.endorsementService.isMenuClickHeaderPage(menuClickContext)) {
        this.menuClickContext.page = EndorsementTypes.AddDriver;
        return;
      }
      if (menuClickContext.currentPageIsValid && menuClickContext.currentPageDataChanged) {
        this.saveAndContinue();
      }
      this.endorsementService.handleUnsavedChangesOrNavigateToUrl(menuClickContext, this.endorsement);
    }
  }

  private beforeAdd = (): boolean => {
    if (!this.isPageValid()) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Add Driver', detail: this.toastValidation });
      return false;
    }
    return true;
  }

  private afterDelete = (): void => {
    this.checkForDuplicateDrivers();
  }

  private initializeNewItem = (): Driver => {
    return Driver.createNewInstanceFromQuestionAnswers(this.endorsementQuestionGroup.questionAnswers);
  }

  public addItem(): void {
    this.driverList.add();
  }

  public isPageValid(): boolean {
    return this.activeDriver.valid &&
      (!this.driverList.currentItem.uploadedDocuments.required || (this.driverList.currentItem.uploadedDocuments.required && this.fileUploadForm.uploadedDocuments.length > 0));
  }

  public onRollback(currentTempage: string): void { }

  public onBack(page: string): void {
    // there is no back button
  }

  public saveAndContinue(type = ""): void {
    if (this.isPageValid()) {
      this.checkForDuplicateDrivers();
      if (this.driverList.items.some(x => !x["isValid"])) {
        this.openToast('License Number is same for highlighted drivers. Please review the drivers and update with correct data to continue.');
      }
      else {
        this.PostAddDriverDetails().then(isSuccess => {
          if (isSuccess) {
            let currrentEndorsementQuestionGroup : EndorsementQuestionGroup =  this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.AddDriver);
            currrentEndorsementQuestionGroup.hasAnswers = true;
            currrentEndorsementQuestionGroup.isValidPage = true;
            this.endorsement.userIsAt = '';
            if (type === "finalSubmit") {
                this.endorsementService.updateStatusToSubmitted(this.endorsement.endorsementId);
            }
            else if (type === "draft") {
              this.router.navigate(['/home'], { state: { data: 'endorsement' } });
            }
            // if menuClickContext is current page or not set, get and update next menuClickContext
            if (!this.menuClickContext || this.menuClickContext?.page === EndorsementTypes.AddDriver) {
              this.endorsementService.menuClickBehavior.next(this.endorsementService.getNextMenuClickContext(EndorsementTypes.AddDriver));
            }
          }
        }).catch(error => {
          console.error(error);
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Add Driver', detail: error });
        })
      }
    }
    else
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Add Driver', detail: this.toastValidation });
  }

  private openToast(body: string): void {
    document.body.style.pointerEvents = "none";
    this.messageService.add({
      key: 'confirmation', sticky: true, severity: 'warn',
      summary: body
    });
  }

  private async PostAddDriverDetails(): Promise<boolean> {
    this.bindAnswersToQuestions();
    let payload = { "payloadData": this.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'postQuestionAnswers', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }

  public bindAnswersToQuestions(): void {
    let questionAnswers: QuestionAnswer[] = [];
    this.driverList.items.forEach((driver, i) => {
      questionAnswers.push(...driver.toQuestionAnswers(i + 1))
    });
    questionAnswers.push(...this.fileUploadForm.convertFileDocumentsToQuestionAnswer());
    this.endorsementQuestionGroup.questionAnswers = questionAnswers;
  }

  private checkForDuplicateDrivers(): void {
    this.driverList.items.forEach(driver => {
      driver['isValid'] = true;
    });

    this.driverList.items.forEach((driver, i) => {
      this.driverList.items.slice(i + 1).forEach(otherDriver => {
        if (driver.licenseStateCode.answer === otherDriver.licenseStateCode.answer && driver.licensePermitNumber.answer === otherDriver.licensePermitNumber.answer) {
          driver["isValid"] = false;
          otherDriver["isValid"] = false;
        }
      });
    });
  }

  public selectDob(): void {
    this.driverList.currentItem.originalLicenseDate.answer = this.driverList.currentItem.dob.answer ? new Date(new Date(this.driverList.currentItem.dob.answer).setFullYear(new Date(this.driverList.currentItem.dob.answer).getFullYear() + 16)) : null;
    this.minOriginalLicenseDate = new Date(this.driverList.currentItem.originalLicenseDate.answer);
  }

  public clickedItem(index: number): void {
    if (!this.isPageValid()) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Add Driver', detail: this.toastValidation });
      return;
    }
    this.driverList.currentPosition = index;
  }

  public dateControlChange() {
    jQuery("#calendarIcon1").inputmask("mm/dd/yyyy", {
      isComplete: (event) => {
        var value = jQuery("#calendarIcon1").val();
        if (jQuery(".p-datepicker")[0] == undefined) {
          if (value != '' && !isNaN(new Date(value).getTime())) {
            var dobDate = value.replace('m', '0').replace('d', '0').replace('y', '0');
            if (this.driverList.currentItem.dob.answer != new Date(dobDate)) {
              this.driverList.currentItem.dob.answer = new Date(dobDate);
            }
          }
        }
      },
      onKeyValidation: (key, result) => {
        $("div").remove(".p-datepicker");
      }
    });

    jQuery("#calendarIcon1").blur(() => {
      if (new Date(this.driverList.currentItem.dob.answer) > this.yesterday) {
        this.driverList.currentItem.dob.answer = this.yesterday;
      }
      this.selectDob();
    });

    jQuery("#calendarIcon2").inputmask("mm/dd/yyyy", {
      isComplete: (event) => {
        var value = jQuery("#calendarIcon2").val();
        if (jQuery(".p-datepicker")[0] == undefined) {
          if (value != '' && !isNaN(new Date(value).getTime())) {
            var oldDate = value.replace('m', '0').replace('d', '0').replace('y', '0');
            if (this.driverList.currentItem.originalLicenseDate.answer != new Date(oldDate)) {
              this.driverList.currentItem.originalLicenseDate.answer = new Date(oldDate);
            }
          }
        }
      },
      onKeyValidation: (key, result) => {
        $("div").remove(".p-datepicker");
      }
    });

    jQuery("#calendarIcon2").blur(() => {
      if (new Date(this.driverList.currentItem.originalLicenseDate.answer) < this.minOriginalLicenseDate) {
        this.driverList.currentItem.originalLicenseDate.answer = this?.minOriginalLicenseDate;
      }
    });

    jQuery("#calendarIcon3").inputmask("mm/dd/yyyy", {
      isComplete: (event) => {
        var value = jQuery("#calendarIcon3").val();
        if (jQuery(".p-datepicker")[0] == undefined) {
          if (value != '' && !isNaN(new Date(value).getTime())) {
            var dohDate = value.replace('m', '0').replace('d', '0').replace('y', '0');
            if (this.driverList.currentItem.dateOfHire.answer != new Date(dohDate)) {
              this.driverList.currentItem.dateOfHire.answer = new Date(dohDate);
            }
          }
        }
      },
      onKeyValidation: (key, result) => {
        $("div").remove(".p-datepicker");
      }
    });
  }

  ngOnDestroy(): void {
    if (!!this.menuClickSubscription) {
      this.menuClickSubscription.unsubscribe();
    }
  }
}
