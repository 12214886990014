<div class="container">
    <div class="main_content mt-2">
        <form #deleteDriverForm="ngForm" name="deleteDriverForm" class="width-100">
            <div class="row mt-0">
                <div class="col-lg-3 col-md-4 pl-0">
                    <list-view *ngIf="!!driverList" header="Delete Driver -" listName="Driver"
                        [labelFormat]="'{firstName.answer} {lastName.answer}'" [itemList]="driverList"
                        [maxItemLimit]="30" [blankDataValidationProperties]="['firstName.answer', 'lastName.answer']"
                        (itemClicked)="clickedItem($event)">
                    </list-view>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <span class="text-lg-right float-lg-right">
                        <cancel-endorsement [endorsementId]="endorsement.endorsementId"></cancel-endorsement>
                    </span>
                    <div class="clearfix"></div>
                    <div class="vehicle_details1 mt-4" *ngIf="!!driverList?.currentItem">
                        <div class="card">
                            <div class="card-header">
                                <h4 *ngIf="driverList.currentItem.firstName.answer" class="mb-1">
                                    <b>{{driverList.currentItem.firstName.answer}}
                                        {{driverList.currentItem.lastName.answer}}</b>
                                </h4>
                                <h4 *ngIf="!!!driverList.currentItem.firstName.answer" class="mb-1"><b>New
                                        Driver</b>
                                </h4>
                            </div>
                            <div class="card-body" *ngIf="driverList.currentItem">
                                <div class="row mb-1" *ngIf="driverList.currentItem.firstName.enabled">
                                    <div class="col-lg-6">
                                        <label for="firstName" id="firstName">First Name:</label>
                                        <span *ngIf="driverList.currentItem.firstName.required" style="color: red;">
                                            *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input id="firstName" name="firstName"
                                            [class.validate_error_cls]="deleteDriverForm.submitted && firstName.invalid"
                                            class="form-control" [(ngModel)]="driverList.currentItem.firstName.answer"
                                            type="text" #firstName="ngModel"
                                            [required]="driverList.currentItem.firstName.required"
                                            placeholder="First Name" [alphaCharactersOnly] autocomplete="off">
                                        <span style="color: red;"
                                            *ngIf="(deleteDriverForm.submitted || !firstName.pristine) && firstName.errors?.alphaCharactersOnly">
                                            Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="driverList.currentItem.lastName.enabled">
                                    <div class="col-lg-6">
                                        <label for="lastName" id="lastName">Last:</label>
                                        <span *ngIf="driverList.currentItem.lastName.required" style="color: red;">
                                            *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input id="lastName" name="lastName"
                                            [class.validate_error_cls]="deleteDriverForm.submitted && lastName.invalid"
                                            class="form-control" [(ngModel)]="driverList.currentItem.lastName.answer"
                                            type="text" #lastName="ngModel"
                                            [required]="driverList.currentItem.lastName.required"
                                            placeholder="Last Name" [alphaCharactersOnly] autocomplete="off">
                                        <span style="color: red;"
                                            *ngIf="(deleteDriverForm.submitted || !lastName.pristine) && lastName.errors?.alphaCharactersOnly">
                                            Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="driverList.currentItem.licensePermitNumber.enabled">
                                    <div class="col-lg-6">
                                        <label for="licenseNumber" id="licenseNumber">Drivers License
                                            Number:</label>
                                        <span *ngIf="driverList.currentItem.licensePermitNumber.required"
                                            style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input id="licenseNumber" name="licenseNumber"
                                            [class.validate_error_cls]="deleteDriverForm.submitted && licenseNumber.invalid"
                                            class="form-control"
                                            [(ngModel)]="driverList.currentItem.licensePermitNumber.answer" type="text"
                                            #licenseNumber="ngModel"
                                            [required]="driverList.currentItem.licensePermitNumber.required"
                                            placeholder="Driver License Number" autocomplete="off">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12 text-center">
                                        <span class="text-lg-right float-lg-right">
                                            <button type="submit" name="deletedrivebtn" id="deletedrivebtn"
                                                class="innerSubButton" (click)="addItem()"
                                                title="Delete another Driver">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                Enter Another Driver
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-4" *ngIf="driverList.currentItem.uploadedDocuments.enabled">
                            <endorsement-file-upload-form #fileUploadForm [questionGroup]="endorsementQuestionGroup"></endorsement-file-upload-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12">
                    <div class="col-mg-6 p-0 mt-3">
                        <span class="text-lg-left float-lg-left" style="margin-left: 250px;">
                            <modify-endorsement-type [endorsement]="endorsement"></modify-endorsement-type>
                        </span>
                    </div>
                    <endorsement-footer 
                        [parentPage]="this"
                        (clickedViewApplication)="bindAnswersToQuestions()">
                    </endorsement-footer>
                </div>
            </div>
        </form>
    </div>
</div>