import { AbstractControl, NG_VALIDATORS } from '@angular/forms'
import { Directive } from '@angular/core';

@Directive({
  selector: '[numbersOnly]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: NumericCharactersValidatorDirective, multi: true }
  ]
})
export class NumericCharactersValidatorDirective {
  constructor() { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    let value = c.value;
    if (!!!value)
      return null;
    if (isNaN(value)) {
      return { 'numbersOnly': true, 'errmsg': 'Only Numeric characters allowed' } 
    }
    if(typeof(value) != 'string') {
      value = value.toString();
    }
    if (value.length > 1) {
      for (var i = 0; i < value.length; i++) {
        var charCode = value.charCodeAt(i);
        if (charCode < 47 || charCode > 58) {
          return { 'numbersOnly': true, 'errmsg': 'Only Numeric characters allowed' }
        }
      }
    }
    return null;
  }
}

