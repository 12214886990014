<div class="container">
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="row hdng_value_cls" [style.width.%]="100">
                <div class="col-lg-3 text-left">
                    <label><b>Policy #:</b></label>
                    <span class="ml-2 value_label" title="{{endorsement?.policyNumber}}">{{endorsement?.policyNumber}}</span>
                </div>
                <div class="col-lg-5">
                    <label><b>Named Insured:</b></label>
                    <span class="ml-2 value_label nameInsurcls" title="{{endorsement?.insuredName}}">{{endorsement?.insuredName}}</span>
                </div>
                <div class="col-lg-4 text-right">
                    <label><b>Endorsement Eff Date:</b></label>
                    <span class="ml-2 value_label" title="EffectiveDate">{{endorsement?.endorsementEffectiveDate | date:'MM-dd-yyyy'}}</span>
                </div>
            </div>
        </div>
    </div>
</div>