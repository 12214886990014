<div class="container">
    <div class="main_content mt-2">
        <form #activeDriver="ngForm" name="activeDriver" class="width-100" licenseNumberValidator>
            <div class="row mt-0">
                <div class="col-lg-3 col-md-4 pl-0">
                    <list-view  header="Add Driver -" listName="Driver"
                        [labelFormat]="'{firstName.answer} {lastName.answer}'" [itemList]="driverList"
                        [maxItemLimit]="30" [blankDataValidationProperties]="['firstName.answer', 'lastName.answer']"
                        (itemClicked)="clickedItem($event)">
                    </list-view>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <span class="text-lg-right float-lg-right">
                        <cancel-endorsement [endorsementId]="endorsement.endorsementId"></cancel-endorsement>
                    </span>
                    <div class="clearfix"></div>
                    <div class="vehicle_details1 mt-4"  *ngIf="!!driverList?.currentItem">
                        <div class="card">
                            <div class="card-header">
                                <h4 *ngIf="driverList.currentItem.firstName.answer" class="mb-1">
                                    <b>
                                        {{driverList.currentItem.firstName.answer}}
                                        {{driverList.currentItem.middleName.answer}}
                                        {{driverList.currentItem.lastName.answer}}
                                    </b>
                                </h4>
                                <h4 *ngIf="!driverList.currentItem.firstName.answer" class="mb-1">
                                    <b>New Driver</b>
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="row mb-1" *ngIf="driverList.currentItem.firstName.enabled">
                                    <div class="col-lg-6">
                                        <label for="firstName">First Name:</label>
                                        <span *ngIf="driverList.currentItem.firstName.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="First Name"
                                            [class.validate_error_cls]="(activeDriver.submitted || !firstName.pristine) && firstName.invalid"
                                            class="form-control"
                                            [(ngModel)]="driverList.currentItem.firstName.answer" type="text"
                                            name="firstName" id="firstName" #firstName="ngModel"
                                            [required]="driverList.currentItem.firstName.required"
                                            [alphaCharactersOnly] autocomplete="off">
                                        <span class="validationred"
                                            *ngIf="(activeDriver.submitted || !firstName.pristine) && !!firstName.errors && firstName.errors.alphaCharactersOnly">
                                            Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="driverList.currentItem.middleName.enabled">
                                    <div class="col-lg-6">
                                        <label for="middleName">Middle:</label>
                                        <span *ngIf="driverList.currentItem.middleName.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="Middle Name"
                                            [class.validate_error_cls]="(activeDriver.submitted || !middleName.pristine) && middleName.invalid"
                                            class="form-control"
                                            [(ngModel)]="driverList.currentItem.middleName.answer" type="text"
                                            name="middleName" id="middleName" #middleName="ngModel"
                                            [alphaCharactersOnly]
                                            [required]="driverList.currentItem.middleName.required"
                                            autocomplete="off">
                                        <span class="validationred"
                                            *ngIf="(activeDriver.submitted || !middleName.pristine) && !!middleName.errors && middleName.errors.alphaCharactersOnly">
                                            Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-1" *ngIf="driverList.currentItem.lastName.enabled">
                                    <div class="col-lg-6">
                                        <label for="lastName">Last:</label>
                                        <span *ngIf="driverList.currentItem.lastName.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="Last Name"
                                            [class.validate_error_cls]="(activeDriver.submitted || !lastName.pristine) && lastName.invalid"
                                            class="form-control" [(ngModel)]="driverList.currentItem.lastName.answer"
                                            type="text" name="lastName" id="lastName" #lastName="ngModel"
                                            [alphaCharactersOnly]
                                            [required]="driverList.currentItem.lastName.required" autocomplete="off">
                                        <span class="validationred"
                                            *ngIf="(activeDriver.submitted || !lastName.pristine) && !!lastName.errors && lastName.errors.alphaCharactersOnly">
                                            Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-1" *ngIf="driverList.currentItem.suffix.enabled">
                                    <div class="col-lg-6">
                                        <label for="suffix">Suffix:</label>
                                        <span *ngIf="driverList.currentItem.suffix.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-dropdown placeholder="Select Suffix" [options]="nameSuffixDropdownValues"
                                            name="suffix" id="suffix"
                                            [(ngModel)]="driverList.currentItem.suffix.answer" #suffix="ngModel"
                                            [required]="driverList.currentItem.suffix.required">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="driverList.currentItem.dob.enabled">
                                    <div class="col-lg-6">
                                        <label for="dob">Date Of Birth:</label>
                                        <span *ngIf="driverList.currentItem.dob.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" [monthNavigator]="true"
                                            inputId="calendarIcon1" [yearNavigator]="true" yearRange="1900:2050"
                                            [class.validate_error_cls_dp]="activeDriver.submitted && dob.invalid"
                                            placeholder="MM / DD / YYYY"
                                            [(ngModel)]="driverList.currentItem.dob.answer" name="dob" id="dob"
                                            #dob="ngModel" [required]="driverList.currentItem.dob.required"
                                            (onSelect)="selectDob()" (onBlur)="selectDob()" [maxDate]="yesterday">
                                        </p-calendar>
                                    </div>
                                </div>
                                    <div class="row mb-1" *ngIf="driverList.currentItem.licenseStateCode.enabled">
                                        <div class="col-lg-6">
                                            <label for="licenseStateCode">Drivers
                                                License State:</label>
                                            <span *ngIf="driverList.currentItem.licenseStateCode.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6">
                                            <p-dropdown
                                                [class.validateDrodown]="activeDriver.submitted && licenseStateCode.invalid"
                                                placeholder="Select Drivers License State"
                                                [options]="stateSettings.StateQuestionOptions" name="licenseStateCode"
                                                id="licenseStateCode"
                                                [(ngModel)]="driverList.currentItem.licenseStateCode.answer"
                                                #licenseStateCode="ngModel"
                                                [required]="driverList.currentItem.licenseStateCode.required">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="row mb-1" *ngIf="driverList.currentItem.licensePermitNumber.enabled">
                                        <div class="col-lg-6">
                                            <label for="licensePermitNumber">Drivers
                                                License Number:</label>
                                            <span *ngIf="driverList.currentItem.licensePermitNumber.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6">
                                            <input placeholder="Driver License Number"
                                                [class.validate_error_cls]="activeDriver.submitted && licensePermitNumber.invalid"
                                                class="form-control"
                                                [(ngModel)]="driverList.currentItem.licensePermitNumber.answer"
                                                type="text" name="licensePermitNumber" id="licensePermitNumber"
                                                #licensePermitNumber="ngModel"
                                                [required]="driverList.currentItem.licensePermitNumber.required"
                                                autocomplete="off">
                                            <span class="validationred"
                                                *ngIf="(activeDriver.submitted || !licensePermitNumber.pristine) && activeDriver?.errors?.invalidLicenseNumber">
                                                {{activeDriver.errors.errmsg}}
                                            </span>
                                        </div>
                                    </div>
                                <div class="row mb-1" *ngIf="driverList.currentItem.originalLicenseDate.enabled">
                                    <div class="col-lg-6">
                                        <label for="originalLicenseDate">Original
                                            Date Licensed:</label>
                                        <span *ngIf="driverList.currentItem.originalLicenseDate.required"
                                            style="color: red;">*</span>
                                        <span class="business_owner_icon"><i class="fa fa-question-circle"
                                                pTooltip="Original date licensed is the date the driver was first licensed to the drive with operators license (not CDL)"
                                                aria-hidden="true"></i></span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" [monthNavigator]="true"
                                            inputId="calendarIcon2" [yearNavigator]="true" yearRange="1900:2050"
                                            [class.validate_error_cls_dp]="activeDriver.submitted&& originalLicenseDate.invalid"
                                            placeholder="MM / DD / YYYY" [minDate]="minOriginalLicenseDate"
                                            [(ngModel)]="driverList.currentItem.originalLicenseDate.answer"
                                            name="originalLicenseDate" id="originalLicenseDate"
                                            #originalLicenseDate="ngModel"
                                            [required]="driverList.currentItem.originalLicenseDate.required">
                                        </p-calendar>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="driverList.currentItem.dateOfHire.enabled">
                                    <div class="col-lg-6">
                                        <label for="dateOfHire">Date of Hire:</label>
                                        <span *ngIf="driverList.currentItem.dateOfHire.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" [monthNavigator]="true"
                                            inputId="calendarIcon3" [yearNavigator]="true" yearRange="1900:2050"
                                            [class.validate_error_cls_dp]="activeDriver.submitted && dateOfHire.invalid"
                                            placeholder="MM / DD / YYYY"
                                            [(ngModel)]="driverList.currentItem.dateOfHire.answer" name="dateOfHire"
                                            #dateOfHire="ngModel" id="dateOfHire"
                                            [required]="driverList.currentItem.dateOfHire.required">
                                        </p-calendar>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12">
                                        <span class="text-lg-right float-lg-right">
                                            <button type="submit" name="addNewDriver" id="addNewDriver"
                                                class="innerSubButton" title="Add New Driver" data-toggle="modal"
                                                (click)="addItem()">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                Add New Driver
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-4" *ngIf="driverList.currentItem.uploadedDocuments.enabled">
                            <endorsement-file-upload-form #fileUploadForm [questionGroup]="endorsementQuestionGroup"></endorsement-file-upload-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12">
                    <div class="col-mg-6 p-0 mt-3">
                        <span class="text-lg-left float-lg-left" style="margin-left: 250px;">
                            <modify-endorsement-type [endorsement]="endorsement"></modify-endorsement-type>
                        </span>
                    </div>
                    <endorsement-footer 
                        [parentPage]="this"
                        (clickedViewApplication)="bindAnswersToQuestions()">
                    </endorsement-footer>
                </div>
            </div>
        </form>
    </div>
</div>