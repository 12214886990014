import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/services/commonService';
import { SiteBanner } from 'src/app/modal/SiteBanner'
import { MessageService } from 'primeng/api';

@Component({
  selector: 'upsert-banner-component',
  templateUrl: './upsert-banner.component.html',
  styleUrls: ['./upsert-banner.component.css']
})

export class UpsertBannerComponent implements OnInit {

  @Input() bannerList: Array<SiteBanner>;
  @Input() siteBanner: SiteBanner = new SiteBanner();
  @Input() isEditBanner: boolean = false;
  @Output() closeButtonClicked = new EventEmitter();
  @Output() refreshBannerList = new EventEmitter();

  public bannerTypeQuestionOptions = Constants.BannerTypes;

  constructor(
    private commonService: CommonService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void { }

  public sumbitForm(bannerForm: NgForm): void {
    if (bannerForm.valid) {
      let validateBannerEffectiveDate = this.validateBannerEffectiveDate();
      if (validateBannerEffectiveDate != "") {
        this.openToast('toast', 'error', 'Internal Admin Management', validateBannerEffectiveDate);
        return;
      }
      if (this.checkForDuplicateSiteBanner())
        return;
      const serializedBanner = {
        ...this.siteBanner,
        effectiveFrom: this.serializeDate(this.siteBanner.effectiveFrom),
        effectiveTo: this.serializeDate(this.siteBanner.effectiveTo),
      };
      let id = this.isEditBanner ? this.siteBanner.id : 0;
      let payload = { "urlData": [id], "payloadData": serializedBanner }
      this.commonService.post('InternalAdmin', 'UpsertBannerInfo', payload).toPromise().then((event: any) => {
        if (!!event && event == 1) {
          this.openToast('toast', 'success', 'Internal Admin Management', 'Banner Information is successfully saved');
          this.siteBanner = new SiteBanner();
          this.refreshBannerList.emit();
          this.closeButtonClicked.emit();
        } else {
          this.openToast('toast', 'error', 'Internal Admin Management', 'Failed to save the Banner Information');
        }
      });
    }
    else {
      this.openToast('toast', 'error', 'Internal Admin Management', 'Please fill the required fields');
    }
  }
  private serializeDate(date: Date): string {
    if (date) {
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because months are 0-based
      const day = ('0' + date.getDate()).slice(-2);
      const year = date.getFullYear();
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      return `${month}/${day}/${year} ${hours}:${minutes}`;
    }
    return null;
  }
  private validateBannerEffectiveDate(): string {
    let errorMessage: string = "";
    if (new Date(this.siteBanner.effectiveFrom).valueOf() < new Date().valueOf()) {
      errorMessage = 'From Time Stamp cannot be in the past\n';
    }
    if (new Date(this.siteBanner.effectiveTo).valueOf() <= new Date(this.siteBanner.effectiveFrom).valueOf()) {
      errorMessage = errorMessage + 'To Time Stamp must be greater than From Time stamp';
    }
    return errorMessage;
  }
  private checkForDuplicateSiteBanner(): boolean {
    if (this.siteBanner.enabled) {
      if (!!this.bannerList && this.bannerList.length > 0 &&
        this.bannerList.filter(x => (((new Date(x.effectiveFrom) <= new Date(this.siteBanner.effectiveFrom) && new Date(x.effectiveTo) > new Date(this.siteBanner.effectiveFrom)) || (new Date(x.effectiveFrom) < new Date(this.siteBanner.effectiveTo) && new Date(x.effectiveTo) >= new Date(this.siteBanner.effectiveTo))) && x.enabled && (!this.isEditBanner || (this.isEditBanner && x.id != this.siteBanner.id)))).length > 0) {
        this.openToast('toast', 'error', 'Internal Admin Management', 'Banner for this time range exists, please select different time range');
        return true;
      }
      return false;
    }
    return false;
  }
  public closeEditBannerPopup(): void {
    this.closeButtonClicked.emit();
  }
  public resetCreateBannerPopup(): void {
    this.siteBanner = new SiteBanner();
  }
  private openToast(key: string, severity: string, summary: string, detail: string): void {
    this.messageService.add({ key: key, severity: severity, summary: summary, detail: detail });
  }
}