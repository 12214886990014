import { Component, OnInit } from '@angular/core';
import { ServiceLayer } from '../../../services/serviceLayer';
import { DAO } from '../../../modal/DAO'
import * as $ from "jquery";
declare var jQuery: any;
import { MessageService } from 'primeng/api';
import { ResponseContainer } from 'src/app/modal/questionnaire.models';
import { CommonService } from 'src/app/services/commonService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  constructor(public serviceLayer: ServiceLayer, public dao: DAO, private messageService: MessageService,
    private commonService: CommonService) { }
  // ========= Page Load =====================
  ngOnInit(): void {
    this.dao.DOTList = new ResponseContainer;
    setTimeout(function () { (<any>$('#UserID')).focus(); }, 100);
    this.getLogin();
  }
  // ========= Open Popup
  public async openPopup() {
    this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Log In', detail: 'Please fill required fields highlighted in red.' });
  }
  public async getLogin() {
    let payload = { "urlData": [this.dao.userCompany] }
    return this.dao.LoginModal = await this.commonService.get('login', 'getLoginModel', payload).toPromise();
  }
  public async postLogin() {
    let payload = { "urlData": [this.dao.userCompany], "payloadData": this.dao.LoginModal }
    return await this.commonService.post('login', 'postLoginModel', payload).toPromise().then((data: any) => {
      this.dao.PolicySubTemplate = 'newsubmission'
    });
  }
}
