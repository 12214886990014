import { QuestionAnswer } from '../questionnaire.models';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from '../Endorsements/Endorsement.Model';

export class EndorsementValidator {
    private static isEndorsementEffectiveToday: boolean = false;

    public static isSelectedEndorsementsAreValid(currentPage: string, endorsement: Endorsement, selectedEndorsements: Array<EndorsementQuestionGroup>): boolean {
        let isValid = true;

        //Get the EndorsementEffectiveDate to verify addVehcile lossLetter document 
        this.isEndorsementEffectiveToday = endorsement.IsEndorsementPolicyEffectivePriorToToday();

        //Manually set all the page as valid
        selectedEndorsements.forEach(group => group.isValidPage = true);

        selectedEndorsements.filter(group => group.selected).forEach(group => {
            if (group.groupKey !== currentPage) {
                let isValidKey = this.validateEndorsementGroup(group);

                if (!isValidKey) {
                    group.isValidPage = false;
                    isValid = false;
                }
            }
        });

        endorsement.selectedEndorsementGroups = selectedEndorsements;
        return isValid;
    }

    private static validateEndorsementGroup(group: EndorsementQuestionGroup): boolean {
        let isValidKey = true;

        if (group.groupKey === EndorsementTypes.AddVehicle) {
            isValidKey = this.isValidVehicleDetails(group.questionAnswers.filter(qa => qa.group.trim().includes('VEH')));
        } else {
            isValidKey = !group.questionAnswers.some(qa => !this.hasValidAnswer(qa));
        }

        return isValidKey;
    }

    private static isValidVehicleDetails(questionAnswers: QuestionAnswer[]): boolean {
        if (questionAnswers.length === 0) return false;

        let vehicleGroups = [...new Set(questionAnswers.map(qa => qa.group))];
        if (vehicleGroups.length === 0) return false;

        let isValid = true;
        // Iterate over each vehicle group
        vehicleGroups.forEach(group => {
            // Filter questionanswers by current vehicle group
            let filteredQuestionAnswers = questionAnswers.filter(qa => qa.group === group);
            if (!(filteredQuestionAnswers.length > 0 && this.isValidVehicleQuestionAnswers(filteredQuestionAnswers))) {
                isValid = false;
            }
        });

        return isValid;
    }

    // check if there are any invalid keys or any invalid childKeys in addVehicle  
    private static isValidVehicleQuestionAnswers(questionAnswers: QuestionAnswer[]): boolean {
        let isValid = true;

        questionAnswers.forEach(qa => {
            if (qa.key === 'lossLetter') {
                if (!this.isValidLossLetterDocument(qa)) {
                    isValid = false;
                }
            }
            if(!!qa.parentKey) {
                let parentQuestion : QuestionAnswer = questionAnswers.find(x => x.key === qa.parentKey);
                if((parentQuestion.type === 'Radio' && parentQuestion.answer === 'Yes') || parentQuestion.answer == 'Power Unit'){
                    if (!this.hasValidAnswer(qa)) {
                        isValid = false;
                    }
                }
            }
            else if (!!!qa.parentKey && qa.key !== 'lossLetter') {
                if (!this.hasValidAnswer(qa)) {
                    isValid = false;
                }
            }
        });

        return isValid;
    }

    // check if the lossLetter in the addVehicle is valid 
    private static isValidLossLetterDocument(questionAnswer: QuestionAnswer): boolean {
        let isValid: boolean = true;
        if (this.isEndorsementEffectiveToday && !questionAnswer.answer) {
            isValid = false;
        }
        return isValid;
    }

    //check if the answer is valid
    private static hasValidAnswer(questionAnswer: QuestionAnswer) : boolean {
        let isValid: boolean = true;
        if (questionAnswer.required && !!!questionAnswer.answer) {
            isValid = false;
        }
        if (!!questionAnswer.answer) {
            if (!this.isValidMinandMaxLength(questionAnswer)) {
                isValid = false; // Answer length is not valid
            }

            if (questionAnswer.type === 'Date' && !this.isValidDate(questionAnswer.answer)) {
                isValid = false; // Answer is not a valid date
            }
        }

        return isValid;
    }

    private static isValidMinandMaxLength(questionAnswer: QuestionAnswer): boolean {
        let isValid: boolean = true;
    
        if (!!questionAnswer.minimum && questionAnswer.answer.length < questionAnswer.minimum) {
            isValid = false;
        }
    
        if (!!questionAnswer.maximum && questionAnswer.answer.length > questionAnswer.maximum) {
            isValid = false;
        }
    
        return isValid;
    }

    private static isValidDate(answer: string) : boolean {
        return !isNaN(new Date(answer).getTime());
    }
}