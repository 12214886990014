import { dropDownOptions } from "./DAO";

export class LossHistoryConstants {
    public static LossHistoryPriorCarrier: Array<dropDownOptions> = [
        { label: "Berkley Prime", value: "Berkley Prime" },
        { label: "Great West", value: "Great West" },
        { label: "Northland", value: "Northland" },
        { label: "Sentry", value: "Sentry" },
        { label: "Acuity", value: "Acuity" },
        { label: "Canal", value: "Canal" },
        { label: "AIG", value: "AIG" },
        { label: "Travelers", value: "Travelers" },
        { label: "National Interstate", value: "National Interstate" },
        { label: "National Casualty", value: "National Casualty" },
        { label: "Starr", value: "Starr" },
        { label: "Auto Owners", value: "Auto Owners" },
        { label: "Nationwide", value: "Nationwide" },
        { label: "AmTrust", value: "AmTrust" },
        { label: "Carolina Casualty", value: "Carolina Casualty" },
        { label: "Great Divide", value: "Great Divide" },
        { label: "Sentry", value: "Sentry" },
        { label: "Crum & Forster", value: "Crum & Forster" },
        { label: "IAT Insurance Group", value: "IAT Insurance Group" },
        { label: "EMC Insurance Companies", value: "EMC Insurance Companies" },
        { label: "Progressive Corporation", value: "Progressive Corporation" },
        { label: "Federated Mutual Insurance Company", value: "Federated Mutual Insurance Company" },
        { label: "Lloyds of London Insurance Co", value: "Lloyds of London Insurance Co" },
        { label: "Navigators Insurance Co", value: "Navigators Insurance Co" },
        { label: "Gallagher Bassett", value: "Gallagher Bassett" },
        { label: "County Hall Insurance Company", value: "County Hall Insurance Company" },
        { label: "Claims Direct Access", value: "Claims Direct Access" },
        { label: "National Indemnity Company", value: "National Indemnity Company" },
        { label: "Paramount General Agency", value: "Paramount General Agency" },
        { label: "United Specialty Insurance Co", value: "United Specialty Insurance Co" },
        { label: "Westfield Insurance", value: "Westfield Insurance" },
        { label: "National Specialty Insurance Company", value: "National Specialty Insurance Company" },
        { label: "State National Insurance Company", value: "State National Insurance Company" },
        { label: "Aegis Security Insurance", value: "Aegis Security Insurance" },
        { label: "Sagamore Insurance Company", value: "Sagamore Insurance Company" },
        { label: "Liberty Mutual", value: "Liberty Mutual" },
        { label: "Berkshire Hathaway Homestate Companies", value: "Berkshire Hathaway Homestate Companies" },
        { label: "Cottingham and Butler Claims Services", value: "Cottingham and Butler Claims Services" },
        { label: "North American Risk Services", value: "North American Risk Services" },
        { label: "TST Insurance Services, LLC", value: "TST Insurance Services, LLC" },
        { label: "Triangle Insurance Company, Inc", value: "Triangle Insurance Company, Inc" },
        { label: "Focus", value: "Focus" },
        { label: "Seneca", value: "Seneca" },
        { label: "Allied World Assurance Company", value: "Allied World Assurance Company" },
        { label: "Harco National Insurance Company", value: "Harco National Insurance Company" },
        { label: "RISCOM", value: "RISCOM" },
        { label: "Protective Insurance", value: "Protective Insurance" },
        { label: "Baldwin & Lyons Inc", value: "Baldwin & Lyons Inc" },
        { label: "Allianz", value: "Allianz" },
        { label: "Armour Risk Management, Inc.", value: "Armour Risk Management, Inc." },
        { label: "NationsBuilders Insurance Services, Inc.", value: "NationsBuilders Insurance Services, Inc." },
        { label: "Copper Point Insurance Companies", value: "Copper Point Insurance Companies" },
        { label: "Copper Point Insurance Companies", value: "Copper Point Insurance Companies" },
        { label: "AMIC Administrator Services", value: "AMIC Administrator Services" },
        { label: "Hallmark Specialty Underwriters", value: "Hallmark Specialty Underwriters" },
        { label: "Everest Re", value: "Everest Re" },
        { label: "Sedgwick", value: "Sedgwick" },
        { label: "Greenwich Insurance Company", value: "Greenwich Insurance Company" },
        { label: "Arch Insurance Company", value: "Arch Insurance Company" },
        { label: "Burns & Wilcox Ltd", value: "Burns & Wilcox Ltd" },
        { label: "Prime Insurance Company", value: "Prime Insurance Company" },
        { label: "Spirit Commercial Auto RRG", value: "Spirit Commercial Auto RRG" },
        { label: "Argonaut Insurance Company", value: "Argonaut Insurance Company" },
        { label: "Tysers Insurance Brokers", value: "Tysers Insurance Brokers" },
        { label: "Wesco Insurance Company", value: "Wesco Insurance Company" },
        { label: "National Continental Insurance Company", value: "National Continental Insurance Company" },
        { label: "Chesterfield Group", value: "Chesterfield Group" },
        { label: "Eastern Atlantic Insurance Co", value: "Eastern Atlantic Insurance Co" },
        { label: "Hudson Insurance Company", value: "Hudson Insurance Company" },
        { label: "Maxum Specialty Insurance Group", value: "Maxum Specialty Insurance Group" },
        { label: "XL Catlin Insurance", value: "XL Catlin Insurance" },
        { label: "American Inter-Fidelity Exchange", value: "American Inter-Fidelity Exchange" },
        { label: "The Cincinnati Insurance Companies", value: "The Cincinnati Insurance Companies" },
        { label: "Milford Casualty Insurance Company", value: "Milford Casualty Insurance Company" },
        { label: "Bell & Clements Ltd", value: "Bell & Clements Ltd" },
        { label: "Selective Insurance", value: "Selective Insurance" },
        { label: "Farmers Insurance", value: "Farmers Insurance" },
        { label: "CNA Insurance", value: "CNA Insurance" },
        { label: "Endurance Assurance Corporation", value: "Endurance Assurance Corporation" },
        { label: "Midwest Family Mutual Insurance Company", value: "Midwest Family Mutual Insurance Company" },
        { label: "Intact Insurance", value: "Intact Insurance" },
        { label: "Shelter Mutual Insurance Company", value: "Shelter Mutual Insurance Company" },
        { label: "Great American Insurance Company", value: "Great American Insurance Company" },
        { label: "American Insurance Company", value: "American Insurance Company" },
        { label: "Chubb Limited Insurance company", value: "Chubb Limited Insurance company" },
        { label: "JCS Insurance Agency", value: "JCS Insurance Agency" },
        { label: "Century Insurance Group", value: "Century Insurance Group" },
        { label: "The Hartford", value: "The Hartford" },
        { label: "QBE Insurance Group Limited", value: "QBE Insurance Group Limited" },
        { label: "A-One Commercial Insurance RRG, Inc", value: "A-One Commercial Insurance RRG, Inc" },
        { label: "Zurich North America", value: "Zurich North America" },
        { label: "Sompo International Holdings Ltd", value: "Sompo International Holdings Ltd" },
        { label: "Lancer Management Company", value: "Lancer Management Company" },
        { label: "United Fire & Casualty Company", value: "United Fire & Casualty Company" },
        { label: "Elite Transportation Risk Retention Group", value: "Elite Transportation Risk Retention Group" },
        { label: "ProSight Specialty Insurance", value: "ProSight Specialty Insurance" },
        { label: "GuideOne Insurance", value: "GuideOne Insurance" },
        { label: "Markel Corporation", value: "Markel Corporation" },
        { label: "West Bend Mutual Insurance Company", value: "West Bend Mutual Insurance Company" },
        { label: "Secura Insurance Company", value: "Secura Insurance Company" },
        { label: "Aspen Insurance", value: "Aspen Insurance" },
        { label: "Gemini Transportation Underwriters", value: "Gemini Transportation Underwriters" },
        { label: "RLI Corp.", value: "RLI Corp." },
        { label: "Erie Insurance Co", value: "Erie Insurance Co" },
        { label: "Falls Lake National Insurance Company", value: "Falls Lake National Insurance Company" },
        { label: "Donegal Insurance Group", value: "Donegal Insurance Group" },
        { label: "Topa Insurance Company", value: "Topa Insurance Company" },
        { label: "FCCI Insurance Group", value: "FCCI Insurance Group" },
        { label: "Continental Trucking Association Inc", value: "Continental Trucking Association Inc" },
        { label: "Bitco Insurance Companies", value: "Bitco Insurance Companies" },
        { label: "Gulf Coast Claims Service", value: "Gulf Coast Claims Service" },
        { label: "Cherokee Insurance Company", value: "Cherokee Insurance Company" },
        { label: "AmGuard Insurance Company", value: "AmGuard Insurance Company" },
        { label: "Global Hawk Insurance Company RRG", value: "Global Hawk Insurance Company RRG" },
        { label: "The Littleton Group", value: "The Littleton Group" },
        { label: "Knightbrook Insurance Company", value: "Knightbrook Insurance Company" },
        { label: "Self Insured Exposure", value: "Self Insured Exposure" },
        { label: "Endurance American Specialty", value: "Endurance American Specialty" },
        { label: "Austin Mutual Insurance Company", value: "Austin Mutual Insurance Company" },
        { label: "Skyward Specialty Insurance", value: "Skyward Specialty Insurance" },
        { label: "Triton Claim Management", value: "Triton Claim Management" },
        { label: "Trisura Specialty Insurance Co", value: "Trisura Specialty Insurance Co" },
        { label: "Maritime Program Group", value: "Maritime Program Group" },
        { label: "Grand River Insurance", value: "Grand River Insurance" },
        { label: "Innovative Risk Management", value: "Innovative Risk Management" },
        { label: "SVA Underwriting Services", value: "SVA Underwriting Services" },
        { label: "Affirmative Risk Management", value: "Affirmative Risk Management" },
        { label: "Clear Blue Insurance Company", value: "Clear Blue Insurance Company" },
        { label: "The Hanover Insurance Group", value: "The Hanover Insurance Group" },
        { label: "Athens Administrators", value: "Athens Administrators" },
        { label: "Watford Insurance Co", value: "Watford Insurance Co" },
        { label: "United Fire Group", value: "United Fire Group" },
        { label: "Continental Western Group", value: "Continental Western Group" },
        { label: "Kinsale Insurance", value: "Kinsale Insurance" },
        { label: "National General Insurance", value: "National General Insurance" },
        { label: "Transatlantic Underwriters", value: "Transatlantic Underwriters" },
        { label: "Alaska National Insurance Company", value: "Alaska National Insurance Company" },
        { label: "Brazos", value: "Brazos" },
        { label: "Western National Insurance", value: "Western National Insurance" },
        { label: "Vanliner Insurance Company", value: "Vanliner Insurance Company" },
        { label: "Integrity Insurance", value: "Integrity Insurance" },
        { label: "IFG Companies", value: "IFG Companies" },
        { label: "Dongbu Insurance", value: "Dongbu Insurance" },
        { label: "Velocity Risk Underwriters", value: "Velocity Risk Underwriters" },
        { label: "Stonewood Insurance Company", value: "Stonewood Insurance Company" },
        { label: "Atlantic Casualty Insurance Company", value: "Atlantic Casualty Insurance Company" },
        { label: "Berkley Fire & Marine", value: "Berkley Fire & Marine" },
        { label: "Country Mutual Insurance Company", value: "Country Mutual Insurance Company" },
        { label: "Amerisure Mutual Insurance Company", value: "Amerisure Mutual Insurance Company" },
        { label: "International Programs Group", value: "International Programs Group" },
        { label: "McCord & Associates", value: "McCord & Associates" },
        { label: "ACCREDITED SURETY AND CASUALTY COMPANY, INC.", value: "ACCREDITED SURETY AND CASUALTY COMPANY, INC." },
        { label: "UNITED STATES FIRE INSURANCE CO.", value: "UNITED STATES FIRE INSURANCE CO." },
        { label: "PROGRESSIVE NORTHERN INSURANCE COMPANY", value: "PROGRESSIVE NORTHERN INSURANCE COMPANY" },
        { label: "BERKLEY NATIONAL INSURANCE COMPANY", value: "BERKLEY NATIONAL INSURANCE COMPANY" },
        { label: "SUTTON NATIONAL", value: "SUTTON NATIONAL" },
        { label: "PEKIN INSURANCE CO", value: "PEKIN INSURANCE CO" },
        { label: "Avant", value: "Avant" },
        { label: "THIRD COAST INSURANCE COMPANY", value: "THIRD COAST INSURANCE COMPANY" },
        { label: "ACE PROPERTY AND CASUALTY INSURANCE COMPANY", value: "ACE PROPERTY AND CASUALTY INSURANCE COMPANY" },
        { label: "INCLINE CASUALTY COMPANY", value: "INCLINE CASUALTY COMPANY" },
        { label: "PROGRESSIVE HAWAII INSURANCE CORP", value: "PROGRESSIVE HAWAII INSURANCE CORP" },
        { label: "SMALL FLEET ADVANTAGE RRG, INC", value: "SMALL FLEET ADVANTAGE RRG, INC" },
        { label: "CENTRAL MUTUAL INSURANCE COMPANY", value: "CENTRAL MUTUAL INSURANCE COMPANY" },
        { label: "NATIONAL UNION FIRE INS. CO. OF PITSBGH. PA", value: "NATIONAL UNION FIRE INS. CO. OF PITSBGH. PA" },
        { label: "NATIONAL CONTINENTAL INSURANCE CO.", value: "NATIONAL CONTINENTAL INSURANCE CO." },
        { label: "DB INSURANCE CO., LTD", value: "DB INSURANCE CO., LTD" },
        { label: "ARTISAN & TRUCKERS CASUALTY COMPANY", value: "ARTISAN & TRUCKERS CASUALTY COMPANY" },
        { label: "Parker and Associates L.L.C.", value: "Parker and Associates L.L.C." },
        { label: "NORTH RIVER INSURANCE CO", value: "NORTH RIVER INSURANCE CO" },
        { label: "HOUSTON SPECIALTY INSURANCE COMPANY", value: "HOUSTON SPECIALTY INSURANCE COMPANY" },
        { label: "GB Group, LLC", value: "GB Group, LLC" },
        { label: "RSUI Group, Inc", value: "RSUI Group, Inc" },
        { label: "Fundamental Underwriters", value: "Fundamental Underwriters" },
        { label: "TEXAS INSURANCE COMPANY", value: "TEXAS INSURANCE COMPANY" },
        { label: "Rural Mutual Insurance Company", value: "Rural Mutual Insurance Company" },
        { label: "TEXCAZ Insurance Services", value: "TEXCAZ Insurance Services" },
        { label: "Great Lakes", value: "Great Lakes" },
        { label: "Benchmark", value: "Benchmark" },
        { label: "Spinnaker", value: "Spinnaker" },
        { label: "PRIME PROPERTY & CASUALTY INSURANCE INC", value: "PRIME PROPERTY & CASUALTY INSURANCE INC" },
        { label: "NTA General Insurance Agency", value: "NTA General Insurance Agency" },
        { label: "White Pine Insurance Company", value: "White Pine Insurance Company" },
        { label: "Daily Underwriters of America", value: "Daily Underwriters of America" },
        { label: "SCOTTSDALE INSURANCE COMPANY", value: "SCOTTSDALE INSURANCE COMPANY" },
        { label: "Decus", value: "Decus" },
        { label: "American Hallmark Insurance Company of Texas", value: "American Hallmark Insurance Company of Texas" },
        { label: "ACE AMERICAN INSURANCE COMPANY", value: "ACE AMERICAN INSURANCE COMPANY" },
        { label: "PROGRESSIVE SOUTHEASTERN INSURANCE COMPANY", value: "PROGRESSIVE SOUTHEASTERN INSURANCE COMPANY" },
        { label: "PMA Companies", value: "PMA Companies" },
        { label: "Synergy", value: "Synergy" },
        { label: "SPG Insurance Solutions, LLC", value: "SPG Insurance Solutions, LLC" },
        { label: "NBIS", value: "NBIS" },
        { label: "State Street Claims Services, Inc", value: "State Street Claims Services, Inc" },
        { label: "Canopius US Insurance, Inc", value: "Canopius US Insurance, Inc" },
        { label: "PRIME PROPERTY & CASUALTY INSURANCE INC", value: "PRIME PROPERTY & CASUALTY INSURANCE INC" },
        { label: "Motorists Mutual Insurance Company", value: "Motorists Mutual Insurance Company" },
        { label: "Encova Insurance", value: "Encova Insurance" },
        { label: "OBSIDIAN SPECIALTY INSURANCE COMPANY", value: "OBSIDIAN SPECIALTY INSURANCE COMPANY" },
        { label: "INDEMNITY INSURANCE CO. OF NORTH AMERICA", value: "INDEMNITY INSURANCE CO. OF NORTH AMERICA" },
        { label: "Unique", value: "Unique" },
        { label: "Oxford", value: "Oxford" },
        { label: "First Light Program Managers", value: "First Light Program Managers" },
        { label: "Northfield Insurance Company", value: "Northfield Insurance Company" },
        { label: "Tokio Marine Management Inc", value: "Tokio Marine Management Inc" },
        { label: "Transportation Agency Partners", value: "Transportation Agency Partners" },
        { label: "COLUMBIA INSURANCE COMPANY", value: "COLUMBIA INSURANCE COMPANY" },
        { label: "COLONY SPECIALTY INSURANCE COMPANY", value: "COLONY SPECIALTY INSURANCE COMPANY" },
        { label: "BRIERFIELD INSURANCE COMPANY", value: "BRIERFIELD INSURANCE COMPANY" },
        { label: "TCB Insurance Programs", value: "TCB Insurance Programs" },
        { label: "HDVI", value: "HDVI" },
        { label: "Platinum Trucking Program", value: "Platinum Trucking Program" },
        { label: "Burlington", value: "Burlington" },
        { label: "XL SPECIALTY INSURANCE COMPANY", value: "XL SPECIALTY INSURANCE COMPANY" },
        { label: "NATIONAL INTERSTATE INS CO. OF HAWAII, INC.", value: "NATIONAL INTERSTATE INS CO. OF HAWAII, INC." }


    ];

    public static TypeOfLosses: Array<dropDownOptions> = [
        { label: "Liability-UM", value: "Liability-UM" },
        { label: "Liability-PIP", value: "Liability-PIP" },
        { label: "Liability-Med Pay", value: "Liability-Med Pay" },
        { label: "Liability-BI", value: "Liability-BI" },
        { label: "Liability-PD", value: "Liability-PD" },
        { label: "Liability-Pollution/Environmental", value: "Liability-Pollution/Environmental" },
        { label: "Comprehensive", value: "Comprehensive" },
        { label: "Collision", value: "Collision" },
        { label: "Specified causes of Loss", value: "Specified causes of Loss" },
        { label: "Trailer Interchange", value: "Trailer Interchange" },
        { label: "Cargo", value: "Cargo" },
        { label: "General Liability", value: "General Liability" },
        { label: "Towing", value: "Towing" },
        { label: "Other", value: "Other" }
    ];

    public static TypeOfLossStatus: Array<dropDownOptions> = [
        { label: "Open", value: "Open" },
        { label: "Closed", value: "Closed" },
    ];
}
