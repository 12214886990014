import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'
import { CreateEndorsementComponent } from './create-endorsement/create-endorsement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { MenuIconBarComponent } from './menu-icon-bar/menu-icon-bar.component';
import { EndorsementContainerComponent } from './endorsement-container/endorsement-container.component';
import { AddDriverComponent } from './add-driver/add-driver.component';
import { DeleteDriverComponent } from './delete-driver/delete-driver.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { DeleteVehicleComponent } from './delete-vehicle/delete-vehicle.component';
import { AddAdditionalInsuredComponent } from './add-additional-insured/add-additional-insured.component';
import { DeleteAdditionalInsuredComponent } from './delete-additional-insured/delete-additional-insured.component';
import { OtherEndorsementsComponent } from './otherEndorsements/otherEndorsements.component';
import { DropdownModule } from 'primeng/dropdown';
import { SharedDirectivesModule } from 'src/app/shared-directives/shared-directives.module';
import { SharedComponentsModule } from '../sharedComponents/shared-components.module';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { CancelEndorsementComponent } from './cancel-endorsement/cancel-endorsement.component';
import { InputMaskModule } from 'primeng/inputmask';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { ListViewComponent } from './list-view/list-view.component';
import { ModifyEndorsementTypeComponent } from 'src/app/endorsement/modify-endorsement-type/modify-endorsement-type.component';
import { DialogModule } from 'primeng/dialog';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadFormComponent } from './file-upload-form/file-upload-form.component';
import { EndorsementFooterComponent } from '../endorsement/endorsement-footer/endorsement-footer.component';

@NgModule({
  declarations: [
    CreateEndorsementComponent,
    MenuIconBarComponent,
    EndorsementContainerComponent,
    AddDriverComponent,
    DeleteDriverComponent,
    AddVehicleComponent,
    DeleteVehicleComponent,
    AddAdditionalInsuredComponent,
    DeleteAdditionalInsuredComponent,
    OtherEndorsementsComponent,
    CancelEndorsementComponent,
    TitleBarComponent,
    ListViewComponent,
    ModifyEndorsementTypeComponent,
    FileUploadFormComponent,
    EndorsementFooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    CalendarModule,
    CheckboxModule,
    InputNumberModule,
    ToastModule,
    RouterModule,
    ReactiveFormsModule,
    FileUploadModule,
    DropdownModule,
    SharedDirectivesModule,
    SharedComponentsModule,
    TooltipModule,
    InputMaskModule,
    DropdownModule,
    DialogModule,
    RadioButtonModule
  ],
  providers: [
    EndorsementService
  ],
  exports: [
    RouterModule
  ]
})
export class EndorsementModule { }
