<button name="requestAnotherEndorsement" id="requestAnotherEndorsement" type="button" class="btnsuccess"
    title="Add/Remove Endorsement Type" (click)="showModifyEndorsementDialog=true">
    <div class="circle">
        <span class="character">+</span>
        <span class="character">/</span>
        <span class="character">-</span>
    </div>
    Add/Remove Endorsement Type
</button>

<p-dialog [(visible)]="showModifyEndorsementDialog" [modal]="true" (onHide)="closeModifyEndorsementDialog()">
    <ng-template pTemplate="header">
        <div class="ml-4 pt-2">
            <h6>Add/Remove Endorsement Type</h6>
        </div>
    </ng-template>
    <div class="row mt-1">
        <div class="col-lg-12 editUser">
            <p-card class="userBorder">
                <form #modifyEndorsementForm="ngForm" style="width:100%"
                    (ngSubmit)="modifyEndorsementForm.valid && onContinue()">
                    <div class="col-lg-12">
                        <div class="checkbox-container">
                            <span *ngFor="let item of endorsementTypes">
                                <p-checkbox name="endorsementType" class="m-0 p-2" [(ngModel)]="selectedGroupKeys"
                                    [label]="item.label" [value]="item.value" #endorsementTypes="ngModel" required
                                    [inputId]="selectEndorsementType" id="endorsementType">
                                </p-checkbox>
                            </span>
                        </div>
                        <span
                            *ngIf="modifyEndorsementForm.submitted && !modifyEndorsementForm.controls['endorsementType']?.valid"
                            style="color: red;">
                            Please select at-least one endorsement type to proceed further
                        </span>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-end mt-3">
                        <button class="btnsuccess mr-2" title="Continue" name="Continue" id="Continue" type="submit">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i> Continue
                        </button>
                    </div>
                </form>
            </p-card>
        </div>
    </div>
</p-dialog>

<p-toast position="center" key="warningWithYesNo" preventOpenDuplicates='true' [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="yes" id="yes"
                        (click)="updateEndorsementGroupKeys()">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="no" id="no" (click)="clearToast()">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>