<!-- Broadened PIP Yes/No Question -->
<radiobutton-question
    *ngIf="broadenedPIPYesNo.enabled"
    #broadenedPipRadiobutton
    [question]="broadenedPIPYesNo"
    [required]="true"
    (clicked)="broadenedPipSelection()"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<!-- Broadened PIP -->
<dropdown-question
    *ngIf="showBroadenedPIP"
    #broadenedPipDropdown
    [question]="broadenedPIP"
    [disabled]="isBroadenedPipDisabled"
    [requiredAmtOrDecline]="broadenedPIP.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Broadened PIP Deductible-->
<dropdown-question
    *ngIf="showBroadenedPIPDeductible"
    #broadenedPipDeductibleDropdown
    [question]="broadenedPIPDeductible"
    [disabled]="isBroadenedPipDeductibleDisabled"
    [requiredAmtOrDecline]="broadenedPIPDeductible.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>
