<!-- Liability Limit -->
<dropdown-question
   *ngIf="liabilityLimit.enabled"
   #liabilityLimitDropdown
   label="Liability"
   [question]="liabilityLimit"
   [requiredAmtOrDecline]="liabilityLimit.required"
   [disabled]="!(liabilityLimit?.questionOptions?.length > 1)"
   [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Medical Expense Benefits -->
<dropdown-question
   *ngIf="medExpenseBenefits.enabled"
   #medExpenseBenefitsDropdown
   [question]="medExpenseBenefits"
   [requiredAmtOrDecline]="medExpenseBenefits.required"
   [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Income Loss Benefits -->
<dropdown-question
   *ngIf="incomeLoss.enabled"
   #incomeLossDropdown
   [question]="incomeLoss"
   [requiredAmtOrDecline]="incomeLoss.required"
   [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Tort Limit -> Accept/Reject -->
<radiobutton-question
    *ngIf="tortLimitSelect.enabled"
    #tortLimitRadiobutton
    [question]="tortLimitSelect"
    [required]="tortLimitSelect.required"
    (clicked)="tortLimitSelection()"
    [radioButtonLabel1]="'Accept'"
    [radioButtonLabel2]="'Reject'"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<!-- Tort Limit Dropdown -->
<dropdown-question
    *ngIf="tortLimits.enabled"
    #tortLimitDropdown
    [question]="tortLimits"
    [disabled]="isTortLimitsDisabled"
    [requiredAmtOrDecline]="tortLimits.required"
    [isFormSubmitted]="isFormSubmitted"
    (changed)="invalidTortLimits()">
</dropdown-question>
<div class="row">
    <div class="col-lg-6"></div>
    <div class="col-lg-6">
     <span class="error-message" *ngIf="tortLimitValidation">
        {{validationMessage}}
     </span>
    </div>
 </div>

 <!-- Medical Payment -->
<dropdown-question
*ngIf="mpLimit.enabled"
#mpLimitDropdown
label="Medical Payments"
[question]="mpLimit"
[requiredAmtOrDecline]="mpLimit.required"
[isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Does applicant wish to reject Uninsured Motorist, because insured is providing Workers Compensation Coverage to all employees and is a registered Motor Carrier? -->
<radiobutton-question
*ngIf="umReject?.enabled"
#umRejectRadiobutton
[question]="umReject"
(clicked)="resetUMCoverage()"
[isFormSubmitted]="isFormSubmitted">
</radiobutton-question>
