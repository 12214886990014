import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { QuestionAnswer, Questionnaire, ResponseContainer } from '../../../modal/questionnaire.models';
import { FileuploadService } from '../../../services/fileupload.service';
import _ from 'lodash';

export class FileToUploadFormGroup extends FormGroup {
    readonly fileQuestionaryDetail = this.get('fileQuestionaryDetail') as FormControl;
    uploadedFiles = new Array<FileToUpload>();
    initialQuestionAnswers = new Array<QuestionAnswer>();
    public referenceNumber: string;

    constructor(readonly fb: FormBuilder = new FormBuilder(),
        public fileuploadService: FileuploadService) {
        super(fb.group({
        }).controls);
    }

    public async initializeFormControls(apiResponse: ResponseContainer) {
        await this.initializeDataFrom(apiResponse);
        this.synchronizeFormControls();
    }

    public toQuestionAnswers(): QuestionAnswer[] {
        let uploadedFileQAs = new Array<QuestionAnswer>();
        this.uploadedFiles.forEach((c, i) => {
            var TempFileUpload = _.cloneDeep(this.initialQuestionAnswers[0]);
            TempFileUpload.answer = c.guid;
            TempFileUpload.fileName = c.fileName;
            TempFileUpload.rowIndex = i + 1;
            uploadedFileQAs.push(TempFileUpload);

        })
        return uploadedFileQAs;
    }

    private async initializeDataFrom(apiResponse: ResponseContainer) {
        this.referenceNumber = apiResponse.dotQsnr.findQuestion('number').answer;
        this.initialQuestionAnswers = apiResponse?.losssHistoryQuestionnaire?.findQuestions("lossRunsUpload");
        //populate this.uploadedFiles first
        if (this.initialQuestionAnswers != null && this.initialQuestionAnswers.length > 0) {
            let referenceNumber = apiResponse.dotQsnr.findQuestion('number').answer;
            await Promise.all(this.initialQuestionAnswers.map((qa, i) => {
                if (!!qa.answer) {
                    let fileUploaded = new FileToUpload();
                    fileUploaded.key = qa.key;
                    fileUploaded.rowIndex = qa.rowIndex;
                    fileUploaded.guid = qa.answer;
                    fileUploaded.fileName = qa["fileName"];
                    if (!this.uploadedFiles.some(x => x.guid == qa.answer))
                        this.uploadedFiles.push(fileUploaded);
                }
            }));
            // then call API as it is slow
            this.uploadedFiles.forEach((file, i) => {
                this.fileuploadService.getFileDetails(file.guid, referenceNumber).then(fileToUpload => {
                    let fileUploaded = new FileToUpload();
                    file.progressValue = 100;
                    file.size = fileToUpload.fileSize;
                    file.filesize = this.fileuploadService.bytesToSize(fileToUpload.fileSize);
                    file.fileName = fileToUpload.fileName;
                });
            });
        }
    }

    public isPageValid() {
        if (this.valid && !!!this.initialQuestionAnswers[0]?.required)
            return true;
        return this.valid && this.uploadedFiles.length > 0;
    }

    public synchronizeFormControls() {
        const strFileUploadButton = "fileUploadButton";
        //Added control for uploaded files
        this.uploadedFiles.forEach((element, i) => {
            let hasCtrl = false;
            for (const field in this.controls) { 
                const control = this.get(field);
                if (control.value?.toString().toLowerCase() == element.guid?.toString().toLowerCase())
                    hasCtrl = true;
              }
            if (hasCtrl === false)
                this.addControl(`${this.initialQuestionAnswers[0].key}_${i + 1}`, new FormControl(element.guid));
        });

        //delete control for deleted uploaded file
        let hasUploadButton = false;
        for (const field in this.controls) { // 'field' is a string
            const control = this.get(field); 
            let hasFile = false;
            this.uploadedFiles.forEach((element, i) => 
                {               
                    if (control.value?.toString().toLowerCase() == element.guid?.toString().toLowerCase())
                        hasFile = true;
                }
            );
            if (field == strFileUploadButton)
            { 
                hasUploadButton = true;
            }
            else if (hasFile == false)
            {
                this.removeControl(field);
            }                
        }    

        if (hasUploadButton == false) {            
            this.addControl(strFileUploadButton,  new FormControl(strFileUploadButton));
            if (this.initialQuestionAnswers[0].required)
                this.controls[strFileUploadButton].setValidators(Validators.required);
        }
    }
}

export class FileToUpload {
    key: string;
    rowIndex: number;
    progressValue: number;
    size: number;
    filesize: string;
    guid: string;
    fileName: string;
}
