<button name="btncancelendorsement" id="btncancelendorsement" type="button" class="btnCancelSub" data-toggle="modal"
    data-target="#cancelendorsementPopUp" title="Cancel Endorsement">
    <i class="fa fa-times" aria-hidden="true"></i>
    Cancel Endorsement
</button>

<div class="modal fade" id="cancelendorsementPopUp" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="p-text-center" style="color: #000 !important;">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h6 style="color: inherit !important;">All of your data will be lost if you cancel this submission.
                        Are you sure you want to continue?</h6>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="p-grid p-fluid">
                                <div class="p-col-8" style="display: flex;">
                                    <button name="btnCancelEndorsementYes" id="btnCancelEndorsementYes" type="button"
                                        class="p-button-success mr-2" pButton data-dismiss="modal"
                                        (click)="confirmCancelEndorsement()" label="Yes"></button>
                                    <button name="btnCancelEndorsementNo" id="btnCancelEndorsementNo" type="button"
                                        class="p-button-secondary ml-2" pButton data-dismiss="modal"
                                        label="No"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>