import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DAO } from 'src/app/modal/DAO';
import { QuestionAnswer, QuestionAnswerHelper, Questionnaire } from 'src/app/modal/questionnaire.models';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'underinsured-motorist',
    templateUrl: './underinsured-motorist.component.html',
    styleUrls: ['./underinsured-motorist.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderinsuredMotoristComponent implements OnInit{

    @Input() questionnaire: Questionnaire;
    @Input() isFormSubmitted: boolean;
    @Input() productForm: NgForm;
    @Input() umBiLimit: string;
    @Input() umPdPrd: string;
    @Input() umBiLimitQuestionBehaviore: BehaviorSubject<QuestionAnswer>;
    @Input() umPdPrdQuestionBehaviore: BehaviorSubject<QuestionAnswer>;
    @Input() uimBiQuestionBehaviore: BehaviorSubject<QuestionAnswer>;
    @Output() uimBiChanged = new EventEmitter();

    public uimBiPrd: QuestionAnswer; //UIM-BI-PD-CSL
    public uimBiPrdDed: QuestionAnswer; //UIM-BI-PD-CSL-DED
    public isUimBiPrdDedDisabled: boolean = false;

    public uimBi: QuestionAnswer; //UIM-BI
    public isUimBiDisabled: boolean = false;

    public uimPrd: QuestionAnswer; //UIM-PD
    public isUimPrdDisabled: boolean = false;

    public uimPrdDed: QuestionAnswer; //UIM-PdDed
    public isUimPrdDedDisabled: boolean = false;
    public isWorkInProgress: boolean = false;

    public tortLimitSelect: QuestionAnswer;
    public validationMessage: string = "";

    public excludeUimBi: QuestionAnswer;
    public excludeUimBiPrd: QuestionAnswer;

    constructor(public dao: DAO, private productService: ProductService) {

    }

    public ngOnInit(): void {
        this.loadQuestionAnswers();
        this.onUimBiChanged();
        this.uimBiPrdChanged();
        this.loadValidationMessage();
        
        this.umBiLimitQuestionBehaviore.subscribe(question => {
            if(!!question && this.excludeUimBi?.enabled){
                if(this.hasSelection(question) && question?.answer != "30,000/60,000"){
                    this.uimBi.answer = question?.answer;
                }else{
                    this.excludeUimBi.answer = "";
                    this.productForm.controls?.excludeUimBi?.updateValueAndValidity();
                }
            }
        });

        this.umPdPrdQuestionBehaviore.subscribe(question => {
            if(!!question && this.excludeUimBiPrd?.enabled){
                if(this.hasSelection(question) && question?.answer != "85,000"){
                    this.uimBiPrd.answer = question?.answer;
                }else{
                    this.excludeUimBiPrd.answer = "";
                    this.productForm.controls?.excludeUimBiPrd?.updateValueAndValidity();
                }
            }
        });
    }

    public get showUimBiPrd(): boolean {
        let isUimBiPrdValid = false;
        if(this.uimBiPrd?.enabled && this.uimBi?.enabled && !this.hasSelection(this.uimBi)){
            isUimBiPrdValid = true;
        }else if(this.excludeUimBiPrd?.enabled && this.excludeUimBiPrd?.answer == "No"){
            isUimBiPrdValid = true;
        }
        return isUimBiPrdValid;
    }
    
    public get showUimBi(): boolean {
        let isUmBiValid = false;
        if(this.uimBi?.enabled && (!this.uimBiPrd?.enabled || !this.hasSelection(this.uimBiPrd))){
            isUmBiValid = true;
        }else if(this.excludeUimBi?.enabled && this.excludeUimBi?.answer == "No"){
            isUmBiValid = true;
        }
        return isUmBiValid;
    }

    public get showUimPrd(): boolean {
        return this.uimPrd?.enabled && this.hasSelection(this.uimBi);
    }

    public get showUimBiPrdDed() {
        return this.uimBiPrd?.enabled && this.hasSelection(this.uimBiPrd) && this.uimBiPrdDed?.enabled;
    }
    
    public get showUimPrdDed(): boolean {
        if (this.dao.DOTList.accountInfoQsnr.findQuestion("accountState").answer != 'WV'){
            this.uimPrdDed.enabled = false;
        }
        return this.uimPrdDed?.enabled && this.hasSelection(this.uimBi);
    }

    public get showExcludeUimBiQuestion():boolean{
        return !!this.umBiLimit && this.umBiLimit != "30,000/60,000" &&  this.umBiLimit != "CLICK";
    }  

    public get showExcludeUimBiPrdQuestion():boolean{
        return !!this.umPdPrd && this.umPdPrd != "85,000" && this.umPdPrd  != "CLICK";
    } 

    public uimBiPrdChanged(): void {
        if (this.hasSelection(this.uimBiPrd)) {
            this.uimBi.answer = '';
            this.uimPrd.answer = '';
            this.isUimBiPrdDedDisabled = true;
        }
    }

    public onUimBiChanged(): void {
        if (this.uimBi.answer?.toUpperCase() === 'DECLINE') {
            this.uimPrd.answer = 'DECLINE';
        }
        this.isUimPrdDisabled = this.uimBi.answer === 'DECLINE';
        const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
        if (accounState == 'WV') {
            if (this.questionnaire.questionAnswer.some(x => x.key === 'uimPrdDed')) {
                this.uimPrdDed.answer = this.uimPrdDed?.questionDefault[0]?.code;
                this.isUimPrdDedDisabled = true;
            }
        }
        if (this.hasSelection(this.uimBi)) {
            this.uimBiPrd.answer = '';
        }
        this.uimBiChanged.emit();
        this.uimBiQuestionBehaviore.next(this.uimBi);
    }
    
    public get umAndUimMustMatch(): boolean {
        return this.umBiLimit != 'CLICK' && this.hasSelection(this.uimBi) && this.umBiLimit != this.uimBi.answer && this.uimBi.answer != 'DECLINE' && this.tortLimitSelect.enabled;
    }
    
    private loadValidationMessage(): void {
        this.validationMessage = this.productService.getValidationMessage('um/uim') ?? "";
    }

    private loadQuestionAnswers(): void {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();

        this.uimBiPrd = this.questionnaire.questionAnswer.find(x => x.key === 'uimBiPrd') ?? defaultQuestionAnswer;
        this.uimBiPrdDed = this.questionnaire.questionAnswer.find(x => x.key === 'uimBiPrdDed') ?? defaultQuestionAnswer;
        if(!!this.uimBiPrdDed){
            QuestionAnswerHelper.useDefaultValue.apply(this.uimBiPrdDed);
           }
        this.uimBi = this.questionnaire.questionAnswer.find(x => x.key === 'uimBi') ?? defaultQuestionAnswer;
        this.uimPrd = this.questionnaire.questionAnswer.find(x => x.key === 'uimPrd') ?? defaultQuestionAnswer;
        this.uimPrdDed = this.questionnaire.questionAnswer.find(x => x.key === 'uimPrdDed') ?? defaultQuestionAnswer;
        this.tortLimitSelect = this.questionnaire.questionAnswer.find(x => x.key === 'tortLimitSelect') ?? defaultQuestionAnswer;
        this.excludeUimBi = this.questionnaire.questionAnswer.find(x => x.key === 'isExcludeUimBi') ?? defaultQuestionAnswer;
        this.excludeUimBiPrd = this.questionnaire.questionAnswer.find(x => x.key === 'isExcludeUimBiPrd') ?? defaultQuestionAnswer;

    }

    private getDefaultQuestionAnswer(): QuestionAnswer {
        return {
            answer: null,
            enabled: false,
            required: false,
            group: null,
            hidden: false,
            key: null,
            isVehicleUpdatedbyVEH1: false,
            minimum: null,
            name: null,
            type: '',
            rowIndex: 0,
            questionOptions: []
        }
    }

    private hasSelection(question: QuestionAnswer): boolean {
        return !!question?.answer && question?.answer !== 'CLICK';
    }
}
