<div class="col-lg-6 question-label">
    <label>{{question.questionLabel}}
        <span *ngIf="question?.required">*</span>
    </label>
</div>

<div class="col-lg-6">
    <p-radioButton 
        [value]="radiovalue1" 
        [label]="radioButtonLabel1"
        #radiobuttonModel="ngModel"
        [class.invalid]="showError"
        [name]="name ? name + 'radio' :question?.name"
        [(ngModel)]="question.answer"
        [disabled]="disabled"
        [required]="question?.required"
        (onClick)="ChangeAns('Yes',$event)">
    </p-radioButton>
    <p-radioButton
        class="padding-left"
        [value]="radiovalue2"
        [label]="radioButtonLabel2"
        #radiobuttonModel="ngModel"
        [class.invalid]="showError"
        [name]="name ? name + 'radio' :question?.name"
        [(ngModel)]="question.answer"
        [disabled]="disabled"
        [required]="question?.required"
        (onClick)="ChangeAns('No',$event)">
    </p-radioButton>
</div>