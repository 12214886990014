import { Component, OnInit } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO } from '../../modal/DAO'
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/commonService';
import { MessageService } from 'primeng/api';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';

@Component({
    selector: 'documentLibraryComponent',
    templateUrl: './documentLibrary.html'
})
export class documentLibraryComponent extends AuthenticatedUserComponent implements OnInit {

    faquestions: any;
    imageSrc: string;
    public IsShowUIMGuidelines: boolean = false;
    constructor(
        public dao: DAO, public appConfig: AppConfig,
        public serviceLayer: ServiceLayer,
        protected authenticationService: AuthenticationService,
        private commonService: CommonService,
        private messageService: MessageService,
        private auth0UserService: Auth0UserService) {
        super(authenticationService);
    }

    public ngOnInit() {
        this.dao.PolicySubTemplate = 'documentLibrary';

        if (!!!this.appConfig.auth0Enabled)
        {
            this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(async (isInitial) => {
                if (isInitial)
                {                  
                    this.getFAQuestions().then(data => this.faquestions = data);
                    this.getMapImage();}                
            });            
        }
        else
        {
            this.auth0UserService.userLoaded$.subscribe(
              auth0User => {                  
                  if(!!auth0User?.accessToken)
                  {                    
                    this.getFAQuestions().then(data => this.faquestions = data);
                    this.getMapImage();
                  }
                });  

        }     
    }

    public GetSpecificStateDetails(state) {
        this.dao.OpenDocumentStateGrid = true;
        let payload = { "urlData": [state] }
        this.commonService.get('documentLibrary', 'GetSpecificStateDocument', payload).subscribe((event: any) => {
            if (event) {
                let result = event;
                this.dao.SelectedDocumentStateList = [];
                this.dao.SelectedDocumentStateList = event;
            }
        });
    }

    public getFAQuestions() {
        let payload = { "urlData": [] }
        return this.commonService.get('documentLibrary', 'GetFAQuestions', payload).toPromise();
    }

    public getMapImage() {
        let payload = { "urlData": [] };
        this.commonService.get('DocumentLibrary', 'GetStateMapImage', payload).subscribe((event: any) => {
            if (event != null) {
                if (!!event?.pdfStream) {
                    this.imageSrc = event.pdfStream;
                }
            }
        });
    }

    // ========= Upload the Image =====================
    public uploadImage(events, fileupload) {
        if (!!events.files[0].type.includes('image/')) {
            let fileToUpload = events.files[0];
            let formData = new FormData();
            formData.append('Files', fileToUpload, fileToUpload.name);
            let payload = { "payloadData": formData }
            this.commonService.post('upload', 'ImageUpload', payload).subscribe((event: any) => {
                if (!!event && !!event?.pdfStream) {
                    this.imageSrc = event.pdfStream;
                    this.openToast('success', "State Availability Map Uploaded Successfully")
                } else {
                    this.openToast('error', "Upload Failed")
                }
            });
        }
        fileupload.clear();
    }

    // ========= Toast Message  =====================
    public openToast(type, body) {
        this.messageService.add({ key: 'uploadImagePopUp', sticky: true, severity: type, summary: body });
        document.body.style.pointerEvents = "none";
    }
    public onReject(type) {
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
    }
    downloadSampleTemplate(id, fileName) {
        this.serviceLayer.downloadSampleTemplate(id, fileName);
    }
}
