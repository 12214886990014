<div class="container">
   <div class="row">
      <div class="col-lg-12">
         <p-tabView>
            <button (click)="openOtherTabs('documentLibrary')" class="btnsuccess documentlibText">
               Agent Reference Guide
            </button>
         </p-tabView>
      </div>
   </div>
   <div class="box_sec mt-4">
      <div class="row">
         <div class="col-lg-12">
            <div class="submission_content text-center " *ngIf="dao?.SubmissionList?.length > 0">
               <p class="m-0">Welcome back!</p>
               <p class="m-0">Would you like to continue with a prior non-fleet submission? Select below and start
                  where you left off!
               </p>
               <p class="m-0">Incomplete submissions are not considered cleared.</p>
            </div>
            <div class="submission_content text-center " *ngIf="dao?.SubmissionList?.length == 0">
               <p class="m-0">Welcome back!</p>
               <p class="m-0">You have no pending submissions for our Non-Fleet product.</p>
               <p class="m-0"><small>Please begin submission by selecting "Create a New Submission".</small>
               </p>
            </div>
         </div>
      </div>
      <div>
         <div class="row" style="margin-top: 10px;">
            <div class="col-lg-12 mt-2">
               <p-tabView [(activeIndex)]="activeIndex">
                  <p-tabPanel header="My Submissions">
                     <div class="row">
                        <div class="col-lg-12" style="margin-bottom: 5px;">
                           <div class="float-right text-right mb-2">
                              <button type="button" *ngIf="dao?.UserRole == 'admin' && dao?.IsEndorsementActive == true"
                                 class="btnsuccess mr-2" name="btnEndorsement" id="btnEndorsementID"
                                 title="Create a New Endorsement" (click)="openOtherTabs('endorsement')">
                                 <i class="fa fa-files-o" aria-hidden="true"></i>
                                 Create a New Endorsement
                              </button>
                              <button type="button" class="btnsuccess" name="btnCreateNewSubmission"
                                 id="btnCreateNewSubmissionID" (click)="dao.PolicySubTemplate = 'dot'"
                                 title="Create a New Submission">
                                 <i class="fa fa-files-o" aria-hidden="true"></i>
                                 Create a New Submission
                              </button>
                           </div>
                        </div>
                     </div>
                     <work-in-progress-template [workInProgressSearchModal]="WIPSearchModal"
                        (onSearchButtonClicked)="searchWIPFilterData(false, $event)"
                        [hasNoWorkInProgressResults]="searchWIPShowNoResults" [workInProgressStatusDropdown]="dao.WIPstatusDropdown"
                        (onSubmissionItemSelected)="onRowSelect($event)" [isAgencyView]="false"
                        [workInProgressData]="dao?.SubmissionList">
                     </work-in-progress-template>
                  </p-tabPanel>
                  <p-tabPanel header="Endorsements">
                     <endorsement-view></endorsement-view>
                  </p-tabPanel>
                  <p-tabPanel header="Agency's Submission">
                     <work-in-progress-template [workInProgressSearchModal]="AgencyWIPSearchModal"
                        (onSearchButtonClicked)="searchWIPFilterData(true, $event)"
                        [hasNoWorkInProgressResults]="searchWIPShowNoResults" [workInProgressStatusDropdown]="dao.WIPstatusDropdown"
                        [isAgencyView]="true" [workInProgressData]="dao?.AgencySubmissionList">
                     </work-in-progress-template>
                  </p-tabPanel>
               </p-tabView>
            </div>
         </div>
      </div>
   </div>
</div>