import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { AddVehicleModal } from '../../modal/Endorsements/AddVehicleModel';
import { emptyModal } from '../../modal/DAO';
import { Address } from 'src/app/modal/address.models';
import {AdditionalInsuredFormGroup} from 'src/app/modal/Endorsements/Addittional-Insured-Form-Group'

export class VehicleFormGroup extends FormGroup {
    constructor(readonly addVehicle: AddVehicleModal, readonly formBuilder: FormBuilder = new FormBuilder()) {
      super({
            vehicleType: new FormControl(addVehicle.vehicleType.answer, VehicleFormGroup.getValidator(addVehicle.vehicleType)),
            vehicleYear: new FormControl(!!addVehicle.vehicleYear.answer ? addVehicle.vehicleYear.answer : null, VehicleFormGroup.getValidator(addVehicle.vehicleYear)),
            vehicleMake: new FormControl(addVehicle.vehicleMake.answer, VehicleFormGroup.getValidator(addVehicle.vehicleMake)),
            vehicleModel: new FormControl(addVehicle.vehicleModel.answer, VehicleFormGroup.getValidator(addVehicle.vehicleModel)),
            vehicleVin: new FormControl(addVehicle.vehicleVin.answer, VehicleFormGroup.getValidator(addVehicle.vehicleVin)),
            vehicleStatedAmount: new FormControl(!!addVehicle.vehicleStatedAmount.answer ? addVehicle.vehicleStatedAmount.answer : null, VehicleFormGroup.getValidator(addVehicle.vehicleStatedAmount)),
            vehicleCustomPartsParent: new FormControl(addVehicle.vehicleCustomPartsParent.answer, VehicleFormGroup.getValidator(addVehicle.vehicleCustomPartsParent)),
            vehicleCustomAmount: new FormControl(!!addVehicle.vehicleCustomAmount.answer ? addVehicle.vehicleCustomAmount.answer : null, VehicleFormGroup.getValidator(addVehicle.vehicleCustomAmount)),
            vehicleCustomDesc: new FormControl(addVehicle.vehicleCustomDesc.answer, VehicleFormGroup.getValidator(addVehicle.vehicleCustomAmount)),
            vehicleAddInsuredOrLossPayee: new FormControl(addVehicle.vehicleAddInsuredOrLossPayee.answer, VehicleFormGroup.getValidator(addVehicle.vehicleCustomAmount)),
            additionalInsuredList: formBuilder.array([]),
            lossLetter: formBuilder.array([])
        });
        
        this.handleInitialValuesandValidation();
        this.subscribeToValueChanges();
    }

    static getValidator(control: emptyModal) {
        if (control.required) {
            return Validators.required;
        }
        return null;
    }
  
    private handleInitialValuesandValidation(): void{
        if(this.vehicleAddInsuredOrLossPayee.value === 'Yes' || this.addVehicle.vehicleAddInsuredOrLossPayee.answer == "Yes"){
            this.initializeAdditionalInsuredList();
        }

        if(this.vehicleCustomPartsParent.value === 'No'){
            this.updateValueValidators(this.vehicleCustomPartsParent.value, this.vehicleCustomAmount);
            this.updateValueValidators(this.vehicleCustomPartsParent.value, this.vehicleCustomDesc);
        }

        if(this.vehicleType.value === 'Trailer'){
            this.updateVehicleVinAndModelValidators(this.vehicleType.value);
        }
    }

    private subscribeToValueChanges() {
        this.vehicleType.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleType.answer = value;
            this.updateVehicleVinAndModelValidators(value);
        });

        this.vehicleYear.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleYear.answer = value;
        });

        this.vehicleMake.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleMake.answer = value;
        });

        this.vehicleModel.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleModel.answer = value;
        });

        this.vehicleVin.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleVin.answer = value;
        });

        this.vehicleStatedAmount.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleStatedAmount.answer = value;
        });

        this.vehicleCustomAmount.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleCustomAmount.answer = value;
        });

        this.vehicleCustomDesc.valueChanges.subscribe((value: string) => {
            this.addVehicle.vehicleCustomDesc.answer = value;
        });

        
        this.vehicleCustomPartsParent.valueChanges.subscribe((value: string) => {
            this.vehicleCustomPartsParent.setValue(value, { emitEvent: false });
            this.addVehicle.vehicleCustomPartsParent.answer = value;
            this.updateValueValidators(value, this.vehicleCustomAmount);
            this.updateValueValidators(value, this.vehicleCustomDesc);
        });

        this.vehicleAddInsuredOrLossPayee.valueChanges.subscribe((value: string) => {
            this.vehicleAddInsuredOrLossPayee.setValue(value, { emitEvent: false });
            if (value == "No" && this.addVehicle.vehicleAddInsuredOrLossPayee.answer != value) {
                this.deleteExceptFirst();
            }
            this.addVehicle.vehicleAddInsuredOrLossPayee.answer = value;
        });
    }

    // Update validation and data based on the value
    private updateValueValidators(value: string, control: AbstractControl): void {
        if (value === 'No') {
            control.clearValidators();
            control.setValue(null);
        }
        else {
            control.setValidators(Validators.required);
        }
        control.updateValueAndValidity();
    }

    private updateVehicleVinAndModelValidators(vehicleType: string): void {
        if (vehicleType === 'Trailer') {
            // If vehicle type is "Trailer", remove the required validator from vehicleMake and vehicleModel controls
            this.vehicleVin?.clearValidators();
            this.vehicleModel?.clearValidators();
        } else {
            // Otherwise, reapply the required validator
            this.vehicleVin?.setValidators(VehicleFormGroup.getValidator(this.addVehicle.vehicleMake));
            this.vehicleModel?.setValidators(VehicleFormGroup.getValidator(this.addVehicle.vehicleModel));
        }
        // Trigger validation update
        this.vehicleVin?.updateValueAndValidity();
        this.vehicleModel?.updateValueAndValidity();
    }

    private initializeAdditionalInsuredList(): void {
            let additionalInsuredList =  this.additionalInsuredList;
            let vehicleAdditionalInsured = this.addVehicle.AdditionalInsuredList;
            if (additionalInsuredList && vehicleAdditionalInsured) {
                this.addVehicle.AdditionalInsuredList.map(insuredList => this.additionalInsuredList.push(new AdditionalInsuredFormGroup(insuredList, this.formBuilder)));
            }
     }

    private deleteExceptFirst(): void {
        this.addVehicle.AdditionalInsuredList.splice(1);
        if (this.addVehicle.AdditionalInsuredList.length > 0) {
            let additionalInsured = this.addVehicle.AdditionalInsuredList[0];
            additionalInsured.address = new Address();
            additionalInsured.addInsuredName.answer = "";
            additionalInsured.addInsuredInterestType.answer = "";
            additionalInsured.addInsuredLoanNumber.answer = "";
        }
        this.additionalInsuredList.clear();
    }
    
    get vehicleType():  FormControl
    {
        return this.get('vehicleType') as FormControl;
    } 
    get vehicleYear ():  FormControl
    {
        return this.get('vehicleYear') as FormControl;
    } 
    get vehicleMake ():  FormControl
    {
        return this.get('vehicleMake') as FormControl;
    } 
    get vehicleModel ():  FormControl
    {
        return this.get('vehicleModel') as FormControl;
    } 
    get vehicleVin ():  FormControl
    {
        return this.get('vehicleVin') as FormControl;
    } 
    get vehicleStatedAmount ():  FormControl
    {
        return this.get('vehicleStatedAmount') as FormControl;
    } 
    get vehicleCustomPartsParent ():  FormControl
    {
        return this.get('vehicleCustomPartsParent') as FormControl;
    } 
    get vehicleCustomAmount ():  FormControl
    {
        return this.get('vehicleCustomAmount') as FormControl;
    } 
    get vehicleCustomDesc ():  FormControl
    {
        return this.get('vehicleCustomDesc') as FormControl;
    } 
    get vehicleAddInsuredOrLossPayee ():  FormControl
    {
        return this.get('vehicleAddInsuredOrLossPayee') as FormControl;
    } 

    get additionalInsuredList():  FormArray
    {
        return this.get('additionalInsuredList') as FormArray;
    }

    get lossLetter():  FormArray
    {
        return this.get('lossLetter') as FormArray;
    }
}
