import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@rss/ng-auth-service';
import { takeUntil } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';

@Component({
    selector: 'app-user-authorize',
    templateUrl: './user-authorize.component.html',
    styleUrls: ['./user-authorize.component.css']
})
export class UserAuthorizeComponent extends AuthenticatedUserComponent implements OnInit {

    constructor(
        private appComponent: AppComponent,
        protected authenticationService: AuthenticationService,
        private router: Router) {
        super(authenticationService);
    }

    ngOnInit(): void {
        this.appComponent.triggerLoader('show');
        
        this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(() => {
            this.router.navigateByUrl('/home');
        });
    }
}
