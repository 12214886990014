import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicenseNumberDirective } from 'src/app/validations/license-number-validator.directive';
import { MinAmtDirective } from '../validations/min-amt.directive';
import { AlphaCharactersValidatorDirective } from './alpha-characters-validator.directive';
import { AlphaNumericCharactersValidatorDirective  } from '../validations/alpha-numeric-characters-validator.directive';

@NgModule({
    declarations:
        [
            AlphaCharactersValidatorDirective,
            AlphaNumericCharactersValidatorDirective,
            LicenseNumberDirective,
            MinAmtDirective
        ],
    imports:
        [
            CommonModule
        ],
    exports:
        [
            AlphaCharactersValidatorDirective,
            AlphaNumericCharactersValidatorDirective,
            LicenseNumberDirective,
            MinAmtDirective
        ]
})
export class SharedDirectivesModule { }