import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService, AuthConfig } from '@rss/ng-auth-service';
import { Settings } from './settings';
import { DAO } from '../modal/DAO';
import { Auth0UserService } from './auth0UserService.service';
import { AppConfig } from '../app.config';
/**
 * This guard is an example of how you would provide a `AuthConfig` to the `AuthenticationService`.
 * This is just one way, not the only way. Note that as of now (v1.0.0) if you call login multiple times for different configs
 * unexpected things may happen. I have a hunch that it has to do with SalteAuth's use of SessionStorage and Cookies, but I'm not positive.
 * For now, avoid letting users switch `AuthConfig`s
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authenticationService: AuthenticationService, 
        public appConfig: AppConfig, 
        private setting: Settings,
        private router: Router,
        private dao:DAO,
        private auth0UserService: Auth0UserService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if(this.appConfig.auth0Enabled)
        {
            if(!!this.dao.auth0User1)
                return true;
            else
            {
                this.auth0UserService.login()
            }
            return !!this.dao.auth0User1?.agencycode;
        }
        else
        {
            if (!this.authenticationService.isAuthenticated) {        
                const config = this.getAuthConfigForRoute(state.url);
                this.authenticationService.login(config, state.url);
            }

            return this.authenticationService.isAuthenticated;
        }
    }

    private getAuthConfigForRoute(route: string): AuthConfig {
        const baseConfig: AuthConfig = {
            configId: 'BSB',
            clientId: this.setting.authToken,
            providerUrl: this.setting.providerUrl,
            redirectUrl: this.setting.redirectUrl, //'http://usilg01-ist109:65007/ <-- is Test & local is --> http://localhost:4200/'
            unauthenticatedUrl: this.setting.unauthenticatedUrl,
            unauthorizedRedirect:  this.setting.unauthorizedRedirect,
            apiInGateway: false,
            apiBaseUrl:  this.setting.apiBaseUrl,  // we'll set this individually for each company
            unprotectedRoutes: [
            ],
        };

        return baseConfig;
    }
}
