<div class="container">
    <div class="main_content mt-2">
        <form [formGroup]="deleteVehicleForm">
            <div class="row mt-0">
                <div class="col-lg-3 col-md-4 pl-0">
                    <list-view *ngIf="!!vehicleList" header="PU / Trailer -" listName="PU / Trailer"
                        [labelFormat]="'{vin.answer}'" [itemList]="vehicleList" [maxItemLimit]="30"
                        [blankDataValidationProperties]="['vin.answer']" (itemClicked)="clickedItem($event)">
                    </list-view>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <span class="text-lg-right float-lg-right">
                        <cancel-endorsement [endorsementId]="endorsement.endorsementId"></cancel-endorsement>
                    </span>
                    <div class="clearfix"></div>
                    <div class="vehicle_details1 mt-4" *ngIf="!!vehicleList?.currentItem">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="mb-1">
                                    <b>Delete Unit</b>
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="type">Select Vehicle Type</label>
                                        <span style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-dropdown id="type" formControlName="type" name="type"
                                            [options]="vehicleTypeDropdownValues" placeholder="Select Vehicle Type"
                                            [class.validateDrodown]="isSubmitted && deleteVehicleForm.get('type').invalid">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="vin">VIN #</label>
                                        <span style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="text" id="vin" formControlName="vin" name="vin"
                                            class="form-control" placeholder="Vehicle ID Number"
                                            [class.validate_error_cls]="isSubmitted && deleteVehicleForm.get('vin').invalid"
                                            maxlength="17">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12 text-center">
                                        <span class="text-lg-right float-lg-right">
                                            <button type="button" name="addVehicle" id="addVehicle" class="innerSubButton" (click)="addVehicle()">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                Enter Vehicle for Deletion
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-4" *ngIf="vehicleList.currentItem.uploadedDocuments.enabled">
                            <endorsement-file-upload-form #fileUploadForm [questionGroup]="endorsementQuestionGroup"></endorsement-file-upload-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12">
                    <div class="col-mg-6 p-0 mt-3">
                        <span class="text-lg-left float-lg-left" style="margin-left: 250px;">
                            <modify-endorsement-type [endorsement]="endorsement"></modify-endorsement-type>
                        </span>
                    </div>
                    <endorsement-footer 
                        [parentPage]="this"
                        (clickedViewApplication)="bindAnswersToQuestions()">
                    </endorsement-footer>
                </div>
            </div>
        </form>
    </div>
</div>