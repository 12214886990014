<footer>    
    <div class="footer_sec1">
        <div class="container-fluid">
            <div class="row m-0">
                <div class="col-lg-12 d-flex p-0">
                    <div class="col-lg-4 mb-3 mb-lg-0">
                        <img src="../../../assets/img/BSB_logo.JPG" class="img-fluid">
                    </div>
                    <div class="col-lg-6 ml-3 clr_white">
                        <h3>855-272-7555 <small></small></h3>
                        <p class="mb-1">Berkley Small Business Solutions<br />
                            433 South Main Street, Suite 200 <br />
                            West Hartford, CT 06110<br />
                        </p>
                        <div class="row" style="margin-left: 1px;">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <p class="mb-1" style="margin-left: 8px;">info@berkleysmallbusiness.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clr_white mt-3 mb-0 m-0">
                <div class="col-lg-12 d-flex p-0">
                    <div class="col-lg-5">
                        <p class="mb-0">&copy; {{currentYear}} Berkley Small Business Solutions</p>
                    </div>
                    <div class="col-lg-7 text-lg-right float-right">
                        <p class="mb-0">
                            <a href="http://wrbc.info/Licensing/License.htm" target="_blank">Licensing</a> &nbsp;|&nbsp;
                            <a href="https://www.berkley.com/privacy" target="_blank">Privacy Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer_sec2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    |&nbsp; a Berkley Company
                </div>
            </div>
        </div>
    </div>
</footer>
