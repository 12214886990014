import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO } from '../../modal/DAO'
import { IBasePage } from '../../modal/IBasePage.modules'
import { FormGroupDirective, NgForm } from '@angular/forms';
import { skip, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
declare var jQuery: any;
import _ from 'lodash';
import { MessageService } from 'primeng/api';
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { StateSettings } from 'src/app/modal/state.models';
import { MenuClickContext } from '../../modal/routing.models'
import { QuestionAnswer, Questionnaire, QuestionnaireKeys } from 'src/app/modal/questionnaire.models';

@Component({
  selector: 'app-under-writer',
  templateUrl: './under-writer.component.html',
})
export class UnderWriterComponent implements OnInit, OnDestroy, IBasePage {
  public InValidAns: boolean = false;
  public IsAddBtnClicked: boolean = false;
  public UWList: any;
  public initialUWList: any;
  public showTerminalName: boolean = false;
  public cargoUwTerminalAddress: any[];
  public initialCargoUwTerminalAddress: any[];
  public CheckboxquesIndex: number = -1;
  @Input() RollbackUwData: EventEmitter<string>;
  private deletedIndex: number = 0;
  private isDOTListLoaded: boolean = false;
  private meunuClickSubscription: Subscription;
  private apiCallSubscription: Subscription;
  private RollbackUwDataSubscription: Subscription;

  @ViewChild('UWform') UWform: NgForm;

  constructor(public serviceLayer: ServiceLayer, public dao: DAO, private messageService: MessageService,
    public menuComponent: menuComponent, public stateSettings: StateSettings
  ) { }

  // ========= Page Load =====================
  ngOnInit(): void {
    console.log("UnderWriterComponent inited");
    setTimeout(() => {
      this.meunuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
        (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
      );
      this.RollbackUwDataSubscription = this.RollbackUwData.subscribe(
        (page) => this.onRollback(page)
      )
      this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
        (dotSubmissionDetails) => {
          this.initializeData(dotSubmissionDetails);
        });
    }, 100);
  }

  menuClicked(menuClickContext: MenuClickContext) {
    if (menuClickContext != null && menuClickContext.page != "uw") {
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = !_.isEqual(this.UWList, this.initialUWList)
        || !_.isEqual(this.cargoUwTerminalAddress, this.initialCargoUwTerminalAddress)
    }
  }

  ngDoCheck() {
    if (!this.isDOTListLoaded && !!this.UWList) {
      this.isDOTListLoaded = true;
    }
    this.determineIfFieldsAreShown();
  }

  private determineIfFieldsAreShown(): void {
    const uw = this.dao?.DOTList?.questionnaire?.find(x => x.key === 'uw');
    this.showTerminalName = uw?.questionAnswer.some(x => x.key === 'terminaluwName');
  }

  isPageValid(): boolean {
    return (!!this.UWform?.valid) && this.checkUWValidation();
  }

  onRollback(currentTempage) {
    if (currentTempage == "uw") {
      this.UWList = _.cloneDeep(this.initialUWList);
      this.dao.DOTList.uwQsnr.questionAnswer = this.UWList;
    }
  }

  // ========= Save Underwritter =====================
  public async saveAndContinue(form) {
    this.dao.NotAllowedToSubmit = false;

    if (form.invalid || !this.checkUWValidation()) {
      if (!this.dao.IsSavedFromMenu)
        this.openValidPopup();
      else
        this.dao.NotAllowedToSubmit = true;
      return;
    }
    this.setUwDataForSave();
    let response = await this.serviceLayer.save();
    if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission' });

    } else {
      if (!!response) {
        if (!this.dao.IsSavedFromMenu)
          this.openValidPopup();
      }
      else {
        this.dao.NotAllowedToSubmit = true;

        return false
      }
      return true;
    }
  }
  // ========= Check UW validation =====================
  public checkUWValidation() {
    let IsValidUW = true;
    if (!!this.UWList && this.UWList.length > 0) {
      this.UWList.forEach(uw => {
        if (uw.required && (uw.parentKey === '' || uw.parentKey === uw.key) && (uw.answer == null || uw.answer === '')) {
          IsValidUW = false;
        }
        if (uw.required && uw.parentKey != '') {
          if (this.UWList.filter(x => x.key == uw.parentKey && x.answer == uw.parentValue).length > 0 && (uw.answer == null || uw.answer === '') && uw.key != 'legalLiabilityPropertyChild') {
            IsValidUW = false;
          }
        }
      })
    }
    return IsValidUW;
  }

  // ========= Open validation popup =====================
  public openValidPopup() {
    if (!this.dao.IsSavedFromMenu) {
      this.InValidAns = true;
      this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Underwriting', detail: 'Please answer the required questions.' });
      this.dao.isClickFromMenu = false;
    } else {
      this.dao.NotAllowedToSubmit = true
    }
  }

  private getDefaultCargoTerminalAddress(): any {
    const usePremisesName: boolean = !!this.UWList.find(f => f.key == 'terminaluwName');

    let currentKey = this.terminalAddressCount;
    const cargoTerminalAddress = {
      Street: '',
      City: '',
      State: '',
      PostalCode: '',
      key: "cargoTerminalAddress",
      rowIndex: 1 + currentKey
    }

    if (usePremisesName) {
      cargoTerminalAddress['Name'] = '';
    }

    return cargoTerminalAddress;
  }

  // ========= Change the answer =====================
  ChangeAns(event, index, type) {
    if (!!this.UWList && this.UWList.length > 0) {
      if (this.UWList.filter(uw => uw.type == 'Text' && uw.parentKey == this.UWList[index].key) &&
        this.UWList.filter(uw => uw.type == 'Text' && uw.parentKey == this.UWList[index].key)[0] &&
        this.UWList[index].key != 'legalLiabilityPropertyParent') {
        this.UWList.filter(uw => uw.type == 'Text' && uw.parentKey == this.UWList[index].key)[0].answer = '';
      }
      if (type == "Y" && this.UWList.filter(uw => uw.type == 'Radio' 
                && (this.UWList[index].key == 'refrigerationTrailersParent' ||this.UWList[index].key == 'dumpApplicantParent' )) &&
        this.UWList.filter(uw => uw.type == 'Radio' && (this.UWList[index].key == 'refrigerationTrailersParent'||this.UWList[index].key == 'dumpApplicantParent' ))[0]) {
        this.UWList.filter(uw => uw.type == 'Radio' && uw.parentKey == this.UWList[index].key)[0].answer = '';
      }
      if (this.UWList[index].key == 'legalLiabilityPropertyParent') {
        if (type.toUpperCase() == "Y") {
          const defaultCargoAddress = this.getDefaultCargoTerminalAddress();
          this.cargoUwTerminalAddress = [defaultCargoAddress];
        } else {
          this.cargoUwTerminalAddress = [];
        }
      }
    }
  }
  // ========= Cancel my submission =====================
  public CancelmySubmission() {
    return this.serviceLayer.CancelmySubmission();
  }
  // ========= cancel my submission popup =====================
  public cancelMySubmissionPopUp() {
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
  }
  // ========= On Reject =====================
  public onReject(keyName) {
    this.messageService.clear(keyName);
    document.body.style.pointerEvents = "visible";
  }
  // ========= On Back =====================
  public onBack(page) {
    this.dao.IsBackButtonClicked = true;
    return this.menuComponent.menuClick(page);
  }

  // ========= Adding Another Terminal Address=============
  public async AddAnotherTerminalAddress() {
    const address = this.getDefaultCargoTerminalAddress();
    this.cargoUwTerminalAddress.push(address);
    this.IsAddBtnClicked = false;
  }

  // ========= Deleting Terminal Address==================
  public removeUwTerminalAddress(index) {
    this.deletedIndex = index;
    if (this.cargoUwTerminalAddress[index].Name !== '' &&
      this.cargoUwTerminalAddress[index].Street !== '' &&
      this.cargoUwTerminalAddress[index].City !== '' &&
      this.cargoUwTerminalAddress[index].State !== '' &&
      this.cargoUwTerminalAddress[index].PostalCode !== '') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'deleteTerminalAddress', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
    }
    else {
      this.confirmRemove();
    }
  }

  public async confirmRemove() {
    this.cargoUwTerminalAddress.splice(this.deletedIndex, 1)
    this.messageService.clear('deleteTerminalAddress');
    document.body.style.pointerEvents = "visible";
  }

  public initializeData(apiResponse: any) {
    if (!!!apiResponse)
      return;
    let uwQuestions: Array<QuestionAnswer> = apiResponse.questionnaire.filter(x => x.key == 'uw')[0].questionAnswer;
    var index = 1;
    this.CheckboxquesIndex = -1;
    uwQuestions.forEach((uw, i) => {
      if (uw.parentKey == '' || uw.parentKey == uw.key) {
        uw.parentSequence = index.toString();
        index = index + 1;
      }
      if (uw.group == 'Group2' && this.CheckboxquesIndex == -1) {
        this.CheckboxquesIndex = i;
      }
      if (uw.group == 'Group2') {
        uw.answer = uw.answer == "true";
      }
    });
    let tempCargoUwTerminalAddress = [];
    let getMaxRow = '$..questionnaire[?(@.key =="uw")].questionAnswer[?(@.parentKey =="legalLiabilityPropertyParent")].rowIndex';
    let getMaxRowIndex = _.max(this.serviceLayer.getObjUsingJpath(apiResponse, getMaxRow));

    const terminaluwNames: any[] = uwQuestions.filter(f => f.key == 'terminaluwName') ?? [];
    const terminaluwStreets: any[] = uwQuestions.filter(f => f.key == 'terminaluwStreet') ?? [];
    const terminaluwCities: any[] = uwQuestions.filter(f => f.key == 'terminaluwCity') ?? [];
    const terminaluwStates: any[] = uwQuestions.filter(f => f.key == 'terminaluwState') ?? [];
    const terminaluwPostalCodes: any[] = uwQuestions.filter(f => f.key == 'terminaluwPostalCode') ?? [];

    for (var i = 0; i < getMaxRowIndex; i++) {
      const cargoTerminalAddress = {
        Street: terminaluwStreets[i]?.answer,
        City: terminaluwCities[i]?.answer,
        State: terminaluwStates[i]?.answer,
        PostalCode: terminaluwPostalCodes[i]?.answer,
        key: "cargoTerminalAddress",
        rowIndex: 1 + i,
      };
      if (terminaluwNames.length > i) {
        cargoTerminalAddress['Name'] = terminaluwNames[i].answer;
      }
      tempCargoUwTerminalAddress.push(cargoTerminalAddress);
    }

    this.UWList = uwQuestions;
    this.initialUWList = _.cloneDeep(uwQuestions);
    this.cargoUwTerminalAddress = tempCargoUwTerminalAddress;//have to reset this way, otherwise ui binding will not work
    this.initialCargoUwTerminalAddress = _.cloneDeep(this.cargoUwTerminalAddress)
  }

  public trackItem(index: number, item: any) {
    return `${item.key}-${item.rowIndex}-${item.sequence}`;
  }

  public get terminalAddressCount(): number {
    return this.cargoUwTerminalAddress.length;
  }

  public setUwDataForSave() {
    _.remove(this.UWList, function (n) {
      return n.parentKey == 'legalLiabilityPropertyParent' && n.key != 'legalLiabilityPropertyChild' && n.rowIndex > 1;
    });
    if (!!this.cargoUwTerminalAddress && this.cargoUwTerminalAddress.length > 0 && !!this.UWList.filter(c => c.parentKey == "legalLiabilityPropertyParent")[0]) {
      this.cargoUwTerminalAddress.forEach((address, index) => {
        //============set value to contacts========
        if (index != 0) {

          if (this.UWList.find(c => c.key == "terminaluwName")) {
            var TempNameModel = _.cloneDeep(this.UWList.filter(c => c.key == "terminaluwName")[0]);
            TempNameModel.rowIndex = index + 1;
            TempNameModel.answer = address.Name;
            this.UWList.push(TempNameModel)
          }

          var TempStreetModel = _.cloneDeep(this.UWList.filter(c => c.key == "terminaluwStreet")[0]);
          var TempCityModel = _.cloneDeep(this.UWList.filter(c => c.key == "terminaluwCity")[0]);
          var TempStateModel = _.cloneDeep(this.UWList.filter(c => c.key == "terminaluwState")[0]);
          var TempZipModel = _.cloneDeep(this.UWList.filter(c => c.key == "terminaluwPostalCode")[0]);

          TempStreetModel.rowIndex = index + 1;
          TempCityModel.rowIndex = index + 1;
          TempStateModel.rowIndex = index + 1;
          TempZipModel.rowIndex = index + 1;

          TempStreetModel.answer = address.Street;
          TempCityModel.answer = address.City;
          TempStateModel.answer = address.State;
          TempZipModel.answer = address.PostalCode;

          this.UWList.push(TempStreetModel)
          this.UWList.push(TempCityModel)
          this.UWList.push(TempStateModel)
          this.UWList.push(TempZipModel)
        } else {
          let getNameIndex = this.UWList.findIndex(c => c.key == "terminaluwName");
          let getStreetIndex = this.UWList.findIndex(c => c.key == "terminaluwStreet");
          let getCityIndex = this.UWList.findIndex(c => c.key == "terminaluwCity");
          let getStateIndex = this.UWList.findIndex(c => c.key == "terminaluwState");
          let getZipIndex = this.UWList.findIndex(c => c.key == "terminaluwPostalCode");

          if (getNameIndex > -1) {
            this.UWList[getNameIndex].answer = address.Name;
          }

          this.UWList[getStreetIndex].answer = address.Street;
          this.UWList[getCityIndex].answer = address.City;
          this.UWList[getStateIndex].answer = address.State;
          this.UWList[getZipIndex].answer = address.PostalCode;
        }
      });
    }
    this.dao.DOTList.uwQsnr.questionAnswer = this.UWList;
  }

  public ngOnDestroy(): void {
    if (!!this.meunuClickSubscription)
      this.meunuClickSubscription.unsubscribe();

    if (!!this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.apiCallSubscription = null;
    }
    if (!!this.RollbackUwDataSubscription) {
      this.RollbackUwDataSubscription.unsubscribe();
      this.RollbackUwDataSubscription = null;
    }
  }
}
