import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  private _confirmationDialogData = new BehaviorSubject<any>(null);
  public confirmationDialogData = this._confirmationDialogData.asObservable();
  constructor() { }

  public updateDialogData(data: any){
   this._confirmationDialogData.next(data);
  }
}
