<div class="container box_sec">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <h6 class="createnewuser-btn" style="width: 14%;">
                    <i class="fa fa-list" aria-hidden="true" style="margin-right: 3px;"></i>Banner List
                </h6>
            </div>
            <p-card class="userBorderList bannerlist">
                <div class="table-responsive">
                    <button class="btnsuccess float-right mr-2 mb-1" title="Create New Banner" name="btnCreateNewBanner"
                        id="btnCreateNewBanner" data-toggle="modal" type="button" (click)="createNewBanner()">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Create New Banner
                    </button>
                    <p-table class="table llh_table p-datatable-striped" [value]="bannerlist" #dt1
                        styleClass="p-datatable-striped" [rows]="10" [paginator]="true">pt
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 12%;">Status</th>
                                <th style="width: 15%;">Type</th>
                                <th style="width: 35%;">Message</th>
                                <th style="width: 15%;text-align: center;">Effective From</th>
                                <th style="width: 15%;text-align: center;">Effective To</th>
                                <th style="width: 8%;text-align: center;">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template let-item let-i=rowIndex pTemplate="body">
                            <tr [pSelectableRow]="item">
                                <td style="word-wrap: break-word" title="{{item?.enabled}}">
                                    {{item?.enabled ? 'ON' : 'OFF'}}
                                </td>
                                <td style="word-wrap: break-word" title="{{item?.severity}}">
                                    {{item?.severity | dropDownPipe: bannerTypeQuestionOptions}}
                                </td>
                                <td style="word-wrap: break-word" title="{{item?.text}}">
                                    {{item?.text}}
                                </td>
                                <td style="word-wrap: break-word" title="{{item?.effectiveFrom}}">
                                    {{item?.effectiveFrom | date:"MM/dd/yyyy hh:mm a"}}
                                </td>
                                <td style="word-wrap: break-word" title="{{item?.effectiveTo}}">
                                    {{item?.effectiveTo | date:"MM/dd/yyyy hh:mm a"}}
                                </td>
                                <td style="text-align: center;">
                                    <i class="fa fa-pencil-square-o deleteUserbtn-blue" (click)="editBanner(item)">
                                    </i>
                                    <i class="pi pi-trash deleteUserbtn" (click)="confirmDeleteBanner(item.id)"
                                        style="padding-left: 15px;">
                                    </i>
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-card>
        </div>
    </div>
    <p-dialog header="" [(visible)]="hasCreateOrEditBannerClicked" class="dialog_bulk_edit edit-user-popup"
        [modal]="true" (onHide)="resetBannerData()">
            <upsert-banner-component 
                *ngIf="hasCreateOrEditBannerClicked" 
                [bannerList]="bannerlist"
                [siteBanner]="siteBannerInfo"
                [isEditBanner]="isEditBannerClicked"
                (closeButtonClicked)="resetBannerData()"
                (refreshBannerList)="getandUpdateBannerList()">
            </upsert-banner-component>
    </p-dialog>
    <p-toast position="center" key="warningPopup" preventOpenDuplicates='true' [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    {{message.summary}}
                    <div [innerHTML]="message.detail">{{message.detail}}</div><br />
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnConfirmOptionYes"
                            id="btnConfirmOptionYes" (click)="respondedToWarningPopup('Yes')">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnConfirmOptionNo"
                            id="btnConfirmOptionNoID" (click)="respondedToWarningPopup('No')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>