import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'listbox',
    templateUrl: './listbox.component.html'
})
export class ListboxComponent<T> implements OnInit {

    @Input() header: string;
    @Input() listName: string;
    @Input() labelFormat: string = '';
    @Input() maxItemLimit: number = 0;
    @Input() activeItemIndex: number = 0;
    @Input() blankDataValidationProperties: string[] = []; // This array contains properties used to check for blank data in the item.
    @Input() itemList: T[] = [];

    @Output() addItemButtonClicked = new EventEmitter<void>();
    @Output() removeItemButtonClicked  = new EventEmitter<number>();
    @Output() itemClicked = new EventEmitter<number>();

    public isMasterDeleteCheckBoxChecked: boolean = false;
    public isDataExpanded: boolean = false;

    constructor(private messageService: MessageService) { }

    ngOnInit(): void {
    }

    public addNewItem(): void {
        this.addItemButtonClicked.emit();
    }

    public onItemClick(index: number): void {
        this.itemClicked.emit(index);
    }

    public onChangeMasterCheckBox(event: { checked: boolean; }): void {
        this.itemList.forEach(item => {
            if (!!item)
                item["isDeleteCheckBoxChecked"] = event.checked;
        });
    }

    public onMasterDeleteButtonClick(): void {
        if (this.isAnyItemSelected()) {
            this.removeSelectedDrivers();
        } else {
            this.openToast(`At least one ${this.listName.toLowerCase()} must be selected`);
        }
    }

    private isAnyItemSelected(): boolean {
        return this.itemList.some(item => !!item && !!item["isDeleteCheckBoxChecked"]);
    }

    public confirmRemove(key: string, detail: { type: string, item: T }): void {
        if (detail.type == 'MultipleItem') {
            this.isMasterDeleteCheckBoxChecked = false;
            for (let i = 0; i < this.itemList.length; i++) {
                if (this.itemList[i]['isDeleteCheckBoxChecked']) {
                    this.deleteItem(this.itemList[i]);
                    i--;
                }
            }
        }
        else {
            this.deleteItem(detail.item);
        }
        this.closePopup(key);
    }

    public deleteItem(item: T): void {
        this.activeItemIndex = this.itemList.indexOf(item);
        let isLast = this.activeItemIndex === this.itemList.length - 1;
        this.itemList.splice(this.activeItemIndex, 1);
        if(this.itemList.length > 0){
            this.activeItemIndex += (isLast ? -1 : 0);
        }
        this.removeItemButtonClicked.emit(this.activeItemIndex);
    }
    
    public closePopup(key: string): void {
        this.messageService.clear(key);
        document.body.style.pointerEvents = "visible";
    }

    public getFormattedLabelName(item: T): string {
        if (!!!item || !!!this.labelFormat) {
            return '';
        }

        let formattedString = this.labelFormat.replace(/{(.*?)}/g, (_match, propertyPath) => {
            let nestedValue = this.getNestedValue(item, propertyPath.trim());
            return nestedValue != null ? nestedValue : '';
        });
        return formattedString;
    }

    public hasBlankDataInProperties(item: T): boolean {
        return this.blankDataValidationProperties.some(property => {
            let value = this.getNestedValue(item, property);
            return !!value;
        });
    }

    public isItemValid(item: T): boolean {
        if (this.listName === 'Driver') {
            return item['isValidDriver'];
        } else if (this.listName === 'Vehicle') {
            // Validation for Vehicle screen
        }
        return true;
    }


    public remove(item: T, type: string): void {
        document.body.style.pointerEvents = "none";
        this.messageService.add({
            key: 'removeItem',
            sticky: true,
            severity: 'warn',
            summary: ' Do you want to delete ?',
            data: { type: type, item: item }
        });
    }

    private removeSelectedDrivers(): void {
        document.body.style.pointerEvents = "none";
        this.messageService.add({
            key: 'removeItem',
            sticky: true,
            severity: 'warn',
            summary: ` Do you want to delete the selected ${this.listName.toLowerCase()}(s)? `,
            data: { type: 'MultipleItem' }
        });
    }

    private openToast(body: string): void {
        document.body.style.pointerEvents = "none";
        this.messageService.add({
            key: 'confirmation', sticky: true, severity: 'warn',
            summary: body
        });
    }

    private getNestedValue(item: T, property: string): any {
        if (!!!item || !!!property) {
            return null;
        }

        let properties: Array<string> = property.split('.');
        let nestedValue = item as any;

        for (const prop of properties) {
            if (!!nestedValue && prop in nestedValue) {
                nestedValue = nestedValue[prop];
            } else {
                throw new Error(`Property '${prop}' not found in object.`);
            }
        }

        return nestedValue;
    }


    get isValidToShowAddButton(): boolean {
        return this.itemList.length < this.maxItemLimit;
    }
}