import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any'
})

export class StateDriverLicenseSettings {
  public DriverLicenseQuestionOptions: Array<{ code: string, expression: string, label: string, sequence: number, value: string }> =
    [
      {
        "code": "AL",
        "expression": "^[0-9]{7,8}$",
        "label": "7-8 Numeric",
        "sequence": 1,
        "value": "AL"
      },
      {
        "code": "AK",
        "expression": "^[0-9]{1,7}$",
        "label": "1-7 Numeric",
        "sequence": 2,
        "value": "AK"
      },
      {
        "code": "AZ",
        "expression": "^[0-9]{9}$|^[A-z]{1}[0-9]{8}$",
        "label": "9 Numeric (SSN)\n9 (1 Alpha + 8 Numeric)",
        "sequence": 3,
        "value": "AZ"
      },
      {
        "code": "AR",
        "expression": "^[0-9]{8}$|^[0-9]{9}$",
        "label": "8 Numeric\n-or-\n9 Numeric (SSN)",
        "sequence": 4,
        "value": "AR"
      },
      {
        "code": "CA",
        "expression": "^[A-z]{1}[0-9]{7}$",
        "label": "8 (1 Alpha + 7 Numeric)",
        "sequence": 5,
        "value": "CA"
      },
      {
        "code": "CO",
        "expression": "^[0-9]{9}$",
        "label": "9 Numeric   ",
        "sequence": 6,
        "value": "CO"
      },
      {
        "code": "CT",
        "expression": "^[0-9]{9}$",
        "label": "9 Numeric   ",
        "sequence": 7,
        "value": "CT"
      },
      {
        "code": "DE",
        "expression": "^[0-9]{1,7}$",
        "label": "1-7 Numeric",
        "sequence": 8,
        "value": "DE"
      },
      {
        "code": "DC",
        "expression": "^[0-9]{7}$|^[0-9]{9}$|^[A-z]{2}[0-9]{8}$",
        "label": "7 Numeric\n-or-\n9 Numeric\n-or-\n10 (2 Alpha + 8 Numeric)",
        "sequence": 8,
        "value": "DC"
      },
      {
        "code": "FL",
        "expression": "^[A-z]{1}[0-9]{11,12}$",
        "label": "13 (1 Alpha + 12 Numeric)\n-or-\n12* (1 Alpha + 11 Numeric)",
        "sequence": 9,
        "value": "FL"
      },
      {
        "code": "GA",
        "expression": "^[0-9]{9}$",
        "label": "9 Numeric",
        "sequence": 10,
        "value": "GA"
      },
      {
        "code": "HI",
        "expression": "^[0-9]{9}$|^[Hh]{1}[0-9]{8}$",
        "label": "9 Numeric (SSN)\n-or-\n1 Alpha ('H') + 8 Numeric",
        "sequence": 11,
        "value": "HI"
      },
      {
        "code": "ID",
        "expression": "^[0-9]{9}$|^[A-z]{2}[0-9]{6}[A-z]{1}$",
        "label": "9 Numeric\n-or-\n9 (2 Alpha + 6 Numeric + 1 Alpha)",
        "sequence": 12,
        "value": "ID"
      },
      {
        "code": "IL",
        "expression": "^[A-z]{1}[0-9]{11}$",
        "label": "12 (1 Alpha + 11 Numeric)",
        "sequence": 13,
        "value": "IL"
      },
      {
        "code": "IN",
        "expression": "^[0-9]{10}$|^[Ss]{1}[0-9]{9}$",
        "label": "10 Numeric\n-or-\n1 Alpha S + 9 Numeric",
        "sequence": 14,
        "value": "IN"
      },
      {
        "code": "IA",
        "expression": "^[0-9]{9}$|^[0-9]{3}[A-Z]{2}[0-9]{4}$",
        "label": "9 (3 Numeric + 2 Alpha + 4 Numeric)*\n-or-\n9 Numeric (SSN)*",
        "sequence": 15,
        "value": "IA"
      },
      {
        "code": "KS",
        "expression": "^[A-z0-9]{6}$|^[0-9]{9}$|^[A-z]{1}[0-9]{8}$",
        "label": "6 Alpha/ Numeric\n-or-\n9 Numeric\n-or-\n9 Numeric (SSN)\n-or-\n9 (1 Alpha + 8 Numeric)",
        "sequence": 16,
        "value": "KS"
      },
      {
        "code": "KY",
        "expression": "^[A-z]{1}[0-9]{8}$|^[0-9]{9}$",
        "label": "9 (1 Alpha + 8 Numeric)\n-or-\n9 Numeric (SSN)",
        "sequence": 17,
        "value": "KY"
      },
      {
        "code": "LA",
        "expression": "^[0-9]{1,9}$",
        "label": "1-9 Numeric",
        "sequence": 18,
        "value": "LA"
      },
      {
        "code": "ME",
        "expression": "^[0-9]{7}$",
        "label": "7 Numeric  ",
        "sequence": 19,
        "value": "ME"
      },
      {
        "code": "MD",
        "expression": "^[A-Za-z]{1}[0-9]{12}$|^[M|m]{1}[D|d]{1}[0-9]{11}$",
        "label": "13 (1 Alpha + 12 Numeric)\n-or-\n13 (2 Alpha + 11 Numeric)\n(Alpha has to be MD)",
        "sequence": 20,
        "value": "MD"
      },
      {
        "code": "MA",
        "expression": "^[A-WY-Za-wy-z]{1}[0-9]{8}$|^[0-9]{9}$|^[S|s]{1}[A|a]{1}[0-9]{7}$",
        "label": "9 ( 1 Alpha + 8 Numeric)\n(Alpha cannot be X)\n-or-\n9 Numeric\n-or-\n9 (2 Alpha + 7 Numeric)\n(Alpha has to be SA)",
        "sequence": 21,
        "value": "MA"
      },
      {
        "code": "MI",
        "expression": "^[A-z]{1}[0-9]{12}$",
        "label": "13 (1 Alpha + 12 Numeric)",
        "sequence": 22,
        "value": "MI"
      },
      {
        "code": "MN",
        "expression": "^[A-z]{1}[0-9]{12}$",
        "label": "13 (1 Alpha + 12 Numeric)",
        "sequence": 23,
        "value": "MN"
      },
      {
        "code": "MS",
        "expression": "^[0-9]{9}$",
        "label": "9 Numeric",
        "sequence": 24,
        "value": "MS"
      },
      {
        "code": "MO",
        "expression": "^[A-z]{1}[0-9]{5,9}$|^[0-9]{9,10}$|^[0-9]{9}[A-z]{1}$|^[0-9]{3}[A-z]{1}[0-9]{6}$|^[A-z]{1}[0-9]{15,16}$|^[A-z]{1}[0-9]{15}[A-z]{1}$|^[0-9]{8}[MA|ma|AC|ac]{2}$",
        "label": "6 - 10 (1 Alpha + 5-9 Numeric)\n-or-\n9 - 10 Numeric\n-or-\n10 (9 Numeric + 1 Alpha)\n-or-\n10 (3 Numeric + 1 Alpha + 6 Numeric)\n-or-\n16 (1 Alpha + 15 Numeric)\n-or-\n17 (1 Alpha + 16 Numeric)\n-or-\n17 (1 Alpha + 15 Numeric + 1 Alpha)\n-or-\n10 (8 Numeric + MA or AC*)",
        "sequence": 25,
        "value": "MO"
      },
      {
        "code": "MT",
        "expression": "^[0-9]{13}$|^[0-9]{9}$|^[A-z]{1}[0-9]{1}[A-z0-9]{1}[0-9]{2}(?:(\\s{1})\\1{2}|(-{1})\\2{2}|([A-z]{1})[A-z\\s-]{2})[0-9]{1}$|^[A-z]{1}[0-9]{1}[A-z0-9]{1}[0-9]{2}[^A-z0-9^\\s-]{1}(?:([A-z]{1})[A-z\\s-]{1}|(\\s{1})[\\s-]{1}|(-{1})[A-z-]{1})[0-9]{1}$",
        "label": "9 Numeric\n-or-\n13 Numeric\n-or-\n9 (1 Alpha + 1 Numeric + 1 Alpha/Numeric + 2 Numeric + 3 Special* + 1 Numeric)\n* If the 6th position is a Space, then Positions 7 and 8 must be Spaces. If the 6th position is a Hyphen, then Positions 7 and 8 must be Hyphens. If the 6th position is Alpha, then Positions 7 and 8 must be Alpha, Spaces, or Hyphens. If the 7th position is Alpha, then Position 8 may be Alpha, Space, or Hyphen. If the 7th position is a Space, then Position 8 must be a Space or Hyphen. If the 7th position is a Hyphen, then Position 8 must be Alpha or a Hyphen.",
        "sequence": 26,
        "value": "MT"
      },
      {
        "code": "NE",
        "expression": "^[A-z]{1}[0-9]{3,8}$",
        "label": "4-9 (1 Alpha + 3-8 Numeric)",
        "sequence": 27,
        "value": "NE"
      },
      {
        "code": "NV",
        "expression": "^[0-9]{10}$|^[0-9]{12}$",
        "label": "10 Numeric\n-or-\n12 Numeric",
        "sequence": 28,
        "value": "NV"
      },
      {
        "code": "NH",
        "expression": "^[NHL|nhl]{3}[0-9]{7}[1-9]{1}$|^[0-9]{2}[A-z]{3}[0-9]{4}[1-9]{1}$",
        "label": "11 (NHL + 8 Numeric)\n10 (2 Numeric + 3 Alpha + 5 Numeric)\nLAST POSITION MUST BE NON-ZERO",
        "sequence": 29,
        "value": "NH"
      },
      {
        "code": "NJ",
        "expression": "^[A-z]{1}[0-9]{14}$",
        "label": "15 (1 Alpha + 14 Numeric)",
        "sequence": 30,
        "value": "NJ"
      },
      {
        "code": "NM",
        "expression": "^[0-9]{8,9}$",
        "label": "8-9 Numeric",
        "sequence": 31,
        "value": "NM"
      },
      {
        "code": "NY",
        "expression": "^[0-9]{9}$",
        "label": "9 Numeric*\n* The 9th position is a check digit.",
        "sequence": 32,
        "value": "NY"
      },
      {
        "code": "NC",
        "expression": "^[0-9]{1,12}$",
        "label": "1-12 Numeric",
        "sequence": 33,
        "value": "NC"
      },
      {
        "code": "ND",
        "expression": "^[0-9]{9}$|^[A-z]{3}[0-9]{6}$|^[A-z]{1}[0-9]{8}$",
        "label": "9 Numeric\n-or-\n9 (3 Alpha + 6 Numeric)\nOr\n9 (1 Alpha + 8 Numeric)",
        "sequence": 34,
        "value": "ND"
      },
      {
        "code": "OH",
        "expression": "^[A-z]{2}[0-9]{6}$",
        "label": "8 (2 Alpha + 6 Numeric)",
        "sequence": 35,
        "value": "OH"
      },
      {
        "code": "OK",
        "expression": "^[0-9]{9}$|^[A-HJ-NPR-WYZa-hj-npr-wyz]{1}[0-9]{9}$",
        "label": "9 Numeric\n-or-\n1 Alpha + 9 Numeric\nALPHA CHARACTER MAY NOT BE I, O, Q, or X.",
        "sequence": 36,
        "value": "OK"
      },
      {
        "code": "OR",
        "expression": "^[0]{1}[0-9]{7}$|^[0]{2}[0-9]{7}$|^[0-9]{1,7}$|^[A-z0-9]{1}[0-9]{6}$|^[0]{1}[A-z0-9]{1}[0-9]{6}$|^[0]{2}[A-z0-9]{1}[0-9]{6}$",
        "label": "1-9 numeric\nNotes:\nIf 8 Numeric - 1st position must be 0\nIf 9 Numeric - 1st and 2nd position must be 0\nOr\n(effective 10/21/13)\n7-9 alphanumeric\nNotes:\nIf 7 alphanumeric - 1st position must be alphanumeric\nIf 8 alphanumeric - 1st position must be zero and 2nd position must be alphanumeric\nIf 9 alphanumeric - the first two positions must be zeros and the 3rd position must be alphanumeric\nThe remaining positions are all numeric",
        "sequence": 37,
        "value": "OR"
      },
      {
        "code": "PA",
        "expression": "^[0-9]{8}$",
        "label": "8 Numeric",
        "sequence": 38,
        "value": "PA"
      },
      {
        "code": "RI",
        "expression": "^[0-9]{7,8}$|^[Vv]{1}[0-9]{6}$",
        "label": "8 Numeric\n-or-\n7 Numeric\n-or-\n7 (V + 6 Numeric)",
        "sequence": 39,
        "value": "RI"
      },
      {
        "code": "SC",
        "expression": "^[0-9]{1,10}$",
        "label": "1-10 Numeric",
        "sequence": 40,
        "value": "SC"
      },
      {
        "code": "SD",
        "expression": "^[0-9]{6}$|^[0-9]{8,9}$",
        "label": "6 Numeric\n-or-\n8 Numeric\n-or\n9 Numeric",
        "sequence": 41,
        "value": "SD"
      },
      {
        "code": "TN",
        "expression": "^[0-9]{8,9}$",
        "label": "8 numeric\n-or-\n9 numeric",
        "sequence": 42,
        "value": "TN"
      },
      {
        "code": "TX",
        "expression": "^[0-9]{8}$",
        "label": "8 Numeric",
        "sequence": 43,
        "value": "TX"
      },
      {
        "code": "UT",
        "expression": "^[0-9]{4,9}$|^[0]{1}[0,9]{9}$",
        "label": "4 - 9 Numeric\nor\n10 Numeric*\n* The 1st postion must be 0.",
        "sequence": 44,
        "value": "UT"
      },
      {
        "code": "VT",
        "expression": "^[0-9]{8}$|^[0-9]{7}[Aa]$",
        "label": "8 Numeric\n-or-\n7 Numeric + A",
        "sequence": 45,
        "value": "VT"
      },
      {
        "code": "VA",
        "expression": "^[A-z]{1}[0-9]{8}$|^[0-9]{9}$|^[0-9]{12}$",
        "label": "9 (1 Alpha + 8 Numeric)\n-or-\n9 Numeric (SSN)\n-or-\n12 Numeric",
        "sequence": 46,
        "value": "VA"
      },
      {
        "code": "WA",
        "expression": "^[A-z]{1}[0-9A-z*]{6}[0-9A-z]{2}[0-9]{1}[1-9A-z]{1}[A-NP-Za-np-z]{1}$|^[WDL|wdl]{3}[0-9A-z]{9}$",
        "label": "12 (1 alpha + 6 alpha, numeric, or * + 2 alpha/numeric + 1 numeric + 1 alpha/numeric except zero + 1 alpha/numeric except alpha O\n-or-\nWDL + 9 Alpha/Numeric\n(No special characters)",
        "sequence": 47,
        "value": "WA"
      },
      {
        "code": "WV",
        "expression": "^[0A-GIORSa-giors]{1}[0-9]{6}$|^[XX|xx|1X|1x]{2}[0-9]{5}$",
        "label": "7 (1 Alpha/Numeric + 6 Numeric)\nValid characters for Position 1 are: A, B, C, D, E, F, G, I, O, R,S, or 0 (zero).\n-or-\n7 (2 Alpha/Numeric + 5 Numeric)\nValid characters for Positions 1 and 2 are: XX or 1X.",
        "sequence": 48,
        "value": "WV"
      },
      {
        "code": "WI",
        "expression": "^[A-z]{1}[0-9]{13}$",
        "label": "14 (1 Alpha + 13 Numeric)",
        "sequence": 49,
        "value": "WI"
      },
      {
        "code": "WY",
        "expression": "^[0-9]{9}$|^[0-9]{6}[-]{1}[0-9]{3}$",
        "label": "9 Numeric\n-or-\n10 (6 Numeric + - + 3 Numeric)",
        "sequence": 50,
        "value": "WY"
      }
    ];
}
