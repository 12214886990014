<!-- Is insured covered by Workers Compensation? -->
<radiobutton-question
    *ngIf="insured_wc.enabled"
    #insured_wcRadiobutton
    [question]="insured_wc"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<!--Does the applicant want Extended Personal Injury Protection? -->
<radiobutton-question
    *ngIf="nfPipDed && extendedPip?.enabled && nfPipDed == 'FULL'"
    #extendedPipRadiobutton
    [question]="extendedPip"
    [radiovalue1]="'Y'"
    [radiovalue2]="'N'"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<!--Does the applicant want to exclude coverage for Work Loss? -->
<radiobutton-question
    *ngIf="excludeCoverageForWorkLoss?.enabled"
    #excludeCoverageForWorkLossRadiobutton
    [question]="excludeCoverageForWorkLoss"
    [radiovalue1]="'Y'"
    [radiovalue2]="'N'"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<div *ngIf="!excludeCoverageForWorkLoss?.enabled || excludeCoverageForWorkLoss?.answer == 'Y'">
    <!--PIP Work Loss Exclusion Applies-->
    <dropdown-question
        *ngIf="pipWorkLoss?.enabled"
        #addedPIPDedDropdown
        [question]="pipWorkLoss"
        [disabled]="true"
        [required]="pipWorkLoss?.required"
        [isFormSubmitted]="isFormSubmitted">
    </dropdown-question>
</div>

<dropdown-question
    *ngIf="pipDedAppliesTo.enabled"
    #pipDedAppliesToDropdown
    [question]="pipDedAppliesTo"
    [disabled]="true"
    [required]="pipDedAppliesTo.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Does the applicant wish to exclude Loss of Income Benefits from No-Fault/PIP Coverages? -->
<radiobutton-question
    *ngIf="lossOfIncomeBenefits.enabled"
    #lossOfIncomeBenefitsRadiobutton
    [question]="lossOfIncomeBenefits"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<!-- Do you want Added Personal Injury Protection? -->
<radiobutton-question
    *ngIf="Q_addedPIP.enabled"
    #Q_addedPIPRadiobutton
    [question]="Q_addedPIP"
    [isFormSubmitted]="isFormSubmitted"
    (clicked)="changePIPAnswers()">
</radiobutton-question>

<div *ngIf="!Q_addedPIP.enabled || Q_addedPIP.answer == 'Yes'">
    <!-- Added Personal Injury Protection -->
    <dropdown-question
        *ngIf="addedPIP.enabled"
        #addedPIPDropdown
        [question]="addedPIP"
        [requiredAmtOrDecline]="addedPIP.required"
        [isFormSubmitted]="isFormSubmitted">
    </dropdown-question>
    <!-- Added Personal Injury Protection Deductibles-->
    <dropdown-question
        *ngIf="addedPIPDed.enabled"
        #addedPIPDedDropdown
        [question]="addedPIPDed"
        [requiredAmtOrDecline]="addedPIPDed"
        [required]="addedPIPDed.required"
        [isFormSubmitted]="isFormSubmitted">
    </dropdown-question>
</div>

