import _ from 'lodash';

export class ListViewModel<Type> {
    public initialItems: Array<Type> = [];
    public items: Array<Type> = [];
    public currentPosition: number = 0;

    constructor(
        items: Array<Type>,
        public beforeAddCallback: () => boolean,
        public afterAddCallback: () => void,
        public afterDeleteCallback: () => void,
        public initializeNewItemCallback: () => Type
    ) {
        this.initialItems = _.cloneDeep(items);
        this.items = items;
    }

    public get currentItem(): Type {
        return this.items[this.currentPosition];
    }

    public add() {
        if (!!this.beforeAddCallback && !this.beforeAddCallback()) {
            return; // Abort if beforeAddCallback returns false
        }
        let emptyItem = this.initializeNewItemCallback();
        this.items.push(emptyItem);
        this.currentPosition = this.items.length - 1;
        if (!!this.afterAddCallback) {
            this.afterAddCallback();
        }
    }

    public remove(instance: Type) {
        const i = this.items.indexOf(instance);
        if (i >= 0) {
            this.items.splice(i, 1);
            if (this.currentPosition >= this.items.length) {
                this.currentPosition = this.items.length - 1;
            }
        }
        if (!!this.afterDeleteCallback) {
            this.addEntryIfListIsEmpty();
            this.afterDeleteCallback();
        }
    }

    public removeAt(index: number) {
        if (index >= 0 && index < this.items.length) {
            this.items.splice(index, 1);
            if (this.currentPosition >= this.items.length) {
                this.currentPosition = this.items.length - 1;
            }
        }
        if (!!this.afterDeleteCallback) {
            this.addEntryIfListIsEmpty();
            this.afterDeleteCallback();
        }
    }

    public removeItemsAt(indexesToRemove: number[]): void {
        indexesToRemove.sort((a, b) => b - a); // Sorting in descending order to prevent index shifting
        indexesToRemove.forEach(indexToRemove => {
            this.items.splice(indexToRemove, 1);
        });
        this.currentPosition = 0;
        if (!!this.afterDeleteCallback) {
            this.addEntryIfListIsEmpty();
            this.afterDeleteCallback();
        }
    }

    private addEntryIfListIsEmpty() {
        if (this.items.length == 0) {
            let emptyItem = this.initializeNewItemCallback();
            this.items.push(emptyItem);
            this.currentPosition = this.items.length - 1;
        }
    }
}