<div style="text-align: center;">
    <div class="row" style="margin-top: 10px;">
        <div class="col-lg-12 mt-2" style="margin-bottom: 100px;">
            <p-tabView>
                <p-tabPanel header="Frequently Asked Questions">
                    <div>
                        <br/>
                        <br/>
                        <ol class="ol">
                            <li *ngFor=" let item of faquestions">
                               <div class="ques" >{{item.questions}}</div> 
                               
                                <div class="ans" [innerHTML]="item.answers">{{item.answers}}</div>
                               
                            </li>
                            
                        </ol>
                    </div>  
                </p-tabPanel>
                <p-tabPanel header="Templates">
                    <div class="col-lg-3">
                        <label (click)="downloadSampleTemplate('SampleDriverTemplate', 'BSB_DriverUploadTemplate')"
                            style="text-decoration: underline; color: #115740;">
                            <i class="fa fa-files-o" aria-hidden="true"></i>
                            Sample Driver List Upload Template
                        </label>
                    </div>
                    <div class="col-lg-3">
                        <label (click)="downloadSampleTemplate('SampleVehicleTemplate', 'BSB_VehicleUploadTemplate')"
                            style="text-decoration: underline; color: #115740;">
                            <i class="fa fa-files-o" aria-hidden="true"></i>
                            Sample Vehicle List Upload Template
                        </label>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="UnInsured/UnderInsured Motorists Guidelines" *ngIf="IsShowUIMGuidelines">

                    <div class="col-lg-12">
                        <div class="col-lg-6">
                            <div class="row" style="margin-top:10px;">
                                <div class="col-lg-2">
                                    <label>State : </label>
                                </div>
                                <div class="col-lg-4">
                                    <p-dropdown [options]="dao.DocumentStateList" [(ngModel)]="dao.DocumentState"
                                        name="DocumentState" id="DocumentStateID" #DocumentState="ngModel"
                                        (onChange)="GetSpecificStateDetails(dao.DocumentState)"
                                        placeholder="Select State">
                                    </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="table-responsive" style="margin-top: 15px;"
                            *ngIf="dao?.SelectedDocumentStateList?.length > 0">
                            <p-table class="table llh_table p-datatable-striped" styleClass="p-datatable-striped"
                                [value]="dao?.SelectedDocumentStateList">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:15%">UM / UIM Rejection Allowed?</th>
                                        <th style="width:15%">UM / UIM Selection / Rejection Form</th>
                                        <th style="width:20%">UM / UIM State Minimum Limits</th>
                                        <th style="width:10%">PIP (No Fault) Reject Allowed?</th>
                                        <th style="width:20%">PIP (No Fault) Selection / Rejection / Acknowledgement
                                            Form</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-SelectedDocumentStateList let-i=rowIndex
                                    pTemplate="body">
                                    <tr [pSelectableRow]="SelectedDocumentStateList">

                                        <td class="wiplink" name="formNumber" id="formNumberID"
                                            title=" {{SelectedDocumentStateList?.formNumber}}">
                                            <span
                                                (click)="getDataAgreementDocument(SelectedDocumentStateList.id,SelectedDocumentStateList.formNumber)">
                                                {{SelectedDocumentStateList?.formNumber}} </span>
                                        </td>
                                        <td>
                                            {{SelectedDocumentStateList?.isRejectionAllowed}}
                                        </td>
                                        <td>
                                            {{SelectedDocumentStateList?.umUIMStateLimits}}</td>
                                        <td>
                                            {{SelectedDocumentStateList?.isPIPRejectionAllowed}} </td>
                                        <td>
                                            {{SelectedDocumentStateList?.isPIPForm}} </td>
                                    </tr>
                                </ng-template>
                            </p-table>



                        </div>
                    </div>
                </p-tabPanel>
                 <p-tabPanel header="State Availability Map">
                    <div class="row">
                        <div class="col-lg-12 text-lg-right mb-2" style="width:95%;" *ngIf="dao.IsInternalUser">
                            <p-fileUpload mode="basic" #fileUpload class="basic-upload" name="demo[]"
                            [auto]="true" (onSelect)="uploadImage($event,fileUpload)"
                            chooseLabel="Upload State Availability Map" chooseIcon="pi-upload" accept="image/*" maxFileSize="15728640" ></p-fileUpload> 
                        </div>
                        <img src="data:image/png;base64,{{imageSrc}}" *ngIf="!!imageSrc" class="w-100">
                        <div *ngIf="!!!imageSrc" class="no-record-found">No Image Found</div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>

    </div>
    <p-toast position="center" key="uploadImagePopUp" [baseZIndex]="5000" class="registration-success">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1;">
                <div class="p-text-center">
                    {{message?.summary}}
                </div>
                <div class="p-grid p-fluid mt-2">
                    <div class="col-md-12 text-center mt-2">
                        <button type="button" class="registration-success-okbtn" name="btnPopContinue" id="btnPopContinueID"
                            (click)="onReject('uploadImagePopUp')" title="Reload">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>