import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DAO } from '../../modal/DAO'
import { MenuClickContext } from '../../modal/routing.models'
import { ServiceLayer } from '../../services/serviceLayer';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../../app.component';
import _ from 'lodash';
import { NgForm } from '@angular/forms';
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { ProductService } from '../../services/product.service';
import { QuestionAnswer, QuestionOption, Questionnaire } from 'src/app/modal/questionnaire.models';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StateSettings } from 'src/app/modal/state.models';
import { QuestionAnswerHelper } from 'src/app/modal/questionAnswerUtilities';

@Component({
  selector: 'app-productInfo',
  templateUrl: './productInfo.component.html'
})
export class ProductInfoComponent implements OnInit, OnDestroy {
  protected unsubscriber = new Subject();
  public questionnaire: Questionnaire;
  public umBiLimitQuestionBehaviore = new BehaviorSubject<QuestionAnswer>(null);
  public umPdPrdQuestionBehaviore = new BehaviorSubject<QuestionAnswer>(null);
  public uimBiQuestionBehaviore = new BehaviorSubject<QuestionAnswer>(null);
  accountState: string = '';
  constructor(
    public serviceLayer: ServiceLayer,
    public dao: DAO,
    private messageService: MessageService,
    public appComponent: AppComponent,
    public menuComponent: menuComponent,
    public productService: ProductService,
    public stateSettings: StateSettings) { }
    
  public deleteType: string = '';
  public isUninsuredMotoristBodilyInjuryValid: boolean = true;
  public isUnderInsuredMotoristBodilyInjuryLimitValid: boolean = true;
  public isUninsuredMotoristPropertyDamageDeductibleValid: boolean = true;
  public isAddBtnClicked: boolean = false;
  public activeTab: number = 0;
  public IsShowNFNotQualifyErrorMsg: boolean = false;
  public hideNFChild: boolean = false;
  public umReject: QuestionAnswer;
  public fireSurcharge: QuestionAnswer;
  public nfRateBasis: QuestionAnswer;
  public cargoIndicator: QuestionAnswer;
  public accountLegalEntityAnswer: string;
  public cargoPropertyLimit: QuestionAnswer;
  public cargoShipperLimit: QuestionAnswer;
  @ViewChild('productForm') productForm: NgForm;
  deletedIndex: number;
  addedPipValidationMessage: String;
  namedIndividualValidationMessage: String;
  isNFPipLimitDisabled: boolean;

  ngOnInit(): void {
    this.loadValidationMessage();
    this.accountLegalEntityAnswer = this.productService.getAccountLegalEntityAnswer();
    this.serviceLayer.dotSubmissionDetails$.pipe(takeUntil(this.unsubscriber)).subscribe((dotSubmissionDetails) => {
      if (dotSubmissionDetails) {
        this.checkUWQusForProduct(); //show or hide GL and Cargo tab based on UW questionnaire questions
        this.changingFlatDeductibleDropdownValues(); //change flatdeductible question answers and drop down based on UW questionnaire questions
        this.getAndSetValuesFromQuestionnaire();
        this.enableNFPipLimitDropDown();
        this.setCargoShipperLimitDropdownOptions();
        this.dao.initialProductModel = _.cloneDeep(this.dao.ProductModel);
      }
    });

    setTimeout(() => {
      this.serviceLayer.menuClickBehavior.pipe(takeUntil(this.unsubscriber)).subscribe((menuClickContext: MenuClickContext) => {
        if (menuClickContext == null || menuClickContext.page == "product")
          return;
        this.serviceLayer.UpdateFiledsValuesAndValidate(this.productForm);
        menuClickContext.currentPageIsValid = this.productForm.valid;
      });
    }, 100);

    if (this.dao.finalSubmitPage == 'product' || this.dao.finalSubmitPage == 'application') {
      this.serviceLayer.constructGaragingAddress();
    }

  }

  enableNFPipLimitDropDown() {
    this.accountState = this.productService.getAccountStateAnswer();
    if (this.accountState == 'TX' || this.accountState == "DE") {
      this.hideNFChild = true;
      this.isNFPipLimitDisabled = this.dao.ProductModel.productDetails.nfPipLimits.answer == '';
    }
  }

  public ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
  // ========= Open Validation Popup =====================
  public openValidPopup(errmsg: string = this.dao.toastValidation) {
    this.messageService.add({ key: 'productToastKey', severity: 'error', summary: 'Product Info', detail: errmsg });
  }
  // ======
  public AddProductLocation() {
    var tempArray = {
      'premiseStreet': ""
      , 'premiseCity': ""
      , 'premiseState': ""
      , 'premisePostalCode': ""
      , 'payrollAmount': this.dao.ProductModel.PremiseStateDefaultValue
      , 'premiseLocation': this.dao.ProductModel.PremiseLocationDefaultValue
    };
    var tempObj = _.cloneDeep(this.dao.AddProductAnotherLocation);
    tempObj.push(tempArray);
    this.dao.AddProductAnotherLocation = _.cloneDeep(tempObj);
    this.dao.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.serviceLayer.changeLocationDropdown(ind)));

  }
  public AddAnotherInsured() {
    var tempArray = { 'Name': "" };
    var tempObj = _.cloneDeep(this.dao.AddAnotherInsured);
    tempObj.push(tempArray);
    this.dao.AddAnotherInsured = tempObj;
  }
  public AddAnotherWaiver() {
    var tempArray = { 'Name': "" };
    var tempObj = _.cloneDeep(this.dao.AddAnotherWaiver);
    tempObj.push(tempArray);
    this.dao.AddAnotherWaiver = tempObj;
  }
  public AddAnotherInsuredForCommercialAuto() {
    var tempArray = { 'Name': "", 'Street': '', 'City': '', 'State': '', 'PostalCode': '' };
    var tempObj = _.cloneDeep(this.dao.AddAnotherInsuredForCommercialAuto);
    tempObj.push(tempArray);
    this.dao.AddAnotherInsuredForCommercialAuto = tempObj;
  }
  public AddAnotherWaiverForCommercialAuto() {
    var tempArray = { 'Name': "" };
    var tempObj = _.cloneDeep(this.dao.AddAnotherWaiverForCommercialAuto);
    tempObj.push(tempArray);
    this.dao.AddAnotherWaiverForCommercialAuto = tempObj;
  }
  async submitForm(productForm) {
    this.serviceLayer.UpdateFiledsValuesAndValidate(productForm);
    if (productForm.valid) {
      this.saveProduct(productForm)
    } else {
      if (!this.dao.IsSavedFromMenu) {
        var errmsg;
        var glGroup = productForm.form.controls.generalLiabilityGroup;
        this.validateAndChangeTheTab(productForm);
        if (productForm.form.hasError('invalidCombinedCoverages')) {
          errmsg = productForm?.form?.errors?.errmsg;
        }
        else if (!!glGroup && glGroup.invalid) {
          errmsg = this.validateGLControls(glGroup.controls);
        }
        else {
          errmsg = this.dao.toastValidation;
        }
        this.openValidPopup(errmsg);
      } else {
        this.dao.NotAllowedToSubmit = true
      }
    }
  }
  private validateGLControls(controls: any) {
    let errmsg;
    let invalidPayrollIndexes = [];
    for (const controlName in controls) {
      const control = controls[controlName];
      if (control.hasError('required') || (control.hasError('requiredAmtOrDecline'))) {
        errmsg = this.dao.toastValidation;
        return errmsg;
      }
      else if (controlName.startsWith('payrollAmount_')) {
        if (control.hasError('minAmt')) {
          errmsg = control.errors?.errmsg;
          let index = controlName.split('_')[1];
          invalidPayrollIndexes.push(index);
        }
      }
    }
    if (invalidPayrollIndexes.length > 0)
      this.CheckPayrollAmountValidation(invalidPayrollIndexes);
    return errmsg;
  }
  private validateAndChangeTheTab(productForm: NgForm): void {
    let hasCommercialTabValid = productForm?.controls?.commercialAutoGroup?.valid;
    let hasGeneralLiabTabValid = productForm?.controls?.generalLiabilityGroup?.valid;
    let hasMotorCargoTabValid = productForm?.controls?.motorCargGroup?.valid;
    let hasCurrentTabInvalid = false;
    if ((this.activeTab == 0 && !hasCommercialTabValid) || (this.activeTab == 1 && !hasGeneralLiabTabValid) || (this.activeTab == 2 && !hasMotorCargoTabValid)) {
      hasCurrentTabInvalid = true;
    }
    if (!hasCurrentTabInvalid) {
      if (!hasCommercialTabValid) {
        this.activeTab = 0;
      } else if (!hasGeneralLiabTabValid) {
        this.activeTab = 1;
      } else if (!hasMotorCargoTabValid) {
        this.activeTab = 2;
      }
    }
  }
  // ========= Save Product =====================
  public async saveProduct(form) {
    this.dao.AddAnotherNamedIndividual = this.dao.AddAnotherNamedIndividual.filter(x => !x.isDeleted);
    this.dao.NotAllowedToSubmit = false;
    form = this.serviceLayer.formValidation(form);
    let isPremiseLocValid = form.valid;
    this.dao.AddProductAnotherLocation.forEach(pro => {
      if (pro?.premiseLocation == 'CLICK') {
        isPremiseLocValid = false;
      }
    })
    if (isPremiseLocValid || this.dao.ProductModel.productDetails.glIncluded.answer != 'Yes') {
      let shallCallServiceToSave = await this.ValidateUninsuredUnderinsuredMotorist();
      if (shallCallServiceToSave) {
        shallCallServiceToSave = this.validateUnderinsured();
      }
      if (shallCallServiceToSave) {
        shallCallServiceToSave = await this.validateOptionalConverages();
      }
      if (shallCallServiceToSave) {
        shallCallServiceToSave = await this.validateNumberOfEmployees();
      }
      if (shallCallServiceToSave) {
        shallCallServiceToSave = await this.validateTortLimits();
      }
      if (shallCallServiceToSave) {

        let response = await this.serviceLayer.save()
        if (response && response != '' && typeof (response) == 'string') {
          if (this.dao.IsSavedFromMenu) {
            this.dao.NotAllowedToSubmit = true;
          } else {
            this.openValidPopup();
          }
        }
        if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
          if (this.dao.IsSavedFromMenu) {
            this.dao.NotAllowedToSubmit = true;
          } else {
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: 'The effective date must be within 05-60 calendar days from today’s date. Please update "Policy Effective Date" field on Policy Holder screen in order to proceed with the submission' });
          }
        }
      }
      if (!shallCallServiceToSave) {
        if (this.dao.IsSavedFromMenu) {
          this.dao.NotAllowedToSubmit = true;
        }
      }
    }
    else {
      if (!isPremiseLocValid) {
        if (this.dao.IsSavedFromMenu) {
          this.dao.NotAllowedToSubmit = true;
        } else {
          this.openValidPopup();
        }
      }
    }
  }
  public removeProduct(index, type, value) {
    this.deletedIndex = index;
    this.deleteType = type;
    if (value && value != '') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
    }
    else {
      this.confirmRemove();
    }
  }
  public confirmRemove() {
    this.removingProduct(this.deletedIndex, this.deleteType);
    this.messageService.clear('remove')
    document.body.style.pointerEvents = "visible";
  }
  private removingProduct(index, type) {
    if (type == 'location') {
      this.dao.AddProductAnotherLocation.splice(index, 1);
      this.dao.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.serviceLayer.changeLocationDropdown(ind)));
    } else if (type == 'insured') {
      this.dao.AddAnotherInsured.splice(index, 1);
    } else if (type == 'waiver') {
      this.dao.AddAnotherWaiver.splice(index, 1);
    } else if (type == 'insuredForCommercial') {
      this.dao.AddAnotherInsuredForCommercialAuto.splice(index, 1);
    } else if (type == 'waiverForCommercial') {
      this.dao.AddAnotherWaiverForCommercialAuto.splice(index, 1);
    } else if (type == 'insuredForCargo') {
      this.dao.AddAnotherInsuredForCargo.splice(index, 1);
    } else if (type == 'namedIndividual') {
      this.dao.AddAnotherNamedIndividual[index].isDeleted = true;
    }
  }

  public async ValidateUninsuredUnderinsuredMotorist() {
    const umPdPrd = this.questionnaire.questionAnswer.find(x => x.key == 'umPdPrd');
    const umBiLimit = this.questionnaire.questionAnswer.find(x => x.key == 'umBiLimit');
    let shallCallServiceToSave = true;
    if (umBiLimit?.answer == "CLICK" && umPdPrd?.answer == 'CLICK' && umPdPrd?.enabled) {
      shallCallServiceToSave = false;
      this.isUninsuredMotoristBodilyInjuryValid = false;
      this.appComponent.addToast("error", "Uninsured Motorist", "Either Uninsured Motorist - Bodily Injury limits or Uninsured Motorist - Combined Bodily Injury / Property Damage Limit should be selected.");
      this.dao.isClickFromMenu = false;
    }

    return shallCallServiceToSave;
  }

  private hasSelection(question: QuestionAnswer): boolean {
    return !!question?.answer && question?.answer !== 'CLICK';
  }
  private validateTortLimits(): boolean {
    let shallCallServiceToSave = true;

    const tortLimits = this.questionnaire.questionAnswer.find(x => x.key === 'tortLimits');
    const tortLimitSelect = this.questionnaire.questionAnswer.find(x => x.key === 'tortLimitSelect');

    if (tortLimits && tortLimits.enabled && ((this.hasSelection(tortLimits) && tortLimits.answer == tortLimits.questionOptions[3].value && tortLimitSelect.answer == 'No') || !this.hasSelection(tortLimits))) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.dao.isClickFromMenu = false;
      }
    }
    return shallCallServiceToSave;

  }
  private validateUnderinsured(): boolean {
    let shallCallServiceToSave = true;

    const uimBiPrd = this.questionnaire.questionAnswer.find(x => x.key === 'uimBiPrd');
    const uimBi = this.questionnaire.questionAnswer.find(x => x.key === 'uimBi');
    const uimPrd = this.questionnaire.questionAnswer.find(x => x.key === 'uimPrd');
    const uimPrdDed = this.questionnaire.questionAnswer.find(x => x.key === 'uimPrdDed');

    if (shallCallServiceToSave && uimBiPrd?.enabled && uimBi?.enabled && !this.hasSelection(uimBiPrd) && !this.hasSelection(uimBi)) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.appComponent.addToast("error", "Underinsured Motorist", "Underinsured Motorist - Combined Bodily Injury / Property Damage Limit or Underinsured Motorist - Bodily Injury is required.");
        this.dao.isClickFromMenu = false;
      }
    }

    if (shallCallServiceToSave && uimPrd?.enabled && this.hasSelection(uimBi) && !this.hasSelection(uimPrd)) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.appComponent.addToast("error", "Underinsured Motorist", "Underinsured Motorist - Property Damage Limit is required.");
        this.dao.isClickFromMenu = false;
      }
    }
    if (shallCallServiceToSave && uimPrdDed?.enabled && this.hasSelection(uimBi) && !this.hasSelection(uimPrdDed)) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.appComponent.addToast("error", "Underinsured Motorist", "Underinsured Motorist - Property Damage Deductible is required.");
        this.dao.isClickFromMenu = false;
      }
    }
    const umBiLimit = this.questionnaire.questionAnswer.find(x => x.key == 'umBiLimit');
    // UM and UIM must match
    if (this.questionnaire?.questionAnswer?.find(x => x.key === 'tortLimitSelect') && this.questionnaire?.questionAnswer?.filter(x => x.questionLabel === 'Underinsured Motorist - Bodily Injury')[0]?.answer != umBiLimit?.answer && umBiLimit?.answer != 'CLICK' && this.hasSelection(uimBi) && this.questionnaire?.questionAnswer?.filter(x => x.questionLabel === 'Underinsured Motorist - Bodily Injury')[0]?.answer != 'DECLINE') {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        shallCallServiceToSave = false;
        this.dao.isClickFromMenu = false;
      }
    }

    return shallCallServiceToSave;
  }

  public CheckPayrollAmountValidation(invalidIndexes: number[]): void {
    const minimumPayrollAmount = Number(
      parseFloat(
        this?.dao?.ProductModel?.productDetails?.payrollAmount?.minimum
      ).toFixed(2)
    );

    invalidIndexes.forEach((index) => {
      const element = this.dao.AddProductAnotherLocation[index];

      if (element && Number(element.payrollAmount) < minimumPayrollAmount) {
        element.payrollAmount = minimumPayrollAmount.toString();
      }
    });
  }

  public async CheckPremiseState(state, index, msg: string = '') {
    const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
    const mailState = this.dao.DOTList.accountInfoQsnr.findQuestion("mailState")?.answer;
    if (state != accounState && state != mailState) {
      this.messageService.add({ key: 'productToastKey', severity: 'error', summary: 'Premise State', detail: msg != '' ? msg : this.dao.PremiseStateValidation });
      this.dao.AddProductAnotherLocation[index].premiseState = "";
    }
  }
  // ========= Cancel my submission popup =====================
  public cancelMySubmissionPopUp() {
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'CancelMySubmission', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
  }
  // ========= Cancel my submission =====================
  public CancelmySubmission() {
    return this.serviceLayer.CancelmySubmission();
  }
  // ========= On reject =====================
  public onReject(type) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
  }
  public async IsShowGLTab() {
    if (this.dao.ProductModel.productDetails.glIncluded.answer == 'No' || this.dao.ProductModel.productDetails.glIncluded.answer == '') {
      this.dao.ProductModel.productDetails.glIncluded.answer = 'Yes'
      setTimeout(() => {
        this.dao.showingGlTab = true;
        this.activeTab = 1;
      }, 5);
      window.scrollTo(0, 300);
    } else if (this.dao.ProductModel.productDetails.glIncluded.answer == 'Yes') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'RemoveGL', sticky: true, severity: 'warn', summary: ' Do you want to remove general liability ?' });
    }
  }
  public async IsShowGLTabForProduct() {
    if (this.dao.ProductModel.productDetails.glIncluded.answer == 'Yes') {
      this.dao.ProductModel.productDetails.glIncluded.answer = 'No'
      await this.serviceLayer.BindGLForProduct('GLRemoved');
      this.dao.showingGlTab = false;
      this.activeTab = 0;
      window.scrollTo(0, 300);
    }
    this.messageService.clear('RemoveGL')
    document.body.style.pointerEvents = "visible";
  }

  public UnderinsuredRuleCheck() {
    const umBiLimit = this.questionnaire.questionAnswer.find(x => x.key == 'umBiLimit')
    const uimBi = this.questionnaire.questionAnswer.find(x => x.key == 'uimBi')
    if (umBiLimit.answer == 'DECLINE') {
      if (uimBi?.answer == 'DECLINE') {
        this.isUnderInsuredMotoristBodilyInjuryLimitValid = true;
      }
    }
    else {
      if (umBiLimit.answer != 'CLICK') {
        if (uimBi?.answer == 'CLICK') {
          this.isUnderInsuredMotoristBodilyInjuryLimitValid = false;
        }
        else {
          this.isUnderInsuredMotoristBodilyInjuryLimitValid = true;
        }
      }
    }
  }
  public checkDifferentLocationInProduct(value, index) {
    const accountInfoQnnr = this.dao.DOTList.accountInfoQsnr;
    if (value != "newAddressAsPremise" && this.dao.AddProductAnotherLocation.filter(x => x.premiseLocation == value).length == 1) {
      if (value == 'businessAddressAsGLPremise') {        
        if (accountInfoQnnr != undefined) {
          this.dao.AddProductAnotherLocation[index].premiseStreet = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountStreet")[0]?.answer;
          this.dao.AddProductAnotherLocation[index].premiseCity = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountCity")[0]?.answer;
          this.dao.AddProductAnotherLocation[index].premiseState = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountState")[0]?.answer;
          this.dao.AddProductAnotherLocation[index].premisePostalCode = accountInfoQnnr.questionAnswer.filter(y => y.key == "accountPostalCode")[0]?.answer;
        }
      }
      else if (value == 'mailingAddressAsGLPremise') {
        this.dao.AddProductAnotherLocation[index].premiseStreet = accountInfoQnnr.findQuestion("mailStreet").answer;
        this.dao.AddProductAnotherLocation[index].premiseCity = accountInfoQnnr.findQuestion("mailCity").answer;
        this.dao.AddProductAnotherLocation[index].premiseState = accountInfoQnnr.findQuestion("mailState").answer;
        this.dao.AddProductAnotherLocation[index].premisePostalCode = accountInfoQnnr.findQuestion("mailPostalCode").answer;
      }
      else {
        let vehicleAddress = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, "$..questionnaire[?(@.key =='vehiclePrefill')].questionAnswer[?(@.group == '" + value + "')]");
        if (vehicleAddress.length > 0) {
          this.dao.AddProductAnotherLocation[index].premiseStreet = vehicleAddress.filter(c => c.key == 'vehicleGarageStreetAddress')[0].answer;
          this.dao.AddProductAnotherLocation[index].premiseCity = vehicleAddress.filter(c => c.key == 'vehicleGarageCity')[0].answer;
          this.dao.AddProductAnotherLocation[index].premiseState = vehicleAddress.filter(c => c.key == 'vehicleGarageState')[0].answer;
          this.dao.AddProductAnotherLocation[index].premisePostalCode = vehicleAddress.filter(c => c.key == 'vehicleGaragePostalCode')[0].answer;
        }
      }
    } else if (value == 'newAddressAsPremise') {
      this.dao.AddProductAnotherLocation[index].premiseStreet = "";
      this.dao.AddProductAnotherLocation[index].premiseCity = "";
      this.dao.AddProductAnotherLocation[index].premiseState = this.dao.DOTList.questionnaire.filter(x => x.key == "AccountInfo")[0].questionAnswer.filter(y => y.key == "accountState")[0].answer;
      this.dao.AddProductAnotherLocation[index].premisePostalCode = "";
    } else if (value != 'CLICK') {
      var validationMsg: string = 'Premise Location Already Exists';
      this.messageService.add({ key: 'productToastKey', severity: 'error', summary: 'Premise Location', detail: validationMsg });
      this.dao.AddProductAnotherLocation[index].premiseLocation = "";
    }
    this.dao.AddProductAnotherLocation.map((c, ind) => c.premiseList = _.cloneDeep(this.serviceLayer.changeLocationDropdown(ind)));
  }

  public async validateOptionalConverages() {
    if ((this.dao.ProductModel.productDetails.insurableInterestParent.required && (this.dao.ProductModel.productDetails.insurableInterestParent.answer == undefined
      || this.dao.ProductModel.productDetails.insurableInterestParent.answer == ''))
      || (this.dao.ProductModel.productDetails.autoWavierOfSubrogation.required && (this.dao.ProductModel.productDetails.autoWavierOfSubrogation.answer == undefined
        || this.dao.ProductModel.productDetails.autoWavierOfSubrogation.answer == ''))) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        this.appComponent.addToast("error", "Product Info", this.dao.toastValidation);
        this.dao.isClickFromMenu = false;
        return false;
      }
    }
    if (this.dao.ProductModel.productDetails.glIncluded.answer == 'Yes' && ((this.dao.ProductModel.productDetails.additionalInsuredIncludedParent.required && (this.dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer == undefined
      || this.dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer == ''))
      || (this.dao.ProductModel.productDetails.glWavierOfSubrogation.required && (this.dao.ProductModel.productDetails.glWavierOfSubrogation.answer == undefined
        || this.dao.ProductModel.productDetails.glWavierOfSubrogation.answer == '')))) {
      if (this.dao.IsSavedFromMenu) {
        this.dao.NotAllowedToSubmit = true;
      } else {
        this.appComponent.addToast("error", "General Liability", this.dao.toastValidation);
        this.dao.isClickFromMenu = false;
        return false;
      }
    }
    return true;
  }
  // ========= Numbers only =====================
  public numbersOnly(e) {
    return this.serviceLayer.numbersOnly(e);
  }

  public async validateNumberOfEmployees() {
    if (this.dao?.ProductModel?.productDetails?.empBenefitsLiab?.answer != 'DECLINE' && this.dao?.ProductModel?.productDetails?.glIncluded?.answer === "Yes") {
      let numOfEmployees = this.dao.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer;
      let minimum = this.dao.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.minimum;
      if (isNaN(Number(numOfEmployees)) || Number(this.dao.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer) < Number(minimum)) {
        if (this.dao.IsSavedFromMenu) {
          this.dao.NotAllowedToSubmit = true;
        } else {
          this.appComponent.addToast("error", "General Liability", "Number of Employees must be greater than 0.");
          this.dao.isClickFromMenu = false;
          return false;
        }
      }
    }
    else {
      this.dao.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer = '';
    }
    return true;
  }
  // ========= On Back =====================
  public onBack(page) {
    this.dao.IsBackButtonClicked = true;
    return this.menuComponent.menuClick(page);
  }

  // Validate address
  public ValidateAddress(event, value, type, index) {
    let result = this.serviceLayer.ValidateAddress(event, value);
    if (result != null) {
      if (type == "premiseStreet") {
        this.dao.AddProductAnotherLocation[index].premiseStreet = result;
      }
    }
  }
  public async IsShowCargoTab() {
    if (this.dao.ProductModel.productDetails.cargoIncluded.answer == 'No' || this.dao.ProductModel.productDetails.cargoIncluded.answer == '') {
      this.dao.ProductModel.productDetails.cargoIncluded.answer = 'Yes';
      let accountCargoIndicatorQ = this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator");
      if (!!accountCargoIndicatorQ?.answer) {
        accountCargoIndicatorQ.answer = "Yes"
      }
      if (!!this.cargoIndicator) {
        this.cargoIndicator.answer = "Yes";
      }
      setTimeout(() => {
        this.activeTab = 2;
      }, 5);
      window.scrollTo(0, 300);
    } else if (this.dao.ProductModel.productDetails.cargoIncluded.answer == 'Yes') {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'RemoveCargo', sticky: true, severity: 'warn', summary: ' Are you sure that you want to remove Motor Truck Cargo coverage from this submission? When yes is selected, we will remove all related responses from this submission.' });
    }
  }

  public async IsShowCargoTabForProduct() {
    if (this.dao.ProductModel.productDetails.cargoIncluded.answer == 'Yes') {
      this.dao.ProductModel.productDetails.cargoIncluded.answer = 'No'
      let accountCargoIndicatorQ = this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator");
      if (!!accountCargoIndicatorQ?.answer) {
        accountCargoIndicatorQ.answer = "No";
      }
      if (!!this.cargoIndicator) {
        this.cargoIndicator.answer = "No";
      }
      await this.serviceLayer.BindcargoForProduct('CargoRemoved');
      this.activeTab = 0;
      window.scrollTo(0, 300);
    }
    this.messageService.clear('RemoveCargo')
    document.body.style.pointerEvents = "visible";
  }

  public async AddAnotherInsuredForCargo() {
    this.isAddBtnClicked = false;
    var tempArray = { 'Name': '', 'Street': '', 'City': '', 'State': '', 'PostalCode': '' };
    var tempObj = _.cloneDeep(this.dao.AddAnotherInsuredForCargo);
    tempObj.push(tempArray);
    this.dao.AddAnotherInsuredForCargo = _.cloneDeep(tempObj);
  }
  setselectedIndex(event) {
    if (!this.dao.showingGlTab && event.index == 1) {
      setTimeout(() => {
        this.activeTab = 2;
      }, 5);
    }
    else {
      this.activeTab = event.index;
    }
  }

  public checkNFIsNotQualify(response, value) {
    if (this.dao.ProductModel.productDetails.nfPipLimits.answer == response
      && ((this.dao.ProductModel.productDetails[value + 1].answer == 'No' && this.dao.ProductModel.productDetails[value + 2].answer != '')
        || (this.dao.ProductModel.productDetails[value + 2].answer == 'No' && this.dao.ProductModel.productDetails[value + 1].answer != ''))) {
      this.dao.ProductModel.productDetails.nfPipLimits.answer = 'CLICK'
      this.dao.ProductModel.productDetails[value + 1].answer = '';
      this.dao.ProductModel.productDetails[value + 2].answer = '';
      this.IsShowNFNotQualifyErrorMsg = true;
      this.hideNFChild = true;
    } else {
      this.hideNFChild = false;
    }
  }
  public nfLimitOnChange() {
    if (this.dao.ProductModel.productDetails.nfPipLimits.answer == 'CLICK' && this.hideNFChild) {
      this.IsShowNFNotQualifyErrorMsg = true;
    } else {
      this.hideNFChild = false;
      this.IsShowNFNotQualifyErrorMsg = false;
    }
    const keyList: Array<string> = new Array<string>();
    keyList.push("NF_250kWithPIPME_Child1");
    keyList.push("NF_250kWithPIPME_Child2");
    keyList.push("NF_50K_Child1");
    keyList.push("NF_50K_Child2");
    keyList.push("NF_PIPMedOptOut_Child1");
    keyList.push("NF_PIPMedOptOut_Child2");
    keyList.forEach(x => {
      if (this.dao.ProductModel.productDetails) {
        this.dao.ProductModel.productDetails[x].answer = '';
      }
    });
  }

  onChangeNfPipDed(nfPipDed) {
    if (this.questionnaire?.questionAnswer?.find(x => x.key === 'nfBrdDed')?.enabled)
      this.questionnaire.questionAnswer.find(x => x.key === 'nfBrdDed').answer = nfPipDed;
  }
  public AddNamedIndividual() {
    this.productService.AddNamedIndividual();
  }
  public get getNamedIndividualCount() {
    if (this.dao.AddAnotherNamedIndividual.filter(x => (!x.isDeleted)).length < 3) {
      return true;
    }
    return false;
  }
  private loadValidationMessage(): void {
    this.addedPipValidationMessage = this.productService.getValidationMessage('addedPIP') ?? "";
    this.namedIndividualValidationMessage = this.productService.getValidationMessage('namedIndividual') ?? "";
  }
  getAndSetValuesFromQuestionnaire() {
    this.questionnaire = this.productService.getQuestionnaire();
    this.umReject = this.questionnaire?.questionAnswer?.find(x => x.key === "umReject");
    this.fireSurcharge = this.questionnaire?.questionAnswer?.find(x => x.key === "fireSurcharge");
    this.nfRateBasis = this.questionnaire?.questionAnswer?.find(x => x.key === "nfRateBasis");

    //Cargo Indicator Details
    let accountQuestionnaire = this.dao.DOTList?.questionnaire?.find(q => q.key === 'AccountInfo');
    if (!!accountQuestionnaire) {
      this.cargoIndicator = accountQuestionnaire.questionAnswer?.find(x => x.key === "cargoIndicator");
    }

     // Property Limit
     this.cargoPropertyLimit = this.dao.DOTList.productQsnr.findQuestion("propertyLimit");
     if(!!this.cargoPropertyLimit){
      QuestionAnswerHelper.useDefaultValue.apply(this.cargoPropertyLimit);  
     }
     // Cargo Shipper Limit
     this.cargoShipperLimit = this.dao.DOTList.productQsnr.findQuestion("cargoShipperLimit");
     if(!!this.cargoShipperLimit){
      QuestionAnswerHelper.useDefaultValue.apply(this.cargoShipperLimit);
     }
  }

  // ====== Check UW question for GL enable======
  private checkUWQusForProduct() {
    let UWAnswerForGL = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key == "operateMobleEquip" || @.key == "contractsAssumeLiabilityParent")]');
    let UWAnswerForCargo = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key == "unattendedLoadedTrailers" || @.key == "actualDamageInLossEvent" || @.key == "IsDOTBillIssued")]');

    if (!!UWAnswerForGL && UWAnswerForGL.length > 0) {
      this.dao.ProductModel.IsGLShow = true;
      if (UWAnswerForGL.filter(c => c.answer == "true" || c.answer == true || c.answer == "Y")?.length > 0) {
        this.dao.ProductModel.IsGLShow = false;
        this.dao.ProductModel.productDetails.glIncluded.answer = 'No';
      }
    }
    if (!!UWAnswerForCargo && UWAnswerForCargo.length > 0) {
      this.dao.ProductModel.IsCargoShow = true;
      UWAnswerForCargo.forEach(c => {
        if ((c.answer == "Y" && c.key == "unattendedLoadedTrailers") || (c.answer == "Y" && c.key == "actualDamageInLossEvent") || (c.answer == "N" && c.key == "IsDOTBillIssued")) {
          this.dao.ProductModel.IsCargoShow = false;
          let accountCargoIndicatorQ = this.dao.DOTList.accountInfoQsnr.findQuestion("cargoIndicator");
          if (!!accountCargoIndicatorQ?.answer) {
            accountCargoIndicatorQ.answer = "Yes"
          }
        }
      });
    }
  }

  public async changingFlatDeductibleDropdownValues() {
    let UWAnswerForCargoFlatDeductible = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key =="refrigerationTrailersParent")].answer');
    if (UWAnswerForCargoFlatDeductible && UWAnswerForCargoFlatDeductible.length > 0) {
      let dropdownValues = _.cloneDeep(this.dao.ProductModel.productDetails.flatDeductible.questionOptions);
      if (UWAnswerForCargoFlatDeductible == "Y") {
        _.remove(dropdownValues, function (n) {
          return n.sequence == 1
        })
        if (this.dao.ProductModel.productDetails.flatDeductible.answer == '1,000') {
          this.dao.ProductModel.productDetails.flatDeductible.answer = '2,500';
        }

      }
      this.dao.ProductModel.productDetails.flatDeductible.questionOptions = dropdownValues;
    }
  }

  public onCargoNameInsuredClick() : void{
    this.dao.AddAnotherInsuredForCargo = [];
    this.AddAnotherInsuredForCargo();
    this.changingPropertyLimitDropdown()
  }

  public changingPropertyLimitDropdown(){
    if(!!this.cargoPropertyLimit && !!this.cargoShipperLimit){
      this.cargoShipperLimit.answer = this.cargoPropertyLimit.answer;
      this.setCargoShipperLimitDropdownOptions();
    }
  }
  public setCargoShipperLimitDropdownOptions() : void{
    if(!!this.cargoPropertyLimit && !!this.cargoShipperLimit){
      let propertyLimitInNumber : number = Number(this.cargoPropertyLimit.answer.replace(/,/g, ''));
      let cargoShipperLimitOptions : Array<QuestionOption> = [];
      this.cargoPropertyLimit.questionOptions.forEach(item => {
        let cargoShipperLimitInNumber : number= Number(item.code.replace(/,/g, ''));
        if(cargoShipperLimitInNumber <= propertyLimitInNumber){
          cargoShipperLimitOptions.push(item);
        }
      })
      this.cargoShipperLimit.questionOptions = cargoShipperLimitOptions;
    }
  }
}
