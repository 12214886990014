import { Component, Input, OnInit } from '@angular/core';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
@Component({
  selector: 'stop-GapEmployersLiability',
  templateUrl: './stop-GapEmployersLiability.component.html',
  styleUrls: ['./stop-GapEmployersLiability.component.css']
})
export class StopGapEmployersLiabilityComponent implements OnInit {
  @Input() questionnaire: Questionnaire;
  @Input() isFormSubmitted: boolean;
  public sgEmployeresliab: QuestionAnswer;
  public sgPayroll: QuestionAnswer;
  public sgBIAccidentEach: QuestionAnswer;
  public sgBIDiseaseLimit: QuestionAnswer;
  public sgBIDiseaseEach: QuestionAnswer;


  constructor() { }

  ngOnInit(): void {
    this.loadQuestionAnswers();
  }
  sgEmployeresliabSelection() {
    if (this.sgEmployeresliab.answer == 'No') {
      this.sgPayroll.answer = "";
    }
  }

  private loadQuestionAnswers(): void {
    this.sgEmployeresliab = this.LoadAnswerFromDefault('sgEmployeresliab');
    this.sgPayroll = this.LoadAnswerFromDefault('sgPayroll');
    this.sgBIAccidentEach = this.LoadAnswerFromDefault('sgBIAccidentEach');
    this.sgBIDiseaseLimit = this.LoadAnswerFromDefault('sgBIDiseaseLimit');
    this.sgBIDiseaseEach = this.LoadAnswerFromDefault('sgBIDiseaseEach');
  }

  public LoadAnswerFromDefault(key: string): QuestionAnswer {
    var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
    let questionAnswer = this.questionnaire.questionAnswer.find(x => x.key === key) ?? defaultQuestionAnswer;
    if (!questionAnswer?.answer && questionAnswer?.questionDefault?.length > 0) {
      questionAnswer.answer = questionAnswer.questionDefault[0].code;
    }
    return questionAnswer;
  }

  private getDefaultQuestionAnswer(): QuestionAnswer {
    return {
      answer: null,
      enabled: false,
      required: false,
      group: null,
      hidden: false,
      key: null,
      isVehicleUpdatedbyVEH1: false,
      minimum: null,
      name: null,
      type: '',
      rowIndex: 0,
      questionOptions: [],
      questionLabel: ''
    }
  }
}
