import { Component, OnInit } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO } from '../../modal/DAO'
import * as $ from "jquery";
declare var jQuery: any;
import {MessageService} from 'primeng/api';
import { ResponseContainer } from 'src/app/modal/questionnaire.models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  constructor(public serviceLayer: ServiceLayer, public dao: DAO,private messageService: MessageService) { }
  // ========= Page Load =====================
  ngOnInit(): void {
  	this.dao.DOTList = new ResponseContainer;    
  }
}
