import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/services/commonService';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { Router } from '@angular/router';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { dropDownOptions } from 'src/app/modal/DAO';
import { ListViewModel } from 'src/app/modal/Endorsements/ListView.Model';
import { DeleteVehicle } from 'src/app/modal/Endorsements/DeleteVehicle.Model';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { Subscription } from 'rxjs';
import { FileUploadFormComponent } from '../file-upload-form/file-upload-form.component';
import _ from 'lodash';

@Component({
  selector: 'delete-vehicle',
  templateUrl: './delete-vehicle.component.html'
})
export class DeleteVehicleComponent implements OnInit, OnDestroy, IBasePage {
  @ViewChild('fileUploadForm') fileUploadForm: FileUploadFormComponent;

  public deleteVehicleForm: FormGroup;
  public endorsementQuestionGroup: EndorsementQuestionGroup;
  private readonly toastValidation: string = 'Please fill the required fields highlighted in red';
  private menuClickSubscription: Subscription;
  private menuClickContext: MenuClickContext;
  public isSubmitted: boolean = false;
  public vehicleTypeDropdownValues: Array<dropDownOptions> = Constants.VehicleType;
  public vehicleList: ListViewModel<DeleteVehicle>;
  public endorsement: Endorsement = new Endorsement();

  constructor
    (
      private formBuilder: FormBuilder,
      private messageService: MessageService,
      private commonService: CommonService,
      private endorsementService: EndorsementService,
      public router: Router
    ) {
    this.deleteVehicleForm = this.formBuilder.group({});
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
  }

  ngOnInit(): void {
    this.initializeData();
    this.menuClickSubscription = this.endorsementService.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
  }

  ngOnDestroy(): void {
    if (!!this.menuClickSubscription) {
      this.menuClickSubscription.unsubscribe();
    }
  }

  private async getQuestionAnswers(): Promise<QuestionAnswer[]> {
    let payload = { "urlData": [this.endorsement.endorsementId, EndorsementTypes.DeleteVehicle] };
    return this.commonService.get('endorsement', 'getQuestionAnswers', payload).toPromise()
      .then(result => {
        return result
      })
      .catch(error => Promise.reject(error));
  }

  public async initializeData(): Promise<void> {
    await this.endorsementService.reloadQuestionGroups(this.endorsement);
    this.endorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.DeleteVehicle);
    this.getQuestionAnswers().then(questionAnswers => {
      if (!!questionAnswers && questionAnswers.length > 0) {
        let vehicleQuestionAnswers = DeleteVehicle.instantiateArrayFrom(questionAnswers);
        this.vehicleList = new ListViewModel<DeleteVehicle>(
          vehicleQuestionAnswers,
          this.beforeAdd,
          this.afterAdd,
          this.afterDelete,
          this.initializeNewItem
        );
        this.endorsementQuestionGroup.questionAnswers = questionAnswers;
        this.buildReactiveForm();
      }
    }).catch(error => {
      console.error(error);
    });
  }

  public buildReactiveForm(): void {
    let currentItem: DeleteVehicle = this.vehicleList.currentItem;
    if (!!!currentItem) return;

    this.deleteVehicleForm = this.formBuilder.group({
      type: [currentItem.type.answer, currentItem.type.required ? Validators.required : null],
      vin: [currentItem.vin.answer, currentItem.vin.required ? Validators.required : null]
    });
    this.bindFormValueToCurrentVehicle();
  }

  private bindFormValueToCurrentVehicle(): void {
    this.deleteVehicleForm.valueChanges.subscribe((formValue) => {
      let currentItem: DeleteVehicle = this.vehicleList.currentItem;
      if (!!currentItem) {
        currentItem.type.answer = formValue.type;
        currentItem.vin.answer = formValue.vin;
      }
    });
  }

  private findDuplicateVinIndexes(): void {
    const vinCounts: Map<string, number> = new Map();

    // Count occurrences of each VIN
    this.vehicleList.items.forEach((vehicle) => {
      const vin = vehicle.vin.answer.trim();
      vinCounts.set(vin, (vinCounts.get(vin) || 0) + 1);
    });

    // Iterate through items again and set flags for duplicates
    this.vehicleList.items.forEach((vehicle, index) => {
      const vin = vehicle.vin.answer.trim();
      if (vinCounts.get(vin) && vinCounts.get(vin) > 1) {
        vehicle['isValid'] = false;
      }
      else {
        vehicle['isValid'] = true;
      }
    });
  }

  public saveAndContinue(type = ""): void {
    this.isSubmitted = true;
    if (this.isPageValid()) {
      this.findDuplicateVinIndexes();
      if (this.vehicleList.items.some(x => !x['isValid'])) {
        this.isSubmitted = false;
        this.messageService.add({ key: 'toast', severity: 'warn', summary: 'Endorsements - Delete Vehicle', detail: 'Duplicate VIN numbers have been identified, please correct the vehicles highlighted in red' });
        return;
      }
      this.postQuestionAnswers().then(isSuccess => {
        if (isSuccess) {
          this.isSubmitted = false;
          let currrentEndorsementQuestionGroup : EndorsementQuestionGroup =  this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.DeleteVehicle);
          currrentEndorsementQuestionGroup.hasAnswers = true;
          currrentEndorsementQuestionGroup.isValidPage = true;
          this.endorsement.userIsAt = '';
          if (type === "finalSubmit") {
              this.endorsementService.updateStatusToSubmitted(this.endorsement.endorsementId);
          }
          else if (type === "draft") {
            this.router.navigate(['/home'], { state: { data: 'endorsement' } });
          }
          // if menuClickContext is current page or not set, get and update next menuClickContext
          if (!this.menuClickContext || this.menuClickContext?.page === EndorsementTypes.DeleteVehicle) {
            this.endorsementService.menuClickBehavior.next(this.endorsementService.getNextMenuClickContext(EndorsementTypes.DeleteVehicle));
          }
        }
      }).catch(error => {
        console.error(error);
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Vehicle', detail: error });
      })
    }
    else
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Vehicle', detail: this.toastValidation });
  }

  private async postQuestionAnswers(): Promise<boolean> {
    this.bindAnswersToQuestions();
    let payload = { "payloadData": this.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'postQuestionAnswers', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }

  public bindAnswersToQuestions(): void {
    let questionAnswers: QuestionAnswer[] = [];
    this.vehicleList.items.forEach((vehicle, i) => {
      let vehicleQuestionAnswers = vehicle.toQuestionAnswers(i + 1);
      questionAnswers.push(...vehicleQuestionAnswers)
    });
    questionAnswers.push(...this.fileUploadForm.convertFileDocumentsToQuestionAnswer());
    this.endorsementQuestionGroup.questionAnswers = questionAnswers;
  }

  public clickedItem(index: number): void {
    this.isSubmitted = true;
    if (!this.isPageValid()) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Vehicle', detail: this.toastValidation });
      return;
    }
    this.isSubmitted = false;
    this.vehicleList.currentPosition = index;
    this.buildReactiveForm();
    this.findDuplicateVinIndexes();
  }

  public initializeNewItem = (): DeleteVehicle => {
    return DeleteVehicle.createNewInstanceFromQuestionAnswers(this.endorsementQuestionGroup.questionAnswers);
  }

  public beforeAdd = (): boolean => {
    this.isSubmitted = true;
    if (!this.isPageValid()) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Vehicle', detail: this.toastValidation });
      return false;
    }
    return true;
  }

  public addVehicle(): void {
    this.vehicleList.add();
  }

  public afterAdd = (): void => {
    this.isSubmitted = false;
    this.buildReactiveForm();
  }

  public afterDelete = (): void => {
    this.isSubmitted = false;
    this.buildReactiveForm();
    this.findDuplicateVinIndexes();
  }

  public menuClicked(menuClickContext: MenuClickContext): void {
    this.menuClickContext = menuClickContext;
    if (menuClickContext != null && menuClickContext.page != EndorsementTypes.DeleteVehicle) {
      if (!this.deleteVehicleForm || !this.fileUploadForm) {
        this.menuClickContext.page = EndorsementTypes.DeleteVehicle;
        return;
      }
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = this.deleteVehicleForm.touched || !_.isEqual(this.vehicleList.initialItems, this.vehicleList.items) || this.fileUploadForm.isFormDataChanged();
      if (this.endorsementService.isMenuClickHeaderPage(menuClickContext)) {
        this.menuClickContext.page = EndorsementTypes.DeleteVehicle;
        return;
      }
      if (menuClickContext.currentPageIsValid && menuClickContext.currentPageDataChanged) {
        this.saveAndContinue();
      }
      this.endorsementService.handleUnsavedChangesOrNavigateToUrl(menuClickContext, this.endorsement);
    }
  }

  public isPageValid(): boolean {
    return this.deleteVehicleForm.valid &&
      (!this.vehicleList.currentItem.uploadedDocuments.required || (this.vehicleList.currentItem.uploadedDocuments.required && this.fileUploadForm.uploadedDocuments.length > 0));
  }

  public onRollback(currentTempage: string): void { }

  public onBack(page: string): void { }
}
