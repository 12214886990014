<p-toast key="toast" position="top-center" [baseZIndex]="5000" 
         [style]="{'white-space': 'pre-line'}"  preventOpenDuplicates='true'>
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column" style="flex: 1">
      <div class="p-text-left">
        <h4>{{message.summary}}</h4>
      </div>
      <p innerHtml="{{message.detail}}"></p>
    </div>
  </ng-template>
</p-toast>
<!-- <div *ngIf="(router.url != '/login' && router.url != '/newsubmission' && router.url != '/registration' && router.url != '/dot')"> -->
<app-header></app-header>

<!--Decline when APS is down  -->
<p-toast position="center" key="declinePopup" class="validation_toast " [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                {{message?.summary}}
            </div>
            <div class="p-grid p-fluid mt-2">
                <div class="col-md-12 text-center mt-2">
                    <button type="button" class="unauthorized_okbtn" name="btnPopContinue" id="btnPopContinueID"
                        (click)="reload()" title="Reload">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" key="duplicatePopup" class="validation_toast " [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                {{message?.summary}}
            </div>
            <div class="p-grid p-fluid mt-2">
                <div class="col-md-12 text-center mt-2">
                    <button type="button" class="unauthorized_okbtn" name="btnPopContinue" id="btnPopContinueID"
                        (click)="reload()" title="Reload">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="registrationMsgPopup" class="validation_toast " [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                {{message?.summary}}
            </div>
            <div class="p-grid p-fluid mt-2">
                <div class="col-md-12 text-center mt-2">
                    <button type="button" class="unauthorized_okbtn" name="btnPopContinue" id="btnPopContinueID"
                        (click)="onReject('registrationMsgPopup', message?.data)" title="Reload">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="registrationSuccessPopup" [baseZIndex]="5000" class="registration-success">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1;">
            <div class="p-text-center">
                {{message?.summary}}
            </div>
            <div class="p-grid p-fluid mt-2">
                <div class="col-md-12 text-center mt-2">
                    <button type="button" class="registration-success-okbtn" name="btnPopContinue" id="btnPopContinueID"
                        (click)="onReject('registrationSuccessPopup')" title="Reload">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" key="confirmationPopup" (onClose)="ConfirmationHandler('No', '')" preventOpenDuplicates='true' [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">                    
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnConfirmOptionYes"
                        id="btnConfirmOptionYes" (click)="ConfirmationHandler('Yes', message.data)">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">                   
                    <button type="button" class="warnBtnerror" title="No" name="btnConfirmOptionNo" id="btnConfirmOptionNoID"
                        (click)="ConfirmationHandler('No', message.data)">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<div class="container-fluid container_height">
    <div  *ngIf="!dao.isDuplicateDOT">
        <router-outlet></router-outlet>
    </div>
    <div class="app-loader" id="loaderForServiceCall" style="display:none"></div>
</div>

<p-toast position="center" key="greenSuccessPopupWithOk" class="validation_toast appinfoconfirm" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            {{message.summary}}
          <div [innerHTML]="message.detail">{{message.detail}}</div>
            <div class="p-grid p-fluid mt-3">
                <div class="col-md-12 text-center validation_toast_btn">
                    <button type="button" pButton (click)="ok('greenSuccessPopupWithOk', message?.data)" label="Ok"
                        class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="brownWarningPopupWithOk" preventOpenDuplicates='true' [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1; padding: 3px;;">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <div>{{message.summary}}</div>
                <div [innerHTML]="message.detail">{{message.detail}}</div><br />

                <div class="p-grid p-fluid">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <button type="button" class="warnBtnSuccess" title="OK" name="btnok" id="btnokID"
                            (click)="ok('brownWarningPopupWithOk')">
                            <i class="fa fa-thumbs-up" aria-hidden="true" tooltip="OK"></i>
                            OK
                        </button>
                    </div>
                    <div class="col-sm-3"></div>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<app-footer></app-footer>
