import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileToUploadFormGroup } from "./FileToUploadFormGroup";
import { Constants } from 'src/app/modal/ProjConstants.models';
import { FileuploadService } from '../../../services/fileupload.service';
import { MessageService } from "primeng/api";
import { DAO } from 'src/app/modal/DAO';
import { CommonService } from 'src/app/services/commonService';
@Component({
    selector: 'file-upload-form',
    templateUrl: './file-upload-form.component.html',
    styleUrls: ['./file-upload-form.component.css']
})
export class FileUploadFormComponent implements OnInit {
    @Input() fileUploadFormGroup: FileToUploadFormGroup;
    @Input() isSubmitted: boolean = false;
    public fileNetAllowedFileExtensions = Constants.FileNetAllowedFileExtensions.toString();

    constructor(public fb: FormBuilder, public fileuploadService: FileuploadService
        , private messageService: MessageService
        , public dao: DAO
        , private commonService: CommonService,) { }

    ngOnInit(): void {

    }

    fileUpload() {
        const fileInput = document.querySelector('.p-fileupload-choose') as HTMLElement;
        if (fileInput) {
            fileInput.click();
        }
    }

    // ========= Click upload =====================
    clickUpload(event) {
        if (event && event.target && event.target.className == 'p-fileupload-content') {
            this.fileUpload();
        }
    }

    // ========= Upload the file=====================
    async onUpload(event) {
        const synchronizeFileUploadControls = () => {
            this.fileUploadFormGroup.synchronizeFormControls();
        };

        let response = this.fileuploadService.onUploadingFiles(event.files, this.fileUploadFormGroup.uploadedFiles,
            'lossRunDocument', this.fileUploadFormGroup.referenceNumber, synchronizeFileUploadControls
        )
        if (!!response) this.openValidPopup(response);
    }

    // ========= Open Validation Popup =====================
    public openValidPopup(msg: string = '') {
        this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Loss History', detail: msg != '' ? msg : this.dao.toastValidation, sticky: true });
        //this.dao.isClickFromMenu = false;
    }

    // ========= Delete File =====================
    deleteFile(item, i) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'Delete', sticky: true, severity: 'warn', summary: ' Do you want to delete ?', data: i });
    }

    // ========= Confirm Delete =====================
    confirmDelete(i) {
        this.messageService.clear();
        document.body.style.pointerEvents = "visible";
        this.deletingDocuments(i);
    }

    deletingDocuments(i) {
        let uploadArray = this.fileUploadFormGroup.uploadedFiles;
        let payload = { "urlData": [this.fileUploadFormGroup.uploadedFiles[i].guid] }
        this.commonService.get('delete', 'FileUpload', payload).subscribe((event: any) => {
            uploadArray.splice(i, 1);
            this.fileUploadFormGroup.synchronizeFormControls();
        });
    }

    // ========= On Reject =====================
    public onReject(type) {
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
    }
}
