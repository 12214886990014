import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WIPSearchModal } from '../new-submission/new-submission.component';
import { dropdown } from 'src/app/modal/DAO';
import { ServiceLayer } from 'src/app/services/serviceLayer';
import { NgForm } from '@angular/forms';
import { workInProgress } from 'src/app/modal/routing.models';
declare var jQuery: any;
import * as $ from "jquery";

@Component({
  selector: 'work-in-progress-template',
  templateUrl: './work-in-progress-template.component.html',
  styleUrls: ['./work-in-progress-template.component.css']
})
export class WorkInProgressTemplateComponent implements OnInit {
  @ViewChild('searchForm') searchWIPForm: NgForm;
  @Input() workInProgressSearchModal: WIPSearchModal = new WIPSearchModal();
  @Input() workInProgressStatusDropdown: Array<dropdown> = new Array<dropdown>();
  @Input() workInProgressData: Array<workInProgress> = new Array<workInProgress>();
  @Input() hasNoWorkInProgressResults: boolean = false;
  @Input() isAgencyView: boolean = false;
  @Input() isEndorsementView: boolean = false;
  @Output() onSubmissionItemSelected = new EventEmitter<workInProgress>();
  @Output() onEndorsementItemSelected = new EventEmitter<workInProgress>();
  @Output() onSearchButtonClicked = new EventEmitter<boolean>();
  public isSubmitted: boolean = false;
  public isFormChanged: boolean = false;

  constructor(public serviceLayer: ServiceLayer, private el: ElementRef) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.dateControlChange();
      this.searchWIPForm.form.valueChanges.subscribe(() => {
        this.isFormChanged = true;
      });
    }, 3000);
  }

  public numbersOnly(e): boolean {
    return this.serviceLayer.numbersOnly(e, 'WIPSearch');
  }

  //  change Date control with mask
  private dateControlChange(): void {
    var that = this;
    jQuery(this.el.nativeElement).find('#calendarIcon1').inputmask("mm/dd/yyyy", {
      isComplete: function (event) {
        var value = event.join('');
        if (jQuery(".p-datepicker")[0] == undefined) {
          if (value != '' && !isNaN(new Date(value).getTime())) {
            var dobDate = value.replace('m', '0').replace('d', '0').replace('y', '0');
            if (that.workInProgressSearchModal.beginningEffectiveDate != new Date(dobDate)) {
              that.workInProgressSearchModal.beginningEffectiveDate = new Date(dobDate);
            }
          }
        }
      },
      onKeyValidation: function (key, result) {
        $("div").remove(".p-datepicker");
      }
    });

    jQuery(this.el.nativeElement).find('#calendarIcon1').blur(function () {
      if (new Date(that.workInProgressSearchModal.beginningEffectiveDate) > that.workInProgressSearchModal.endingEffectiveDate) {
        that.workInProgressSearchModal.beginningEffectiveDate = that.workInProgressSearchModal.endingEffectiveDate;
      }
    })

    jQuery(this.el.nativeElement).find('#calendarIcon2').inputmask("mm/dd/yyyy", {
      isComplete: function (event) {
        var value = event.join('');
        if (jQuery(".p-datepicker")[0] == undefined) {
          if (value != '' && !isNaN(new Date(value).getTime())) {
            var dobDate = value.replace('m', '0').replace('d', '0').replace('y', '0');
            if (that.workInProgressSearchModal.endingEffectiveDate != new Date(dobDate)) {
              that.workInProgressSearchModal.endingEffectiveDate = new Date(dobDate);
            }
          }
        }
      },
      onKeyValidation: function (key, result) {
        $("div").remove(".p-datepicker");
      }
    });
    jQuery(this.el.nativeElement).find('#calendarIcon2').blur(function () {
      if (new Date(that.workInProgressSearchModal.endingEffectiveDate) < that.workInProgressSearchModal.beginningEffectiveDate) {
        that.workInProgressSearchModal.endingEffectiveDate = that.workInProgressSearchModal.beginningEffectiveDate;
      }
    })
  }

  public getWIPFilter(searchForm: NgForm): void {
    this.isFormChanged = false;
    if (searchForm.valid) {
      this.onSearchButtonClicked.emit(false);
    }
    this.isSubmitted = true;
  }

  public onRowSelect(event: workInProgress): void {
    this.onSubmissionItemSelected.emit(event);
  }

  public clickedInProgressEndorsement(event: workInProgress): void {
    if (event.status == "Incomplete") {
      this.onEndorsementItemSelected.emit(event);
    }
  }

  public resetWIPFilter(searchForm: NgForm): void {
    this.isFormChanged = false;
    this.workInProgressSearchModal = new WIPSearchModal();
    this.onSearchButtonClicked.emit(true);
    searchForm.resetForm();
    this.isSubmitted = false;
  }
}
