<div class="container dotcls">
    <div class="box_sec mt-4">
        <div class="row">
            <div class="col-lg-12">
                <form #dotForm="ngForm" style="width:100%">
                    <div class="dot_sectin_cls">
                            <div class="col-lg-12 dot_label" style="padding-left:8px;">
                                <label><b>Retailer Sub-Code</b></label>
                            </div>
                            <div class="row col-lg-12">
                                <div class="col-lg-2 dot_label" style="padding: 8px;">
                                    Sub-Code
                                </div>
                                <div class="col-lg-4" style="padding-left:0px;">
                                    <input class="form-control"
                                        [class.validate_error_cls]="IsSubmit && retailerSubCodeModal.invalid"
                                        placeholder="Enter the Retailer Sub-Code" retailerSubCodeValidator
                                        [(ngModel)]="retailerSubCode" type="text" name="retailerSubCodeModal"
                                        #retailerSubCodeModal="ngModel" id="retailerSubCodeModal" maxlength="20"
                                        (keypress)="keyPress($event)">
                                    <span class="validationred" *ngIf="IsSubmit && retailerSubCodeModal.invalid">
                                        Special characters except # and - are not allowed
                                    </span>
                                </div>
                                <div class="col-lg-1"></div>
                                <div class="col-lg-3 dot_label" style="padding: 8px; text-align: right;">
                                    Is this the incumbent agent?
                                </div>
                                <div class="col-lg-2">
                                    <div class="p-field-radiobutton">
                                        <p-radioButton class="radio_button" name="isIncumbentAgent"
                                            value="Yes" id="isIncumbentAgentYes"
                                            [(ngModel)]="isIncumbentAgent.answer">
                                        </p-radioButton>
                                        <label class="radio_button_lbl" for="isIncumbentAgentYes">Yes</label>
                                        <p-radioButton class="radio_button" name="isIncumbentAgent"
                                            value="No" id="isIncumbentAgentNo"
                                            [(ngModel)]="isIncumbentAgent.answer">
                                        </p-radioButton>
                                        <label class="radio_button_lbl" for="isIncumbentAgentNo">No</label>

                                    </div>
                                </div>
                            </div>
                        <div class="my-1">
                            <div class="dot_info_msg">
                                Check your Department of Transportation (DOT #) to start a commercial auto submission. If there are multiple DOT #’s on the account, please check the DOT # corresponding to largest number of power units.
                            </div>
                        </div>
                        <div class="row my-3 pt-2">
                            <div class="col-lg-4">
                                <input  (change)="isShowFleet = false;powerUnitsSize = ''"
                                    class="form-control"
                                    [class.validate_error_cls]="IsSubmit && dotModal.invalid"
                                    placeholder="Enter the Primary DOT#"
                                    [(ngModel)]="dotNumber" type="text" name="dotModal"
                                    #dotModal="ngModel" id="dotModal" title="Primary DOT#"
                                    maxlength="8" autocomplete="none"                 
                                    numbersOnly (keypress)="numbersOnly($event)" required>
                                    <span class="validationred" *ngIf="dotForm.submitted && dotModal.errors?.numbersOnly">
                                        Please enter whole numbers only
                                    </span>
                            </div>
                            <div class="col-lg-3 text-left">
                                <button class="btnsuccess" type="submit" name="btnSubmit" id="btnSubmitID"
                                    (click)="SubmitDOT();IsSubmit = true;isShowFleet = false;powerUnitsSize = ''"
                                    title="Submit">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div *ngIf="isShowFleet" class="mt-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <hr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 dot_label">
                                    <label name="PowerUnits" id="PowerUnitsID">Select the number of power units.
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-lg-4 {{IsSubmitFleet && !FleetSizevalue?.valid ? 'validateDrodown' : ''}}">
                                    <p-dropdown [options]="fleetSizeOptions" [(ngModel)]="powerUnitsSize"
                                        name="FleetSizevalue" id="ddFleetSizevalueID" #FleetSizevalue="ngModel"
                                        placeholder="Select" [required]="true">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 mt-2 dot_submission_para">
                                    Note: Power units do not include trailer types.
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-12 mt-3">
                                    <div class="row">
                                        <div class="col-lg-5" *ngIf="dotEffectiveDate?.enabled">
                                            <div class="row">
                                                <div class="col-lg-6 dot_label">
                                                    <label for="dotEffectiveDate">Policy Effective Date:
                                                        <span style="color: red;">{{dotEffectiveDate.required ? '*' : ''}}</span>
                                                    </label>
                                                    <span class="business_owner_icon"><i class="fa fa-question-circle"
                                                            pTooltip="The effective date must be within 05-60 calendar days from today's date."
                                                            aria-hidden="true"></i></span>
                                                </div>
                                                <div class="col-lg-6 pr-0 pl-0" [class.validate_error_cls_calendar]="IsSubmitFleet && !policyEffDate?.valid">
                                                    <p-calendar name="dotEffectiveDate" id="dotEffectiveDate" [(ngModel)]="dotEffectiveDate.answer" 
                                                                #policyEffDate="ngModel" dateFormat="mm/dd/yy" [showIcon]="true" inputId="dotEffectiveDatecalendarIconInput" 
                                                                [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"  placeholder="MM / DD / YYYY" [minDate]="mindotEffectiveDate"
                                                                [maxDate]="maxdotExpirationDate" (onSelect)="updatedotExpirationDate()" (onBlur)="updatedotExpirationDate()" [required]="true">
                                                    </p-calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5">
                                            <div class="row" *ngIf="dotExpirationDate?.enabled">
                                                <div class="col-lg-4 mt-2 dot_label">
                                                    <label for="dotExpirationDate">Expiration Date:
                                                        <span style="color: red;">{{dotExpirationDate.required ? '*' : ''}}</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6 pr-0 pl-0">
                                                    <p-calendar id="dotExpirationDate" name="dotExpirationDate" [(ngModel)]="dotExpirationDate.answer"  
                                                                #policyExpDate="ngModel" dateFormat="mm/dd/yy" [showIcon]="true" 
                                                                [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050" placeholder="MM / DD / YYYY" disabled>
                                                    </p-calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <div class="row mt-0 float-right m-0">
                                                <button type="button" class="btnsuccess" name="btnContinue" id="btnContinueID"
                                                    (click)="continueDOT();IsSubmitFleet = true" title="Continue">
                                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>                              
                                </div>
                                <div class="col-lg-12">
                                    <div class="col-lg-8" *ngIf="IsSubmitFleet && dotEffectiveDate.answer == '' ">
                                        <label class="error_msg" 
                                        name="lblerror3" id="lblerror3ID">
                                        The effective date does not meet our submission guidelines at this time. Please be
                                        sure to submit business with an effective date that is within 05-60 calendar days
                                        from the current date.
                                        </label>
                                    </div>
                                </div>                               
                            </div>                         
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <p-toast position="center" key="responseMsg" class="validation_toast" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    {{message?.data}}
                </div>
                <div class="p-grid p-fluid mt-2">
                    <div class="p-col-6">
                        <button type="button" name="btnPopContinue" id="btnPopContinueID"
                            class="{{message.severity == 'warn' ? 'warnBtnSuccess float-right' : message.severity == 'error' ? 'unauthorized_okbtn float-right' : 'btnsuccess float-right'}}"
                            (click)="submit()" title="Continue">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Continue
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button"
                            class="{{message.severity == 'warn' ? 'warnBtnerror' : message.severity == 'error' ? 'unauthorized_cancelbtn' : 'btnerror'}}"
                            name="btnPopCancel" id="btnPopCancelID" (click)="cancel()" title="Cancel">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast key="RequiredMsg" position="top-center"></p-toast>
    <p-toast key="Success" position="top-center"></p-toast>
</div>

<p-toast position="center" key="cab_invalid" class="validation_toast" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                {{message?.data}}
            </div>
            <div class="p-grid p-fluid mt-3">
                <div class="col-md-12 text-center validation_toast_btn">
                    <button type="button" pButton (click)="resetPrimaryDOT('cab_invalid')" label="Ok"
                        class="warnPBtnSuccess"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
