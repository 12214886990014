import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/component/home/home.component';
import { PolicyContainerComponent } from './component/policy-container/policy-container.component';
import { UnAuthorizedComponent } from './component/un-authorized/un-authorized.component';
import { AuthGuard } from './services/auth.guard';
import { documentLibraryComponent } from './component/documentLibrary/documentLibrary';
import { userManagementComponent } from './component/userManagement/userManagement';
import { endorsementComponent } from './component/endorsement/endorsement';
import { UserAuthorizeComponent } from './component/user-authorize/user-authorize.component';
import { InternalAdminComponent } from './component/InternalAdmin/internalAdmin';
import { BannerAdminComponent } from './component/InternalAdmin/BannerAdmin/banner-admin.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'documentLibrary', component: documentLibraryComponent, canActivate: [AuthGuard] },
  { path: 'userManagement', component: userManagementComponent, canActivate: [AuthGuard] },
  { path: 'endorsement', component: endorsementComponent, canActivate: [AuthGuard] },
  { path: 'home', component: PolicyContainerComponent, canActivate: [AuthGuard] },
  { path: 'authenticating', component: UserAuthorizeComponent },
  { path: 'unAuthorized', component: UnAuthorizedComponent },
  { path: 'internalAdmin', component: InternalAdminComponent, canActivate: [AuthGuard], 
    children: [{ path: '', redirectTo: 'bannerAdmin', pathMatch: 'full' }, 
               { path: 'bannerAdmin', component: BannerAdminComponent, canActivate: [AuthGuard] }] 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }