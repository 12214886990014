import { Component, Input, OnInit } from '@angular/core';
import { DAO } from 'src/app/modal/DAO';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
@Component({
  selector: 'app-municipal-tax',
  templateUrl: './municipal-tax.component.html',
  styleUrls: ['./municipal-tax.component.css']
})
export class MunicipalTaxComponent implements  OnInit{
  @Input() questionnaire: Questionnaire;
  @Input() isFormSubmitted: boolean;
  @Input() defaultDisable: boolean = false;
  @Input() name: string;
  public municipalLocation: QuestionAnswer; 
  public municipalLimit: QuestionAnswer; 

  constructor(public dao: DAO) { }

  ngOnInit(): void {
    this.loadQuestionAnswers();
  }

  private loadQuestionAnswers(): void {
    var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
    this.municipalLocation = this.questionnaire.questionAnswer.find(x => x.key === 'municipalLocation') ?? defaultQuestionAnswer;
    this.municipalLimit = this.questionnaire.questionAnswer.find(x => x.key === 'municipalCityLimit') ?? defaultQuestionAnswer;
  }

  public onValueChanged(): void {
    if (this.dao?.ProductModel?.productDetails?.municipalLocation) {
        this.dao.ProductModel.productDetails.municipalLocation.answer = this.municipalLocation.answer;
    }
    if (this.dao?.ProductModel?.productDetails?.municipalCityLimit) {
      this.dao.ProductModel.productDetails.municipalCityLimit.answer = this.municipalLimit.answer;
    }
  }

  private hasSelection(question: QuestionAnswer): boolean {
    return !!question?.answer && question?.answer !== 'CLICK';
  }

  private getDefaultQuestionAnswer(): QuestionAnswer {
    return {
        answer: null,
        enabled: false,
        required: false,
        group: null,
        hidden: false,
        key: null,
        isVehicleUpdatedbyVEH1: false,
        minimum: null,
        name: null,
        type: '',
        rowIndex: 0,
        questionOptions: []
    }
  }
}
