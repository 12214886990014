import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm, NgModel, NgModelGroup } from '@angular/forms';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';


@Component({
    selector: 'radiobutton-question',
    templateUrl: './radiobutton-question.component.html',
    styleUrls: ['./radiobutton-question.component.css'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'row mb-1' },
    viewProviders: [{
        provide: ControlContainer,
        deps: [[Optional, NgModelGroup]],
        useFactory: (ngModelGroup: NgModelGroup) => ngModelGroup,
      },]
})
export class RadiobuttonQuestionComponent implements OnInit {

    @ViewChild('radiobuttonModel', { static: true }) radiobuttonModel: NgModel;

    @Input() question: QuestionAnswer;
    @Input() valid: boolean = true;
    @Input() isFormSubmitted: boolean;
    @Input() disabled: boolean = false;
    @Output() clicked = new EventEmitter();
    @Input() name: string;
    @Input() radioButtonLabel1 : string = 'Yes';
    @Input() radioButtonLabel2 : string = 'No';
    @Input() radiovalue1: string ="Yes";
    @Input() radiovalue2: string ="No";

    constructor() { }

    ngOnInit(): void {
    }
    
    public get showError(): boolean {
        return (this.isFormSubmitted || !this.radiobuttonModel?.pristine) && (this.radiobuttonModel.invalid || !this.valid);
    }

    public ChangeAns(type, event): void {
        this.clicked.emit();
    }
}
