<div class="row mb-1"
*ngIf="trailerDamageLimit?.enabled">
    <div class="col-lg-12">
        <label><b><u>Trailer Interchange</u></b></label>
    </div>
</div>

<textbox-question
    *ngIf="trailerDamageLimit.enabled"
    #trailerDamageLimitTextbox
    [question]="trailerDamageLimit"
    [minAmt]="trailerDamageLimit.minimum" [maxAmt]="trailerDamageLimit.maximum"
    [isFormSubmitted]="isFormSubmitted">
</textbox-question>

<dropdown-question
    *ngIf="trailerCompDeductible.enabled"
    #trailerCompDeductibleDropdown
    [question]="trailerCompDeductible"
    [disabled]="true"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<dropdown-question
    *ngIf="trailerCollDeductible.enabled"
    #trailerCollDeductibleDropdown
    [question]="trailerCollDeductible"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<dropdown-question
    *ngIf="trailerRadius.enabled"
    #trailerRadiusDropdown
    [question]="trailerRadius"
    [disabled]="true"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<dropdown-question
    *ngIf="trailerZoneFrom.enabled"
    #trailerZoneFromDropdown
    [question]="trailerZoneFrom"
    [disabled]="true"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<dropdown-question
    *ngIf="trailerZoneTo.enabled"
    #trailerZoneToDropdown
    [question]="trailerZoneTo"
    [requiredAmtOrDecline]="trailerZoneTo.required" [supressErrorMessage]="true"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<textbox-question
    *ngIf="nonOwnedTrailerPerDay.enabled"
    #nonOwnedTrailerPerDayTextbox
    [question]="nonOwnedTrailerPerDay"
    [isFormSubmitted]="isFormSubmitted">
</textbox-question>

<textbox-question
    *ngIf="nonOwnedTrailerPerYear.enabled"
    #nonOwnedTrailerPerYearTextbox
    [question]="nonOwnedTrailerPerYear"
    [isFormSubmitted]="isFormSubmitted">
</textbox-question>

<radiobutton-question
    *ngIf="trailerShipments.enabled"
    #trailerShipmentsRadiobutton
    [disabled]="isTrailerShipmentDisabled"
    [question]="trailerShipments"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<div class="row mb-1" *ngIf="isTrailerShipmentDisabled">
    <div class="col-lg-12">
        <b>(Note : CA 23 17 of UIIE is not available for {{accountState}} state)</b>
    </div>
</div>
