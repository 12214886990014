<div class="container">
  <div class="row mt-2 mb-2">
    <div class="col-md-12 mt-3">
      <div class="header_menu_cls d-flex justify-content-center">
        <div *ngFor="let menuItem of selectedMenuItems" class="menu-circle mr-3 ml-3"
          [class.active-menu]="router.url.includes(menuItem.code) || hasAnswers(menuItem.code)"
          [class.highlight-current-menu]="router.url.includes(menuItem.code)"
          [class.highlight-Invalid-menu]="endorsement.isFinalSubmit && !isValidPage(menuItem.code)">
          <a [title]="menuItem.title" (click)="menuClick(menuItem.code)">
            <span class="fa-stack fa-lg">
              <i [ngClass]="menuItem.icon1Class" aria-hidden="true"></i>
              <i [ngClass]="menuItem.icon2Class"
                style="font-size: 0.8em;" aria-hidden="true"></i>
            </span>
            <br />
            {{ menuItem.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast position="center" key="madeChange" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="p-flex p-flex-column">
      <div class="p-text-center">
        <i class="pi pi-exclamation-triangle"></i>
        <h4>{{message.summary}}</h4>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-6">
          <button type="button" class="warnBtnSuccess float-right" title="OK" name="btnLHPopUpYes" id="btnLHPopYesID"
            (click)="onOkChanges(message.data)">
            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
            OK
          </button>
        </div>
        <div class="p-col-6">
          <button type="button" class="warnBtnerror" title="Cancel" name="btnLHPopUpNo" id="btnLHPopNoID"
            (click)="onCancelChanges('madeChange')">
            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>