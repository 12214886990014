import { DatePipe } from '@angular/common';
import { workInProgress } from '../routing.models';
import { IEndorsementWIP } from '../IEndorsementWIP';

export class WorkInProgress implements workInProgress {
    id: string;
    nameInsured: string;
    dotNumber: number;
    referenceNumber: string;
    effectiveDate: string;
    page: string;
    status: string;
    submissionTimeStamp: string;
    agentName: string;
    aAgencyCode: string;
    clientId: string;
    policyNumber: string;
    email: string;
    proceedAs: string;

    constructor (endorsementWIP: IEndorsementWIP) {
        const datePipe = new DatePipe('en-US');

        this.id = endorsementWIP.endorsementId.toString();
        this.nameInsured = endorsementWIP.namedInsured;
        this.status = endorsementWIP.status;
        this.agentName = endorsementWIP.agentName;
        this.policyNumber = endorsementWIP.policyNumber;
        this.page = endorsementWIP.userIsAt ?? '';
        this.effectiveDate = datePipe.transform(endorsementWIP.endorsementEffectiveDate, 'yyyy-MM-dd') || '';
        this.submissionTimeStamp = datePipe.transform(endorsementWIP.submittedOn, 'MM/dd/yyyy hh:mm:ss a') || '';
        this.proceedAs = endorsementWIP.proceedAs;
    }
}