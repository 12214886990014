import { Component, OnInit } from '@angular/core';
import { DAO } from 'src/app/modal/DAO';
import _ from 'lodash';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '@rss/ng-auth-service';
import { CommonService } from 'src/app/services/commonService';
import { SiteBanner } from 'src/app/modal/SiteBanner';
import { MessageService } from 'primeng/api';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';

@Component({
    selector: 'banner-admin-component',
    templateUrl: './banner-admin.component.html',
    styleUrls: ['./banner-admin.component.css']
})
export class BannerAdminComponent extends AuthenticatedUserComponent implements OnInit{

    public hasCreateOrEditBannerClicked: boolean = false;
    public bannerlist: Array<SiteBanner>;
    private deleteBannerId: number;
    public isEditBannerClicked: boolean = false;
    public siteBannerInfo: SiteBanner = new SiteBanner();
    public bannerTypeQuestionOptions = Constants.BannerTypes;

    constructor(
        public dao: DAO,
        protected authenticationService: AuthenticationService,
        private commonService: CommonService,
        private messageService: MessageService,
        private auth0UserService: Auth0UserService,
        public appConfig: AppConfig
    ) {
        super(authenticationService);
    }

    ngOnInit(): void {
        this.dao.PolicySubTemplate = 'InternalAdmin';

        if (this.appConfig.auth0Enabled)
        {
          this.auth0UserService.userLoaded$.subscribe(
            auth0User => {
              if(!!auth0User?.accessToken)
              {                
                this.getandUpdateBannerList();;
              }
            });   
        }
        else
        {
            this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(() => {
            this.dao.userMail = this.userEmail;
            this.dao.IsInternalUser = this.isInternalUser;
            this.dao.UserRole = this.isAdmin ? "admin" : this.dao.UserRole;
            this.getandUpdateBannerList();
            });
        }
       
    }
    getandUpdateBannerList(): void {
        let payload = { "urlData": [] };
        this.commonService.get('InternalAdmin', 'GetAndUpdateBannerList', payload)
            .subscribe(
                (event: any) => {
                    this.bannerlist = event as Array<SiteBanner>;
                });
        ;
    }
    public editBanner(banner: SiteBanner): void {
        if (banner != null) {
            this.siteBannerInfo = _.cloneDeep(banner);
            this.siteBannerInfo.effectiveFrom = new Date(this.siteBannerInfo.effectiveFrom);
            this.siteBannerInfo.effectiveTo = new Date(this.siteBannerInfo.effectiveTo);
            window.scrollTo(0, 0)
            this.hasCreateOrEditBannerClicked = true;
            this.isEditBannerClicked = true;
        }
    }
    private deleteBanner(): void {
        let payload = { "urlData": [this.deleteBannerId] }
        this.commonService.delete('InternalAdmin', 'DeleteBannerInfo', payload).toPromise().then((event: any) => {
            if (!!event && event == 1) {
                this.openToast('toast', false, 'success', 'Internal Admin Management', 'Banner Information is successfully deleted');
                this.getandUpdateBannerList();
            } else {
                this.openToast('toast', false, 'error', 'Internal Admin Management', 'Failed to delete the Banner Information');
            }
        });
    }
    private openToast(key: string, sticky: boolean, severity: string, summary: string, detail: string): void {
        this.messageService.add({ key: key, sticky: sticky, severity: severity, summary: summary, detail: detail });
    }
    public confirmDeleteBanner(id: number): void {
        this.deleteBannerId = id;
        document.body.style.pointerEvents = "none";
        this.openToast('warningPopup', true, 'warn', '', 'Do you want to delete ?');
    }
    public respondedToWarningPopup(value: string): void {
        this.messageService.clear();
        if (value == 'Yes') {
            this.deleteBanner();
        }
        else {
            document.body.style.pointerEvents = "visible";
        }
    }
    public createNewBanner(): void {
        this.hasCreateOrEditBannerClicked = true;
        this.siteBannerInfo = new SiteBanner();
        this.isEditBannerClicked = false;
        window.scrollTo(0, 0)
    }
    public resetBannerData(): void {
        this.hasCreateOrEditBannerClicked = false;
        this.siteBannerInfo = new SiteBanner();
    }
}

