import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthClientConfig, AuthConfig, AuthService, IdToken, User } from '@auth0/auth0-angular';
import { GetTokenSilentlyOptions} from '@auth0/auth0-spa-js/dist/typings/global';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { AuthenticatedUserComponent } from '../authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { AppConfig } from 'src/app/app.config';
import { Auth0User } from 'src/app/modal/user.models';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { DAO } from 'src/app/modal/DAO';
import { ServiceLayer } from 'src/app/services/serviceLayer';
import { Router } from '@angular/router';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-auth0-login',
  templateUrl: './auth0-login.component.html',
  styleUrls: ['./auth0-login.component.css']
})
export class Auth0LoginComponent implements OnInit, OnDestroy  {

    public auth0User: Auth0User = null;
    public environment: string;
    private userSubscription: Subscription;
    private idTokenSubscription: Subscription;
    private appStateSubscription: Subscription;
    private accessTokenSubscription: Subscription;

  constructor(public appConfig: AppConfig,
    public authenticationServiceOld: AuthenticationService,
    public auth0Service: AuthService,
    public authClientConfig: AuthClientConfig,
    private serviceLayer: ServiceLayer,
    private dao: DAO,
    private router: Router,
    public auth0UserService: Auth0UserService,
    private messageService: MessageService,
    private appComponent: AppComponent) { 
    this.environment = this.appConfig.config["env"];
  }

  ngOnInit(): void {    
    this.auth0UserService.userLoaded$.subscribe(
      auth0User => {
        this.auth0User = auth0User;
      });   
  }

  public login(): void { 
    this.appComponent.triggerLoader('show');       
    this.auth0UserService.login();
  }

  public logout(): void {        
    this.auth0UserService.logout( );    
  }

  public changePassword(): void {
    this.auth0UserService.changePassword().then(result => {
      if (!!result) {
        //The link will expire in 5 days
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'greenSuccessPopupWithOk', sticky: true, severity: 'success', detail: 'An email with a link to change the password has been sent to User email ID ' + this.auth0User.email + '. The link will expire in 5 days.' });
      }
    })
    .catch(error => {
        console.error(error);
    })
  }

  public ngOnDestroy(): void {
    this.auth0UserService.unsubscribeAuth0Events();
  }
}
