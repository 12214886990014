import { emptyModal } from "../DAO";
import { QuestionAnswer } from "../questionnaire.models";

export class DeleteVehicle {
    public type: emptyModal = new emptyModal();
    public vin: emptyModal = new emptyModal();
    public uploadedDocuments: emptyModal = new emptyModal();

    static instantiateArrayFrom(questionAnswers: Array<QuestionAnswer>): Array<DeleteVehicle> {
        let vehicleList: Array<DeleteVehicle> = [];
        if (!!!questionAnswers || questionAnswers.length === 0) {
            return vehicleList;
        }

        let numberOfVehicle: number = questionAnswers.filter(x => x.key == "type").length;
        for (let i = 1; i <= numberOfVehicle; i++) {
            let filteredQuestionAnswers: Array<QuestionAnswer> = questionAnswers.filter(x => x.rowIndex == i);
            let tempVehicle: DeleteVehicle = this.instantiateFromSingleQuestionAnswerSet(filteredQuestionAnswers);
            vehicleList.push(tempVehicle);
        }
        return vehicleList;
    }

    static instantiateFromSingleQuestionAnswerSet(questionAnswers: Array<QuestionAnswer>): DeleteVehicle {
        let tempVehicle: DeleteVehicle = new DeleteVehicle();
        if (!questionAnswers || questionAnswers.length === 0) {
            return tempVehicle;
        }
        Object.keys(tempVehicle).forEach(property => {
            let tempQuestionAnswer = questionAnswers.find(x => x.key == property);
            if (!!tempQuestionAnswer) {
                let questionAnswer = emptyModal.initializeFrom(tempQuestionAnswer);
                if (questionAnswer.answer == null) questionAnswer.answer = '';
                tempVehicle[property] = questionAnswer;
            }
        });
        return tempVehicle;
    }

    static createNewInstanceFromQuestionAnswers(questionAnswers: Array<QuestionAnswer>): DeleteVehicle {
        let firstQuestionAnswerSet: Array<QuestionAnswer> = questionAnswers.filter(x => x.rowIndex == 1);
        let tempVehicle: DeleteVehicle = this.instantiateFromSingleQuestionAnswerSet(firstQuestionAnswerSet);
        Object.keys(tempVehicle).forEach(property => {
            tempVehicle[property].answer = "";
        });
        return tempVehicle;
    }

    public toQuestionAnswers(rowIndex: number = 0): Array<QuestionAnswer> {
        let questionAnswers: Array<QuestionAnswer> = [];
        Object.keys(this).forEach(property => {
            if (this[property] instanceof Object) {
                this[property].rowIndex = rowIndex;
                questionAnswers.push(this[property]);
            }
        });
        return questionAnswers;
    }
}