<div class="container box_sec mt-4">
    <div class="row">
        <div class="col-lg-2 pl-4 ipad_28 userManagementClass">
            <label class="mt-1" name="lblUserAction" id="lblUserAction">
                Select User Action <span class="text-danger">*</span>
            </label>
        </div>
        <div class="col-lg-3 ipad_65">
            <p-dropdown 
                [options]="internalAdminUserActionsQuestionOptions"
                [(ngModel)]="internalAdminUserAction"
                name="ddInternalAdminUserAction" 
                id="ddInternalAdminUserAction"
                #ddInternalAdminUserAction="ngModel" 
                required>
            </p-dropdown>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>