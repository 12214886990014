import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DAO } from 'src/app/modal/DAO';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';

@Component({
    selector: 'no-fault',
    templateUrl: './no-fault.component.html',
    styleUrls: ['./no-fault.component.css']
})
export class NoFaultComponent implements OnInit, OnChanges {

    @Input() questionnaire: Questionnaire;
    @Input() isFormSubmitted: boolean;
    @Input() nfPipDed: String;

    public Q_addedPIP: QuestionAnswer; // Do you want Added Personal Injury Protection?
    public addedPIP: QuestionAnswer; // Added Personal Injury Protection
    public addedPIPDed: QuestionAnswer; // Added Personal Injury Protection Deductibles
    public insured_wc: QuestionAnswer; // Is insured covered by Workers Compensation?
    public lossOfIncomeBenefits: QuestionAnswer; // Does the applicant wish to exclude Loss of Income Benefits from No-Fault/PIP Coverages?
    public extendedPip: QuestionAnswer; //Does the applicant want Extended Personal Injury Protection?
    public excludeCoverageForWorkLoss: QuestionAnswer; //Does the applicant want to exclude coverage for Work Loss?
    public pipWorkLoss: QuestionAnswer; //PIP Work Loss Exclusion Applies
    public pipDedAppliesTo: QuestionAnswer; //PIP Deductible Applies

    constructor(private dao: DAO) { }

    public ngOnInit(): void {
        this.loadQuestionAnswers();
    }
    private loadQuestionAnswers(): void {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
        this.Q_addedPIP = this.questionnaire.questionAnswer.find(x => x.key === 'Q_addedPIP') ?? defaultQuestionAnswer;
        this.addedPIP = this.questionnaire.questionAnswer.find(x => x.key === 'addedPIP') ?? defaultQuestionAnswer;
        this.addedPIPDed = this.questionnaire.questionAnswer.find(x => x.key === 'addedPIPDed') ?? defaultQuestionAnswer;
        this.insured_wc = this.questionnaire.questionAnswer.find(x => x.key === 'insured_wc') ?? defaultQuestionAnswer;
        this.lossOfIncomeBenefits = this.questionnaire.questionAnswer.find(x => x.key === 'lossOfIncomeBenefits') ?? defaultQuestionAnswer;
        this.extendedPip = this.questionnaire.questionAnswer.find(x => x.key === 'extendedPip') ?? defaultQuestionAnswer;
        this.excludeCoverageForWorkLoss = this.questionnaire.questionAnswer.find(x => x.key === 'excludeCoverageForWorkLoss') ?? defaultQuestionAnswer;
        this.pipWorkLoss = this.questionnaire.questionAnswer.find(x => x.key === 'pipWorkLoss') ?? defaultQuestionAnswer;
        this.pipDedAppliesTo = this.questionnaire.questionAnswer.find(x => x.key === 'pipDedAppliesTo') ?? defaultQuestionAnswer;
    }
    private getDefaultQuestionAnswer(): QuestionAnswer {
        return {
            answer: null,
            enabled: false,
            required: false,
            group: null,
            hidden: false,
            key: null,
            isVehicleUpdatedbyVEH1: false,
            minimum: null,
            name: null,
            type: '',
            rowIndex: 0,
            questionOptions: []
        }
    }
  changePIPAnswers() {
    if (this.Q_addedPIP.answer == 'No') {
      this.addedPIP.answer = '';
      this.addedPIPDed.answer = '';
    }
  }

  public ngOnChanges(changes:SimpleChanges):void {
    this.ChangeExtendedPIPAnswers();
  }

  ChangeExtendedPIPAnswers(){
    if(this.extendedPip?.answer && this.nfPipDed != "FULL"){
      this.extendedPip.answer = "";
    }
  }
}
