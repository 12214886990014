export class BrowserDetails {

    public static getBrowserName(): string { 
        let nameAndVersion: string;
         // Iterate over each browser type to find a match in the useragent string
        this.BrowserTypes.forEach((browser) => {
            let version : string = this.getVersion(browser.regex);
            // If a version is found and nameAndVersion is still undefined, set the browserNameAndVersion
            if (!!version && !nameAndVersion) {
                nameAndVersion = browser.name;
            }
        });

        return nameAndVersion;
    }

    private static getVersion(regex : RegExp) : string {
        let userAgent : string = navigator.userAgent;
        // Match the useragent string against the browser regex
        let matchedBrowser :string[] = userAgent.match(regex);
        return !!matchedBrowser ? matchedBrowser[2] : null;
    }

    // Declared an array of browsertypes with their names and corresponding regex patterns
    private static BrowserTypes : Array<{name : string, regex : RegExp}> = 
    [
        { name: 'Microsoft Edge', regex: /(edg|edge)\/([\d.]+)/i },
        { name: 'Google Chrome', regex: /(chrome|chromium|crios)\/([\d.]+)/i },
        { name: 'Mozilla Firefox', regex: /(firefox|fxios)\/([\d.]+)/i },
        { name: 'Opera Mini', regex: /(opera|opr)\/([\d.]+)/i },
        { name: 'Safari', regex: /version\/([\d.]+).*safari/i },
        { name: 'Internet Explorer', regex: /(msie \d+.\d+|rv:\d+.\d+)/i }
    ];

}