export class Address {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    toString() {
        return this.street + " " +
                this.city + " " +
                this.state + " " +
                this.postalCode;
    }
}