<div class="col-lg-6 question-label">
    <label>{{labelText}}
        <span *ngIf="question?.required">*</span>
    </label>
</div>

<div class="col-lg-6">
    <p-dropdown
        [ngClass]="{ invalid: showError }"
        [placeholder]="question?.questionHelpText"
        #dropdownModel="ngModel"
        [name]="name ? name + 'drop' :question?.name"
        [(ngModel)]="question.answer"
        [options]="question?.questionOptions"
        [requiredAmtOrDecline]="requiredAmtOrDecline"
        [disabled]="disabled"
        (onChange)="onSelectionChanged($event)">
    </p-dropdown>
    <span class="error-message" *ngIf="showError && !supressErrorMessage">
        {{validationMessage ? validationMessage : labelText + ' is required'}}
    </span>
</div>
