import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './sharedComponents/header/header.component';
import { PolicyHolderComponent } from './component/policy-holder/policy-holder.component';
import { DropdownModule } from 'primeng/dropdown'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { CommonService } from './services/commonService';
import { Settings } from './services/settings';
import { ServiceLayer } from './services/serviceLayer';
import { DAO } from './modal/DAO';
import { FooterComponent } from './sharedComponents/footer/footer.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { menuComponent } from './sharedComponents/menu/menu.component';
import { PolicyContainerComponent } from './component/policy-container/policy-container.component';
import { UnderWriterComponent } from './component/under-writer/under-writer.component';
import { LossHistoryComponent } from './component/loss-history/loss-history.component';
import { TooltipModule } from 'primeng/tooltip';
import { InputMaskModule } from 'primeng/inputmask';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputNumberModule } from 'primeng/inputnumber';
import { LoginComponent } from './component/agentregistration/login/login.component';
import { RegistrationComponent } from './component/agentregistration/registration/registration.component';
import { NewSubmissionComponent } from './component/agentregistration/new-submission/new-submission.component';
import { DotComponent } from './component/agentregistration/dot/dot.component';
import { VehiclesComponent } from './component/vehicles/vehicles.component';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AuthGuard } from './services/auth.guard';
import { HomeComponent } from './component/home/home.component';
import { DriverComponent } from './component/driver/driver.component';
import { ProductInfoComponent } from './component/product/productInfo.component';
import { ApplicationInfoComponent } from './component/application-info/application-info.component';
import { TabViewModule } from 'primeng/tabview';
import { EditorModule } from 'primeng/editor';
import { DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { documentLibraryComponent } from './component/documentLibrary/documentLibrary';
import { userManagementComponent } from './component/userManagement/userManagement';
import { PickListModule } from 'primeng/picklist';
import { endorsementComponent } from './component/endorsement/endorsement';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';
import { RequiredAmtOrDeclineDirective } from './validations/required-amt-or-decline.directive';
import { MaxAmtDirective } from './validations/max-amt.directive';
import { CheckboxValidatorDirective } from './validations/checkboxValidator.directive';
import { UserAuthorizeComponent } from './component/user-authorize/user-authorize.component';
import { DropdownQuestionComponent } from './sharedComponents/questions/dropdown-question/dropdown-question.component';
import { UnderinsuredMotoristComponent } from './component/product/underinsured-motorist/underinsured-motorist.component';
import { RequiredAtleastOnefieldDirective } from './validations/required-atleast-onefield.directive';
import { RadiobuttonQuestionComponent } from './sharedComponents/questions/radiobutton-question/radiobutton-question.component';
import { BroadenedPIPComponent } from './component/product/broadened-pip/broadened-pip.component';
import { TotalHundredValidatorDirective } from './validations/total-hundred-validator.directive';
import { UniqueValueValidatorDirective } from './validations/unique-value-validator.directive';
import { UninsuredMotoristComponent } from './component/product/uninsured-motorist/uninsured-motorist.component';
import { MunicipalTaxComponent } from './component/product/municipal-tax/municipal-tax.component';
import { StopGapEmployersLiabilityComponent } from './component/product/stop-GapEmployersLiability/stop-GapEmployersLiability.component';
import { TextboxQuestionComponent } from './sharedComponents/questions/textbox-question/textbox-question.component';
import { AuthModule } from '@auth0/auth0-angular';
import { StateSettings } from './modal/state.models';
import { StateDriverLicenseSettings } from 'src/app/modal/state.driversLicense.models';
import { SelectEitherOrOptionDirective } from './validations/select-either-or-option.directive';
import { NoFaultComponent } from './component/product/no-fault/no-fault.component';
import { OptionalCoverageComponent } from './component/product/optional-coverage/optional-coverage.component';
import { SiteBannerComponent } from './sharedComponents/site-banner/site-banner.component';
import { DefaultCoverageComponent } from './component/product/default-coverage/default-coverage.component';
import { TrailerInterchangeComponent } from './component/product/trailer-interchange/trailer-interchange.component';
import { VehicleListValidatorDirective } from './validations/vehicle-list-validator';
import { NumericCharactersValidatorDirective } from './validations/only-numeric-characters-validator.directive';
import { UserCreationComponent } from './component/userManagement/user-creation/user-creation.component';
import { SessionManagementComponent } from './sharedComponents/session-management/session-management.component';
import { PositiveIntegersOnlyDirective } from './validations/positive-integers-validator.directive';
import { UpsertBannerComponent } from './component/InternalAdmin/BannerAdmin/upsert-banner.component';
import { InternalAdminComponent } from './component/InternalAdmin/internalAdmin';
import { BannerAdminComponent } from './component/InternalAdmin/BannerAdmin/banner-admin.component';
import { RetailerSubCodeDirective } from './validations/retailer-sub-code-validator';
import { DropDownPipe } from './utilities/pipe/dropDownPipe';
import { WorkInProgressTemplateComponent } from './component/agentregistration/work-in-progress-template/work-in-progress-template.component';
import { EndorsementViewComponent } from './component/agentregistration/endorsement-wip/endorsement-view.component';
import { EndorsementModule } from '../app/endorsement/endorsement.module'
import { EndorsementRoutingModule } from '../app/endorsement/endorsement-routing.module'
import { SharedDirectivesModule } from 'src/app/shared-directives/shared-directives.module'
import { SharedComponentsModule } from './sharedComponents/shared-components.module';
import { LossHistoryVersion2Component } from './component/loss-history-version-2/loss-history-version-2.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LiabilityAndPhysicalDamageComponent } from './component/loss-history-version-2/liability-and-physical-damage/liability-and-physical-damage.component';
import { FileUploadFormComponent } from './component/loss-history-version-2/file-upload-form/file-upload-form.component';
import { FourYearLossHistoryComponent } from './component/loss-history-version-2/four-year-loss-history/four-year-loss-history.component';
import { DocumentService } from './services/document.service';
import { Auth0LoginComponent } from './sharedComponents/auth0-login/auth0-login.component';
import { Auth0UserService } from './services/auth0UserService.service';

function initConfig(config: AppConfig) {
  return () => config.load()
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PolicyHolderComponent,
    FooterComponent,
    menuComponent,
    PolicyContainerComponent,
    UnderWriterComponent,
    LossHistoryComponent,
    LoginComponent,
    RegistrationComponent,
    NewSubmissionComponent,
    DotComponent,
    VehiclesComponent,
    HomeComponent,
    DriverComponent,
    ProductInfoComponent,
    ApplicationInfoComponent,
    documentLibraryComponent,
    userManagementComponent,
    endorsementComponent,
    RequiredAmtOrDeclineDirective,
    MaxAmtDirective,
    CheckboxValidatorDirective,
    UserAuthorizeComponent,
    DropdownQuestionComponent,
    UnderinsuredMotoristComponent,
    RequiredAtleastOnefieldDirective,
    TotalHundredValidatorDirective,
    UniqueValueValidatorDirective,
    RadiobuttonQuestionComponent,
    BroadenedPIPComponent,
    MunicipalTaxComponent,
    StopGapEmployersLiabilityComponent,
    TextboxQuestionComponent,
    UninsuredMotoristComponent,
    SelectEitherOrOptionDirective,
    NoFaultComponent,
    OptionalCoverageComponent,
    SiteBannerComponent,
    DefaultCoverageComponent,
    TrailerInterchangeComponent,
    VehicleListValidatorDirective,
    NumericCharactersValidatorDirective,
    UserCreationComponent,
    SessionManagementComponent,
    PositiveIntegersOnlyDirective,
    UpsertBannerComponent,
    InternalAdminComponent,
    BannerAdminComponent,
    RetailerSubCodeDirective,
    DropDownPipe,
    WorkInProgressTemplateComponent,
    EndorsementViewComponent,
    LossHistoryVersion2Component,
    LiabilityAndPhysicalDamageComponent,    
    FileUploadFormComponent, FourYearLossHistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DropdownModule,
    FormsModule,
    BrowserAnimationsModule,
    CalendarModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    TooltipModule,
    InputMaskModule,
    TableModule,
    FileUploadModule,
    HttpClientModule,
    ProgressBarModule,
    InputNumberModule,
    DataViewModule,
    ToastModule,
    AutoCompleteModule,
    TabViewModule,
    EditorModule,
    DialogModule,
    CardModule,
    PickListModule,
    EndorsementModule,
    EndorsementRoutingModule,
    SharedDirectivesModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    AuthModule.forRoot()
  ],
  providers: [
    DAO, 
    AppConfig,
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
    {
      provide: CommonService
    },
    Settings,
    ServiceLayer, DocumentService,
    AppComponent, PolicyHolderComponent, NewSubmissionComponent,
    MessageService, DatePipe, menuComponent, VehiclesComponent, Settings,
    Auth0UserService],    
  bootstrap: [AppComponent]
})
export class AppModule { }
