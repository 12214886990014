<div class="container ">
    <div class="main_content mt-2">
        <form #lossForm="ngForm" style="width:100%" (ngSubmit)="saveAndContinue()">
            <div class="row">
                <div class="col-lg-12">
                    <span class="text-lg-right float-lg-right">
                        <button type="button" class="btnCancelSub" name="btnLHCancelSub" id="btnLHCancelSubID"
                            title="Cancel my Submission" (click)="cancelMySubmissionPopUp()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Cancel my Submission
                        </button>
                    </span>
                    <h3>Loss History Information</h3>
                    <p class="ml-0 mt-3 pb-0 mb-2">Please count occurrences. Multiple claimants/claims related to one
                        occurrence should only be counted once.
                    </p>
                    <p class="mt-2 mb-1">
                        <b>All claim counts should include all first dollar claims.</b> <br />
                    </p>
                    <label class="auto_llh mb-0 pl-2">Auto Liability Loss History</label>
                    <p class="mt-2 pl-2 llh_grey mb-1">
                        <b>Report the Auto Liability loss history for the current year and all years to the current
                            term, up to additional three years, based on how many years the insured has had Motor
                            Carrier Authority</b> <br />
                        Enter at-fault BI/PD claims with incurred loss and expense greater than or equal to $1,500.
                        <br />
                        Do not include uninsured/underinsured motorist, no fault, medical payments or cargo losses.
                    </p>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="table-responsive">
                                <p-table class="table llh_table" [rows]="15"
                                    [value]="LossRunList?.autoLiabilityGridDetail">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center">Prior Policy Period Effective Date</th>
                                            <th class="text-center">Prior Policy Period Expiration Date</th>
                                            <th>Historical Power Units</th>
                                            <th>Number of Claims</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template let-autoLiability let-i=rowIndex pTemplate="body">
                                        <tr>
                                            <td class="text-center">{{autoLiability[0]?.answer | date : 'MM/dd/yyyy'}}
                                            </td>
                                            <td class="text-center">{{autoLiability[1]?.answer | date : 'MM/dd/yyyy'}}
                                            </td>
                                            <td *ngIf="autoLiability[2]">
                                                <input class="form-control"
                                                    [class.validate_error_cls]="(lossForm.submitted || dao?.isSubmitForm) && powerunits.invalid"
                                                    [(ngModel)]="autoLiability[2].answer"
                                                    placeholder="Enter power unit count" type="text"
                                                    name="powerunits_{{i}}"
                                                    [required]="(i== 3 && autoLiability[3].answer != '') || i != 3 ? true : autoLiability[2]?.required"
                                                    #powerunits="ngModel" (keypress)="numbersOnly($event)" [numbersOnly]
                                                    id="powerunitsID_{{i}}">
                                            </td>
                                            <td *ngIf="autoLiability[3]">
                                                <input class="form-control"
                                                    [class.validate_error_cls]="(lossForm.submitted || dao?.isSubmitForm) && claims.invalid"
                                                    [(ngModel)]="autoLiability[3].answer"
                                                    placeholder="Enter the total # of claims" type="text"
                                                    [required]="(i== 3 && autoLiability[2].answer != '') || i != 3 ? true : autoLiability[2]?.required"
                                                    name="claims_{{i}}" id="claimsID_{{i}}" #claims="ngModel"
                                                    minlength="1" maxlength="2" [numbersOnly]
                                                    (keypress)="numbersOnly($event)">
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                    <!-- Is Liability Losses Incurred Starts -->
                    <div class="row">
                        <div *ngIf="LossRunList?.liabIsLossIncurred?.enabled" style="width:100%" class="px-2">
                            <div class="col-lg-10">
                                <div class="row">
                                    <div class="col-lg-9 col-md-9">
                                        <label name="lblliabIsLossIncurred" id="lblliabIsLossIncurredID"
                                            [class.validationred]="(lossForm.submitted || dao?.isSubmitForm) && LossRunList.liabIsLossIncurred.answer == ''"
                                            class="float-left mr-3">{{LossRunList.liabIsLossIncurred.questionLabel}}<span
                                                class="ml-1" style="color: red;"
                                                *ngIf="LossRunList.liabIsLossIncurred.required">*</span></label>
                                    </div>
                                    <div class="col-lg-3 col-md-3">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="rbLiabIsLossIncurred" id="rbLiabIsLossIncurredYesID"
                                                (onClick)="ChangedliabIsLossIncurred('Yes')" value="Yes" class="radio_button"
                                                inputId="Yes" [(ngModel)]="LossRunList.liabIsLossIncurred.answer"
                                                required>
                                            </p-radioButton>
                                            <label class="radio_button_lbl" name="lblLiabIsLossIncurredYes"
                                                id="lblLiabIsLossIncurredYesID">Yes</label>
                                            <p-radioButton name="rbLiabIsLossIncurred" id="rbLiabIsLossIncurredNoID"
                                                (onClick)="ChangedliabIsLossIncurred('No')" class="radio_button" value="No"
                                                inputId="No" [(ngModel)]="LossRunList.liabIsLossIncurred.answer"
                                                required>
                                            </p-radioButton>
                                            <label class="radio_button_lbl" name="lblLiabIsLossIncurredNo"
                                                id="lblLiabIsLossIncurredNoID">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Is Liability Losses Incurred Ends -->
                    <!-- Liability Individual Losses Starts -->
                    <div
                        *ngIf="LossRunList?.liabIsLossIncurred?.enabled && LossRunList.liabIsLossIncurred.answer == 'Yes'">
                        <div class="row">
                            <div style="width:100%" class="px-2">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <label name="liabIndividualLosses" id="liabIndividualLossesID"
                                                class="float-left mr-3">{{LossRunList.liabIndividualLosses.questionLabel}}
                                                <span class="ml-1" style="color: red;"
                                                    *ngIf="LossRunList.liabIndividualLosses.required">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-5" style="padding-left:60px;">
                                            <p-dropdown
                                                [class.validateDrodown]="(lossForm.submitted || dao?.isSubmitForm) && liabIndividualLosses.invalid"
                                                #liabIndividualLosses="ngModel" name="liabIndividualLosses"
                                                id="liabIndividualLossesID"
                                                [requiredAmtOrDecline]="LossRunList.liabIndividualLosses.required"
                                                [(ngModel)]="LossRunList.liabIndividualLosses.answer"
                                                [options]="LossRunList.liabIndividualLosses.questionOptions"
                                                (onChange)="changedLiabIndividualLosses()">
                                            </p-dropdown>
                                            <span class="validationred"
                                                *ngIf="(lossForm.submitted || !liabIndividualLosses.pristine) && liabIndividualLosses.errors?.requiredAmtOrDecline">
                                                Auto Liability Large Loss must be Selected
                                            </span>
                                            <span class="validationred"
                                                *ngIf="(lossForm.submitted || !liabIndividualLosses.pristine) && (liabIndividualLosses.errors?.InvalidLossCount)">
                                                Total individual loss incurred within 4 years must match with the Loss
                                                Date and Loss Amount entered below
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12"
                            *ngIf="!!LossRunList.liabIndividualLosses.answer && isShowLiabLossIncurredGrid && LossRunList.tempLiabLossIncurredGridDetail.length>0">
                            <label class="comp_lh" name="lblLHHeader" id="lblLHHeaderID">{{'Auto Liability Large
                                Loss'}}</label>
                        </div>
                        <div *ngIf="!!LossRunList.liabIndividualLosses.answer && isShowLiabLossIncurredGrid && LossRunList.tempLiabLossIncurredGridDetail.length>0">
                            <p-table class="table llh_table" [rows]="15"
                                [value]="LossRunList.tempLiabLossIncurredGridDetail">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="text-center" style="width: 48%;">Loss Date</th>
                                        <th class="text-center" style="width: 48%;">Total Incurred Loss and Expenses</th>
                                        <th style="width: 4%;"></th>
                                    </tr>
                                </ng-template>
                                <ng-template let-LiabLossValues let-i=rowIndex pTemplate="body">
                                    <tr>
                                        <td>
                                            <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon1_{{i}}"
                                                [monthNavigator]="true" [yearNavigator]="true"
                                                [class.validate_error_cls_dp]="(dao.isSubmitForm || lossForm.submitted) && liabLossDate.invalid"
                                                placeholder="MM / DD / YYYY" [readonlyInput]="true"
                                                [(ngModel)]="LiabLossValues[0].answer" name="liabLossDate_{{i}}"
                                                id="liabLossDate_{{i}}" #liabLossDate="ngModel" required
                                                [minDate]="liabLossMinDate" [maxDate]="liabLossMaxDate"
                                                [yearRange]="liabLossDateRange">
                                            </p-calendar>
                                        </td>
                                        <td>
                                            <p-inputNumber
                                                [class.validate_error_cls]="(dao?.isSubmitForm || lossForm.submitted) && liabLossAmount.invalid"
                                                placeholder="Enter the Loss Amount" name="liabLossAmount_{{i}}"
                                                id="liabLossAmount_{{i}}" required 
                                                [maxFractionDigits]="0" mode="currency" currency="USD" locale="en-US"
                                                [(ngModel)]="LiabLossValues[1].answer" #liabLossAmount="ngModel"
                                                inputId="liabLossAmount_{{i}}" [minAmt]="100000">
                                            </p-inputNumber>
                                            <span class="validationred"
                                                *ngIf="(lossForm.submitted || !liabLossAmount.pristine) && liabLossAmount.errors?.minAmt">
                                                Loss Amount must be greater than $100,000
                                            </span>
                                        </td>
                                        <td>
                                            <span class="text-lg-center float-lg-center"
                                                *ngIf="LossRunList.tempLiabLossIncurredGridDetail.length - 1 == i && i != 0">
                                                <a class="add_class" title="Delete" name="removeLossrunPopup"
                                                    id="removeLossrunPopupID_LiabLoss_{{i}}"
                                                    (click)="removeLossrunPopup('liabIndividualLosses',i)">
                                                    <i class="fa fa-trash" style="font-size: 25px;margin-left: -2px;"
                                                        aria-hidden="true"></i>
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <!-- Liability Individual Losses Ends -->
                    <div class="row" *ngFor="let item of LossRunList?.questionaryDetail; let i= index;">
                        <div ngModelGroup="subLossGroup" #subLossgroup="ngModelGroup" style="width:100%" class="px-2">
                            <div class="col-lg-10"
                                *ngIf="((item?.parentKey == '' || item?.parentKey === item?.key) || (item?.IsShowQues)) && (item?.key != 'glLossInclude' || isShowGLbasedUWque) && (item?.key != 'cargoLossInclude' || isShowCargobasedUWque) && (item?.key != 'glLossParent' || isShowGLbasedUWque) && (item?.key != 'cargoLossParent' || isShowCargobasedUWque)">
                                <div class="row">
                                    <div class="col-lg-9 col-md-9">
                                        <label name="lblanswer" id="lblanswerID"
                                            [ngClass]="(lossForm.submitted || dao?.isSubmitForm) && (item?.answer == null || item?.answer == '') ? 'validationred' : ''"
                                            class="float-left mr-3">{{item?.questionLabel}}<span class="ml-1"
                                                style="color: red;" *ngIf="item?.required">*</span></label>
                                    </div>
                                    <div class="col-lg-3 col-md-3">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="radLH_{{i}}" id="radLHID_{{i}}"
                                                (onClick)="ChangeAns($event,i,'Yes')" value="Yes" class="radio_button"
                                                inputId="Yes" [(ngModel)]="item.answer" [required]="item.required">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" name="radLHlblYes_{{i}}"
                                                id="radLHlblYesID_{{i}}">Yes</label>
                                            <p-radioButton name="radLH_{{i}}" id="radLHID_{{i}}"
                                                (onClick)="ChangeAns($event,i,'No')" class="radio_button" value="No"
                                                inputId="No" [(ngModel)]="item.answer" [required]="item.required">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" name="radLHlblNo_{{i}}"
                                                id="radLHlblNoID_{{i}}">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Comp Starts -->
                        <div class="col-lg-12"
                            *ngIf="item?.answer == 'Yes' && item?.IsShowQues && item?.key == 'compLossParent'">
                            <label class="comp_lh" name="lblLHHeader" id="lblLHHeaderID">{{'Comp/OTC Loss
                                History'}}</label>
                        </div>
                        <div class="col-lg-12 px-2" *ngIf="item?.answer == 'Yes' && item?.key == 'compLossParent'">
                            <div ngModelGroup="compGroup" #compgroup="ngModelGroup" style="width:100%" class="px-2">
                                <div class="table-responsive">
                                    <span
                                        class="text-lg-right float-lg-right  {{LossRunList?.tempCompGridDetail?.length == 4 ? 'lossAddDisable' : ''}}">
                                        <a class="add_class" title="Add" name="btnLHAddLoss" id="btnLHAddLossID"
                                            (click)="compgroup.valid && addLossrun(item?.key); (!compgroup.valid ? compgroup.submitted = true : compgroup.submitted = false);">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <p-table class="table llh_table" [rows]="15"
                                        [value]="LossRunList?.tempCompGridDetail">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">Prior Policy Period Effective Date</th>
                                                <th class="text-center">Prior Policy Period Expiration Date</th>
                                                <th>Historical Power Units</th>
                                                <th>Number of Claims</th>
                                                <th style="width: 3%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template let-CompValues let-i=rowIndex pTemplate="body">
                                            <tr>
                                                <td class="text-center">{{CompValues[0]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td class="text-center">{{CompValues[1]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td *ngIf="CompValues[2]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || compgroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && powerunits1.invalid"
                                                        [(ngModel)]="CompValues[2].answer"
                                                        placeholder="Enter power unit count" type="text"
                                                        name="powerunits1_{{item?.key}}_{{i}}" required
                                                        #powerunits1="ngModel" (keypress)="numbersOnly($event)"
                                                        id="powerunits1ID_{{item?.key}}_{{i}}" [numbersOnly]>
                                                </td>
                                                <td *ngIf="CompValues[3]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || compgroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && claims1.invalid"
                                                        [(ngModel)]="CompValues[3].answer"
                                                        placeholder="Enter the total # of claims" type="text" required
                                                        name="claims1_{{item?.key}}_{{i}}"
                                                        id="claims1ID_{{item?.key}}_{{i}}" #claims1="ngModel"
                                                        (keypress)="numbersOnly($event)" minlength="1" maxlength="2"
                                                        [numbersOnly]>
                                                </td>
                                                <td>
                                                    <span class="text-lg-center float-lg-center"
                                                        *ngIf="LossRunList?.tempCompGridDetail?.length - 1 == i && i != 0">
                                                        <a class="add_class" title="Delete" name="removeLossrunPopup"
                                                            id="removeLossrunPopupID_{{item?.key}}_{{i}}"
                                                            (click)="removeLossrunPopup(item?.key,i)">
                                                            <i class="fa fa-trash"
                                                                style="font-size: 25px;margin-left: -2px;"
                                                                aria-hidden="true"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                        <!-- Comp Ends -->
                        <!-- Coll Starts -->
                        <div class="col-lg-12"
                            *ngIf="item?.answer == 'Yes' && item?.IsShowQues && item?.key == 'colllLossParent'">
                            <label class="comp_lh" name="lblLHHeader" id="lblLHHeaderID">{{'Collision Loss
                                History'}}</label>
                        </div>
                        <div class="col-lg-12 px-2" *ngIf="item?.answer == 'Yes' && item?.key == 'colllLossParent'">
                            <div ngModelGroup="collGroup" #collgroup="ngModelGroup" style="width:100%" class="px-2">
                                <div class="table-responsive">
                                    <span
                                        class="text-lg-right float-lg-right  {{LossRunList?.tempcollisionGridDetail?.length == 4 ? 'lossAddDisable' : ''}}">
                                        <a class="add_class" title="Add" name="btnLHAddLoss" id="btnLHAddLossID"
                                            (click)="collgroup.valid && addLossrun(item?.key); (!collgroup.valid ? collgroup.submitted = true : collgroup.submitted = false);">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <p-table class="table llh_table" [rows]="15"
                                        [value]="LossRunList?.tempcollisionGridDetail">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">Prior Policy Period Effective Date</th>
                                                <th class="text-center">Prior Policy Period Expiration Date</th>
                                                <th>Historical Power Units</th>
                                                <th>Number of Claims</th>
                                                <th style="width: 3%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template let-CollValues let-i=rowIndex pTemplate="body">
                                            <tr>
                                                <td class="text-center">{{CollValues[0]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td class="text-center">{{CollValues[1]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td *ngIf="CollValues[2]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || collgroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && powerunits1.invalid"
                                                        [(ngModel)]="CollValues[2].answer"
                                                        placeholder="Enter power unit count" type="text"
                                                        name="powerunits1_{{item?.key}}_{{i}}" required
                                                        #powerunits1="ngModel" (keypress)="numbersOnly($event)"
                                                        id="powerunits1ID_{{item?.key}}_{{i}}" [numbersOnly]>
                                                </td>
                                                <td *ngIf="CollValues[3]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || collgroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && claims1.invalid"
                                                        [(ngModel)]="CollValues[3].answer"
                                                        placeholder="Enter the total # of claims" type="text" required
                                                        name="claims1_{{item?.key}}_{{i}}"
                                                        id="claims1ID_{{item?.key}}_{{i}}" #claims1="ngModel"
                                                        (keypress)="numbersOnly($event)" minlength="1" maxlength="2"
                                                        [numbersOnly]>
                                                </td>
                                                <td>
                                                    <span class="text-lg-center float-lg-center"
                                                        *ngIf="LossRunList?.tempcollisionGridDetail?.length - 1 == i && i != 0">
                                                        <a class="add_class" title="Delete" name="removeLossrunPopup"
                                                            id="removeLossrunPopupID_{{item?.key}}_{{i}}"
                                                            (click)="removeLossrunPopup(item?.key,i)">
                                                            <i class="fa fa-trash"
                                                                style="font-size: 25px;margin-left: -2px;"
                                                                aria-hidden="true"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                        <!-- Coll Ends -->
                        <!-- Gl Starts -->
                        <div class="col-lg-12"
                            *ngIf="item?.answer == 'Yes' && item?.IsShowQues && item?.key == 'glLossParent' && isShowGLbasedUWque">
                            <label class="comp_lh" name="lblLHHeader" id="lblLHHeaderID">{{'General Liability Loss
                                History'}}</label>
                        </div>
                        <div class="col-lg-12 px-2"
                            *ngIf="item?.answer == 'Yes' && item?.IsShowQues && (item?.key == 'glLossParent' && isShowGLbasedUWque)">
                            <div ngModelGroup="glGroup" #glgroup="ngModelGroup" style="width:100%" class="px-2">
                                <div class="table-responsive">
                                    <span
                                        class="text-lg-right float-lg-right  {{LossRunList?.tempGenLiabilityGridDetail?.length == 4 ? 'lossAddDisable' : ''}}">
                                        <a class="add_class" title="Add" name="btnLHAddLoss" id="btnLHAddLossID"
                                            (click)="glgroup.valid && addLossrun(item?.key); (!glgroup.valid ? glgroup.submitted = true : glgroup.submitted = false);">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <p-table class="table llh_table" [rows]="15"
                                        [value]="LossRunList?.tempGenLiabilityGridDetail">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">Prior Policy Period Effective Date</th>
                                                <th class="text-center">Prior Policy Period Expiration Date</th>
                                                <th>Annual Payroll Excluding Drivers</th>
                                                <th>Number of Claims</th>
                                                <th style="width: 3%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template let-GlValues let-i=rowIndex pTemplate="body">
                                            <tr>
                                                <td class="text-center">{{GlValues[0]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td class="text-center">{{GlValues[1]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td *ngIf="item?.key == 'glLossParent' && GlValues[2]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || glgroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && powerunits2.invalid"
                                                        [(ngModel)]="GlValues[2].answer"
                                                        placeholder="Enter Annual Payroll" type="text" required
                                                        name="annualpayroll1_{{item?.key}}_{{i}}"
                                                        id="annualpayroll1ID_{{item?.key}}_{{i}}" #powerunits2="ngModel"
                                                        (keypress)="numbersOnly($event)"
                                                        [numbersOnly]>
                                                </td>
                                                <td *ngIf="item?.key == 'glLossParent' && GlValues[3]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || glgroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && claims1.invalid"
                                                        [(ngModel)]="GlValues[3].answer"
                                                        placeholder="Enter the total # of claims" type="text" required
                                                        name="claims1_{{item?.key}}_{{i}}"
                                                        id="claims1ID_{{item?.key}}_{{i}}" #claims1="ngModel"
                                                        (keypress)="numbersOnly($event)" minlength="1" maxlength="2"
                                                        [numbersOnly]>
                                                </td>
                                                <td>
                                                    <span class="text-lg-center float-lg-center"
                                                        *ngIf="LossRunList?.tempGenLiabilityGridDetail?.length - 1 == i && i != 0">
                                                        <a class="add_class" title="Delete" name="removeLossrunPopup"
                                                            id="removeLossrunPopupID_{{item?.key}}_{{i}}"
                                                            (click)="removeLossrunPopup(item?.key,i)">
                                                            <i class="fa fa-trash"
                                                                style="font-size: 25px;margin-left: -2px;"
                                                                aria-hidden="true"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                        <!-- Gl Ends -->
                        <!-- Cargo Starts -->
                        <div class="col-lg-12"
                            *ngIf="item?.answer == 'Yes' && item?.IsShowQues && item?.key == 'cargoLossParent' && isShowCargobasedUWque">
                            <label class="comp_lh" name="lblLHHeader" id="lblLHHeaderID">{{'Motor Truck Cargo Loss
                                History'}}</label>
                        </div>
                        <div class="col-lg-12 px-2"
                            *ngIf="item?.answer == 'Yes' && item?.IsShowQues && (item?.key == 'cargoLossParent' && isShowCargobasedUWque)">
                            <div ngModelGroup="cargoGroup" #cargogroup="ngModelGroup" style="width:100%" class="px-2">
                                <div class="table-responsive">
                                    <span
                                        class="text-lg-right float-lg-right  {{LossRunList?.tempCargoGridDetail?.length == 4 ? 'lossAddDisable' : ''}}">
                                        <a class="add_class" title="Add" name="btnLHAddLoss" id="btnLHAddLossID"
                                            (click)="cargogroup.valid && addLossrun(item?.key); (!cargogroup.valid ? cargogroup.submitted = true : cargogroup.submitted = false);">
                                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <p-table class="table llh_table" [rows]="15"
                                        [value]="LossRunList?.tempCargoGridDetail">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="text-center">Prior Policy Period Effective Date</th>
                                                <th class="text-center">Prior Policy Period Expiration Date</th>
                                                <th>Historical Power Units</th>
                                                <th>Number of Claims</th>
                                                <th style="width: 3%;"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template let-CargoValues let-i=rowIndex pTemplate="body">
                                            <tr>
                                                <td class="text-center">{{CargoValues[0]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td class="text-center">{{CargoValues[1]?.answer | date :
                                                    'MM/dd/yyyy'}}</td>
                                                <td *ngIf="item?.key != 'glLossInclude' && CargoValues[2]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || cargogroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && powerunits1.invalid"
                                                        [(ngModel)]="CargoValues[2].answer"
                                                        placeholder="Enter power unit count" type="text"
                                                        name="powerunits1_{{item?.key}}_{{i}}" required
                                                        #powerunits1="ngModel" (keypress)="numbersOnly($event)"
                                                        id="powerunits1ID_{{item?.key}}_{{i}}" [numbersOnly]>
                                                </td>
                                                <td *ngIf="item?.key == 'cargoLossParent' && CargoValues[3]">
                                                    <input class="form-control"
                                                        [class.validate_error_cls]="(subLossgroup.submitted || cargogroup?.submitted || dao?.isSubmitForm || lossForm.submitted) && claims1.invalid"
                                                        [(ngModel)]="CargoValues[3].answer"
                                                        placeholder="Enter the total # of claims" type="text" required
                                                        name="claims1_{{item?.key}}_{{i}}"
                                                        id="claims1ID_{{item?.key}}_{{i}}" #claims1="ngModel"
                                                        (keypress)="numbersOnly($event)" minlength="1" maxlength="2"
                                                        [numbersOnly]>
                                                </td>
                                                <td>
                                                    <span class="text-lg-center float-lg-center"
                                                        *ngIf="LossRunList?.tempCargoGridDetail?.length - 1 == i && i != 0">
                                                        <a class="add_class" title="Delete" name="removeLossrunPopup"
                                                            id="removeLossrunPopupID_{{item?.key}}_{{i}}"
                                                            (click)="removeLossrunPopup(item?.key,i)">
                                                            <i class="fa fa-trash"
                                                                style="font-size: 25px;margin-left: -2px;"
                                                                aria-hidden="true"></i>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                        <!-- Cargo ends -->
                    </div>

                    <div class="uplod col-lg-8 offset-lg-3 mt-2 float-right px-2">
                        <label name="lblfileUpload" id="lblfileUploadID" class="upload_label">Upload a minimum of 3 years of loss runs<sup
                                *ngIf="(LossRunList?.fileQuestionaryDetail?.length ? LossRunList?.fileQuestionaryDetail[0].required : false)">*</sup></label>

                        <a (click)="fileUpload()" name="fileUpload" id="fileUploadID"
                            class="{{((lossForm.submitted || dao?.isSubmitForm) && LossRunList?.uploadFiles?.length == 0 && LossRunList?.fileQuestionaryDetail?.length > 0 ? LossRunList?.fileQuestionaryDetail[0].required : false) ? 'fileUpload_error_cls' : ''}} drag_and_drop">
                            <h3 class="mb-0">
                                Drag & Drop Files Here
                            </h3>
                            <p class="mb-0">or</p>
                            <h6>
                                Browse Files
                            </h6>
                        </a>
                        <p-fileUpload name="demo[]" id="demoID[]" class="UploadCls" (onSelect)="onUpload($event)"
                            (click)="clickUpload($event)" [accept]="fileNetAllowedFileExtensions"
                            multiple="multiple" maxFileSize="15728640"
                            invalidFileTypeMessageSummary="{0}: Invalid file type.">
                        </p-fileUpload>
                        <div class="row"
                            *ngFor="let item of LossRunList?.uploadFiles; let i= index;">
                            <div class="col-lg-12 mt-3">
                                <div class="upload_file_icon mr-3">
                                    <i class="fa fa-file-o" aria-hidden="true"></i>
                                </div>
                                <div class="upload_file_details">
                                    <div class="upload_file_name">
                                        {{item?.fileName}}
                                    </div>
                                    <span class="row text-right float-right upload_size_details"><b
                                            class="color_dark">({{item?.filesize}})</b>
                                        <i class="fa fa-times-circle-o ml-2 fa-lg color_green pointerCur"
                                            title="Delete {{item?.fileName}}" (click)="deleteFile(item,i)"
                                            aria-hidden="true"></i>
                                    </span><br>
                                    <p-progressBar [value]="item?.progressValue"></p-progressBar>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-2">
                            <div class="small color_grey">
                                The combined file size limit is 15 MB.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 px-2" *ngIf="dao.PolicyTemplate == 'losshistory'">
                    <div class="col-lg-12 mt-3">
                        <span class="uplod text-lg-left mr-3">
                            <button type="button" name="btnLHback" id="btnLHbackID" class="btnerror"
                                (click)="onBack('uw')" title="Back">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                Back
                            </button>
                        </span>
                        <span class="text-lg-right float-lg-right"
                            *ngIf="LossRunList && LossRunList?.autoLiabilityGridDetail?.length > 0">
                            <button type="submit" name="btnLHSaveContinue" id="btnsaveContinueID" class="btnsuccess"
                                title="Save & Continue" data-toggle="modal">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Save & Continue
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-toast class="validation-toast" key="myKey1" position="top-center"></p-toast>
    <p-toast position="center" key="Delete" (onClose)="onReject('Delete')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnLHPopUpYes"
                            id="btnLHPopYesID" (click)="confirmDelete()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnLHPopUpNo" id="btnLHPopNoID"
                            (click)="onReject('Delete')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="CancelMySubmission" (onClose)="onReject('CancelMySubmission')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnLHCancelsubYes"
                            id="btnLHCancelSubYesID" (click)="CancelmySubmission()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnLHPopcancelsubNo"
                            id="btnLHPopcancelsubNoID" (click)="onReject('CancelMySubmission')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>

    <p-toast position="center" key="LossRun" (onClose)="onReject('LossRun')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" name="btnremoveLossrunYes"
                            id="btnremoveLossrunYesID" title="Yes" (click)="removeLossrun()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnremoveLossrunNo"
                            id="btnremoveLossrunNoID" (click)="onReject('LossRun')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>
