import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateEndorsementComponent } from "../endorsement/create-endorsement/create-endorsement.component"
import { AuthGuard } from '../services/auth.guard';
import { EndorsementContainerComponent } from './endorsement-container/endorsement-container.component';
import { AddDriverComponent } from './add-driver/add-driver.component';
import { DeleteDriverComponent } from './delete-driver/delete-driver.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { DeleteVehicleComponent } from './delete-vehicle/delete-vehicle.component';
import { AddAdditionalInsuredComponent } from './add-additional-insured/add-additional-insured.component';
import { DeleteAdditionalInsuredComponent } from './delete-additional-insured/delete-additional-insured.component';
import { OtherEndorsementsComponent } from './otherEndorsements/otherEndorsements.component';

const routes: Routes = [
    { path: 'createEndorsement', component: CreateEndorsementComponent, canActivate: [AuthGuard] },
    {
        path: 'endorsements',
        component: EndorsementContainerComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'addDriver', component: AddDriverComponent, canActivate: [AuthGuard] },
            { path: 'deleteDriver', component: DeleteDriverComponent, canActivate: [AuthGuard] },
            { path: 'addVehicle', component: AddVehicleComponent, canActivate: [AuthGuard] },
            { path: 'deleteVehicle', component: DeleteVehicleComponent, canActivate: [AuthGuard] },
            { path: 'addAdditionalInsured', component: AddAdditionalInsuredComponent, canActivate: [AuthGuard] },
            { path: 'deleteAdditionalInsured', component: DeleteAdditionalInsuredComponent, canActivate: [AuthGuard] },
            { path: 'otherEndorsements', component: OtherEndorsementsComponent, canActivate: [AuthGuard] }
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EndorsementRoutingModule { }