<div class="container appinfo">
    <div class="row m-0">
        <div class="main_content mt-2">
            <span class="text-lg-right float-lg-right">
                <button type="button" class="btnCancelSub" name="btncancelmySubmissionName" id="btncancelmySubmissionID"
                    (click)="cancelMySubmissionPopUp()" title="Cancel my Submission">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    Cancel my Submission
                </button>
            </span>
            <h3>Application Submission</h3>
            <div class="clearfix"></div>

            <div class="row mb-2">
                <form #applicationForm="ngForm" style="width:100%">
                    <div class="col-lg-12 mt-3" *ngIf="!!applicationInfoModel.applicationDetails">
                        <div class="row mt-2" *ngIf="displayIftaFileUpload">
                            <div class="col-md-6">
                                <label name="lblfileUpload" id="lblfileUploadID" class="upload_label">Upload applicable for the following document</label>
                                <ul>
                                    <li>
                                        IFTAs- four full quarters including the most recent
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <label>
                                    {{applicationInfoModel.applicationDetails?.iftaDocumentId?.questionLabel}}
                                    <span
                                        style="color: red;">{{applicationInfoModel.applicationDetails?.iftaDocumentId?.required
                                        ? '*' : ''}}</span>
                                </label>
                                <a (click)="fileUpload('iftaDocument')" name="iftafileUpload" id="iftafileUploadID" class="drag_and_drop"
                                    [class.fileUpload_error_cls]="(applicationForm.submitted || dao?.isSubmitForm) && !iftaDocsSatisfied">
                                    <h3 class="mb-0">
                                        Drag & Drop Files Here
                                    </h3>
                                    <p class="mb-0">or</p>
                                    <h6>
                                        Browse Files
                                    </h6>
                                </a>
                                <p-fileUpload name="demo2[]" id="demoID2[]" class="UploadCls iftaDocument"
                                    (onSelect)="onUpload($event)" (click)="clickUpload($event,'iftaDocument')"
                                    [accept]="fileNetAllowedFileExtensions" multiple="multiple"
                                    invalidFileTypeMessageSummary="{0}: Invalid file type." maxFileSize="15728640">
                                </p-fileUpload>
                                <div class="row filesrow" style="margin-top: 20px;"
                                    *ngFor="let item of applicationInfoModel.iftaDocumentFiles; let i= index;">
                                    <div class="col-lg-12">
                                        <div class="col-md-1 upload_file_icon">
                                            <i class="fa fa-file-o" style="margin-left: -2px;" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-md-11 upload_file_details">
                                            <div class="upload_file_name">
                                                {{item?.fileName}}
                                            </div>
                                            <span class="row text-right float-right upload_size_details"><b
                                                    class="color_dark">({{item?.filesize}})</b>
                                                <i class="fa fa-times-circle-o ml-2 fa-lg color_green pointerCur"
                                                    title="Delete {{item?.fileName}}" (click)="deleteFile(item,i)"
                                                    aria-hidden="true"></i>
                                            </span><br>
                                            <p-progressBar [value]="item?.progressValue"></p-progressBar>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-2">
                                    <div class="small color_grey">
                                        The combined file size limit is 15 MB.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2" style="padding: 0px 15px;">
                            <label name="lblquestionInfoText" id="questionInfoTextID"
                                [class.validationred]="(applicationForm.submitted || dao?.isSubmitForm) && (applicationInfoModel.applicationDetails.motorCarrierFilingParent.answer == null || applicationInfoModel.applicationDetails.motorCarrierFilingParent.answer == '')">
                                Are motor carrier filings needed for this account? If so, please list the filings needed
                                <span
                                    style="color: red;">{{applicationInfoModel.applicationDetails?.motorCarrierFilingParent?.required
                                    ?
                                    '*' : ''}}</span>
                            </label>
                            <div class="" style="margin-left: 1%;">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="motorCarrierFilingParent" id="motorCarrierFilingParentID"
                                        value="Y" class="radio_button" required inputId="Y"
                                        #motorCarrierFilingParent="ngModel"
                                        [(ngModel)]="applicationInfoModel.applicationDetails.motorCarrierFilingParent.answer">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblMotorCarrierFilingParent"
                                        id="lblMotorCarrierFilingParentID">Yes</label>
                                    <p-radioButton name="motorCarrierFilingParent" id="motorCarrierFilingParentID"
                                        class="radio_button" value="N" required inputId="N"
                                        #motorCarrierFilingParent="ngModel"
                                        [(ngModel)]="applicationInfoModel.applicationDetails.motorCarrierFilingParent.answer">
                                    </p-radioButton>
                                    <label class="radio_button_lbl" name="lblMotorCarrierFilingParent"
                                        id="lblMotorCarrierFilingParentID">No</label>
                                </div>
                            </div>
                        </div>
                        <div class="mt-1" *ngIf="motorCarrierFilingParent.value == 'Y'">
                            <textarea class="form-control" rows="4" required
                                [(ngModel)]="applicationInfoModel.applicationDetails.motorCarrierFilingChild.answer"
                                name="motorCarrierFilingChild" id="motorCarrierFilingChildID" type="text"></textarea>
                        </div>
                        <div class="mt-2">
                            <label class="" name="lblquestionInfoText" id="questionInfoTextID">
                                Enter any notes you would like the Berkley Small Business Solutions Underwriter to
                                consider below:
                            </label>
                        </div>
                        <div class="mt-1">
                            <textarea class="form-control" rows="4"
                                [(ngModel)]="applicationInfoModel.applicationDetails.underwriterNote.answer"
                                name="underwriterNote" id="underwriterNote" type="text"></textarea>
                        </div>
                        <div class="row mt-3 mb-3">
                            <div class="col-lg-12 text-center">
                                <div class="col-lg-12 p-0 mt-3">
                                    <button type="button" class="btnerror float-left" title="Back"
                                        (click)="onBack('product')">
                                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                        Back
                                    </button>
                                    <span class="text-lg-right float-lg-right">
                                        <button type="submit" class="btnsuccess"
                                            (click)="saveAndContinue('OpenPdf')" title="View Application">
                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            View Application
                                        </button>
                                        <button type="submit" style="margin-left: 15px;"
                                            class="btnsuccess" (click)="saveAndContinue()" title="Save & Submit"
                                            data-toggle="modal">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                            Save & Submit
                                        </button>
                                        <button type="submit" style="margin-left: 15px;"
                                            class="btnsuccess" (click)="saveAndContinue('Draft')"
                                            id="btnsaveContinueID" title="Save as Draft & Close" data-toggle="modal">
                                            <i class="fa fa-save" aria-hidden="true"></i>
                                            Save as Draft & Close
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<p-toast position="center" key="confirmation" class="validation_toast appinfoconfirm" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                {{message?.data}}
            </div>
            <div class="p-grid p-fluid mt-3">
                <div class="col-md-12 text-center validation_toast_btn">
                    <button type="button" pButton (click)="onReject('confirmation')" label="Ok"
                        class="p-button-success"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" key="confirmModal" (onClose)="onReject('confirmModal')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
          <div class="p-text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>Do you want to submit your quote now?</h4>
            <h4 *ngIf="!!message.data.lossRunDocWarning">Minimum 3 years of loss runs will be required prior to binding the quote.</h4>
            <h4 *ngIf="!!message.data.iftaDocWarning">Most recent 4 full quarters IFTAs will be required prior to binding the quote.</h4>
          </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnSavePopUpYes"
                        id="btnSavePopYesID" (click)="confirmSave()">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="btnSavePopUpNo" id="btnSavePopNoID"
                        (click)="onReject('confirmModal')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" key="c" (onClose)="onReject('c')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" name="btnDVCancelSubPopUpYes" id="btnDVCancelsubPopUpYesID" pButton
                        (click)="CancelmySubmission()" label="Yes" class="p-button-success"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton name="btnDVCancelSubPopUpNo" id="btnDVCancelsubPopUpNoID"
                        (click)="onReject('c')" label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast class="validation-toast" key="applicationToastKey" position="top-center"></p-toast>
<p-toast position="center" key="Delete" (onClose)="onReject('Delete')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnLHPopUpYes"
                        id="btnLHPopYesID" (click)="confirmDelete()">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="btnLHPopUpNo" id="btnLHPopNoID"
                        (click)="onReject('Delete')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
