import { Validator, NG_VALIDATORS, FormControl} from '@angular/forms'
import { Directive, OnInit, Input  } from '@angular/core';

@Directive({
  selector: '[checkboxValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CheckboxValidatorDirective, multi: true }
  ]
})
export class CheckboxValidatorDirective {

  
  @Input("checkboxValidator") required:boolean
  constructor() { }
  ngOnInit() {
  }
 
  validate(form: FormControl) {

    if (form.value == false)
      return { 'checkboxValidator': false}
    return true;
    
  }
}