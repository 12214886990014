<div class="container regscrn">
    <div class="row ">
        <form #f="ngForm" style="width:100%"
            (ngSubmit)="(f.valid && validateEmails()) ? postRegistration(f) : openPopup()">
            <div class="row m-0">
                <div class="col-lg-6">
                    <div class="registration_cls">
                        <h2 class="text-left">Agent Registration</h2>
                        <div class="row my-4 pt-2">
                            <div class="col-lg-6">
                                <input
                                    class="{{f.submitted && !FirstName?.valid ? 'form-control validate_error_cls' : 'form-control'}}"
                                    [(ngModel)]="dao.RegistrationModal.givenName" placeholder="First Name" required
                                    type="text" name="FirstName" #FirstName="ngModel" id="FirstNameID">
                            </div>
                            <div class="col-lg-6">
                                <input
                                    class="{{f.submitted && !LastName?.valid ? 'form-control validate_error_cls' : 'form-control'}}"
                                    [(ngModel)]="dao.RegistrationModal.surName" placeholder="Last Name" required
                                    type="text" name="LastName" #LastName="ngModel" id="LastNameID">
                            </div>
                        </div>

                        <div class="row my-4 pt-2">
                            <div class="col-lg-6">
                                <input
                                    class="{{f.submitted && (!EmailAddress?.valid || !validateEmails()) ? 'form-control validate_error_cls' : 'form-control'}}"
                                    [(ngModel)]="dao.RegistrationModal.email" placeholder="Email Address" required
                                    type="text" name="EmailAddress" #EmailAddress="ngModel" id="EmailAddressID">
                                <div *ngIf="f.submitted && !validateEmails()">
                                    <span style="color: red;">
                                        Enter valid email address
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <p-autoComplete required [suggestions]="dao?.RegistrationModal?.AgencyCodeList" (completeMethod)="completeMethod($event)" [(ngModel)]="dao.RegistrationModal.agentCode"
                                        name="AgentCode" #AgentCode="ngModel" id="AgentCodeID"
                                        class="{{f.submitted && !AgentCode?.valid ? 'autocomplete validateAutoComplete_error_cls' : 'autocomplete'}}" placeholder="Agency Code"
                                        forceSelection="true" ></p-autoComplete>
                            </div>
                        </div>


                        <div class="my-4 pt-2 text-center">
                            <!-- <button class="registration_button" title="Create Account" type="submit">Create
                                Account</button> -->
                                <button type="submit" class="btnsuccess" title="Create Account" data-toggle="modal" name="CreateAccount" id="CreateAccountID">
                                    <i class="fa fa-user-plus" aria-hidden="true"></i>
                                    Create Account
                                </button>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="regis_info">
                        <h3 class="mb-3">Registration is Easy!</h3>
                        <p>Simply complete the form below to create your account.</p>
                        <p>Any questions? Please email us at for assistance. <br/>                           
                           <b><a href="mailto: Info@berkleyprimetrans.com">Info@berkleyprimetrans.com</a></b>
                        </p>
                    </div>

                </div>
            </div>
        </form>
    </div>
    <p-toast key="myKey1" position="top-center"></p-toast>
    <p-toast key="myKey2" position="top-center"></p-toast>
</div>