import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';

@Component({
  selector: 'endorsement-container',
  templateUrl: './endorsement-container.component.html'
})
export class EndorsementContainerComponent {

  public endorsement: Endorsement;

  constructor(
    public router: Router,
    private messageService: MessageService
  ) {
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
    if (!!!this.endorsement?.endorsementId) {
      this.router.navigateByUrl('/home');
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    let targetUrlLastPart = event.currentTarget.location.href.split('/').pop();
    if (Object.values(EndorsementTypes).includes(targetUrlLastPart as EndorsementTypes)) {
      let isedsGroupAvailable = this.endorsement.selectedEndorsementGroups.some(x => x.groupKey == targetUrlLastPart && x.selected);
      if (!isedsGroupAvailable) {
        this.messageService.add({ key: 'declinePopup', sticky: true, severity: 'error', summary: 'The endorsement question group you are trying to navigate to has been removed from your selections. We are redirecting you to the home screen.' });
        document.body.style.pointerEvents = "none";
      }
    }
  }
}
