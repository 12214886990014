import { Component, OnInit, OnDestroy, Input, EventEmitter } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO, emptyModal } from '../../modal/DAO';
import { MenuClickContext } from '../../modal/routing.models';
import { MessageService } from 'primeng/api';
import { menuComponent } from '../../sharedComponents/menu/menu.component';
import { ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/commonService';
import { DatePipe } from '@angular/common';
import { QuestionAnswer, Questionnaire, ResponseContainer } from 'src/app/modal/questionnaire.models';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { AppComponent } from 'src/app/app.component';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import * as $ from "jquery";
import _ from 'lodash';
declare var require: any;
declare var jQuery: any;


@Component({
    selector: 'app-loss-history',
    templateUrl: './loss-history.component.html'
})

export class LossHistoryComponent implements OnInit, OnDestroy, IBasePage {

    @Input() RollbackLossData: EventEmitter<string>;
    @ViewChild('lossForm') lossForm: FormGroupDirective;
    @ViewChild('subLossgroup') subLossgroup: FormGroupDirective;
    @ViewChild('cargogroup') cargogroup: FormGroupDirective;
    @ViewChild('glgroup') glgroup: FormGroupDirective;
    @ViewChild('compgroup') compgroup: FormGroupDirective;
    @ViewChild('collgroup') collgroup: FormGroupDirective;

    protected unsubscriber = new Subject();
    public currentFile;
    public currentFileIndex;
    private subscription: Subscription;
    public keyName;
    public keyIndex;
    public liabLossMinDate: Date;
    public liabLossMaxDate: Date = new Date();
    public liabLossDateRange: string;
    public isShowLiabLossIncurredGrid: boolean = false;
    public isShowGLbasedUWque: boolean = true;
    public isShowCargobasedUWque: boolean = true;
    public fileNetAllowedFileExtensions = Constants.FileNetAllowedFileExtensions.toString();
    public LossRunList: LossHistoryDetails = new LossHistoryDetails();
    public initialLossRunList: LossHistoryDetails = new LossHistoryDetails();
    private menuClickSubscription: Subscription;
    private apiCallSubscription: Subscription;
    private RollbackLossDataSubscription: Subscription;

    constructor(
        public serviceLayer: ServiceLayer,
        public dao: DAO,
        private messageService: MessageService,
        public menuComponent: menuComponent,
        private commonService: CommonService,
        public fileuploadService: FileuploadService,
        public appComponent: AppComponent,
        public datepipe: DatePipe
    ) { }

    // ========= Page Load =====================
    ngOnInit(): void {
        setTimeout(function () { (<any>$('#powerunitsID_0')).focus(); }, 100);
        jQuery('.p-fileupload-content').on('click', function (event) {
            this.fileUpload();
        });
        this.menuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
            (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
        );
        this.RollbackLossDataSubscription = this.RollbackLossData.subscribe(
            (page) => this.onRollback(page)
        );
        this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
            (dotSubmissionDetails) => {
                this.calculateMinMaxLossInsuredDate(dotSubmissionDetails);
                this.changeLossGL(dotSubmissionDetails);
                this.changeLossCargo(dotSubmissionDetails);
                this.initializeData(dotSubmissionDetails);
                this.setLossDocuments(dotSubmissionDetails);
            }
        );
    }

    public menuClicked(menuClickContext: MenuClickContext): void {
        if (menuClickContext != null && menuClickContext.page != "losshistory") {
            menuClickContext.currentPageIsValid = this.isPageValid();
            menuClickContext.currentPageDataChanged = !_.isEqual(this.LossRunList, this.initialLossRunList)
        }
    }
    public onRollback(currentTempage: string): void {
        if (currentTempage == "losshistory") {
            this.LossRunList = _.cloneDeep(this.initialLossRunList);
        }
    }
    // ========= Numbers only =====================
    public numbersOnly(e) {
        return this.serviceLayer.numbersOnly(e, 'loss');
    }
    // ========= File Upload =====================
    fileUpload() {
        jQuery('.p-fileupload-choose').click();
    }
    // ========= Change the answer =====================
    ChangeAns(event, index, type) {
        let jsonPathRef = require('jsonpath');
        if (this.LossRunList.questionaryDetail && this.LossRunList.questionaryDetail.length > 0) {
            if (this.LossRunList.questionaryDetail[index]) {
                this.LossRunList.questionaryDetail[index]["IsShowQues"] = (type == "Yes" ? true : (this.LossRunList.questionaryDetail[index].parentKey == "" ? false : true));
                if ((this.LossRunList.questionaryDetail[index]["key"] == 'compLossParent' || this.LossRunList.questionaryDetail[index]["key"] == 'phyDamageParent') && type == 'No' && this.LossRunList.compGridDetail.length > 0) {
                    this.LossRunList.tempCompGridDetail = [];
                    this.LossRunList.compGridDetail[0].forEach(sub => {
                        if (sub.key == 'compHistoricalPowerUnits' || sub.key == 'compNumberOfClaims') {
                            sub.answer = '';
                        }
                    })
                    this.LossRunList.tempCompGridDetail.push(_.cloneDeep(this.LossRunList.compGridDetail[0]));
                }
                if ((this.LossRunList.questionaryDetail[index]["key"] == 'glLossParent' || this.LossRunList.questionaryDetail[index]["key"] == 'glLossInclude') && type == 'No' && this.LossRunList.genLiabilityGridDetail.length > 0) {
                    this.LossRunList.tempGenLiabilityGridDetail = [];
                    this.LossRunList.genLiabilityGridDetail[0].forEach(sub => {
                        if (sub.key == 'glAnnualPayroll' || sub.key == 'glNumberOfClaims') {
                            sub.answer = '';
                        }
                    })
                    this.LossRunList.tempGenLiabilityGridDetail.push(_.cloneDeep(this.LossRunList.genLiabilityGridDetail[0]));
                }
                if ((this.LossRunList.questionaryDetail[index]["key"] == 'colllLossParent' || this.LossRunList.questionaryDetail[index]["key"] == 'phyDamageParent') && type == 'No' && this.LossRunList.collisionGridDetail.length > 0) {
                    this.LossRunList.tempcollisionGridDetail = [];
                    this.LossRunList.collisionGridDetail[0].forEach(sub => {
                        if (sub.key == 'collHistoricalPowerUnits' || sub.key == 'collNumberOfClaims') {
                            sub.answer = '';
                        }
                    })
                    this.LossRunList.tempcollisionGridDetail.push(_.cloneDeep(this.LossRunList.collisionGridDetail[0]));
                }
                if ((this.LossRunList.questionaryDetail[index]["key"] == 'cargoLossParent' || this.LossRunList.questionaryDetail[index]["key"] == 'cargoLossInclude') && type == 'No' && this.LossRunList.cargoGridDetail.length > 0) {
                    this.LossRunList.tempCargoGridDetail = [];
                    this.LossRunList.cargoGridDetail[0].forEach(sub => {
                        if (sub.key == 'cargoHistoricalPowerUnits' || sub.key == 'cargoNumberOfClaims') {
                            sub.answer = '';
                        }
                    })
                    this.LossRunList.tempCargoGridDetail.push(_.cloneDeep(this.LossRunList.cargoGridDetail[0]));
                }
                let getChildDetail = this.LossRunList.questionaryDetail.filter(data => data.parentKey == this.LossRunList.questionaryDetail[index].key);
                //==============show hide the child questions==============
                if (getChildDetail != null && getChildDetail.length > 0) {
                    getChildDetail.forEach(dt => {
                        let childIndex = this.LossRunList.questionaryDetail.findIndex(data => data.key == dt.key);
                        let childDetails = this.LossRunList.questionaryDetail[childIndex];
                        if (childIndex >= 0) {
                            this.LossRunList.questionaryDetail[childIndex]["IsShowQues"] = (type == "Yes" && childDetails["parentValue"] == 'Y' ? true : (type == "No" && childDetails["parentValue"] == 'N' ? true : false));
                            if (type == "No") {
                                this.LossRunList.questionaryDetail[childIndex]["answer"] = null;
                            }
                        }
                    })
                }
            }
        }
        setTimeout(function () { (<any>$('#powerunits1_0')).focus(); }, 100);
    }
    // ========= Add loss run =====================
    addLossrun(type) {
        let mainGrid = type == 'compLossParent' ? 'compGridDetail' : type == 'colllLossParent' ? 'collisionGridDetail' : type == 'glLossParent' ? 'genLiabilityGridDetail' : 'cargoGridDetail';
        let tempGrid = type == 'compLossParent' ? 'tempCompGridDetail' : type == 'colllLossParent' ? 'tempcollisionGridDetail' : type == 'glLossParent' ? 'tempGenLiabilityGridDetail' : 'tempCargoGridDetail';
        var length = this.LossRunList[tempGrid].length
        if (this.LossRunList[tempGrid] && this.LossRunList[mainGrid] && length < this.LossRunList[mainGrid].length) {
            if (length > 0) {
                this.LossRunList[mainGrid][length][0].answer = new Date(new Date(this.serviceLayer.getCorrectDate(this.LossRunList[mainGrid][length - 1][0].answer)).setFullYear(new Date(this.LossRunList[mainGrid][length - 1][0].answer).getFullYear() - 1))
                this.LossRunList[mainGrid][length][1].answer = new Date(new Date(this.serviceLayer.getCorrectDate(this.LossRunList[mainGrid][length - 1][1].answer)).setFullYear(new Date(this.LossRunList[mainGrid][length - 1][1].answer).getFullYear() - 1))
            }
            this.LossRunList[mainGrid][length][2].answer = null;
            this.LossRunList[mainGrid][length][3].answer = null;
            this.LossRunList[tempGrid].push(_.cloneDeep(this.LossRunList[mainGrid][length]));
        }
    }

    public isPageValid(): boolean {
        return !!!this.lossForm?.invalid
            && !!!this.compgroup?.invalid
            && !!!this.collgroup?.invalid
            && !!!this.glgroup?.invalid
            && !!!this.cargogroup?.invalid
            && this.LossRunList.uploadFiles.length > 0
    }

    public saveAndContinue(): void {
        this.dao.NotAllowedToSubmit = false;
        if (this.isPageValid()) {
            this.setLossDataToDOTList();
            this.dao.IsVehicleBreadcrumpClicked = false;
            this.serviceLayer.save();
        }
        else {
            if (!this.dao.IsSavedFromMenu) {
                let errorMessage;
                if (!this.lossForm?.invalid && !this.compgroup?.invalid && !this.collgroup?.invalid && !this.glgroup?.invalid && !this.cargogroup?.invalid) {
                    errorMessage = 'Please fill required fields highlighted in red';
                }
                else if (this.LossRunList.uploadFiles.length == 0) {
                    errorMessage = 'Loss Run documentation required';
                }
                this.appComponent.addToast('error', 'Loss History', errorMessage ?? this.dao.toastValidation);
            } else {
                this.dao.NotAllowedToSubmit = true
            }
        }
    }

    // ========= Upload the file=====================
    async onUpload(event) {
        let referenceNumber: string = this.dao.DOTList.dotQsnr?.findQuestion('number')?.answer;
        let response = this.fileuploadService.onUploadingFiles(event.files, this.LossRunList.uploadFiles, 'lossRunDocument', referenceNumber);
        if (!!response) this.openValidPopup(response);
    }

    // ========= Delete File =====================
    deleteFile(item, i) {
        this.currentFile = item;
        this.currentFileIndex = i;
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'Delete', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
    }

    // ========= Confirm Delete =====================
    confirmDelete() {
        this.messageService.clear();
        document.body.style.pointerEvents = "visible";
        this.deletingDocuments();
    }
    
    deletingDocuments() {
        let uploadArray = this.LossRunList.uploadFiles;
        let payload = { "urlData": [this.currentFile.guid] }
        this.commonService.get('delete', 'FileUpload', payload).subscribe((event: any) => {
            uploadArray.splice(this.currentFileIndex, 1);
        });
    }
    // ========= Open Validation Popup =====================
    public openValidPopup(msg: string = '') {
        this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Loss History', detail: msg != '' ? msg : this.dao.toastValidation, sticky: true });
        this.dao.isClickFromMenu = false;
    }
    // ========= Remove loss run =====================
    public async removeLossrun() {
        let type = this.keyName;
        let i = this.keyIndex;
        let mainGrid = type == 'compLossParent' ? 'compGridDetail' : type == 'colllLossParent' ? 'collisionGridDetail' : type == 'glLossParent' ? 'genLiabilityGridDetail' : type == 'cargoLossParent' ? 'cargoGridDetail' : 'liabLossIncurredGridDetail';
        let tempGrid = type == 'compLossParent' ? 'tempCompGridDetail' : type == 'colllLossParent' ? 'tempcollisionGridDetail' : type == 'glLossParent' ? 'tempGenLiabilityGridDetail' : type == 'cargoLossParent' ? 'tempCargoGridDetail' : 'tempLiabLossIncurredGridDetail';
        this.LossRunList[mainGrid][i].forEach(sub => {
            if (sub.key == 'compHistoricalPowerUnits' || sub.key == 'compNumberOfClaims'
                || sub.key == 'glAnnualPayroll' || sub.key == 'glNumberOfClaims'
                || sub.key == 'collHistoricalPowerUnits' || sub.key == 'collNumberOfClaims'
                || sub.key == 'cargoHistoricalPowerUnits' || sub.key == 'cargoNumberOfClaims'
                || sub.key == 'liabLossDate' || sub.key == 'liabLossAmount') {
                sub.answer = '';
            }
        })
        this.LossRunList[tempGrid]['splice'](i, 1);
        if (type == 'liabIndividualLosses') {
            if (this.LossRunList.tempLiabLossIncurredGridDetail.length != this.LossRunList.liabIndividualLosses.answer) {
                this.lossForm.form.controls.liabIndividualLosses.setErrors({ 'InvalidLossCount': true });
            }
        }
        this.messageService.clear('LossRun');
        document.body.style.pointerEvents = "visible";
    }
    // ============== Removing gl,comp,coll,cargo grid datas popup  ================================
    public removeLossrunPopup(key, index) {
        this.keyIndex = index;
        this.keyName = key;
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'LossRun', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
    }
    // ========= Click upload =====================
    clickUpload(event) {
        if (event && event.target && event.target.className == 'p-fileupload-content') {
            this.fileUpload();
        }
    }
    // ========= Cancel my submission =====================
    public CancelmySubmission() {
        return this.serviceLayer.CancelmySubmission();
    }
    // ========= Cancel my submission popup =====================
    public cancelMySubmissionPopUp() {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'CancelMySubmission', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
    }
    // ========= On Reject =====================
    public onReject(type) {
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
    }
    // ========= On Back =====================
    public onBack(page) {
        this.dao.IsBackButtonClicked = true;
        return this.menuComponent.menuClick(page);
    }
    public ngOnDestroy(): void {
        if (!!this.subscription)
            this.subscription.unsubscribe();
        this.unsubscriber.next();
        this.unsubscriber.complete();
        if (!!this.menuClickSubscription)
            this.menuClickSubscription.unsubscribe();

        if (!!this.apiCallSubscription) {
            this.apiCallSubscription.unsubscribe();
            this.apiCallSubscription = null;
        }
        if (!!this.RollbackLossDataSubscription) {
            this.RollbackLossDataSubscription.unsubscribe();
            this.RollbackLossDataSubscription = null;
        }
    }
    public initializeData(apiResponse: any) {
        this.LossRunList.autoLiabilityGridDetail = [];
        this.LossRunList.compGridDetail = [];
        this.LossRunList.genLiabilityGridDetail = [];
        this.LossRunList.tempCompGridDetail = [];
        this.LossRunList.tempGenLiabilityGridDetail = [];
        this.LossRunList.collisionGridDetail = [];
        this.LossRunList.tempcollisionGridDetail = [];
        this.LossRunList.cargoGridDetail = [];
        this.LossRunList.tempCargoGridDetail = [];
        this.LossRunList.liabLossIncurredGridDetail = [];
        this.LossRunList.tempLiabLossIncurredGridDetail = [];
        let getLossHistoryQuery = '$..questionnaire[?(@.key =="loss")].questionAnswer';
        let lossHistoryDetail = this.serviceLayer.getObjUsingJpath(apiResponse, getLossHistoryQuery);
        if (!!lossHistoryDetail) {
            let getLiabIndividualLossesQuery = "$..[?(@.key=='liabIndividualLosses')]";
            let liabIndividualLosses = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getLiabIndividualLossesQuery));
            this.LossRunList.liabIndividualLosses = liabIndividualLosses[0];

            let getLiabIsLossIncurredQuery = "$..[?(@.key=='liabIsLossIncurred')]";
            let liabIsLossIncurred = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getLiabIsLossIncurredQuery));
            this.LossRunList.liabIsLossIncurred = liabIsLossIncurred[0];
            //========get auto liability grid detail=====
            for (let i = 1; i <= 4; i++) {
                let getAutoLiabilityGridQuery = "$..[?( (@.rowIndex==" + i + ") &&  (@.key=='liabEffectiveDate' || @.key=='liabExpirationDate'  || @.key=='liabHistoricalPowerUnits'  ||@.key=='liabNumberOfClaims')  )]";
                let AutoLiabilityGridQuery = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getAutoLiabilityGridQuery));
                this.LossRunList.autoLiabilityGridDetail.push(AutoLiabilityGridQuery);
            }
            //========get comp/otc loss history grid detail=====
            for (let i = 1; i <= 4; i++) {
                let getCompGridQuery = "$..[?( (@.rowIndex==" + i + ") &&  (@.key=='compEffectiveDate' || @.key=='compExpirationDate'  || @.key=='compHistoricalPowerUnits'  ||@.key=='compNumberOfClaims')  )]";
                let compGridQuery = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getCompGridQuery));
                this.LossRunList.compGridDetail.push(compGridQuery);
            }
            //========get coll loss history grid detail=====
            for (let i = 1; i <= 4; i++) {
                let getcollGridQuery = "$..[?( (@.rowIndex==" + i + ") &&  (@.key=='collEffectiveDate' || @.key=='collExpirationDate'  || @.key=='collHistoricalPowerUnits'  ||@.key=='collNumberOfClaims')  )]";
                let collGridQuery = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getcollGridQuery));
                this.LossRunList.collisionGridDetail.push(collGridQuery);
            }
            //========get GL grid detail=====
            for (let i = 1; i <= 4; i++) {
                let getGLQuery = "$..[?( (@.rowIndex==" + i + ") &&  (@.key=='glEffectiveDate' || @.key=='glExpirationDate'  || @.key=='glAnnualPayroll'  ||@.key=='glNumberOfClaims')  )]";
                let glGridQuery = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getGLQuery));
                glGridQuery.forEach(gl => {
                    gl.answer = (gl.key == 'glAnnualPayroll' && gl.answer == '') ? null : gl.answer;
                });
                this.LossRunList.genLiabilityGridDetail.push(glGridQuery);
            }
            //========get Cargo grid detail=====
            for (let i = 1; i <= 4; i++) {
                let getCargoQuery = "$..[?( (@.rowIndex==" + i + ") &&  (@.key=='cargoEffectiveDate' || @.key=='cargoExpirationDate'  || @.key=='cargoHistoricalPowerUnits'  ||@.key=='cargoNumberOfClaims')  )]";
                let cargoGridQuery = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getCargoQuery));
                this.LossRunList.cargoGridDetail.push(cargoGridQuery);
            }//========get Liability Losses Incurred grid detail=====
            for (let i = 1; i <= 10; i++) {
                let getLiabLossesQuery = "$..[?( (@.rowIndex==" + i + ") &&  (@.key=='liabLossDate' || @.key=='liabLossAmount'))]";
                let liabLossesGridQuery = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getLiabLossesQuery));
                if (liabLossesGridQuery.length > 0) {
                    if (!isNaN(new Date(this.datepipe.transform((liabLossesGridQuery[0].answer), 'MM-dd-yyyy')).getTime())) {
                        liabLossesGridQuery[0].answer = this.datepipe.transform(new Date(this.datepipe.transform((liabLossesGridQuery[0].answer), 'MM-dd-yyyy')), 'MM/dd/yyyy');
                    }
                    this.LossRunList.liabLossIncurredGridDetail.push(liabLossesGridQuery);
                }
            }
            //=============== get questionaries================
            let getQuestionaryQuery = "$..[?(@.key == 'phyDamageParent' || @.key == 'compLossParent' || @.key == 'colllLossParent' || @.key == 'glLossInclude' || @.key == 'cargoLossParent' || @.key == 'glLossParent' || @.key == 'cargoLossInclude' )]";
            let questionaryDetails = _.cloneDeep(this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getQuestionaryQuery));
            this.LossRunList.questionaryDetail = questionaryDetails;
            if (this.LossRunList.questionaryDetail.length > 0) {
                this.LossRunList.questionaryDetail.forEach(que => {
                    que.IsShowQues = (que.answer == undefined || que.answer == '') ? false : true;
                    if (que.answer == undefined) {
                        que.answer = '';
                    }
                });
            }
            //=============== get questionaries for file upload================
            let getQuestionaryQueryForFile = "$..[?(@.key == 'lossRunsUpload')]";
            let fileQuestionaryDetails = this.serviceLayer.getObjUsingJpath(lossHistoryDetail, getQuestionaryQueryForFile);
            this.LossRunList.fileQuestionaryDetail = fileQuestionaryDetails;
            //========create temp for Comp/OTC Loss History grid==========
            if (this.LossRunList.compGridDetail.length > 0) {
                this.LossRunList.compGridDetail.forEach(c => {
                    if (!!c[2]?.answer && c[2].answer != '') {
                        this.LossRunList.tempCompGridDetail.push(c);
                    }
                })
                if (this.LossRunList.tempCompGridDetail.length == 0) {
                    this.LossRunList.tempCompGridDetail.push(this.LossRunList.compGridDetail[0])
                }
            }
            //========create temp for General Liability Loss History grid==========
            if (this.LossRunList.genLiabilityGridDetail.length > 0) {
                this.LossRunList.genLiabilityGridDetail.forEach(c => {
                    if (!!c[2]?.answer && c[2].answer != '') {
                        this.LossRunList.tempGenLiabilityGridDetail.push(c);
                    }
                })
                if (this.LossRunList.tempGenLiabilityGridDetail.length == 0) {
                    this.LossRunList.tempGenLiabilityGridDetail.push(this.LossRunList.genLiabilityGridDetail[0])
                }
            }
            //========create temp for collision History grid==========

            if (this.LossRunList.collisionGridDetail.length > 0) {
                this.LossRunList.collisionGridDetail.forEach(c => {
                    if (!!c[2]?.answer && c[2].answer != '') {
                        this.LossRunList.tempcollisionGridDetail.push(c);
                    }
                })
                if (this.LossRunList.tempcollisionGridDetail.length == 0) {
                    this.LossRunList.tempcollisionGridDetail.push(this.LossRunList.collisionGridDetail[0])
                }
            }
            //========create temp for cargoGridDetail grid==========
            if (this.LossRunList.cargoGridDetail.length > 0) {
                this.LossRunList.cargoGridDetail.forEach(c => {
                    if (c.length > 0 && !!c[2]?.answer && c[2].answer != '') {
                        this.LossRunList.tempCargoGridDetail.push(c);
                    }
                })
                if (this.LossRunList.tempCargoGridDetail.length == 0) {
                    this.LossRunList.tempCargoGridDetail.push(this.LossRunList.cargoGridDetail[0])
                }
            }
            //========create temp for liabLossIncurredGri==========
            if (this.LossRunList.liabLossIncurredGridDetail.length > 0) {
                this.LossRunList.liabLossIncurredGridDetail.forEach(c => {
                    this.LossRunList.tempLiabLossIncurredGridDetail.push(c);
                })
            }
        }
        this.isShowLiabLossIncurredGrid = this.LossRunList?.liabIndividualLosses?.answer != 'CLICK';
        this.initialLossRunList = _.cloneDeep(this.LossRunList);
    }
    public setLossDataToDOTList() {
        var query = '$..questionnaire[?(@.key =="loss")].questionAnswer';
        let lossHistoryDetail = this.serviceLayer.getObjUsingJpath(this.dao.DOTList, query);
        if (lossHistoryDetail != null && lossHistoryDetail.length > 0) {
            lossHistoryDetail[0].forEach((c, i) => {
                let GridQuery = "$..[?(@.key=='" + c.key + "' && @.rowIndex=='" + c.rowIndex + "')]";
                let AutoLiabilityGrid = this.serviceLayer.getObjUsingJpath(this.LossRunList.autoLiabilityGridDetail, GridQuery);
                let tempCompGrid = this.serviceLayer.getObjUsingJpath(this.LossRunList.tempCompGridDetail, GridQuery);
                let tempGenLiaGrid = this.serviceLayer.getObjUsingJpath(this.LossRunList.tempGenLiabilityGridDetail, GridQuery);
                let tempCollLiaGrid = this.serviceLayer.getObjUsingJpath(this.LossRunList.tempcollisionGridDetail, GridQuery);
                let tempCargoGrid = this.serviceLayer.getObjUsingJpath(this.LossRunList.tempCargoGridDetail, GridQuery);
                if (c.key == 'lossRunsUpload' && this.LossRunList.uploadFiles && this.LossRunList.uploadFiles.length > 0) {
                    c.answer = this.LossRunList.uploadFiles[0].guid;
                    c.fileName = this.LossRunList.uploadFiles[0].fileName;
                }
                if (AutoLiabilityGrid && AutoLiabilityGrid.length > 0) {
                    c.answer = AutoLiabilityGrid[0].answer;
                }
                if (tempCompGrid && tempCompGrid.length > 0) {
                    c.answer = tempCompGrid[0].answer;
                }
                else if (c.key == 'compHistoricalPowerUnits' || c.key == 'compNumberOfClaims') {
                    c.answer = '';
                }
                if (tempGenLiaGrid && tempGenLiaGrid.length > 0) {
                    c.answer = tempGenLiaGrid[0].answer;
                }
                else if (c.key == 'glAnnualPayroll' || c.key == 'glNumberOfClaims') {
                    c.answer = '';
                }
                if (tempCollLiaGrid && tempCollLiaGrid.length > 0) {
                    c.answer = tempCollLiaGrid[0].answer;
                }
                else if (c.key == 'collHistoricalPowerUnits' || c.key == 'collNumberOfClaims') {
                    c.answer = '';
                }
                if (tempCargoGrid && tempCargoGrid.length > 0) {
                    c.answer = tempCargoGrid[0].answer;
                }
                else if (c.key == 'cargoHistoricalPowerUnits' || c.key == 'cargoNumberOfClaims') {
                    c.answer = '';
                }
            });
            if (lossHistoryDetail[0].some(x => x.key == 'liabIsLossIncurred') && this.LossRunList.liabIsLossIncurred.answer == "Yes") {
                _.remove(lossHistoryDetail[0], function (n) {
                    return n.key == 'liabLossDate' || n.key == 'liabLossAmount';
                })
                for (var i = 0; i < this.LossRunList.tempLiabLossIncurredGridDetail.length; i++) {
                    let liabLosses = _.cloneDeep(this.LossRunList.tempLiabLossIncurredGridDetail[0]);
                    liabLosses[0].answer = this.LossRunList.tempLiabLossIncurredGridDetail[i].find(x => x.key == 'liabLossDate').answer;
                    liabLosses[0].rowIndex = i + 1;
                    lossHistoryDetail[0].push(liabLosses[0]);
                    liabLosses[1].answer = this.LossRunList.tempLiabLossIncurredGridDetail[i].find(x => x.key == 'liabLossAmount').answer;
                    liabLosses[1].rowIndex = i + 1;
                    lossHistoryDetail[0].push(liabLosses[1]);
                }
            }
            // ====loss questions save===
            lossHistoryDetail[0].forEach((ques, i) => {
                if (ques.key == 'phyDamageParent' || ques.key == 'compLossParent' || ques.key == 'colllLossParent' || ques.key == 'glLossParent' || ques.key == 'glLossInclude' || ques.key == 'cargoLossParent' || ques.key == 'cargoLossInclude') {
                    ques.answer = this.serviceLayer.getObjUsingJpath(this.LossRunList.questionaryDetail, "$..[?(@.key == '" + ques.key + "')]")[0].answer;
                }
                if (ques.key == 'liabIsLossIncurred') {
                    ques.answer = this.LossRunList.liabIsLossIncurred.answer;
                }
                if (ques.key == 'liabIndividualLosses') {
                    ques.answer = this.LossRunList.liabIndividualLosses.answer;
                }
            });

            if (this.LossRunList.uploadFiles && this.LossRunList.uploadFiles.length > 0) {
                _.remove(lossHistoryDetail[0], function (n) {
                    return n.key == 'lossRunsUpload' && n.rowIndex != 1;
                })
                this.LossRunList.uploadFiles.forEach((c, i) => {
                    if (i != 0 && lossHistoryDetail[0] && lossHistoryDetail[0].filter(l => l.key == 'lossRunsUpload')) {
                        var TempFileUpload = _.cloneDeep(lossHistoryDetail[0].filter(l => l.key == 'lossRunsUpload')[0]);
                        TempFileUpload.answer = c.guid;
                        TempFileUpload.fileName = c.fileName;
                        TempFileUpload.rowIndex = i + 1;
                        lossHistoryDetail[0].push(TempFileUpload);
                    }
                })
            }
        }
    }
    public ChangedliabIsLossIncurred(value: string): void {
        if (value == 'No' || (value == 'Yes' && !!!this.LossRunList.liabIndividualLosses.answer)) {
            this.LossRunList.liabIndividualLosses.answer = "";
            this.LossRunList.liabLossIncurredGridDetail = [];
            this.LossRunList.tempLiabLossIncurredGridDetail = [];
        }
    }
    changedLiabIndividualLosses() {
        this.isShowLiabLossIncurredGrid = false;
        if (this.LossRunList.liabIndividualLosses.answer == 'CLICK') {
            this.LossRunList.tempLiabLossIncurredGridDetail = [];
            this.LossRunList.liabLossIncurredGridDetail = [];
            return;
        }
        if (Number(this.LossRunList.liabIndividualLosses.answer) >= 1 && Number(this.LossRunList.liabIndividualLosses.answer) <= 10) {
            this.isShowLiabLossIncurredGrid = true;
            let lengthOfLiabLossesGrid = this.LossRunList.tempLiabLossIncurredGridDetail.length;
            let spliceIndex;
            let spliceCount;
            if (lengthOfLiabLossesGrid > Number(this.LossRunList.liabIndividualLosses.answer)) {
                spliceIndex = this.LossRunList.liabIndividualLosses.answer;
                spliceCount = lengthOfLiabLossesGrid - this.LossRunList.liabIndividualLosses.answer;
                this.LossRunList.tempLiabLossIncurredGridDetail.splice(spliceIndex, spliceCount);
            }
            else if (lengthOfLiabLossesGrid < Number(this.LossRunList.liabIndividualLosses.answer)) {
                if (lengthOfLiabLossesGrid == 0) {
                    let lossQuestionAnswer = this.dao.DOTList?.questionnaire?.find(q => q.key === 'loss').questionAnswer;
                    let getLiabLossesQuery = "$..[?( (@.rowIndex=='1') &&  (@.key=='liabLossDate' || @.key=='liabLossAmount'))]";
                    let liabLossesGridQuery = this.serviceLayer.getObjUsingJpath(lossQuestionAnswer, getLiabLossesQuery);
                    this.LossRunList.liabLossIncurredGridDetail.push(liabLossesGridQuery);
                }
                for (var i = lengthOfLiabLossesGrid; i < Number(this.LossRunList.liabIndividualLosses.answer); i++) {
                    let liabLosses = _.cloneDeep(this.LossRunList.liabLossIncurredGridDetail[0]);
                    liabLosses[0].answer = '';
                    liabLosses[0].rowIndex = i + 1;
                    liabLosses[1].rowIndex = i + 1;
                    liabLosses[1].answer = '';
                    this.LossRunList.tempLiabLossIncurredGridDetail.push(liabLosses);
                }
            }
            this.LossRunList.liabLossIncurredGridDetail = _.cloneDeep(this.LossRunList.tempLiabLossIncurredGridDetail);
        }
    }

    public changeLossCargo(apiResponse: any) {
        this.checkUWQusForLoss(apiResponse);
        let lossdetails = this.serviceLayer.getObjUsingJpath(apiResponse,
            '$..questionnaire[?(@.key =="loss")]..[?(@.key =="cargoLossInclude" || @.parentKey =="cargoLossInclude" || @.key =="cargoLossParent" || @.parentKey =="cargoLossParent")]');
        if (!!lossdetails && lossdetails.length > 0 && !this.isShowCargobasedUWque) {
            lossdetails.forEach(c => {
                if (c.key == 'cargoLossInclude' || c.key == 'cargoLossParent') {
                    c.answer = 'No';
                    if (c.parentKey == 'cargoLossInclude')
                        c.answer = '';
                }
                else if (c.key != 'cargoEffectiveDate' && c.key != 'cargoExpirationDate') {
                    c.answer = '';
                }
            });
        }
    }

    // ===== Change loss question based UW GL question
    public changeLossGL(apiResponse: any) {
        this.checkUWQusForLoss(apiResponse);
        let lossdetails = this.serviceLayer.getObjUsingJpath(apiResponse, '$..questionnaire[?(@.key =="loss")]..[?(@.key =="glLossInclude" || @.parentKey =="glLossInclude" || @.key =="glLossParent" || @.parentKey =="glLossParent")]');
        if (!!lossdetails && lossdetails.length > 0 && !this.isShowGLbasedUWque) {
            lossdetails.forEach(c => {
                if (c.key == 'glLossInclude' || c.key == 'glLossParent') {
                    c.answer = 'No';
                    if (c.parentKey == 'glLossInclude')
                        c.answer = '';
                }
                else if (c.key != 'glEffectiveDate' && c.key != 'glExpirationDate') {
                    c.answer = '';
                }
            });
        }
    }

    // ====== Check UW question for Loss History======
    public checkUWQusForLoss(apiResponse: any) {
        let UWAnswer = this.serviceLayer.getObjUsingJpath(apiResponse, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key == "operateMobleEquip" || @.key == "contractsAssumeLiabilityParent")]');
        let cargoUWAnswer = this.serviceLayer.getObjUsingJpath(apiResponse, '$..questionnaire[?(@.key == "uw")].questionAnswer[?(@.key == "unattendedLoadedTrailers" || @.key == "actualDamageInLossEvent" || @.key == "IsDOTBillIssued")]');
        if (!!UWAnswer && UWAnswer.length > 0) {
            this.isShowGLbasedUWque = true;
            if (UWAnswer.filter(c => c.answer == "true" || c.answer == true || c.answer == "Y")?.length > 0) {
                this.isShowGLbasedUWque = false;
            }
        }
        if (!!cargoUWAnswer && cargoUWAnswer.length > 0) {
            this.isShowCargobasedUWque = true;
            cargoUWAnswer.forEach(c => {
                if ((c.answer == "Y" && c.key == "unattendedLoadedTrailers") || (c.answer == "Y" && c.key == "actualDamageInLossEvent") || (c.answer == "N" && c.key == "IsDOTBillIssued")) {
                    this.isShowCargobasedUWque = false;
                }
            });
        }
    }

    // set Loss documents to the LossRunList.uploadFiles
    private async setLossDocuments(apiResponse: ResponseContainer): Promise<void> {
        var lossRunFiles: QuestionAnswer[] = apiResponse?.losssHistoryQuestionnaire?.findQuestions("lossRunsUpload");
        if (lossRunFiles != null && lossRunFiles.length > 0) {
            this.LossRunList.uploadFiles = [];
            this.initialLossRunList.uploadFiles = [];
            let referenceNumber = this.dao.DOTList.dotQsnr.findQuestion('number').answer;
            await Promise.all(lossRunFiles.map((item) => {
                if (!!item.answer) {
                    this.fileuploadService.getFileDetails(item.answer, referenceNumber).then(fileToUpload => {
                        fileToUpload.progressValue = 100;
                        fileToUpload.size = fileToUpload.fileSize;
                        fileToUpload.filesize = this.fileuploadService.bytesToSize(fileToUpload.fileSize);
                        fileToUpload.guid = fileToUpload.id;
                        if (!this.LossRunList.uploadFiles.some(x => x.guid == fileToUpload.id))
                            this.LossRunList.uploadFiles.push(fileToUpload);
                        this.initialLossRunList.uploadFiles.push(fileToUpload);
                    });
                }
            }));
        }
    }

    private calculateMinMaxLossInsuredDate(apiResponse: ResponseContainer): void {
        if (!!apiResponse?.accountInfoQsnr) {
            let policyEffectiveDate: QuestionAnswer = apiResponse.accountInfoQsnr.findQuestion("policyEffectiveDate");
            if (policyEffectiveDate != null) {
                let effectiveDate: Date = this.serviceLayer.getCorrectDate(policyEffectiveDate.answer);
                this.liabLossMaxDate = effectiveDate;
                this.liabLossMinDate = new Date(effectiveDate);
                this.liabLossMinDate.setFullYear(effectiveDate.getFullYear() - 4);
                this.liabLossDateRange = this.liabLossMinDate.getFullYear() + ':' + this.liabLossMaxDate.getFullYear();
            }
        }
    }
}
class LossHistoryDetails {
    public autoLiabilityGridDetail: any[] = [];
    public compGridDetail: any[] = [];
    public collisionGridDetail: any[] = [];
    public genLiabilityGridDetail: any[] = [];
    public questionaryDetail: any;
    public fileQuestionaryDetail: any;
    public fileUploadArray: any[] = [];
    public tempCompGridDetail: any[] = [];
    public tempGenLiabilityGridDetail: any[] = [];
    public tempcollisionGridDetail: any[] = [];
    public uploadFiles: any[] = [];
    public cargoGridDetail: any[] = [];
    public tempCargoGridDetail: any[] = [];
    public liabIsLossIncurred: emptyModal = new emptyModal();
    public liabIndividualLosses: emptyModal = new emptyModal();
    public liabLossIncurredGridDetail: any[] = [];
    public tempLiabLossIncurredGridDetail: any[] = [];
}