import { Validator, FormGroup, AbstractControl, NgModelGroup, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit, forwardRef, Input } from '@angular/core';
import { UserInputService } from '../services/userInput.service';

@Directive({
  selector: '[alphaNumericCharactersOnly]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AlphaNumericCharactersValidatorDirective, multi: true }
  ]
})
export class AlphaNumericCharactersValidatorDirective {

  constructor(private userInputService: UserInputService) { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    let result: boolean = false;
    if (!!c.value)
      result = this.userInputService.valueContainsNonAlphaNumericCharacters(c.value);
    if (result) {
      return { 'alphanumericCharactersOnly': true, 'errmsg': 'Only Alpha Numeric characters allowed' }
    }
    return null;
  }
}