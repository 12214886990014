import { Validator, FormGroup, AbstractControl, NgModelGroup, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit, forwardRef, Input } from '@angular/core';
import { UserInputService } from '../services/userInput.service';

@Directive({
  selector: '[alphaCharactersOnly]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: AlphaCharactersValidatorDirective, multi: true }
  ]
})
export class AlphaCharactersValidatorDirective {

  constructor(private userInputService: UserInputService) { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    let result: boolean = false;
    if (c.value == undefined || c.value == "")
      return null;
    else {
      result = this.userInputService.valueContainsNonAlphaCharacters(c.value);
    }
    if (result) {
      return { 'alphaCharactersOnly': true, 'errmsg': 'Only Alpha characters allowed' }
    }
    return null;
  }
}

