<div class="col-lg-6 question-label">
    <label>{{question.questionLabel}}
        <span *ngIf="question?.required">*</span>
    </label>
</div>
<div class="col-lg-6">
    <p-inputNumber 
    #textboxModel="ngModel"
    [class.invalid]="showError"
    [name]="question?.name"
    [placeholder]="question?.questionHelpText"
    [(ngModel)]="question.answer"
    [required]="question.required"
    [minAmt]="minAmt" [maxAmt]="maxAmt">
    </p-inputNumber>
    <span class="error-message" *ngIf="showError">
        {{validationMessage}}
    </span>
</div>