import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/commonService';
import { WIPSearchModal } from '../new-submission/new-submission.component';
import { workInProgress } from 'src/app/modal/routing.models';
import { IEndorsementWIP } from 'src/app/modal/IEndorsementWIP';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { dropDownOptions } from 'src/app/modal/DAO';
import { Endorsement, EndorsementQuestionGroup } from 'src/app/modal/Endorsements/Endorsement.Model';
import { WorkInProgress } from 'src/app/modal/Endorsements/Work-In-Progress.Model';
import { Router } from '@angular/router';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { AuthenticationService } from '@rss/ng-auth-service';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { takeUntil } from 'rxjs/operators';
import { Settings } from 'src/app/services/settings';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'endorsement-view',
  templateUrl: './endorsement-view.component.html'
})
export class EndorsementViewComponent extends AuthenticatedUserComponent implements OnInit {
  public endorsementList: Array<workInProgress>;
  public endorsementWIPSearchModal: WIPSearchModal = new WIPSearchModal();
  public endorsementStatuses: Array<dropDownOptions> = Constants.EndorsementStatusDropDrown;
  public hasNoWorkInProgressResults: boolean = false;

  constructor(
    private commonService: CommonService,
    public router: Router,
    private endorsementService: EndorsementService,
    protected authenticationService: AuthenticationService,
    private auth0UserService: Auth0UserService,
    public appConfig: AppConfig
  ) { super(authenticationService); }

  ngOnInit(): void {
    if (this.appConfig.auth0Enabled)
      {
        this.auth0UserService.userLoaded$.subscribe(
          auth0User => {
            if(!!auth0User?.accessToken)
            {
              this.getEndorsementList();
            }
          });   
      }
      else
      {
        this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(async () => {
          this.getEndorsementList();
        });
      }
   
  }

  public async searchWIPFilterData(isReset: boolean): Promise<void> {
    if (isReset) {
      this.endorsementWIPSearchModal = new WIPSearchModal();
    }
    await this.getEndorsementList();
    if (!!this.endorsementList && this.endorsementList.length > 0) {
      this.hasNoWorkInProgressResults = false;
    } else {
      this.hasNoWorkInProgressResults = true;
    }
  }

  private async getEndorsementList(): Promise<void> {
    let payload = { "payloadData": this.endorsementWIPSearchModal };

    await this.commonService.post('endorsement', 'postEndorsementWIPFilter', payload).toPromise()
      .then(async (data: IEndorsementWIP[]) => {
        if (!!data) {
          this.endorsementList = data.map((item: IEndorsementWIP) => new WorkInProgress(item));
        }
        else {
          this.endorsementList = [];
        }
      });
  }

  //============ On table row click ============
  public async routeToCorrespondingEndorsementPage(event: workInProgress): Promise<void> {
    let endorsement = Endorsement.instantiateFromWorkInProgress(event);
    endorsement.selectedEndorsementGroups = await this.endorsementService.getEndorsementQuestionGroups(endorsement.endorsementId);
    let route = Constants.EndorsementBaseRoute;
    if (!!endorsement.userIsAt) {
      route += endorsement.userIsAt;
    }
    else {
      route += endorsement.selectedEndorsementGroups[0]?.groupKey;
    }
    this.router.navigateByUrl(route, { state: { endorsement: endorsement } });
  }
}
