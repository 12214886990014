<div class="container dotcls">
    <div class="box_sec mt-4">
        <h3>Endorsement Details</h3>
        <div class="row mt-2 p-3 endorsement-view">
            <form #policySearchForm="ngForm" style="width:100%">
                <div class="col-lg-12 row">
                    <div class="col-lg-6 row">
                        <div class="col-lg-6 font-weight-bold">
                            <label for="policyNumber" id="policyNumber">Input Policy Number:</label>
                            <span style="color: red;"> *</span>
                        </div>
                        <div class="col-lg-6">
                            <p-inputNumber inputId="policyNumber" name="policyNumber" id="policyNumber"
                                [(ngModel)]="endorsement.policyNumber" [useGrouping]="false" maxlength="7"
                                [disabled]="endorsement.isPolicyDetailsAvailable" #policyNo="ngModel" required
                                [minAmt]="7800000" placeholder="Enter the Policy Number" autocomplete="off"
                                [class.validate_error_cls]="policySearchForm.submitted && policyNo.invalid"></p-inputNumber>
                            <span *ngIf="policySearchForm.submitted && policyNo.invalid && !!!validationMessge"
                                style="color: red; white-space: nowrap;">Please input Policy Number and submit
                                again</span>
                            <span *ngIf="!!validationMessge"
                                style="color: red; white-space: nowrap;">{{validationMessge}}</span>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <button *ngIf="!endorsement.isPolicyDetailsAvailable" class="btnsuccess" type="submit"
                            name="btnSubmit" id="btnSubmit" title="Submit" (click)="onSubmit()">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            Submit
                        </button>
                    </div>
                </div>
            </form>
            <form #endorsementdetailsForm="ngForm" style="width:100%">
                <div class="col-lg-12 row p-0 m-0" *ngIf="endorsement.isPolicyDetailsAvailable">
                    <div class="col-lg-12 row mt-3">
                        <div class="col-lg-6 row">
                            <div class="col-lg-6 font-weight-bold">
                                <label for="policyEffectiveDate" id="policyEffectiveDate">Policy Effective
                                    Date:</label>
                                <span style="color: red;"> *</span>
                            </div>
                            <div class="col-lg-6">
                                <p-calendar [(ngModel)]="endorsement.policyEffectiveDate" name="policyEffectiveDate"
                                    [showIcon]="true" id="policyEffectiveDate" disabled
                                    inputId="calendarIcon"></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 row mt-3">
                        <div class="col-lg-6 row">
                            <div class="col-lg-6 font-weight-bold">
                                <label name="endorsementEffectiveDate" id="endorsementEffectiveDate">Endorsement
                                    Effective Date:</label>
                                <span style="color: red;"> *</span>
                            </div>
                            <div class="col-lg-6">
                                <p-calendar type="text" dataType="string" dateFormat="mm/dd/yy" [monthNavigator]="true"
                                    [(ngModel)]="endorsement.endorsementEffectiveDate" id="endorsementEffectiveDate"
                                    name="endorsementEffectiveDate"
                                    [class.validate_error_cls_dp]="endorsementdetailsForm.submitted && endorseEffectiveDate.invalid"
                                    placeholder="MM / DD / YYYY" yearRange="1900:2050" [showIcon]="true"
                                    [yearNavigator]="true" #endorseEffectiveDate="ngModel" inputId="calendarIcon1"
                                    required [minDate]="endorsement.policyEffectiveDate"
                                    [maxDate]="maxEndorsementEffectiveDate"></p-calendar>
                                <span *ngIf="endorsementdetailsForm.submitted && endorseEffectiveDate.invalid"
                                    style="color: red; white-space: nowrap;">Endorsement effective
                                    date is required to proceed further</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 row mt-3">
                        <div class="col-lg-12 row" >
                            <div class="col-lg-6 font-weight-bold">
                                <label name="proceedAsLbl" id="proceedAsLbl">How would you like the requested endorsement processed?:</label>
                                <span style="color: red;"> *</span>
                            </div>
                            <div> 
                                <div class="row p-field-radiobutto">
                                    <div class="field-radiobutton ml-2">
                                        <p-radioButton name="proceedAs" value="Endorsement"
                                        [(ngModel)]="endorsement.proceedAs" class="radio_button_mt8" inputId="proceedAsEndorsement" #proceedAs="ngModel" required>
                                        </p-radioButton>
                                    </div>
                                    <label class="radio_button_lbl" name="lblproceedAsEndorsement"
                                    id="lblproceedAsEndorsement">Endorsement</label>
                                    <div class="field-radiobutton ml-2">
                                        <p-radioButton name="proceedAs" value="Quote"
                                        [(ngModel)]="endorsement.proceedAs" class="radio_button_mt8" inputId="proceedAsQuote"  #proceedAs="ngModel" required>
                                        </p-radioButton>                                            
                                    </div>
                                    <label class="radio_button_lbl" name="lblproceedAsQuote"
                                    id="lblproceedAsQuote">Quote</label>
                                </div>
                                <span *ngIf="endorsementdetailsForm.submitted && !endorsementdetailsForm.controls['proceedAs']?.valid"
                                      style="color: red;">Please confirm how you would like to process this endorsement request</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 row  mt-3">
                        <div class="col-lg-12 row">
                            <div class="col-lg-3 font-weight-bold">
                                <label for="selectEndorsementType" id="selectEndorsementType">Select Endorsement
                                    Type:</label>
                                <span style="color: red;"> *</span>
                            </div>
                            <div class="col-lg-9">
                                <div class="checkbox-container">
                                    <span *ngFor="let item of endorsementTypes">
                                        <p-checkbox name="endorsementType"
                                            [(ngModel)]="endorsement.selectedEndorsementGroupKeys" [label]="item.label"
                                            [value]="item.value" #endorsementTypes="ngModel" required
                                            [inputId]="selectEndorsementType" id="endorsementType">
                                        </p-checkbox>
                                    </span>
                                </div>
                                 <span
                                    *ngIf="endorsementdetailsForm.submitted && !endorsementdetailsForm.controls['endorsementType']?.valid"
                                    style="color: red;">
                                    Please select at-least one endorsement type to proceed further
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col text-left">
                                <button class="btnsuccess mr-2" title="Reset" name="Reset" id="Reset"
                                    data-toggle="modal" type="reset" (click)="onReset()">
                                    <i class="fa fa-refresh" aria-hidden="true"></i> Reset
                                </button>
                            </div>
                            <div class="col text-right">
                                <button class="btnsuccess mr-2" title="Continue" name="Continue" id="Continue"
                                    data-toggle="modal" (click)="onContinue(endorsementdetailsForm)" type="submit">
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i> Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
