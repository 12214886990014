import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { QuestionAnswer, Questionnaire, ResponseContainer } from "../../../modal/questionnaire.models";
import { emptyModal } from '../../../modal/DAO';
import { DateService } from 'src/app/services/date.service';
import { LossRecord } from 'src/app/component/loss-history-version-2/liability-and-physical-damage/LiabilityAndPhysicalDamageFormGroup'

export class FourYearLossHistoryFormGroup extends FormGroup {
    public lossHistoryList: Array<LossDetailsRecord> = [];
    public newLossHistory: LossDetailsRecord;
    public lossHistoryFormArray: FormArray;
    public policyEffectiveDate: Date;
    public oldValue: any;

    constructor(readonly fb: FormBuilder = new FormBuilder()) {
        super(fb.group({}).controls);
        this.lossHistoryFormArray = this.fb.array([]);
    }

    public initializeFormControls(apiResponse: ResponseContainer) {
        this.initializeLossHistoryObjects(apiResponse);
        this.setPolicyEffectiveDate(apiResponse);
        this.buildFormControls();
        this.subscribeToValueChanges();
        this.oldValue = this.value;
    }

    private setPolicyEffectiveDate(apiResponse: ResponseContainer): void {
        let policyEffectiveDate: QuestionAnswer = apiResponse.accountInfoQsnr.findQuestion("policyEffectiveDate");
        if (policyEffectiveDate != null) {
            let dateService: DateService = new DateService();
            this.policyEffectiveDate = dateService.getCorrectDate(policyEffectiveDate.answer);
        }
    }

    private initializeLossHistoryObjects(apiResponse: ResponseContainer): void {
        const lossQuestionnaire: Questionnaire = apiResponse.losssHistoryQuestionnaire;
        if (lossQuestionnaire.questionAnswer.length == 0) return;

        this.lossHistoryList = [];
        var valuationDateQuestionAnswers = lossQuestionnaire.findQuestions("valuationDate");
        if(!!valuationDateQuestionAnswers && valuationDateQuestionAnswers.length > 0){
            for(let i = 1; i <= valuationDateQuestionAnswers.length; i++){
                if(i == 1){
                    this.newLossHistory = LossDetailsRecord.initializeFrom(apiResponse, i);
                }else{
                    this.lossHistoryList.push(LossDetailsRecord.initializeFrom(apiResponse, i));
                }
            }
        }
    }

    public SetNumberOfClaimBasedOnPhysicalDamage(liabilityAndPDArray: Array<LossRecord>) {
        let dateService = new DateService();
        let aggregatedLossDate = LossDetailsRecord.aggregatedLossDate(this.lossHistoryList);
        liabilityAndPDArray.forEach(liabilityAndPD => {
            let effectiveDate: Date = dateService.getCorrectDate(liabilityAndPD.liabeffectiveDate.answer);
            let expirationDate: Date = dateService.getCorrectDate(liabilityAndPD.liabexpirationDate.answer);
            liabilityAndPD.liabNumberOfClaims.answer = LossDetailsRecord.getNumberOfClaims(aggregatedLossDate, effectiveDate, expirationDate);
        })
    }
    

    get isValidValuationDate(): boolean {
        let dateService = new DateService();
        return this.lossHistoryList.some(lossRecord => {
            const valuationDate = dateService.getCorrectDate(lossRecord.valuationDate.answer);
            const differenceInMs = Math.abs(this.policyEffectiveDate?.getTime() - valuationDate?.getTime());
            // Convert milliseconds to days
            const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
            return differenceInDays > 100;
        });
    }

    private buildFormControls(): void {
        this.addControl("newLossFormGroup", this.createLossFormGroupWithValues(this.newLossHistory))
        this.lossHistoryList.forEach(x => {
            this.lossHistoryFormArray.push(this.createLossFormGroupWithValues(x));
        })
        this.addControl("historyFormArray", this.lossHistoryFormArray);
    }

    private createLossFormGroupWithValues(fourYearLoss: LossDetailsRecord): FormGroup {
        return this.fb.group({
            dateofLoss: this.createFormControl(fourYearLoss.dateofLoss),
            valuationDate: this.createFormControl(fourYearLoss.valuationDate),
            priorCarrier: this.createFormControl(fourYearLoss.priorCarrier),
            typeOfLoss: this.createFormControl(fourYearLoss.typeOfLoss),
            indemnityReserved: this.createFormControl(fourYearLoss.indemnityReserved),
            indemnityPaid: this.createFormControl(fourYearLoss.indemnityPaid),
            expenseReserved: this.createFormControl(fourYearLoss.expenseReserved),
            expensePaid: this.createFormControl(fourYearLoss.expensePaid),
            recovery: this.createFormControl(fourYearLoss.recovery),
            lossStatus: this.createFormControl(fourYearLoss.lossStatus)
        });
    }

    private createFormControl(controlEmptyModel: emptyModal) {
        return new FormControl(controlEmptyModel.answer !== '' && controlEmptyModel.answer !== null && controlEmptyModel.answer !== undefined ? controlEmptyModel.answer : null, FourYearLossHistoryFormGroup.getRequiredValidator(controlEmptyModel))
    }

    private subscribeToValueChanges(): void {
        let formControls = Object.keys(new LossDetailsRecord());
        let newLossFormGroup = this.get("newLossFormGroup") as FormGroup;
        formControls.forEach(controlName => {
            const control = newLossFormGroup.get(controlName) as FormControl;
            if (!!control) {
                control.valueChanges.subscribe((value: string) => {
                    this.newLossHistory[controlName].answer = value;
                });
            }
        });
    }

    public moveNewLossToArray(): void {
        this.lossHistoryList.push(this.newLossHistory);
        this.lossHistoryFormArray.push(this.createLossFormGroupWithValues(this.newLossHistory));
        this.newLossHistory = LossDetailsRecord.deepCloneFrom(this.newLossHistory);
        this.resetLossFormGroup();
    }

    public setValuesToList(index: number): void {
        let fg = this.lossHistoryFormArray.at(index) as FormGroup;
        if (!!fg) {
            let formControls = Object.keys(new LossDetailsRecord());
            formControls.forEach(controlName => {
                const control = fg.get(controlName) as FormControl;
                if (!!control) {
                    this.lossHistoryList[index][controlName].answer = control.value;
                }
            });
        }
    }

    public resetFormGroupValues(index: number): void {
        let fg = this.lossHistoryFormArray.at(index) as FormGroup;
        let aLoss = this.lossHistoryList[index];
        Object.keys(fg.controls).forEach(controlName => {
            let control = fg.controls[controlName];
            if (!!control) {
                control.setValue(aLoss[controlName].answer);
                control.updateValueAndValidity();
            }
        });
    }

    public resetLossFormGroup(): void {
        this.emptyFormControlsValues(this.get("newLossFormGroup") as FormGroup);
    }

    public deleteLoss(index: number): void {
        this.lossHistoryList.splice(index, 1);
        this.lossHistoryFormArray.removeAt(index);
    }

    private emptyFormControlsValues(fg: FormGroup): void {
        Object.keys(fg.controls).forEach(controlName => {
            fg.controls[controlName].setValue(null);
        });
        fg.markAsPristine();
    }

    static getRequiredValidator(control: emptyModal) {
        if (control.required) {
            return Validators.required;
        }
        return null;
    }

    public toQuestionAnswers(): Array<QuestionAnswer> {
        let questionAnswers: QuestionAnswer[] = [];
        let lossHistoryList = [this.newLossHistory]
        lossHistoryList = lossHistoryList.concat(this.lossHistoryList);
        lossHistoryList.forEach((fourYearLossHistory: LossDetailsRecord, index: number) => {
            Object.keys(fourYearLossHistory).forEach(property => {
                let detail = fourYearLossHistory[property];
                if (detail instanceof emptyModal && !!detail.key) {
                    detail.rowIndex = index + 1;
                    if (property == "totalIncurred")
                        detail.answer = fourYearLossHistory._totalIncurred;
                    questionAnswers.push(detail);
                }
            });
        })
        return questionAnswers;
    }
}

export class LossDetailsRecord {
    public valuationDate: emptyModal = new emptyModal();
    public priorCarrier: emptyModal = new emptyModal();
    public dateofLoss: emptyModal = new emptyModal();
    public typeOfLoss: emptyModal = new emptyModal();
    public indemnityReserved: emptyModal = new emptyModal();
    public indemnityPaid: emptyModal = new emptyModal();
    public expenseReserved: emptyModal = new emptyModal();
    public expensePaid: emptyModal = new emptyModal();
    public recovery: emptyModal = new emptyModal();
    public lossStatus: emptyModal = new emptyModal();
    public totalIncurred: emptyModal = new emptyModal();
    public isEdit = false;

    get _totalIncurred(): number {
        return (
            (Number(this.indemnityReserved.answer || 0)) +
            (Number(this.indemnityPaid.answer || 0)) +
            (Number(this.expenseReserved.answer || 0)) +
            (Number(this.expensePaid.answer || 0)) -
            (Number(this.recovery.answer || 0))
        );
    }

    static initializeFrom(apiResponse: ResponseContainer, rowIndex: number): LossDetailsRecord {
        const lossQuestionnaire: Questionnaire = apiResponse.losssHistoryQuestionnaire;
        let fourYearLossDetails: LossDetailsRecord = new LossDetailsRecord();

        let ds = new DateService();
        if (!!!lossQuestionnaire.questionAnswer || lossQuestionnaire.questionAnswer.length === 0) {
            return fourYearLossDetails;
        }

        Object.keys(fourYearLossDetails).forEach(property => {
            if (fourYearLossDetails[property] instanceof emptyModal) {
                let lossquestionAnswers: QuestionAnswer[] = lossQuestionnaire.questionAnswer.filter(x => x.key == property && x.rowIndex == rowIndex);
                if (lossquestionAnswers?.length > 0) {
                    let questionAnswer = emptyModal.initializeFrom(lossquestionAnswers[0]);
                    if(!!questionAnswer.key && (questionAnswer.key == 'valuationDate' || questionAnswer.key == 'dateofLoss') && !!questionAnswer.answer){
                        questionAnswer.answer = ds.getDateTimeString(ds.getCorrectDate(questionAnswer.answer));
                    }
                    if (questionAnswer.answer == undefined || questionAnswer.answer == null) questionAnswer.answer = '';
                    fourYearLossDetails[property] = questionAnswer;
                }
            }
        });
        return fourYearLossDetails;
    }

    static deepCloneFrom(fourYearLossDetail: LossDetailsRecord) {
        let tempFourYearLossDetail: LossDetailsRecord = new LossDetailsRecord();
        Object.keys(fourYearLossDetail).forEach(property => {
            if (fourYearLossDetail[property] instanceof emptyModal) {
                let tempEmptyModel = emptyModal.initializeFrom(fourYearLossDetail[property]);
                tempEmptyModel.answer = "";
                tempFourYearLossDetail[property] = tempEmptyModel;
            }
        });
        return tempFourYearLossDetail;
    }

    static getNumberOfClaims(losses: Array<Date>, effectiveDate: Date, expirationDate: Date): number {
        // Filter losses within the effectiveDate and expirationDate range
        const filteredLosses = losses.filter(lossDate =>
            lossDate >= effectiveDate && lossDate <= expirationDate
        );
        // Return the count of filtered losses
        return filteredLosses.length;
    }
    
    static aggregatedLossDate(lossHistoryArray: Array<LossDetailsRecord>): Array<Date> {
        const dateService = new DateService();
        const eligibleLossDates: Array<Date> = [];
    
        // Extract distinct loss dates
        const distinctLossDates: Array<Date> = lossHistoryArray.map(item =>dateService.getCorrectDate(item.dateofLoss.answer)).filter((date, i, self) => 
            self.findIndex(d => d.getTime() === date.getTime()) === i
        )
        // Iterate through distinct loss dates
        distinctLossDates.forEach(lossDate => {
            // Filter loss history records for the current loss date and check eligibility
            const filteredLossHistoryRecords = lossHistoryArray.filter(x =>
                dateService.getCorrectDate(x.dateofLoss.answer).getTime() === lossDate.getTime() &&
                this.isTypeEligible(x.typeOfLoss.answer)
            );
    
            // If no eligible records found, move to the next loss date
            if (filteredLossHistoryRecords.length === 0) return;
    
            const sumOfTotalIncurred = filteredLossHistoryRecords.reduce((accumulator, currentValue) =>
                accumulator + currentValue._totalIncurred, 0
            );
            const sumOfIndemnityReserved = filteredLossHistoryRecords.reduce((accumulator, currentValue) =>
                accumulator + currentValue.indemnityReserved.answer, 0
            );
            const sumOfIndemnityPaid = filteredLossHistoryRecords.reduce((accumulator, currentValue) =>
                accumulator + currentValue.indemnityPaid.answer, 0
            );
    
            if (sumOfTotalIncurred >= 1500 && (sumOfIndemnityReserved + sumOfIndemnityPaid > 0)) {
                eligibleLossDates.push(lossDate); // Add eligible loss date
            }
        });
        return eligibleLossDates;
    }
    
    static isTypeEligible(typeOfLoss: string): boolean {
        return typeOfLoss === 'Liability-BI' || typeOfLoss === 'Liability-PD';
    }

    get hasTouched(): boolean {
        return Object.values(this).some(property => {
            if(property instanceof emptyModal && property.key != "totalIncurred"){
                return property?.answer !== undefined && property?.answer !== null && property?.answer !== '';
            }
            else{
                return false;
            }
        })
    }
}