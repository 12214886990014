import { Component, OnInit ,Input} from '@angular/core';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
@Component({
  selector: 'app-trailer-interchange',
  templateUrl: './trailer-interchange.component.html'
})
export class TrailerInterchangeComponent implements OnInit {
  @Input() questionnaire: Questionnaire;
  @Input() isFormSubmitted: boolean;
  @Input() accountState: string = '';
  public trailerDamageLimit: QuestionAnswer;
  public trailerCompDeductible: QuestionAnswer;
  public trailerCollDeductible: QuestionAnswer;
  public trailerRadius: QuestionAnswer;
  public trailerZoneFrom: QuestionAnswer;
  public trailerZoneTo: QuestionAnswer;
  public nonOwnedTrailerPerDay: QuestionAnswer;
  public nonOwnedTrailerPerYear: QuestionAnswer;
  public trailerShipments: QuestionAnswer;
  isTrailerShipmentDisabled : boolean =  false;
  constructor() { }

  ngOnInit(): void {
    this.loadQuestionAnswers();
  }

  private loadQuestionAnswers(): void {
    this.trailerDamageLimit = this.SetDefaultAnswer('trailerDamageLimit');
    this.trailerCompDeductible = this.SetDefaultAnswer('trailerCompDeductible');
    this.trailerCollDeductible = this.SetDefaultAnswer('trailerCollDeductible');
    this.trailerRadius = this.SetDefaultAnswer('trailerRadius');
    this.trailerZoneFrom = this.SetDefaultAnswer('trailerZoneFrom');
    this.trailerZoneTo = this.SetDefaultAnswer('trailerZoneTo');
    this.nonOwnedTrailerPerDay = this.SetDefaultAnswer('nonOwnedTrailerPerDay');
    this.nonOwnedTrailerPerYear = this.SetDefaultAnswer('nonOwnedTrailerPerYear');
    this.trailerShipments = this.SetDefaultAnswer('trailerShipments');  

    if(!!this.trailerShipments.key && !this.trailerShipments.enabled && !!this.trailerShipments.answer){
      this.isTrailerShipmentDisabled = true;
      this.trailerShipments.enabled =  true;
    }
    
  } 

  public SetDefaultAnswer(key: string): QuestionAnswer {
    var defaultQuestionAnswer = this.intializeNewQuestionAnswer();
    let questionAnswer = this.questionnaire?.questionAnswer?.find(x => x.key === key) ?? defaultQuestionAnswer;
    if (!!!questionAnswer?.answer && questionAnswer?.questionDefault?.length > 0) {
        questionAnswer.answer = questionAnswer.questionDefault[0].code;
    }
    return questionAnswer;
  }

  private intializeNewQuestionAnswer(): QuestionAnswer {
    return {
        answer: null,
        enabled: false,
        required: false,
        group: null,
        hidden: false,
        key: null,
        isVehicleUpdatedbyVEH1: false,
        minimum: null,
        maximum : null,
        name: null,
        type: '',
        rowIndex: 0,
        questionOptions: []
    }
  }

}
