import { Component, OnInit } from '@angular/core';
import { DAO } from '../../modal/DAO'
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { takeUntil } from 'rxjs/operators';
import { Auth0UserService } from 'src/app/services/auth0UserService.service';
import { AppConfig } from 'src/app/app.config';

@Component({
    selector: 'endorsementComponent',
    templateUrl: './endorsement.html'
})
export class endorsementComponent extends AuthenticatedUserComponent implements OnInit {

  constructor(public dao: DAO, public appConfig: AppConfig, 
        authenticationService: AuthenticationService) {
        super(authenticationService);
    }

    ngOnInit(): void {
        this.dao.PolicySubTemplate = 'endorsement';
        if (!this.appConfig.auth0Enabled)
        {
            this.onUserDataLoaded$().pipe(takeUntil(this.unsubscriber)).subscribe(() => {
                this.dao.userMail = this.userEmail;
            });
        }
    }
}
