import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { DAO } from 'src/app/modal/DAO';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'uninsured-motorist',
    templateUrl: './uninsured-motorist.component.html',
    styleUrls: ['./uninsured-motorist.component.css']
})
export class UninsuredMotoristComponent implements OnInit {

    @Input() questionnaire: Questionnaire;
    @Input() productForm: NgForm;
    @Input() isFormSubmitted: boolean;
    @Input() umBiLimitQuestionBehaviore: BehaviorSubject<QuestionAnswer>;
    @Input() umPdPrdQuestionBehaviore: BehaviorSubject<QuestionAnswer>;

    public atFaultLiab: QuestionAnswer; //At-Fault Liability Limit
    public umBiLimit: QuestionAnswer; // Uninsured motorist - Bodily Injury
    public umBiDed: QuestionAnswer; // Uninsured motorist - Bodily Injury Deductible
    public umPdLimit: QuestionAnswer; // Uninsured motorist - Property Damage Limit
    public umPdDed: QuestionAnswer; // Uninsured motorist - Property Damage Deductible
    public umPdPrd: QuestionAnswer; // Uninsured motorist - Combined Bodily Injury / Property Damage Limit
    public umPdPrdDed: QuestionAnswer; // Uninsured motorist - Combined Bodily Injury / Property Damage Deductible
    public umStacked: QuestionAnswer; // Uninsured motorist - Stacked or not Stacked
    public umEconomicLoss: QuestionAnswer; // Uninsured motorist - Economic Loss
    public isUmPdPrdDedDisabled: boolean;
    public isUmBiDedDisabled: boolean;
    public accountState: string;

    constructor(private productService: ProductService) { }

    public ngOnInit(): void {
        this.loadQuestionAnswers();
        this.accountState = this.productService.getAccountStateAnswer();
        this.onUmBiLimitChanged();
        this.onUmPdPrdChanged();
    }
    private loadQuestionAnswers(): void {
        this.atFaultLiab = this.LoadAnswerFromDefault('atFaultLiab');
        this.umBiLimit = this.LoadAnswerFromDefault('umBiLimit');
        this.umBiDed = this.LoadAnswerFromDefault('umBiDed');
        this.umPdLimit = this.LoadAnswerFromDefault('umPdLimit');
        this.umPdDed = this.LoadAnswerFromDefault('umPdDed');
        this.umPdPrd = this.LoadAnswerFromDefault('umPdPrd');
        this.umPdPrdDed = this.LoadAnswerFromDefault('umPdPrdDed');
        this.umStacked = this.LoadAnswerFromDefault('umStacked');
        this.umEconomicLoss = this.LoadAnswerFromDefault('umEconomicLoss');
    }

    public LoadAnswerFromDefault(key: string): QuestionAnswer {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
        let questionAnswer = this.questionnaire.questionAnswer.find(x => x.key === key) ?? defaultQuestionAnswer;
        if (!questionAnswer?.answer && questionAnswer?.questionDefault?.length > 0) {
            questionAnswer.answer = questionAnswer.questionDefault[0].code;
        }
        return questionAnswer;
    }

    get showUmPdPrd() {
        return this.umPdPrd.enabled && this.umBiLimit.enabled && (this.umBiLimit.answer == 'CLICK' || this.umBiLimit.answer == '')
    }
    get showUmPdPrdDed() {
        return this.umPdPrdDed.enabled && this.umPdPrd.enabled && this.umPdPrd.answer != 'CLICK' && this.umPdPrd.answer != '' && this.umPdPrdDed.key != null
    }
    get showUmBiLimit() {
        return (!this.umPdPrd.enabled
            || (this.umPdPrd.enabled && (this.umPdPrd.answer == 'CLICK' || this.umPdPrd.answer == '')))
    }
    get showUmBiDed() {
        return this.umBiLimit.enabled && this.umBiLimit.answer != 'CLICK' && this.umBiLimit.answer != '' && this.umBiDed.key != null
    }
    get showUmPdLimit() {
        return ((this.umPdLimit?.enabled && !this.umPdPrd.enabled)
            || (this.umPdPrd.enabled && this.umBiLimit.answer != 'CLICK' && this.umBiLimit.answer != ''));
    }
    get showUmPdDed() {
        return ((this.umPdDed.enabled && !this.umPdPrd.enabled)
            || (this.umPdPrd.enabled && this.umPdDed.enabled && this.umBiLimit.answer != 'CLICK' && this.umBiLimit.answer != ''));
    }
    get isUmPdLimitDisabled() {
        return (this.umPdLimit.answer == 'DECLINE' && this.umBiLimit.answer == 'DECLINE' && this.accountState != 'CO') || this.umPdLimit.questionOptions.length == 1;
    }
    get isUmPdDedDisabled() {
        return this.umPdDed.answer != 'CLICK';
    }
    onUmBiLimitChanged() {
        // setting and disabling value to 'UmPdLimit' and 'UmPdDed' 
        if (this.umBiLimit.answer != 'CLICK' && this.umBiLimit.answer != 'DECLINE' && this.umBiLimit.answer != '' && this.accountState != 'CO') {
            if (this.accountState == 'GA') {
                if (this.umBiLimit.answer == '25,000/50,000') {
                    this.umBiDed.answer = 'None';
                    this.isUmBiDedDisabled = true;
                }
                else {
                    this.umBiDed.answer = this.umBiDed.answer != 'CLICK' && !this.isUmBiDedDisabled ? this.umBiDed.answer : 'CLICK';
                    this.isUmBiDedDisabled = false;
                }
            }
            else if (this.umBiLimit.answer != 'CLICK' && this.showUmPdPrd) {
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed'))
                    this.umPdDed.enabled = true;
                this.umPdLimit.answer = this.umPdLimit.answer != 'CLICK' && !this.isUmPdLimitDisabled ? this.umPdLimit.answer : 'CLICK';
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed'))
                this.umPdDed.answer = this.umPdDed.answer != 'CLICK' ? this.umPdDed.answer : this.umPdDed.questionDefault[0].code;
            }
            else if (this.umBiLimit.answer != 'CLICK' && !this.showUmPdPrd) {
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdLimit'))
                    this.umPdLimit.enabled = true;
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed'))
                    this.umPdDed.enabled = true;
                this.umPdLimit.answer = this.umPdLimit.answer != 'CLICK' && (!this.isUmPdLimitDisabled || this.umPdLimit.questionOptions.length == 1) ? this.umPdLimit.answer : 'CLICK';
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed'))
                this.umPdDed.answer = this.umPdDed.answer != 'CLICK' ? this.umPdDed.answer : this.umPdDed.questionDefault[0].code;
            }
        }
        else if (this.accountState != 'CO') {
            if (this.umBiLimit.answer == 'DECLINE') {
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed'))
                    this.umPdDed.enabled = true;
                this.umPdLimit.answer = 'DECLINE';
                this.umPdDed.answer = 'DECLINE';
            }
            else {
                if (this.accountState != 'OH' && this.accountState != 'UT' && this.accountState != 'RI' && !this.umEconomicLoss?.enabled) {
                    if(this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed'))
                        this.umPdDed.enabled = true;
                }
                else {
                    this.umPdLimit.enabled = false;
                    this.umPdDed.enabled = false;
                }
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdPrdDed') && !!!this.umPdPrdDed.answer)
                    this.umPdPrdDed.answer = this.umPdPrdDed?.questionDefault[0]?.code;
                if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdPrd') && this.accountState != 'NH' ) {
                    this.umPdLimit.answer = 'CLICK';
                    this.umPdDed.answer = 'CLICK';
                }
                else if(this.umPdLimit.questionOptions.some(x => x.code == 'CLICK')) {
                    this.umPdLimit.answer = 'CLICK';
                }
                this.umEconomicLoss.answer = "";
            }
        }
        this.umBiLimitQuestionBehaviore.next(this.umBiLimit);
    }
    onUmPdLimitChanged() {
        if (this.umPdLimit.answer != 'DECLINE') {
            if (this.accountState != 'IL'&& this.questionnaire.questionAnswer.some(x => x.key === 'umPdDed')) {
                this.umPdDed.answer = this.umPdDed?.questionDefault[0]?.code;
            }
            else {
                this.umPdDed.answer = '250 PD';
            }
        }
        else {
            this.umPdDed.answer = this.umPdLimit.answer;
        }
    }
    onUmPdPrdChanged() {
        // setting and disabling value to 'UmPdPrdDed' 
        if (this.umPdPrd.answer != 'CLICK' && this.umPdPrd.answer != 'DECLINE') {
            this.umPdPrdDed.enabled = this.umPdPrdDed.key != null ? true : false;
            if (this.accountState == 'GA') {
                if (this.umPdPrd.answer == '75,000') {
                    this.umPdPrdDed.answer = 'None';
                    this.isUmPdPrdDedDisabled = true;
                }
                else {
                    this.umPdPrdDed.answer = this.umPdPrdDed.answer != 'CLICK' && !this.isUmPdPrdDedDisabled ? this.umPdPrdDed.answer : 'CLICK';
                    this.isUmPdPrdDedDisabled = false;
                }
            }
            else if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdPrdDed')) {
                this.umPdPrdDed.answer = this.umPdPrdDed?.questionDefault[0]?.code;
                this.isUmPdPrdDedDisabled = true;
            }
        }
        else {
            if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdPrdDed') && this.umPdPrd.answer == 'DECLINE') {
                this.umPdPrdDed.answer = 'DECLINE';
                this.isUmPdPrdDedDisabled = true;
            }
            else if (this.questionnaire.questionAnswer.some(x => x.key === 'umPdPrdDed')) {
                this.umPdPrdDed.answer = this.umPdPrdDed?.questionDefault[0]?.code;
                this.isUmPdPrdDedDisabled = false;
            }
        }
        this.umPdPrdQuestionBehaviore.next(this.umPdPrd);
    }
    private getDefaultQuestionAnswer(): QuestionAnswer {
        return {
            answer: null,
            enabled: false,
            required: false,
            group: null,
            hidden: false,
            key: null,
            isVehicleUpdatedbyVEH1: false,
            minimum: null,
            name: null,
            type: '',
            rowIndex: 0,
            questionOptions: []
        }
    }
}
