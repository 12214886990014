import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/services/commonService';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { Router } from '@angular/router';
import { ListViewModel } from 'src/app/modal/Endorsements/ListView.Model';
import { Driver } from '../../modal/Endorsements/Driver.Model';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { Subscription } from 'rxjs';
import { FileUploadFormComponent } from '../file-upload-form/file-upload-form.component';
import _ from 'lodash';

@Component({
  selector: 'delete-driver',
  templateUrl: './delete-driver.component.html'
})
export class DeleteDriverComponent implements OnInit, OnDestroy, IBasePage {
  @ViewChild('deleteDriverForm') deleteDriverForm: NgForm;
  @ViewChild('fileUploadForm') fileUploadForm: FileUploadFormComponent;
  public endorsementQuestionGroup: EndorsementQuestionGroup;
  private readonly toastValidation: string = 'Please fill required fields highlighted in red';
  private menuClickSubscription: Subscription;
  private menuClickContext: MenuClickContext;
  public endorsement: Endorsement = new Endorsement();
  public driverList: ListViewModel<Driver>

  constructor(
    private messageService: MessageService,
    private commonService: CommonService,
    public router: Router,
    private endorsementService: EndorsementService
  ) {
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
  }

  ngOnInit(): void {
    this.initializeData();
    this.menuClickSubscription = this.endorsementService.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
  }

  // Initialize the Data
  public async initializeData(): Promise<void> {
    await this.endorsementService.reloadQuestionGroups(this.endorsement);
    this.endorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.DeleteDriver);
    this.getQuestionAnswers().then(questionAnswers => {
      if (!!questionAnswers && questionAnswers.length > 0) {
        this.driverList = new ListViewModel<Driver>(
          Driver.instantiateArrayFrom(questionAnswers),
          this.beforeAdd,
          null,
          this.afterDelete,
          this.initializeNewItem
        )
        this.endorsementQuestionGroup.questionAnswers = questionAnswers;
      }
    }).catch(error => {
      console.error(error);
    });
  }

  private getQuestionAnswers(): Promise<QuestionAnswer[]> {
    let payload = { "urlData": [this.endorsementQuestionGroup.endorsementId, this.endorsementQuestionGroup.groupKey] };
    return this.commonService.get('endorsement', 'getQuestionAnswers', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }

  public isPageValid(): boolean {
    return this.deleteDriverForm.valid &&
      (!this.driverList.currentItem.uploadedDocuments.required || (this.driverList.currentItem.uploadedDocuments.required && this.fileUploadForm.uploadedDocuments.length > 0));
  }

  private beforeAdd = (): boolean => {
    if (!this.isPageValid()) {
      this.openErrorToast(this.toastValidation);
      return false;
    }
    return true;
  }

  public addItem(): void {
    this.driverList.add();
  }

  private afterDelete = (): void => {
    this.checkForDuplicateDrivers();
  }

  private initializeNewItem = (): Driver => {
    return Driver.createNewInstanceFromQuestionAnswers(this.endorsementQuestionGroup.questionAnswers);
  }

  public clickedItem(index: number): void {
    if (!this.isPageValid()) {
      this.openToast(this.toastValidation);
      return;
    }
    this.driverList.currentPosition = index;
  }

  private openToast(body: string): void {
    document.body.style.pointerEvents = "none";
    this.messageService.add({
      key: 'confirmation', sticky: true, severity: 'warn',
      summary: body
    });
  }

  private openErrorToast(body: string): void {
    this.messageService.add({
      key: 'toast', severity: 'error',
      summary: 'Endorsements - Delete Driver',
      detail: body
    });
  }

  public saveAndContinue(type = ""): void {
    if (this.isPageValid()) {
      this.checkForDuplicateDrivers();
      if (this.driverList.items.some(x => !x["isValid"])) {
        this.openToast('License Number is same for highlighted drivers. Please review the drivers and update with correct data to continue.');
      }
      else {
        this.postQuestionAnswers().then(isSuccess => {
          if (isSuccess) {
            let currrentEndorsementQuestionGroup : EndorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.DeleteDriver);
            currrentEndorsementQuestionGroup.hasAnswers = true;
            currrentEndorsementQuestionGroup.isValidPage = true;
            this.endorsement.userIsAt = '';
            if (type === "finalSubmit") {
                this.endorsementService.updateStatusToSubmitted(this.endorsement.endorsementId);
            }
            else if (type === "draft") {
              this.router.navigate(['/home'], { state: { data: 'endorsement' } });
            }
            // if menuClickContext is current page or not set, get and update next menuClickContext
            if (!this.menuClickContext || this.menuClickContext?.page === EndorsementTypes.DeleteDriver) {
              this.endorsementService.menuClickBehavior.next(this.endorsementService.getNextMenuClickContext(EndorsementTypes.DeleteDriver));
            }
          }
        }).catch(error => {
          console.error(error);
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Driver', detail: error });
        })
      }
    }
    else
      this.openErrorToast(this.toastValidation);
  }

  private postQuestionAnswers(): Promise<boolean> {
    this.bindAnswersToQuestions();
    let payload = { "payloadData": this.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'postQuestionAnswers', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }

  public bindAnswersToQuestions(): void {
    let questionAnswers: QuestionAnswer[] = [];
    this.driverList.items.forEach((driver, i) => {
      questionAnswers.push(...driver.toQuestionAnswers(i + 1))
    });
    questionAnswers.push(...this.fileUploadForm.convertFileDocumentsToQuestionAnswer());
    this.endorsementQuestionGroup.questionAnswers = questionAnswers;
  }

  private checkForDuplicateDrivers(): void {
    this.driverList.items.forEach(driver => {
      driver["isValid"] = true;
    });

    this.driverList.items.forEach((driver, i) => {
      this.driverList.items.slice(i + 1).forEach(otherDriver => {
        if (driver.licensePermitNumber.answer === otherDriver.licensePermitNumber.answer) {
          driver["isValid"] = false;
          otherDriver["isValid"] = false;
        }
      });
    });
  }

  public menuClicked(menuClickContext: MenuClickContext): void {
    this.menuClickContext = menuClickContext;
    if (menuClickContext != null && menuClickContext.page != EndorsementTypes.DeleteDriver) {
      if (!this.deleteDriverForm || !this.fileUploadForm) {
        this.menuClickContext.page = EndorsementTypes.DeleteDriver;
        return;
      }
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = this.deleteDriverForm.touched || !_.isEqual(this.driverList.initialItems, this.driverList.items) || this.fileUploadForm.isFormDataChanged();
      if (this.endorsementService.isMenuClickHeaderPage(menuClickContext)) {
        this.menuClickContext.page = EndorsementTypes.DeleteDriver;
        return;
      }
      if (menuClickContext.currentPageIsValid && menuClickContext.currentPageDataChanged) {
        this.saveAndContinue();
      }
      this.endorsementService.handleUnsavedChangesOrNavigateToUrl(menuClickContext, this.endorsement);
    }
  }

  public onRollback(currentTempage: string): void {
    // Not Needed
  }

  public onBack(page: string): void {
    //There is no back button
  }

  ngOnDestroy(): void {
    if (!!this.menuClickSubscription) {
      this.menuClickSubscription.unsubscribe();
    }
  }
}