import { Injectable } from "@angular/core";
import { DAO } from '../modal/DAO';
import { StateSettings } from '../modal/state.models';
import { Questionnaire } from "../modal/questionnaire.models";
import _ from 'lodash';

@Injectable({
    providedIn: 'any'
})
export class ProductService {

    constructor(private dao: DAO, private state: StateSettings) {

    }

    public getQuestionnaire(): Questionnaire {
        return this.dao.DOTList?.questionnaire?.find(q => q.key === 'product') as Questionnaire;
    }
    public AddNamedIndividual() {
        var nameOfNamedIndividual = { 'Name': "", 'isDeleted': false };
        var tempNamedIndividual = _.cloneDeep(this.dao.AddAnotherNamedIndividual);
        tempNamedIndividual.push(nameOfNamedIndividual);
        this.dao.AddAnotherNamedIndividual = _.cloneDeep(tempNamedIndividual);
    }
    public getValidationMessage(key: string): string {
        let accountState = this.getAccountStateAnswer();
        let validationMessage: string = this.state.getStateSpecificValidationMessage.find(x => x.key == key && x.state == accountState)?.errorMessage;
        if (!validationMessage) {
            validationMessage = this.state.commonValidationMessages.find(x => x.key === key)?.errorMessage;
        }
        return validationMessage;
    }   
    
    public getAccountStateAnswer() {
        return this.dao.DOTList?.questionnaire?.find(x => x.key == 'AccountInfo').questionAnswer?.find(x => x.key == 'accountState').answer;
    }
    public getAccountLegalEntityAnswer() {
        return this.dao.DOTList?.questionnaire?.find(x => x.key == 'AccountInfo').questionAnswer.find(x => x.key == 'legalEntity').answer;
    }
}