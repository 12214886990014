<!-- Hired Auto - yes/no -->
<radiobutton-question
    *ngIf="hiredAutoLiability.enabled"
    #hiredAutoLiabilityRadiobutton
    [question]="hiredAutoLiability"
    [required]="hiredAutoLiability.required"
    (clicked)="hiredAutoLiabilitySelection()"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<div class="pl-3 pr-3" *ngIf="hiredAutoLiability.answer == 'Yes'">
    <!-- Annual Cost of hire -->
    <textbox-question
        *ngIf="annualCostOfHire.enabled"
        #annualCostOfHireName
        [question]="annualCostOfHire"
        [minAmt]="minimumAnnualCostOfHire" 
        [isFormSubmitted]="isFormSubmitted">
    </textbox-question>
    <!-- Hired Auto UM-BI -->
    <dropdown-question
        *ngIf="hiredAutoUmBi?.enabled"
        #hiredAutoUmBiDropdown
        [question]="hiredAutoUmBi"
        [isFormSubmitted]="isFormSubmitted"
        [requiredAmtOrDecline]="hiredAutoUmBi.required"
        [disabled]="hiredAutoUmBi.hidden">
    </dropdown-question>
    <!-- Hired Auto UIM-BI -->
    <dropdown-question
        *ngIf="hiredAutoUimBi.enabled"
        #hiredAutoUimBiDropdown
        [question]="hiredAutoUimBi"
        [isFormSubmitted]="isFormSubmitted"
        [requiredAmtOrDecline]="hiredAutoUimBi.required"
        [disabled]="hiredAutoUimBi.hidden">
    </dropdown-question>
</div>

<!-- Non-Owned Auto Liability - yes/no -->
<radiobutton-question
    *ngIf="nonOwnedAutoLiability.enabled"
    #nonOwnedAutoLiabilityRadiobutton
    [question]="nonOwnedAutoLiability"
    [required]="nonOwnedAutoLiability.required"
    (clicked)="nonOwnedAutoLiabilitySelection()"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<div class="pl-3 pr-3" *ngIf="nonOwnedAutoLiability.answer == 'Yes'">
    <!-- Non-Owned Auto Liability Employees count -->
    <textbox-question
        *ngIf="nonOwnedAutoLiabEmployeesCount.enabled"
        #nonOwnedAutoLiabEmployeesCountName
        [question]="nonOwnedAutoLiabEmployeesCount"
        [minAmt]="nonOwnedAutoLiabEmployeesCount.minimum" 
        [isFormSubmitted]="isFormSubmitted">
    </textbox-question>
</div>