import { Validator, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit, forwardRef, Input } from '@angular/core';
import _ from 'lodash';

@Directive({
  selector: '[minAmt]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MinAmtDirective, multi: true }
  ]
})
export class MinAmtDirective {

  @Input("minAmt") minAmt: string
  constructor() { }

  ngOnInit() {
  }

  validate(c: FormControl) {
    if (this.minAmt == undefined || this.minAmt == "")
      return null;
    let v = c.value ?? "0";
    v = _.cloneDeep (v); // If c is an object, keep original c.value intact
    let newMinVal = this.minAmt;

    if (typeof (v) != "number"){
        if (v instanceof Date)
        {
            v = v.getTime().toString();
            newMinVal = new Date(this.minAmt)?.getTime()?.toString();
        }
        else if ((v.toString().includes("/") || v.toString().includes("-") ) && !!!isNaN(new Date(v.toString()).getTime()))
        {
            v = new Date(v.toString()).getTime();
            newMinVal = new Date(this.minAmt)?.getTime()?.toString();
        }
        else
            v = v.toString().replaceAll(',', '');
    }

    if (Number(v) < Number(newMinVal)) {
      let err = 'Amount must be greater than or equal to your state minimum ' + new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
      }).format(Number(this.minAmt));
      return { 'minAmt': true, 'errmsg': err }
    }
    return null;
  }
}
