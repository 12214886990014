import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm, NgModel, NgModelGroup } from '@angular/forms';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProductService } from 'src/app/services/product.service';


@Component({
    selector: 'textbox-question',
    templateUrl: './textbox-question.component.html',
    styleUrls: ['./textbox-question.component.css'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'row mb-1' },
    viewProviders: [{
        provide: ControlContainer,
        deps: [[Optional, NgModelGroup]],
        useFactory: (ngModelGroup: NgModelGroup) => ngModelGroup,
    },]
})
export class TextboxQuestionComponent implements OnInit {

    @ViewChild('textboxModel', { static: true }) textboxModel: NgModel;

    @Input() question: QuestionAnswer;
    @Input() isFormSubmitted: boolean;
    @Input() minAmt: number;
    @Input() maxAmt: number;
    public validationMessage: string = "";
    constructor(public productService: ProductService) { }

    ngOnInit(): void {
        this.loadValidationMessage();
    }
    
    public get showError(): boolean {
        return (this.isFormSubmitted || !this.textboxModel?.pristine) && this.textboxModel.invalid;
    }
    private loadValidationMessage(): void {
        this.validationMessage = this.productService.getValidationMessage(this.question.key) ?? "";
    }
}
