import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes, PortalStatus } from '../modal/Endorsements/Endorsement.Model';
import { MenuClickContext } from '../modal/routing.models';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Constants } from '../modal/ProjConstants.models';
import { MenuItem } from '../endorsement/menu-icon-bar/menu-icon-bar.component';
import { CommonService } from './commonService';

@Injectable({
  providedIn: 'root'
})
export class EndorsementService {
  public endorsement = new BehaviorSubject<Endorsement>(null);
  public menuClickBehavior = new BehaviorSubject<MenuClickContext>(null);
  public selectedMenuItems: MenuItem[] = [];
  public get endorsement$(): Observable<Endorsement> {
    return this.endorsement.asObservable();
  }
  private skipHeaderPages: string[] = ['Home', 'UserAdmin', 'InternalAdmin'];

  constructor(private router: Router, private messageService: MessageService, private commonService: CommonService) { }

  public requestedModifyEndorsement(endorsement: Endorsement): void {
    this.endorsement.next(endorsement);
  }

  public isMenuClickHeaderPage(menuClickContext: MenuClickContext) {
    return this.skipHeaderPages.includes(menuClickContext?.page);
  }

  public handleUnsavedChangesOrNavigateToUrl(menuClickContext: MenuClickContext, endorsementDetails: Endorsement): void {
    const routeUrl = Constants.EndorsementBaseRoute + menuClickContext.page;
    if (menuClickContext?.currentPageIsValid || !menuClickContext?.currentPageDataChanged) {
      this.router.navigateByUrl(routeUrl, { state: { endorsement: endorsementDetails } });
    } else {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'madeChange', sticky: true, severity: 'warn', summary: "If you go back, any data entered will be lost.  Are you sure you would like to go back?", data: routeUrl });
    }
  }

  /**
   * Get the next menu item from selectedMenuItems based on the endorsement type
   * and returns a new MenuClickContext based on the next element in the array.
   * @param currentEndorsementType the currently displayed endorsement component.
   */
  public getNextMenuClickContext(currentEndorsementType: EndorsementTypes) {
    const currentIndex = this.selectedMenuItems.findIndex((group) => group.code === currentEndorsementType);
    if (currentIndex !== -1 && currentIndex < this.selectedMenuItems.length - 1) {
      const menuClickContext: MenuClickContext = new MenuClickContext();
      menuClickContext.currentPageIsValid = true;
      menuClickContext.page = this.selectedMenuItems[currentIndex + 1].code;
      return menuClickContext;
    }
  }

  public async updateStatusToSubmitted(endorsementId: number): Promise<void> {
    try {
      const result = await this.updateEndorsementStatus(endorsementId);
      //If the status is updated to 'Submitted,' display a pop-up.
      if(result > 0) {
        let details : string = 'Thank you for choosing Berkley Small Business Solutions for your transportation needs. Endorsement request has been submitted successfully. We will contact you once endorsement is processed.';
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'greenSuccessPopupWithOk', sticky: true, severity: 'success', detail: details, data: "Endorsement" });
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  private updateEndorsementStatus(endorsementId: number): Promise<number> {
    let payload = { "urlData": [endorsementId, PortalStatus.Submitted] };
    return this.commonService.put('endorsement', 'updateEndorsementStatus', payload).toPromise()
      .then(result => {
        return result
      })
      .catch(error => Promise.reject(error));
  }

  public getEndorsementQuestionGroups(endorsementId: number): Promise<EndorsementQuestionGroup[]> {
    let payload = { "urlData": [endorsementId] };
    return this.commonService.get('endorsement', 'getEndorsementQuestionGroup', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }

  public async reloadQuestionGroups(anEndorsement: Endorsement) {
    if (anEndorsement.selectedEndorsementGroups.length == 0) {
      anEndorsement.selectedEndorsementGroups = await this.getEndorsementQuestionGroups(anEndorsement.endorsementId);
    }
  }
}
