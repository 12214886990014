<div class="container">
    <div class="main_content mt-2">
        <form [formGroup]="additionalInsuredFrm" [style.width]="'100%'">
            <div class="row mt-0">
                <div class="col-12">
                    <span class="text-lg-right float-lg-right">
                        <cancel-endorsement [endorsementId]="endorsement.endorsementId"></cancel-endorsement>
                    </span>
                </div>
                <div class="col-lg-3 col-md-4 mt-4">
                    <list-view *ngIf="!!additionalInsuredList" header="Additional Insured - " listName="AI" [labelFormat]="'{name.answer}'" class="additionalInsuredList"
                        [itemList]="additionalInsuredList" [maxItemLimit]="30"
                        [blankDataValidationProperties]="['name.answer']" (itemClicked)="clickedItem($event)">
                    </list-view>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <div class="clearfix"></div>
                    <div class="vehicle_details1 mt-4" *ngIf="!!additionalInsuredList?.currentItem">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mb-1">
                                    <h5 class="float-left" style="padding-left: 15px;">Delete Additional Insured</h5>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-5">
                                        <label for="lineOfBusiness" id="lineOfBusiness">Select Line of Business
                                            <span style="color: red;"> *</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-7 pl-0 mt-1">
                                        <div class="checkbox-container">
                                            <span *ngFor="let lob of lineOfBusinessOptions" class="pr-2">
                                                <p-checkbox id="lineOfBusiness_{{lob.label}}"
                                                    name="lineOfBusiness_{{lob.label}}" [label]="lob.label"
                                                    [value]="lob.value"
                                                    [formControl]="additionalInsuredFrm.controls['lineOfBusiness']"
                                                    [class.validate_checkbox]="isSubmitted && additionalInsuredFrm.get('lineOfBusiness').invalid"></p-checkbox>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-5 mt-1">
                                        <label for="name" id="name">Enter Name of Person or Organization
                                            <span style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-7 row" style="display: flex;">
                                        <input type="text" id="name" name="name" formControlName="name"
                                            class="form-control mt-2"
                                            [class.validate_error_cls]="isSubmitted && additionalInsuredFrm.get('name').invalid"
                                            placeholder="Insured Name" autocomplete="off">

                                        <div formGroupName="address">
                                            <input type="text" id="street" name="street" formControlName="street"
                                                class="form-control mt-2"
                                                [class.validate_error_cls]="isSubmitted && additionalInsuredFrm.get('address.street').invalid"
                                                placeholder="Street Address" autocomplete="off" maxlength="40">

                                            <div class="row mb-1 mt-2">
                                                <div class="col-lg-4 col-md-4 col-4">
                                                    <input type="text" id="city" name="city" formControlName="city"
                                                        class="form-control"
                                                        [class.validate_error_cls]="isSubmitted && additionalInsuredFrm.get('address.city').invalid"
                                                        placeholder="City" autocomplete="off">
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-4">
                                                    <p-dropdown id="state" name="state" formControlName="state"
                                                        placeholder="Select State"
                                                        [options]="stateSettings.StateQuestionOptions"
                                                        [class.validateDrodown]="isSubmitted && additionalInsuredFrm.get('address.state').invalid">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-4">
                                                    <div [class.validate_error_cls]="isSubmitted && additionalInsuredFrm.get('address.postalCode').invalid">
                                                        <p-inputMask id="postalCode" name="postalCode"
                                                            formControlName="postalCode" mask="99999" autocomplete="off"
                                                            placeholder="Zip">
                                                        </p-inputMask>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 row mt-3 mb-3 d-block">
                                        <span class="text-lg-right float-lg-right">
                                            <button type="submit" name="addNM" id="addNM" class="innerSubButton"
                                                (click)="addItem()"
                                                title="Add AI for Deletion" data-toggle="modal">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                Enter Additional Insured for Deletion
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-4"*ngIf="additionalInsuredList.currentItem.uploadedDocuments.enabled">
                            <endorsement-file-upload-form #fileUploadForm [questionGroup]="endorsementQuestionGroup"></endorsement-file-upload-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12">
                    <div class="col-mg-6 p-0 mt-3">
                        <span class="text-lg-left float-lg-left" style="margin-left: 250px;">
                            <modify-endorsement-type [endorsement]="endorsement"></modify-endorsement-type>
                        </span>
                    </div>
                    <endorsement-footer 
                        [parentPage]="this"
                        (clickedViewApplication)="bindAnswersToQuestions()">
                    </endorsement-footer>
                </div>
            </div>
        </form>
    </div>
</div>