<div class="container">
    <div class="row" style="margin-top: 25px !important;">
			<div class="col-md-12 text-center">
				<img src="assets/img/unauthorized.png" class="img-fluid">
			</div>
			<div class="col-md-12 text-center">
				<h3 class="No_authorization">You have logged in Auth0</h3>
			</div>
			<div class="col-md-12 text-center mt-3">
				<button  type="submit" (click)="returnToHome()" class="btn btn-success" title="Return Home" label="Return Home">Return Home</button>
			</div>
	</div>
</div>