import { DateService } from "src/app/services/date.service";
import { QuestionAnswer } from "../questionnaire.models";
import { workInProgress } from "../routing.models";

export class Endorsement {
    private _endorsementEffectiveDate: Date;
    private _policyEffectiveDate: Date;
    private _policyExpirationDate: Date;
    policyNumber: number;
    selectedEndorsementGroupKeys: Array<EndorsementTypes> = [];
    selectedEndorsementGroups: Array<EndorsementQuestionGroup> = [];
    isPolicyDetailsAvailable: boolean = false;
    insuredName: string;
    endorsementId: number;
    userIsAt: string;
    proceedAs: EndorsementProceedAs | null;
    isFinalSubmit: boolean = false;

    constructor(plainObject?: object) {
        if (plainObject) {
            this.policyNumber = Number(plainObject['policyNumber']);
            this.selectedEndorsementGroupKeys = plainObject['selectedEndorsementGroupKeys'];
            this.selectedEndorsementGroups = plainObject['selectedEndorsementGroups'];
            this.isPolicyDetailsAvailable = plainObject['isPolicyDetailsAvailable'];
            this.insuredName = plainObject['insuredName'];
            this.endorsementId = Number(plainObject['endorsementId']);
            this.userIsAt = plainObject['userIsAt'];
            this.proceedAs = EndorsementProceedAs[plainObject['proceedAs'] as keyof typeof EndorsementProceedAs];
            this.isFinalSubmit = plainObject['isFinalSubmit'];
            this.endorsementEffectiveDate = new Date(plainObject['_endorsementEffectiveDate']);
            this.policyEffectiveDate = new Date(plainObject['_policyEffectiveDate']);
            this.policyExpirationDate = new Date(plainObject['_policyExpirationDate']);
        }
    }

    get endorsementEffectiveDate(): Date {
        return this._endorsementEffectiveDate;
    }

    set endorsementEffectiveDate(value: Date) {
        let ds: DateService = new DateService();
        this._endorsementEffectiveDate = ds.getCorrectDate(value);
    }

    get policyEffectiveDate(): Date {
        return this._policyEffectiveDate;
    }

    set policyEffectiveDate(value: Date) {
        let ds: DateService = new DateService();
        this._policyEffectiveDate = ds.getCorrectDate(value);
    }

    get policyExpirationDate(): Date {
        return this._policyExpirationDate;
    }

    set policyExpirationDate(value: Date) {
        let ds: DateService = new DateService();
        this._policyExpirationDate = ds.getCorrectDate(value);
    }
    
    public static instantiateFromPolicyDetail(policyDetail: PolicyDetail): Endorsement {
        let endorsement: Endorsement = new Endorsement();
        endorsement.policyNumber = policyDetail.policyNumber;
        endorsement.policyEffectiveDate = policyDetail.effectiveDate;
        endorsement.policyExpirationDate = policyDetail.expirationDate;
        endorsement.insuredName = policyDetail.insuredName;
        return endorsement;
    }

    public static instantiateFromWorkInProgress(workInProgress: workInProgress): Endorsement {
        let endorsement: Endorsement = new Endorsement();
        let ds: DateService = new DateService();
        endorsement.endorsementId = Number(workInProgress.id);
        endorsement.insuredName = workInProgress.nameInsured;
        endorsement.policyNumber = Number(workInProgress.policyNumber);
        endorsement.endorsementEffectiveDate =  ds.getCorrectDate(workInProgress.effectiveDate);
        endorsement.userIsAt = workInProgress.page;
        endorsement.proceedAs = EndorsementProceedAs[workInProgress.proceedAs as keyof typeof EndorsementProceedAs];
        return endorsement;
    }

    public IsEndorsementPolicyEffectivePriorToToday() : boolean {
        let endorsementEffectiveDate = this.endorsementEffectiveDate.getTime() 
        let todayDate = new Date().getTime();
        return endorsementEffectiveDate < todayDate;
    }
}

export class PolicyDetail {
    policyNumber: number;
    effectiveDate: Date;
    expirationDate: Date;
    status: string;
    insuredName: string;
}

export class EndorsementQuestionGroup {
    questionnaireId: number;
    endorsementId: number;
    groupKey: string;
    sequence: number;
    selected: boolean;
    hasAnswers: boolean;
    isValidPage: boolean;
    questionAnswers: QuestionAnswer[] = [];
}

export enum EndorsementTypes {
    AddDriver = 'addDriver',
    DeleteDriver = 'deleteDriver',
    AddVehicle = 'addVehicle',
    DeleteVehicle = 'deleteVehicle',
    AddAdditionalInsured = 'addAdditionalInsured',
    DeleteAdditionalInsured = 'deleteAdditionalInsured',
    otherEndorsements = 'otherEndorsements'
}

export enum PortalStatus {
    Incomplete = 'Incomplete',
    Submitted = 'Submitted',
    Cancelled = 'Cancelled',
    Issued = "Issued",
    Bound = "Bound"
}

export enum EndorsementProceedAs {
    Endorsement = 'Endorsement',
    Quote = 'Quote'
}
export const PredefinedOrder: EndorsementTypes[] = [
    EndorsementTypes.AddDriver,
    EndorsementTypes.DeleteDriver,
    EndorsementTypes.AddVehicle,
    EndorsementTypes.DeleteVehicle,
    EndorsementTypes.AddAdditionalInsured,
    EndorsementTypes.DeleteAdditionalInsured,
    EndorsementTypes.otherEndorsements
];