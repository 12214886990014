import { Validator, NG_VALIDATORS, FormControl, NgForm} from '@angular/forms'
import { Directive, OnInit, forwardRef, Input  } from '@angular/core';

@Directive({
  selector: '[requiredAtleastOnefield]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: RequiredAtleastOnefieldDirective, multi: true }
  ]
})
export class RequiredAtleastOnefieldDirective {

   constructor() { }
  ngOnInit() {
  }
 
  validate(frm: NgForm) {


    if (frm?.value == null)
      return { 'requiredAtleastOnefield': true, 'errmsg':  'Please input at least one search criteria.'}

    let valid = false;
    Object.keys(frm.value).forEach(tempKey => {
      if (frm.value[tempKey] != undefined && frm.value[tempKey] != null && frm.value[tempKey].toString().trim() != "" ) {
        valid = true;
      }
    });
    if (!valid)
      return { 'requiredAtleastOnefield': true, 'errmsg':  'Please input at least one search criteria.'};
    return null
  }

}
