import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';

@Component({
    selector: 'optional-coverage',
    templateUrl: './optional-coverage.component.html',
    styleUrls: ['./optional-coverage.component.css']
})
export class OptionalCoverageComponent implements OnInit {

    @Input() questionnaire: Questionnaire;
    @Input() isFormSubmitted: boolean;
    @Input() umBiLimitQuestionBehaviore: BehaviorSubject<QuestionAnswer>;
    @Input() uimBiQuestionBehaviore: BehaviorSubject<QuestionAnswer>;

    public hiredAutoLiability: QuestionAnswer;
    public annualCostOfHire: QuestionAnswer;
    public hiredAutoUimBi: QuestionAnswer;
    public hiredAutoUmBi: QuestionAnswer;
    public minimumAnnualCostOfHire: number = 5000;
    public uimBi: QuestionAnswer;
    public umBi: QuestionAnswer;
    public nonOwnedAutoLiability: QuestionAnswer;
    public nonOwnedAutoLiabEmployeesCount: QuestionAnswer;

    constructor() { }

    public ngOnInit(): void {
        this.loadQuestionAnswers();
        this.umBiLimitQuestionBehaviore.subscribe(question => {
            if (!!question && this.hiredAutoUmBi.enabled && this.hiredAutoUmBi.hidden) {
                if (this.hasSelection(question)) {
                    this.hiredAutoUmBi.answer = question.answer;
                }
            }
        });
        this.uimBiQuestionBehaviore.subscribe(question => {
            if (!!question && this.hiredAutoUimBi.enabled && this.hiredAutoUimBi.hidden) {
                if (this.hasSelection(question)) {
                    this.hiredAutoUimBi.answer = question.answer;
                }
            }
        });
    }

    private hasSelection(question: QuestionAnswer): boolean {
        return !!question?.answer && question?.answer !== 'CLICK';
    }

    private loadQuestionAnswers(): void {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
        this.hiredAutoLiability = this.LoadAnswerFromDefault('hiredAutoLiability');
        this.annualCostOfHire = this.LoadAnswerFromDefault('annualCostOfHire');
        this.hiredAutoUimBi = this.LoadAnswerFromDefault('hiredAutoUimBi');
        this.hiredAutoUmBi = this.LoadAnswerFromDefault('hiredAutoUmBi');
        this.nonOwnedAutoLiability = this.LoadAnswerFromDefault('nonOwnedAutoLiability');
        this.nonOwnedAutoLiabEmployeesCount = this.LoadAnswerFromDefault('nonOwnedAutoLiabEmployeesCount');
        this.uimBi = this.questionnaire.questionAnswer.find(x => x.key === 'uimBi') ?? defaultQuestionAnswer;
        this.umBi = this.questionnaire.questionAnswer.find(x => x.key === 'umBiLimit') ?? defaultQuestionAnswer;
        if (this.questionnaire.questionAnswer.some(x => x.key === 'hiredAutoUimBi') && this.hiredAutoUimBi.questionOptions.length == 0)
            this.hiredAutoUimBi.questionOptions = this.questionnaire.questionAnswer.find(x => x.key === 'uimBi').questionOptions;
        if (this.questionnaire.questionAnswer.some(x => x.key === 'hiredAutoUmBi') && this.hiredAutoUmBi.questionOptions.length == 0)
            this.hiredAutoUmBi.questionOptions = this.questionnaire.questionAnswer.find(x => x.key === 'umBiLimit').questionOptions;
    }

    public hiredAutoLiabilitySelection(): void {
        if (this.hiredAutoLiability.answer == 'No') {
            this.annualCostOfHire.answer = '';
        }
        else {
            if (this.hiredAutoUimBi.enabled) {
                this.hiredAutoUimBi.answer = this.uimBi.answer;
            }
            if (this.hiredAutoUmBi.enabled) {
                this.hiredAutoUmBi.answer = this.umBi.answer;
            }
        }
    }

    public nonOwnedAutoLiabilitySelection(): void {
        if (this.nonOwnedAutoLiability.answer == 'No') {
            this.nonOwnedAutoLiabEmployeesCount.answer = '';
        }
    }

    public LoadAnswerFromDefault(key: string): QuestionAnswer {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
        let questionAnswer = this.questionnaire.questionAnswer.find(x => x.key === key) ?? defaultQuestionAnswer;
        if (!questionAnswer?.answer && questionAnswer?.questionDefault?.length > 0) {
            questionAnswer.answer = questionAnswer.questionDefault[0].code;
        }
        return questionAnswer;
    }

    private getDefaultQuestionAnswer(): QuestionAnswer {
        return {
            answer: null,
            enabled: false,
            required: false,
            group: null,
            hidden: false,
            key: null,
            isVehicleUpdatedbyVEH1: false,
            minimum: null,
            name: null,
            type: '',
            rowIndex: 0,
            questionOptions: []
        }
    }
}
