import { AbstractControl, NG_VALIDATORS } from '@angular/forms'
import { Directive } from '@angular/core';

@Directive({
  selector: '[retailerSubCodeValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: RetailerSubCodeDirective, multi: true }
  ]
})
export class RetailerSubCodeDirective {
  constructor() { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    let value = c.value;
    if (!!!value)
      return null;
    if (typeof (value) != 'string') {
      value = value.toString();
    }
    if (value.length > 0) {
      for (var i = 0; i < value.length; i++) {
        var charCode = value.charCodeAt(i);
        // Charcodes -> Uppercase Alphabets(65-90), Lowercase Alphabets(97-122), Numerics(48-57)
        // Charcodes -> WhiteSpaces(32), Hyphen(45), Pound(35)
        if (charCode != 35 && charCode != 45 && charCode != 32 && (charCode < 64 || charCode > 91) && (charCode < 96 || charCode > 123) && (charCode < 47 || charCode > 58)) {
          return { 'invalidRetailerSubCode': true }
        }
      }
    }
    return null;
  }
}
