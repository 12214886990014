import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'default-coverage',
    templateUrl: './default-coverage.component.html',
    styleUrls: ['./default-coverage.component.css']
})
export class DefaultCoverageComponent implements OnInit {

    @Input() questionnaire: Questionnaire;
    @Input() isFormSubmitted: boolean;

    public liabilityLimit: QuestionAnswer;
    public mpLimit: QuestionAnswer;
    public medExpenseBenefits: QuestionAnswer;
    public incomeLoss: QuestionAnswer;
    public tortLimitSelect: QuestionAnswer;
    public tortLimits: QuestionAnswer;
    public isTortLimitsDisabled: boolean = false;
    public Q_addedPIP: QuestionAnswer;
    public addedPIP: QuestionAnswer;
    public umReject: QuestionAnswer;

    public validationMessage: string = "";
    public accountLegalEntityAnswer: string;

    constructor(private productService: ProductService) { }

    public ngOnInit(): void {
        this.loadQuestionAnswers();
        this.loadValidationMessage();
        this.accountLegalEntityAnswer = this.productService.getAccountLegalEntityAnswer();
        if (this.tortLimitSelect.enabled) {
            this.tortLimits.enabled = this.tortLimitSelect.answer != '';
            this.mpLimit.enabled = (this.tortLimitSelect.answer == 'No' && this.tortLimits.answer == this.tortLimits.questionOptions[1].value) || this.tortLimitSelect.answer == '' ? true : false;
            this.isTortLimitsDisabled = this.tortLimitSelect.answer == 'Yes';
            this.Q_addedPIP.enabled = this.tortLimits.answer == this.tortLimits.questionOptions[1].value && this.accountLegalEntityAnswer == 'INDIVIDUAL';
            this.addedPIP.enabled = this.tortLimits.answer == this.tortLimits.questionOptions[1].value && this.accountLegalEntityAnswer == 'INDIVIDUAL';
        }
    }
    private loadQuestionAnswers(): void {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();

        this.liabilityLimit = this.LoadAnswerFromDefault('liabilityLimit');
        this.mpLimit = this.LoadAnswerFromDefault('mpLimit');
        this.medExpenseBenefits = this.LoadAnswerFromDefault('medExpenseBenefits');
        this.incomeLoss = this.LoadAnswerFromDefault('incomeLoss');
        this.tortLimitSelect = this.LoadAnswerFromDefault('tortLimitSelect');
        this.tortLimits = this.LoadAnswerFromDefault('tortLimits');
        this.Q_addedPIP = this.LoadAnswerFromDefault('Q_addedPIP');
        this.addedPIP = this.LoadAnswerFromDefault('addedPIP');
        this.umReject = this.LoadAnswerFromDefault('umReject');
    }

    public LoadAnswerFromDefault(key: string): QuestionAnswer {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();
        let questionAnswer = this.questionnaire.questionAnswer.find(x => x.key === key) ?? defaultQuestionAnswer;
        if ((!!!questionAnswer?.answer && questionAnswer?.questionDefault?.length > 0)
            || (!!questionAnswer?.answer && questionAnswer?.questionDefault?.length > 0 && !!!questionAnswer?.questionOptions?.some(x => x.code == questionAnswer?.answer))) {
            questionAnswer.answer = questionAnswer.questionDefault[0].code;
        }
        return questionAnswer;
    }
    private getDefaultQuestionAnswer(): QuestionAnswer {
        return {
            answer: null,
            enabled: false,
            required: false,
            group: null,
            hidden: false,
            key: null,
            isVehicleUpdatedbyVEH1: false,
            minimum: null,
            name: null,
            type: '',
            rowIndex: 0,
            questionOptions: []
        }
    }
    public tortLimitSelection(): void {
        this.tortLimits.enabled = true;
        if (this.tortLimitSelect.answer == 'Yes') {
            this.tortLimits.answer = this.tortLimits.questionOptions[3].value;
            this.isTortLimitsDisabled = true;
            this.Q_addedPIP.answer = "";
            this.Q_addedPIP.enabled = false;
            this.addedPIP.answer = 'CLICK';
            this.addedPIP.enabled = false;
        }
        else {
            this.tortLimits.answer = this.tortLimits.questionOptions[0].value;
            this.isTortLimitsDisabled = false;
        }
        // to show/hide medical payment
        if (this.tortLimitSelect.answer == 'No' && this.tortLimits.answer == this.tortLimits.questionOptions[1].value) {
            this.mpLimit.enabled = true;
        }
        else if ((this.tortLimitSelect.answer == 'No' && this.tortLimits.answer == this.tortLimits.questionOptions[2].value)
            || (this.tortLimitSelect.answer == 'No' && this.tortLimits.answer == this.tortLimits.questionOptions[3].value)
            || (this.tortLimitSelect.answer == 'Yes')) {
            this.mpLimit.enabled = false;
            this.mpLimit.answer = 'CLICK';
        }
    }

    public invalidTortLimits(): void {
        this.mpLimit.enabled = false;
        this.Q_addedPIP.enabled = false;
        this.Q_addedPIP.answer = "";
        this.addedPIP.answer = 'CLICK';
        this.addedPIP.enabled = false;
        // to show/hide medical payment
        if (this.tortLimitSelect.answer == 'No' && this.tortLimits.answer == this.tortLimits.questionOptions[1].value) {
            this.mpLimit.enabled = true;
        }
        else {
            this.mpLimit.answer = 'CLICK';
        }
        // to show/hide added PIP
        if (this.tortLimits.answer == this.tortLimits.questionOptions[1].value && this.accountLegalEntityAnswer == 'INDIVIDUAL') {
            this.Q_addedPIP.enabled = true;
            this.addedPIP.enabled = true;
        }
    }
    public get tortLimitValidation() {
        return this.tortLimitSelect.answer == 'No' && this.tortLimits.answer == 'C-Insured and all op accepted';
    }
    private loadValidationMessage(): void {
        this.validationMessage = this.productService.getValidationMessage(this.tortLimits.key) ?? "";
    }
    public resetUMCoverage(): void {
        var umbi = this.questionnaire.questionAnswer.find(x => x.key === 'umBiLimit');
        var umStacked = this.questionnaire.questionAnswer.find(x => x.key === 'umStacked');
        if (umbi && umStacked) {
            umbi.answer = umbi?.questionDefault?.length > 0 ? umbi.questionDefault[0]?.code : "";
            umStacked.answer = "";
        }
    }
}
