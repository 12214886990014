<p-card>
   <form #searchForm="ngForm" name="searchForm" id="searchForm" style="width:100%" requiredAtleastOnefield>
      <div class="row m-1">
         <div class="row col-lg-12 mt-2">
            <div class="col-lg-6">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold">
                     <label name="NamedInsured" id="NamedInsured">Named Insured:</label>
                  </div>
                  <div class="col-lg-6">
                     <input [(ngModel)]="workInProgressSearchModal.namedInsured" type="text" class="form-control"
                        placeholder="Named Insured" name="nameInsured" id="NamedInsured" #nameInsured="ngModel"
                        [maxlength]="100">
                  </div>
               </div>
            </div>
            <div *ngIf="!isEndorsementView" class="col-lg-6">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold">
                     <label name="searchdotlabel" id="searchdotlabel">DOT#:</label>
                  </div>
                  <div class="col-lg-6">
                     <input [(ngModel)]="workInProgressSearchModal.dot" type="text" class="form-control"
                        placeholder="DOT#" name="searchdot" id="searchdot" #searchdot="ngModel"
                        numbersOnly (keypress)="numbersOnly($event)" maxlength="8">
                        <div class="row mt-1" *ngIf="searchdot.invalid && searchdot.dirty">
                           <div class="col">
                             <span class="validationred">Only numbers are allowed.</span>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
            <div *ngIf="isEndorsementView" class="col-lg-6">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold">
                     <label name="searchPolicylabel" id="searchPolicylabel">Policy#:</label>
                  </div>
                  <div class="col-lg-6">
                     <input [(ngModel)]="workInProgressSearchModal.policyNumber" type="text" class="form-control"
                        placeholder="Policy Number" name="searchPolicy" id="searchPolicy" #searchdot="ngModel"
                        (keypress)="numbersOnly($event)" maxlength="7">
                  </div>
               </div>
            </div>
            <div *ngIf="!isEndorsementView" class="col-lg-6 mt-3">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold">
                     <label name="BegEffectiveDateLabel" id="BegEffectiveDateLabel">Beginning Effective Date:</label>
                  </div>
                  <div class="col-lg-6">
                     <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" type="text" inputId="calendarIcon1"
                        id="BegEffectiveDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"
                        placeholder="MM / DD / YYYY" [(ngModel)]="workInProgressSearchModal.beginningEffectiveDate"
                        name="BegEffectiveDate" #BegEffectiveDateID="ngModel"
                        [maxDate]="workInProgressSearchModal.endingEffectiveDate"></p-calendar>
                  </div>
               </div>
            </div>
            <div *ngIf="isEndorsementView" class="col-lg-6 mt-3">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold" [class.pr-0]="isEndorsementView">
                     <label name="EndorseEffectiveDateLabel" id="EndorseEffectiveDateLabel">Endorsement Effective
                        Date:</label>
                  </div>
                  <div class="col-lg-6">
                     <p-calendar dataType="string" dateFormat="mm/dd/yy" [showIcon]="true" type="text"
                        inputId="endorsementCalendarIcon" id="EndorseEffectiveDate" [monthNavigator]="true"
                        [yearNavigator]="true" yearRange="1900:2050" placeholder="MM / DD / YYYY"
                        [(ngModel)]="workInProgressSearchModal.endorsementEffectiveDate" name="EndorseEffectiveDate"
                        #EndorseEffectiveDateID="ngModel"></p-calendar>
                  </div>
               </div>
            </div>
            <div *ngIf="!isEndorsementView" class="col-lg-6 mt-3">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold">
                     <label name="endingEffectiveDateLabel" id="endingEffectiveDateLabel">Ending Effective
                        Date:</label>
                  </div>
                  <div class="col-lg-6">
                     <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon2"
                        id="endingEffectiveDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"
                        placeholder="MM / DD / YYYY" [(ngModel)]="workInProgressSearchModal.endingEffectiveDate"
                        name="endingEffectiveDate" #endingEffectiveDate="ngModel"
                        [minDate]="workInProgressSearchModal.beginningEffectiveDate"></p-calendar>
                  </div>
               </div>
            </div>
            <div class="col-lg-6 mt-3">
               <div class="row">
                  <div class="col-lg-6 font-weight-bold">
                     <label name="Status" id="Status">Status:</label>
                  </div>
                  <div class="col-lg-6">
                     <p-dropdown [options]="workInProgressStatusDropdown" [(ngModel)]="workInProgressSearchModal.status"
                        name="Status" id="Status" #Status="ngModel" placeholder="Status"></p-dropdown>
                  </div>
               </div>
            </div>
            <div class="col-lg-6 mt-3 text-right" [class.col-lg-12]="isEndorsementView">
               <button class="btnsuccess mr-2" title="Search" name="Search" id="search" data-toggle="modal"
                  (click)="getWIPFilter(searchForm)" type="submit">
                  <i class="fa fa-search" aria-hidden="true"></i> Search
               </button>
               <button class="btnsuccess" title="Reset" name="reset" id="reset" data-toggle="modal"
                  (click)="resetWIPFilter(searchForm)" type="submit">
                  <i class="fa fa-refresh" aria-hidden="true"></i> Reset
               </button>
            </div>
         </div>
      </div>
      <div class="row float-right" *ngIf="isSubmitted && searchForm.errors?.requiredAtleastOnefield">
         <div class="row mr-2">
            <span class="validationred">At-least one search criteria is required</span>
         </div>
      </div>
      <div class="row float-right" *ngIf="isSubmitted && hasNoWorkInProgressResults && !isFormChanged">
         <div class="row mr-2">
            <span class="color_maroon">No record found matching the search criteria</span>
         </div>
      </div>
   </form>
</p-card>
<div *ngIf="!!workInProgressData && workInProgressData.length > 0">
   <div class="table-responsive mt-3">
      <p-table class="table llh_table p-datatable-striped" [class.endorsement]="isEndorsementView"
         [class.agencyView]="isAgencyView" styleClass="p-datatable-striped" [rows]="10" [value]="workInProgressData"
         [paginator]="true">
         <ng-template pTemplate="header">
            <tr>
               <th pSortableColumn="nameInsured" [pSortableColumnDisabled]="!isEndorsementView" class="namedInsured">
                  Named Insured</th>
               <th *ngIf="!isEndorsementView" style="width: 10%;">DOT#</th>
               <th pSortableColumn="policyNumber" [pSortableColumnDisabled]="!isEndorsementView"
                  *ngIf="isEndorsementView" style="width: 12%;">Policy#</th>
               <th pSortableColumn="effectiveDate" [pSortableColumnDisabled]="!isEndorsementView"
                  class="effectiveDate text-center">{{isEndorsementView ? 'Endorsement Effective Date' : 'Effective
                  Date'}}</th>
               <th pSortableColumn="status" [pSortableColumnDisabled]="!isEndorsementView" class="status">Status</th>
               <th pSortableColumn="agentName" class="agentName" [pSortableColumnDisabled]="!isEndorsementView"
                  *ngIf="isAgencyView || isEndorsementView">Agent Name</th>
               <th pSortableColumn="submissionTimeStamp" [pSortableColumnDisabled]="!isEndorsementView"
                  class="submittedOn">{{isEndorsementView ? 'Submitted On (EST)' : 'Submission Timestamp (EST)'}}</th>
            </tr>
         </ng-template>
         <ng-template let-item let-i=rowIndex pTemplate="body">
            <tr [pSelectableRow]="item">
               <td title="{{item?.nameInsured}}">{{item?.nameInsured}}</td>
               <td *ngIf="!isEndorsementView" [class.wiplink]="item?.status == 'Incomplete'" name="grdDot" id="grdDot"
                  title="{{item?.dotNumber}}">
                  <span *ngIf="!isAgencyView" (click)="onRowSelect(item)">{{item?.dotNumber}}</span>
                  <span *ngIf="isAgencyView">{{item?.dotNumber}}</span>
               </td>
               <td *ngIf="isEndorsementView"  [class.wiplink]="item.status == 'Incomplete'" title="{{item?.policyNumber}}">
                  <span (click)="clickedInProgressEndorsement(item)">{{item?.policyNumber}}</span></td>
               <td class="text-center" title="{{item?.effectiveDate}}">{{item?.effectiveDate}}</td>
               <td title="{{item?.status}}">{{item?.status}}</td>
               <td *ngIf="isAgencyView || isEndorsementView" title="{{item?.agentName}}">{{item?.agentName}}</td>
               <td title="{{item?.submissionTimeStamp}}">{{item?.submissionTimeStamp}}</td>
            </tr>
         </ng-template>
      </p-table>
   </div>
</div>