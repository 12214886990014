import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { dropDownOptions } from 'src/app/modal/DAO';
import { Endorsement, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { CommonService } from 'src/app/services/commonService';
import { EndorsementService } from 'src/app/services/endorsement.service';

@Component({
  selector: 'modify-endorsement-type',
  templateUrl: './modify-endorsement-type.component.html',
  styleUrls: ['./modify-endorsement-type.component.css']
})

export class ModifyEndorsementTypeComponent implements OnInit {

  @Input() endorsement: Endorsement;
  @Output() modifiedEndorsementType = new EventEmitter();

  public endorsementTypes: Array<dropDownOptions> = Constants.EndorsementTypes;
  public showModifyEndorsementDialog: boolean = false;
  public selectedGroupKeys: Array<EndorsementTypes> = [];
  private isBrowserBackButtonClicked: boolean = false;

  constructor(
    public messageService: MessageService,
    private commonService: CommonService,
    public router: Router,
    private endorsementService: EndorsementService
  ) {
    this.isBrowserBackButtonClicked = this.router.getCurrentNavigation()?.trigger == "popstate";
   }

  ngOnInit(): void {
    if (this.isBrowserBackButtonClicked) {
      this.endorsementService
        .getEndorsementQuestionGroups(this.endorsement.endorsementId)
        .then((data) => {
          this.selectedGroupKeys = data
            .filter((x) => x.selected)
            .map((x) => x.groupKey as EndorsementTypes);
        });
    }
    else {
      this.selectedGroupKeys = this.endorsement.selectedEndorsementGroups
        .filter(x => x.selected)
        .map(x => x.groupKey as EndorsementTypes);
    }
  }

  public onContinue(): void {
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'warningWithYesNo', sticky: true, severity: 'warn', summary: 'Do you want to proceed with this change?' });
  }

  public async updateEndorsementType(): Promise<number> {
    if (this.selectedGroupKeys == this.endorsement.selectedEndorsementGroupKeys) {
      document.body.style.pointerEvents = "visible";
      this.messageService.clear();
      this.showModifyEndorsementDialog = false;
      return;
    }
    let endorsementPayload = {
      QuestionGroupKeys: this.selectedGroupKeys,
      Id: this.endorsement.endorsementId
    };
    this.endorsement.selectedEndorsementGroups.forEach(x => {
      x.selected = this.selectedGroupKeys.includes(x.groupKey as EndorsementTypes);
    });
    this.endorsement.selectedEndorsementGroupKeys = this.selectedGroupKeys;
    let payload = { "payloadData": endorsementPayload }
    try {
      const result = await this.commonService.post('endorsement', 'upsertEndorsementDetail', payload).toPromise();
      return result;
    } catch (error) {
      return await Promise.reject(error);
    }
  }

  public updateEndorsementGroupKeys(): void {
    this.updateEndorsementType().then(result => {
      if (result == 0) {
        this.endorsementService.requestedModifyEndorsement(this.endorsement);
        document.body.style.pointerEvents = "visible";
        this.messageService.clear();
        this.showModifyEndorsementDialog = false;
      }
    }).catch(error => {
      console.error(error);
    })
  }

  public clearToast(): void {
    document.body.style.pointerEvents = "visible";
    this.messageService.clear();
  }

  public closeModifyEndorsementDialog(): void {
    this.showModifyEndorsementDialog = false;
    this.selectedGroupKeys = this.endorsement.selectedEndorsementGroups
      .filter(x => x.selected)
      .map(x => x.groupKey as EndorsementTypes);
  }
}
