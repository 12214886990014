import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { AdditionalInsured } from './AdditionalInsured.Model';
import { emptyModal } from '../DAO';
import { from } from 'rxjs';

export class AdditionalInsuredFormGroup extends FormGroup {
    readonly addInsuredInterestType = this.get('addInsuredInterestType') as FormControl;
    readonly addInsuredLoanNumber = this.get('addInsuredLoanNumber') as FormControl;
    readonly addInsuredName = this.get('addInsuredName') as FormControl;
    readonly lineOfBusiness = this.get('lineOfBusiness') as FormControl;
    readonly name = this.get('name') as FormControl;
    readonly street = this.get('street') as FormControl;
    readonly city = this.get('city') as FormControl;
    readonly postalCode = this.get('postalCode') as FormControl;
    readonly state = this.get('state') as FormControl;

    constructor(readonly addInsured: AdditionalInsured, readonly formBuilder: FormBuilder = new FormBuilder()) {
        super({
            addInsuredInterestType: new FormControl(addInsured.addInsuredInterestType?.answer, AdditionalInsuredFormGroup.getValidator(addInsured.addInsuredInterestType)),
            addInsuredLoanNumber: new FormControl(addInsured.addInsuredLoanNumber?.answer),
            addInsuredName: new FormControl(addInsured.addInsuredName?.answer, AdditionalInsuredFormGroup.getValidator(addInsured.addInsuredName)),
            lineOfBusiness: new FormControl(addInsured.lineOfBusiness, AdditionalInsuredFormGroup.getValidator(addInsured.name)),
            name: new FormControl(addInsured.name?.answer, AdditionalInsuredFormGroup.getValidator(addInsured.name)),
            street: new FormControl(addInsured.address.street, Validators.required),
            city: new FormControl(addInsured.address.city, Validators.required),
            postalCode: new FormControl(addInsured.address.postalCode, Validators.required),
            state: new FormControl(addInsured.address.state, Validators.required),
        });

        this.subscribeToValueChanges();
    }

    static getValidator(control: emptyModal) {
        if (control.required) {
            return Validators.required;
        }
        return null;
    }

    private subscribeToValueChanges() {

        this.addInsuredInterestType.valueChanges.subscribe((value: string) => {
            this.addInsured.addInsuredInterestType.answer = value;
        });

        this.addInsuredName.valueChanges.subscribe((value: string) => {
            this.addInsured.addInsuredName.answer = value;
        });

        this.addInsuredLoanNumber.valueChanges.subscribe((value: string) => {
            this.addInsured.addInsuredLoanNumber.answer = value;
        });

        this.lineOfBusiness.valueChanges.subscribe((value: any) => {
            this.addInsured.lineOfBusiness = value;
        });

        this.name.valueChanges.subscribe((value: string) => {
            this.addInsured.name.answer = value;
        });

        this.city.valueChanges.subscribe((value: string) => {
            this.addInsured.address.city = value;
        });

        this.state.valueChanges.subscribe((value: string) => {
            this.addInsured.address.state = value;
        });

        this.street.valueChanges.subscribe((value: string) => {
            this.addInsured.address.street = value;
        });

        this.postalCode.valueChanges.subscribe((value: string) => {
            this.addInsured.address.postalCode = value;
        });
    }
}