<div class="col-lg-12 mt-2" [formGroup]="fourYearLossHistoryFormGroup" [style.width]="'100%'">
    <div [class.table-responsive]="!isEdit" [class.edit-table]="isEdit">
        <h3 *ngIf="isEdit">Loss History Details</h3>
        <p-table class="table llh_table"
            [value]="isEdit ? fourYearLossHistoryFormGroup?.lossHistoryList: [fourYearLossHistoryFormGroup?.newLossHistory]"
            [ngStyle]="{ 'margin-bottom': '0rem' }">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">Valuation Date</th>
                    <th class="text-center">Prior Carrier</th>
                    <th class="text-center">Date of Loss</th>
                    <th class="text-center">Type of Loss</th>
                    <th class="text-center">Indemnity Reserved</th>
                    <th class="text-center">Indemnity Paid</th>
                    <th class="text-center">Expense Reserved</th>
                    <th class="text-center">Expense Paid</th>
                    <th class="text-center">Recovery</th>
                    <th class="text-center" *ngIf="isEdit" >Total Incurred</th>
                    <th class="text-center">Loss Status</th>
                    <th class="text-center" *ngIf="isEdit">Action</th>
                </tr>
            </ng-template>
            <ng-template let-product let-i="rowIndex" pTemplate="body">
                <ng-container *ngIf="isEdit ? fourYearLossHistoryFormGroup?.lossHistoryFormArray?.at(i) :fourYearLossHistoryFormGroup.get('newLossFormGroup') as currentFG">
                    <tr [pEditableRow]="product"
                        [formGroup]="currentFG">
                        <td pEditableColumn>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-calendar id="valuationDate" name="valuationDate" dateFormat="mm/dd/yy" [showIcon]="true"
                                    inputId="valuationDate"
                                    [class.validate_error_cls_dp]="isAddEditButtonClicked && currentFG?.get('valuationDate')?.invalid"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050"
                                    placeholder="MM / DD / YYYY" appendTo="body"
                                    formControlName="valuationDate" [maxDate]="maxDate"
                                    tooltipStyleClass="loss-details-tooltip" tooltipPosition="bottom"
                                    pTooltip="{{currentFG?.get('valuationDate')?.value | date: 'MM/dd/yyyy'}}">
                                </p-calendar>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{ product?.valuationDate?.answer | date: 'MM/dd/yyyy' }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-dropdown id="priorCarrier" class="prior-drp" name="priorCarrier" [filter]="true"
                                    [options]="lossHistoryPriorCarrier" editable="true"
                                    [class.validateDrodown]="isAddEditButtonClicked && currentFG?.get('priorCarrier')?.invalid"
                                    appendTo="body" formControlName="priorCarrier" placeholder="Select"
                                    tooltipStyleClass="loss-details-tooltip" tooltipPosition="bottom"
                                    pTooltip="{{currentFG?.get('priorCarrier')?.value}}">
                                </p-dropdown>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{product?.priorCarrier?.answer}}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-calendar id="dateofLoss" name="dateofLoss" dateFormat="mm/dd/yy" [showIcon]="true"
                                    inputId="dateofLoss"
                                    [class.validate_error_cls_dp]="isAddEditButtonClicked && currentFG?.get('dateofLoss')?.invalid"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050"
                                    placeholder="MM / DD / YYYY" appendTo="body"
                                    formControlName="dateofLoss" [maxDate]="maxDate"
                                    tooltipStyleClass="loss-details-tooltip" tooltipPosition="bottom"
                                    pTooltip="{{currentFG?.get('dateofLoss')?.value | date: 'MM/dd/yyyy'}}">
                                </p-calendar>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{ product?.dateofLoss?.answer | date: 'MM/dd/yyyy' }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-dropdown id="typeOfLoss" name="typeOfLoss" [options]="typeOfLoss" [filter]="true"
                                    [class.validateDrodown]="isAddEditButtonClicked && currentFG?.get('typeOfLoss')?.invalid"
                                    appendTo="body" formControlName="typeOfLoss" placeholder="Select"
                                    tooltipStyleClass="loss-details-tooltip" tooltipPosition="bottom"
                                    pTooltip="{{currentFG?.get('typeOfLoss')?.value}}">
                                </p-dropdown>
                            </div>
                            <div class="text-center text-break" *ngIf="isEdit && !product?.isEdit">
                                {{product?.typeOfLoss?.answer}}
                            </div>

                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-inputNumber id="indemnityReservedAmount" name="indemnityReservedAmount" mode="currency"
                                    value="" placeholder="Enter Reserved Amount $0" locale="en-US" currency="USD"
                                    [class.validate_error_cls]="isAddEditButtonClicked && currentFG?.get('indemnityReserved')?.invalid"
                                    formControlName="indemnityReserved" autocomplete="off" minAmt="0" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{ product?.indemnityReserved?.answer | currency:'USD' : 'symbol' : '1.0-0' }}
                            </div>

                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-inputNumber id="indemnityPaidAmount" name="indemnityPaidAmount" mode="currency" value=""
                                    placeholder="Enter Paid Amount $0" locale="en-US" currency="USD"
                                    [class.validate_error_cls]="isAddEditButtonClicked && currentFG?.get('indemnityPaid')?.invalid"
                                    formControlName="indemnityPaid" autocomplete="off" minAmt="0" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{product?.indemnityPaid?.answer | currency:'USD' : 'symbol' : '1.0-0' }}
                            </div>

                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-inputNumber id="expenseReservedAmount" name="expenseReservedAmount" mode="currency"
                                    value="" placeholder="Enter Expense Reserved Amount $0" locale="en-US" currency="USD"
                                    [class.validate_error_cls]="isAddEditButtonClicked && currentFG?.get('expenseReserved')?.invalid"
                                    formControlName="expenseReserved" autocomplete="off" minAmt="0" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{product?.expenseReserved?.answer | currency:'USD' : 'symbol' : '1.0-0' }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-inputNumber id="expensePaidAmount" name="expensePaid" mode="currency" value=""
                                    placeholder="Enter Expense Paid Amount $0" locale="en-US" currency="USD"
                                    [class.validate_error_cls]="isAddEditButtonClicked && currentFG?.get('expensePaid')?.invalid"
                                    formControlName="expensePaid" autocomplete="off" minAmt="0" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{product?.expensePaid?.answer | currency:'USD' : 'symbol' : '1.0-0' }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-inputNumber id="recoveryAmount" name="recoveryAmount" mode="currency" value=""
                                    placeholder="Enter Recovery Amount $0" locale="en-US" currency="USD"
                                    [class.validate_error_cls]="isAddEditButtonClicked && currentFG?.get('recovery')?.invalid"
                                    formControlName="recovery" autocomplete="off" minAmt="0" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{product?.recovery?.answer | currency:'USD' : 'symbol' : '1.0-0' }}
                            </div>
                        </td>
                        <td style="text-align: center;" *ngIf="isEdit">{{product?._totalIncurred | currency:'USD' : 'symbol' : '1.0-0' }}</td>
                        <td>
                            <div *ngIf="product?.isEdit || !isEdit">
                                <p-dropdown id="lossStatus" name="lossStatus" [options]="typeOfLossStatus"
                                    [class.validateDrodown]="isAddEditButtonClicked && currentFG?.get('lossStatus')?.invalid"
                                    placeholder="Select" appendTo="body" formControlName="lossStatus">
                                </p-dropdown>
                            </div>
                            <div class="text-center" *ngIf="isEdit && !product?.isEdit">
                                {{product?.lossStatus?.answer}}
                            </div>
                        </td>
                        <td style="text-align: center;font-size: 19px;" *ngIf="isEdit">
                            <i *ngIf="!product?.isEdit" class="fa fa-pencil-square-o deleteUserbtn-blue pl-2" (click)="product.isEdit = true;"></i>
                            <i *ngIf="!product?.isEdit" class="pi pi-trash deleteUserbtn ml-4" (click)="deleteLossHistory(i)"></i>
                            <i *ngIf="product?.isEdit" class="pi pi-check deleteUserbtn pl-2" (click)="validateAndUpdateLossHistory(i, product, currentFG)"></i>
                            <i *ngIf="product?.isEdit" class="pi pi-times deleteUserbtn ml-4" (click)="fourYearLossHistoryFormGroup.resetFormGroupValues(i);product.isEdit = false;"></i>
                        </td>
                    </tr>
                </ng-container>
            </ng-template>
        </p-table>
        <div *ngIf="isEdit">
            <span *ngIf="fourYearLossHistoryFormGroup.isValidValuationDate" style="color: red;">Updated Loss runs will be required before binding.</span>
        </div>
    </div>
    <div class="col-md-12 px-2 mt-3" *ngIf="!isEdit">
        <span class="text-lg-right float-lg-right">
            <button class="btnsuccess mr-2" title="Reset" name="reset" id="reset" data-toggle="modal"
                (click)="resetNewLossHistory()" type="button">
                <i class="fa fa-refresh" aria-hidden="true"></i> Reset
            </button>
            <button type="button" name="addLoss" id="btnAddLoss{{!isEdit}}" class="btnsuccess"
                [class.button-invalid]="isSubmitted && fourYearLossHistoryFormGroup.lossHistoryFormArray?.length === 0 || fourYearLossHistoryFormGroup.newLossHistory?.hasTouched"
                title="Add Loss" data-toggle="modal" (click)="validateAndAddLossesHistory()">
                Add Loss
            </button>
        </span>
    </div>
</div>
