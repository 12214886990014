
<div [formGroup]="fileUploadFormGroup" class="uplod col-lg-8 offset-lg-3 mt-2 float-right px-2">
  <label name="lblfileUpload" id="lblfileUploadID" class="upload_label">
    Upload a minimum of 4 years of loss runs unless the applicant has been in business only for 3 years, then upload 3
    years of loss runs.<sup *ngIf="(fileUploadFormGroup?.initialQuestionAnswers[0]?.required)">*</sup>
  </label>

  <a (click)="fileUpload()" name="fileUpload" id="fileUploadID"
    class="drag_and_drop"
     [class.fileUpload_error_cls]="isSubmitted && fileUploadFormGroup.uploadedFiles.length == 0 
                && fileUploadFormGroup.initialQuestionAnswers[0].required">
    <h3 class="mb-0">
      Drag & Drop Files Here
    </h3>
    <p class="mb-0">or</p>
    <h6>
      Browse Files
    </h6>
  </a>
  <p-fileUpload name="demo[]" id="demoID[]" class="UploadCls" (onSelect)="onUpload($event)"
                (click)="clickUpload($event)" [accept]="fileNetAllowedFileExtensions"
                multiple="multiple" maxFileSize="15728640"
                invalidFileTypeMessageSummary="{0}: Invalid file type.">
  </p-fileUpload>
  <div class="row"
       *ngFor="let item of fileUploadFormGroup.uploadedFiles; let i= index;">
    <div class="col-lg-12 mt-3">
      <div class="upload_file_icon mr-3">
        <i class="fa fa-file-o" aria-hidden="true"></i>
      </div>
      <div class="upload_file_details">
        <div class="upload_file_name">
          {{item?.fileName}}
        </div>
        <span class="row text-right float-right upload_size_details">
          <b class="color_dark">({{item?.filesize}})</b>
          <i class="fa fa-times-circle-o ml-2 fa-lg color_green pointerCur"
             title="Delete {{item?.fileName}}" (click)="deleteFile(item,i)"
             aria-hidden="true"></i>
        </span><br>
        <p-progressBar [value]="item?.progressValue"></p-progressBar>
      </div>
    </div>
  </div>
  <div class="col-lg-12 mt-2">
    <div class="small color_grey">
      The combined file size limit is 15 MB.
    </div>
  </div>
<p-toast position="center" key="Delete" (onClose)="onReject('Delete')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnLHPopUpYes"
                        id="btnLHPopYesID" (click)="confirmDelete(message.data)">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="btnLHPopUpNo" id="btnLHPopNoID"
                        (click)="onReject('Delete')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
