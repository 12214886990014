<div class="row">
    <h6 class="createnewuser-btn" style="width: 16%;">
        <i class="fa fa-edit mr-1" aria-hidden="true"></i>{{isEditBanner ? "Update Banner" :
        "Create Banner"}}
    </h6>
    <div class="col-lg-12 editUser">
        <p-card class="userBorder">
            <form #bannerForm="ngForm" style="width:100%" (ngSubmit)="sumbitForm(bannerForm)">
                <div class="row col-md-12 pr-1">
                    <div class="col-md-3 pl-4">
                        <div class="col-md-12 ipad_28 userManagementClass">
                            <label name="lblstatus" id="lblstatus">
                                Status <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-md-12 ipad_65">
                            <div class="p-field-radiobutton banner">
                                <p-radioButton name="status" [value]="false" label="OFF" #status="ngModel"
                                    class="radio_button"
                                    [class.radioButtonValidator]="bannerForm.submitted && status.invalid" required
                                    [(ngModel)]="siteBanner.enabled">
                                </p-radioButton>
                                <p-radioButton name="status" class="radio_button" #status="ngModel" [value]="true"
                                    label="ON" [class.radioButtonValidator]="bannerForm.submitted && status.invalid"
                                    required [(ngModel)]="siteBanner.enabled">
                                </p-radioButton>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="col-md-12 ipad_28 userManagementClass">
                            <label name="lbltype" id="lbltype">
                                Type <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-md-12 ipad_65">
                            <p-dropdown placeholder="Banner Type" [options]="bannerTypeQuestionOptions"
                                [(ngModel)]="siteBanner.severity" name="type" id="type" #type="ngModel" required
                                [class.validateDrodown]="bannerForm.submitted && type.invalid">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="col-md-12 ipad_28 userManagementClass">
                            <label name="lblBannerEffectiveFrom" id="lblBannerEffectiveFrom">
                                Effective From <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-md-12 ipad_65">
                            <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon2"
                                appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                                [class.validate_error_cls_dp]="bannerForm.submitted && effectiveFrom.invalid"
                                placeholder="MM / DD / YYYY" [(ngModel)]="siteBanner.effectiveFrom" name="effectiveFrom"
                                id="effectiveFrom" #effectiveFrom="ngModel" required [showTime]="true"
                                yearRange="2023:2050" hourFormat="24" minuteFormat="2">
                            </p-calendar>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="col-md-12 ipad_28 userManagementClass">
                            <label name="lblBannerEffectiveTo" id="lblBannerEffectiveTo">
                                Effective To <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="col-md-12 ipad_65">
                            <p-calendar dateFormat="mm/dd/yy" appendTo="body" [showIcon]="true"
                                inputId="calendarIcon2" [monthNavigator]="true" [yearNavigator]="true"
                                yearRange="2023:2050"
                                [class.validate_error_cls_dp]="bannerForm.submitted && effectiveTo.invalid"
                                placeholder="MM / DD / YYYY" [(ngModel)]="siteBanner.effectiveTo" name="effectiveTo"
                                id="effectiveTo" #effectiveTo="ngModel" required [showTime]="true" hourFormat="24" minuteFormat="2">
                            </p-calendar>
                        </div>
                    </div>
                </div>
                <div class="row col-md-12 ml-0 p-0 pr-5 pl-4">
                    <div class="col-md-12 ipad_28 userManagementClass">
                        <label name="lblmessage" id="lblmessage">
                            Message <span class="text-danger">*</span>
                        </label>
                    </div>
                    <div class="col-md-12 ipad_65 pr-0">
                        <textarea class="form-control" rows="2" required [(ngModel)]="siteBanner.text"
                            [class.validate_error_cls]="bannerForm.submitted && message.invalid" name="message"
                            id="message" type="text" maxlength="5000" #message="ngModel">
                </textarea>
                    </div>
                </div>
                <div class="row col-md-12 mt-2 pr-3">
                    <div class="col-md-7"></div>
                    <div class="col-md-5 pl-5 mt-1">
                        <button class="btnsuccess  float-right" title="Reset" name="Rreset" id="RresetID"
                            data-toggle="modal"
                            (click)="isEditBanner ? closeEditBannerPopup() : resetCreateBannerPopup()" type="button">
                            <i class="{{isEditBanner ? 'fa fa-close' : 'fa fa-refresh'}}" aria-hidden="true"></i>
                            {{isEditBanner ? "Cancel":"Reset"}}
                        </button>
                        <button class="btnsuccess float-right" title="save" name="save" id="save"
                            style="margin-right: 10px;" data-toggle="modal" type="submit">
                            <i class="{{isEditBanner ? 'fa fa-check' : 'fa fa-save'}}" aria-hidden="true"></i>
                            {{isEditBanner ? "Update" : "Save"}}
                        </button>
                    </div>
                </div>
            </form>
        </p-card>
    </div>
</div>