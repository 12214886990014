import { Component, OnInit } from '@angular/core';
import { DAO } from '../../modal/DAO';
import { AuthenticatedUserComponent } from 'src/app/sharedComponents/authenticated-user/authenticated-user.component';
import { AuthenticationService } from '@rss/ng-auth-service';
import { Constants } from 'src/app/modal/ProjConstants.models';

@Component({
    selector: 'internalAdmin',
    templateUrl: './internalAdmin.html'
})

export class InternalAdminComponent extends AuthenticatedUserComponent implements OnInit {

    public internalAdminUserActionsQuestionOptions = Constants.InternalAdminUserActions;
    public internalAdminUserAction: string;

    constructor(
        public dao: DAO,
        protected authenticationService: AuthenticationService
    ) {
        super(authenticationService);
    }

    ngOnInit(): void {}
}

