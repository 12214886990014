import { NG_VALIDATORS, FormControl} from '@angular/forms'
import { Directive, Input  } from '@angular/core';
import _ from 'lodash';

@Directive({
  selector: '[maxAmt]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxAmtDirective, multi: true }
  ]
})
export class MaxAmtDirective {

  @Input("maxAmt") maxAmt:string
  constructor() { }

  ngOnInit() {
  }
 
  validate(c: FormControl) {
    if (!!!this.maxAmt)
      return null;
    let v = c.value ?? "0";
    v = _.cloneDeep (v); // If c is an object, keep original c.value intact
    let newMaxVal = this.maxAmt;

    if (typeof(v) != "number")
    {
        if (v instanceof Date)
        {
            v = v.getTime().toString();
            newMaxVal = new Date(this.maxAmt)?.getTime()?.toString();
        }
        else if ((v.includes("/") || v.includes("-") ) && !!!isNaN(new Date(v.toString()).getTime()))
        {
            v = new Date(v.toString()).getTime();
            newMaxVal = new Date(this.maxAmt)?.getTime()?.toString();
        }
        else
            v = v.toString().replaceAll(',', '');
    }
    
    if (Number(v) > Number(newMaxVal)) {
      let err = 'Amount must be lesser than or equal to your maximum' + new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(Number(this.maxAmt));
      return { 'maxAmt': true, 'errmsg':err}
    }
    return null;
  }
}
