import { Validator, NG_VALIDATORS, FormControl} from '@angular/forms'
import { Directive, OnInit, forwardRef, Input  } from '@angular/core';

@Directive({
  selector: '[requiredAmtOrDecline]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: RequiredAmtOrDeclineDirective, multi: true }
  ]
})
export class RequiredAmtOrDeclineDirective {

  
  @Input("requiredAmtOrDecline") required:boolean
  constructor() { }
  ngOnInit() {
  }
 
  validate(c: FormControl) {

    if (!this.required)
      return null;

    let v = c.value;
    if (v == "" || v == null || v == "Click to View Options" || v == "CLICK")
      return { 'requiredAmtOrDecline': true, 'errmsg':  'Please decline or choose an amount for the question.'}
    return null;
    
  }
}
