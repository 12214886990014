<div class="container">
    <div class="vehiclescrn main_content mt-2">
        <form [formGroup]="addVehicleForm" class="width-100">
            <div class="row mt-0">
                <div class="col-12">
                    <span class="text-lg-right float-lg-right">
                        <cancel-endorsement [endorsementId]="endorsement.endorsementId"></cancel-endorsement>
                    </span>
                </div>
                <div class="col-lg-3 col-md-4 mt-4 pl-0">
                    <list-view *ngIf="!!vehicleDetailsList" header="Add PU / Trailer - " listName="Add PU / Trailer"
                        [labelFormat]="'{vehicleYear.answer} {vehicleMake.answer} {vehicleModel.answer}'"
                        class="additionalInsuredList" [itemList]="vehicleDetailsList" [maxItemLimit]="30"
                        [blankDataValidationProperties]="['vehicleYear.answer','vehicleMake.answer', 'vehicleModel.answer']"
                        (itemClicked)="clickedItem($event)">
                    </list-view>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <div class="clearfix"></div>
                    <div class="vehicle_details1 mt-4">
                        <div class="card" *ngIf="!!vehicleDetailsList?.currentItem">
                            <div class="card-header">
                                <h4 *ngIf="!!vehicleDetailsList.currentItem.vehicleYear.answer" class="mb-1">
                                    <b>{{vehicleDetailsList.currentItem.vehicleYear.answer}}
                                        {{vehicleDetailsList.currentItem.vehicleMake.answer}}
                                        {{vehicleDetailsList.currentItem.vehicleModel.answer}}</b>
                                </h4>
                                <h4 *ngIf="!!!vehicleDetailsList.currentItem.vehicleYear.answer" class="mb-1"><b>New Unit</b>
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="vehicleType"
                                            id="vehicleType">{{vehicleDetailsList.currentItem.vehicleType.questionLabel}}</label>
                                        <span style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-dropdown [options]="vehicleTypes" name="vehicleType" id="vehicleType"
                                            >
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="year"
                                            id="year">{{vehicleDetailsList.currentItem.vehicleYear.questionLabel}}</label>
                                        <span style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-inputNumber name="vehicleYear" id="vehicleYear" formControlName="vehicleYear"
                                            [class.validate_error_cls]="isSubmitted && addVehicleForm.get('vehicleYear').invalid"
                                            [useGrouping]="false" maxlength="4"
                                            [placeholder]="vehicleDetailsList.currentItem.vehicleYear.questionHelpText ? vehicleDetailsList.currentItem.vehicleYear.questionHelpText : 'YYYY'"
                                            autocomplete="off"></p-inputNumber>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="vehicleMake"
                                            id="vehicleMake">{{vehicleDetailsList.currentItem.vehicleMake.questionLabel}}</label>
                                        <span style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input name="vehicleMake" id="vehicleMake"
                                            [class.validate_error_cls]="isSubmitted && addVehicleForm.get('vehicleMake').invalid"
                                            class="form-control" formControlName="vehicleMake" type="text"
                                            placeholder="MAKE" [alphaNumericCharactersOnly] maxlength="50"
                                            autocomplete="off">
                                        <span style="color: red;"
                                            *ngIf="!!addVehicleForm.get('vehicleMake')?.hasError('alphanumericCharactersOnly')">
                                            Special characters are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="vehicleModel"
                                            id="vehicleModel">{{vehicleDetailsList.currentItem.vehicleModel.questionLabel}}</label>
                                        <span *ngIf="addVehicleForm?.vehicleType?.value != 'Trailer'" style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input name="vehicleModel" id="vehicleModel"
                                            [class.validate_error_cls]="isSubmitted && addVehicleForm.get('vehicleModel').invalid"
                                            class="form-control" formControlName="vehicleModel" placeholder="MODEL"
                                            type="text" alphaNumericCharactersOnly maxlength="50" autocomplete="off">
                                        <span style="color: red;"
                                            *ngIf="addVehicleForm.get('vehicleModel')?.hasError('alphanumericCharactersOnly')">
                                            Special characters are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="vehicleVin"
                                            id="vehicleVin">{{vehicleDetailsList.currentItem.vehicleVin.questionLabel}}</label>
                                        <span *ngIf="addVehicleForm?.vehicleType?.value != 'Trailer'" style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input name="vehicleVin" id="vehicleVin"
                                            [class.validate_error_cls]="isSubmitted && addVehicleForm.get('vehicleVin').invalid"
                                            class="form-control" formControlName="vehicleVin" placeholder="VIN#"
                                            [maxlength]="!isTrailer ? addVehicleForm?.vehicleYear?.value < 1981 ? 13 : 17: 17"
                                            [minlength]="!isTrailer ? addVehicleForm?.vehicleYear?.value < 1981 ? 5 : 17: 0" type="text" autocomplete="off">
                                        <span style="color: red;"
                                            *ngIf="addVehicleForm.get('vehicleVin')?.hasError('alphanumericCharactersOnly')">
                                            Special characters are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="vehicleStatedAmount"
                                            id="vehicleStatedAmount">{{vehicleDetailsList.currentItem.vehicleStatedAmount.questionLabel}}
                                            <span style="color: red;"> *</span></label>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-inputNumber id="vehicleStatedAmount" name="vehicleStatedAmount"
                                            mode="currency"
                                            [class.invalidInputNumber]="isSubmitted && addVehicleForm.get('vehicleStatedAmount').invalid"
                                            value="" placeholder="Enter Stated Amount $0" locale="en-US" currency="USD"
                                            formControlName="vehicleStatedAmount" autocomplete="off" minAmt="0">
                                        </p-inputNumber>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label for="customParts"
                                            [class.validationred]="isSubmitted &&  addVehicleForm.get('vehicleCustomPartsParent').invalid"
                                            id="customParts">{{vehicleDetailsList.currentItem.vehicleCustomPartsParent.questionLabel}}</label>
                                        <span style="color: red;"> *</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div>
                                            <p-radioButton name="vehicleCustomPartsParent" label="Yes" id="customPartsYes"
                                                value="Yes" class="radio_button"
                                                formControlName="vehicleCustomPartsParent">
                                            </p-radioButton>
                                            <p-radioButton name="vehicleCustomPartsParent" label="No"
                                                formControlName="vehicleCustomPartsParent" id="customPartsNo"
                                                class="radio_button" value="No">
                                            </p-radioButton>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="addVehicleForm.get('vehicleCustomPartsParent').value === 'Yes'">
                                    <div class="row mb-1">
                                        <div class="col-lg-6">
                                            <label for="vehicleCustomAmount"
                                                id="vehicleCustomAmount">{{vehicleDetailsList.currentItem.vehicleCustomAmount.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <p-inputNumber name="vehicleCustomAmount" id="vehicleCustomAmount"
                                                [value]=""
                                                [class.invalidInputNumber]="isSubmitted &&  addVehicleForm.get('vehicleCustomAmount').invalid"
                                                placeholder="Enter Custom Amount $0" mode="currency" currency="USD"
                                                formControlName="vehicleCustomAmount" autocomplete="off" locale="en-US"
                                                minAmt="0">
                                            </p-inputNumber>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-lg-6">
                                            <label for="vehicleCustomDesc"
                                                id="vehicleCustomDesc">{{vehicleDetailsList.currentItem.vehicleCustomDesc.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <textarea id="vehicleCustomDesc" name="vehicleCustomDesc"
                                                [class.validate_error_cls]="isSubmitted && addVehicleForm.get('vehicleCustomDesc').invalid"
                                                formControlName="vehicleCustomDesc" placeholder="Enter Description"
                                                locale="en-US" class="form-control" pInputTextarea
                                                autocomplete="off"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label style="font-size: 13px;" for="additionalInsured"
                                            [class.validationred]="isSubmitted &&  addVehicleForm.get('vehicleAddInsuredOrLossPayee').invalid"
                                            id="additionalInsured">{{vehicleDetailsList.currentItem.vehicleAddInsuredOrLossPayee.questionLabel}}
                                            <span style="color: red;"> *</span></label>
                                    </div>
                                    <div class="col-lg-6">
                                        <div>
                                            <p-radioButton name="vehicleAddInsuredOrLossPayee" id="vehicleAddInsuredOrLossPayeeYes"
                                                label="Yes" value="Yes" class="radio_button"
                                                formControlName="vehicleAddInsuredOrLossPayee"
                                                (onClick)="vehicleDetailsList.currentItem.AdditionalInsuredList=[];addInterest()">
                                            </p-radioButton>
                                            <p-radioButton name="vehicleAddInsuredOrLossPayee" id="vehicleAddInsuredOrLossPayeeNo"
                                                class="radio_button" label="No"
                                                formControlName="vehicleAddInsuredOrLossPayee" value="No">
                                            </p-radioButton>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="addVehicleForm?.vehicleAddInsuredOrLossPayee?.value == 'Yes'">
                                    <div formArrayName="additionalInsuredList">
                                        <div>
                                            <div *ngFor="let interest of addVehicleForm.get('additionalInsuredList')?.controls; let i = index;"
                                                [formGroupName]="i">
                                                <div class="row mb-1">
                                                    <div class="col-lg-6">
                                                        <label name="interestType_{{i}}" id="interestType_{{i}}">Select what type of interest:</label>
                                                        <span class="question_icon">
                                                            <i class="fa fa-question-circle"
                                                                pTooltip="Select the type of interest that will be entitled to receive insurance benefits and notices along with insured. Additional insureds receive liability protection while loss payees receives only property damage coverage."
                                                                aria-hidden="true"></i>
                                                        </span>
                                                    </div>
                                                    <div [class.col-lg-6]="i == 0" [class.col-lg-5]="i != 0">
                                                        <p-dropdown placeholder="Select Type" [options]="interestTypes"
                                                            [class.validateDrodown]="isSubmitted &&  addVehicleForm.get('additionalInsuredList.' + i + '.addInsuredInterestType').invalid"
                                                            [formControl]="addVehicleForm?.get('additionalInsuredList.' + i + '.addInsuredInterestType')"
                                                            name="interestType_{{i}}" id="interestType_{{i}}">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-1 pl-2">
                                                        <span *ngIf="i != 0">
                                                            <i class="fa fa-trash delete_icon ml-3" title="delete"
                                                                name="remove_{{i}}" id="remove_{{i}}"
                                                                (click)="remove(i)"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-lg-6">
                                                        <label for="addInsuredName_{{i}}"
                                                            id="addInsuredName_{{i}}">Named Insured</label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <input id="addInsuredName_{{i}}" name="addInsuredName"
                                                            [class.validate_error_cls]="isSubmitted &&  addVehicleForm.get('additionalInsuredList.' + i + '.addInsuredName').invalid"
                                                            class="form-control" type="text" placeholder="Enter Name"
                                                            formControlName="addInsuredName" autocomplete="off">
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="row mt-n2">
                                                        <div class="col-lg-6">
                                                            <label for="address_{{i}}" id="address_{{i}}">Address
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <input type="text" id="street_{{i}}" name="street"
                                                                class="form-control mt-2"
                                                                [class.validate_error_cls]="isSubmitted && addVehicleForm.get('additionalInsuredList.' + i + '.street').invalid"
                                                                placeholder="Street Address" autocomplete="off"
                                                                formControlName="street" maxlength="40"
                                                                autocomplete="off">
                                                            <div class="row mb-1 mt-2">
                                                                <div class="col-lg-4 col-md-4 col-4">
                                                                    <input type="text" id="city_{{i}}" name="city"
                                                                        [class.validate_error_cls]="isSubmitted && addVehicleForm.get('additionalInsuredList.' + i + '.city').invalid"
                                                                        class="form-control" placeholder="City"
                                                                        formControlName="city" autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-4">
                                                                    <div>
                                                                        <p-dropdown id="state_{{i}}" name="state"
                                                                            placeholder="Select State"
                                                                            [options]="stateSettings.StateQuestionOptions"
                                                                            [class.validateDrodown]="isSubmitted && addVehicleForm.get('additionalInsuredList.' + i + '.state').invalid"
                                                                            [formControl]="addVehicleForm?.get('additionalInsuredList.' + i + '.state')">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4 col-4">
                                                                    <div [class.validate_error_cls]="isSubmitted && addVehicleForm.get('additionalInsuredList.' + i + '.postalCode').invalid">
                                                                        <p-inputMask id="addInsuredPostalCode_{{i}}"
                                                                            name="postalCode"
                                                                            formControlName="postalCode" mask="99999"
                                                                            autocomplete="off" placeholder="Zip">
                                                                        </p-inputMask>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-1">
                                                    <div class="col-lg-6 offset-lg-6">
                                                        <input type="text" id="addInsuredLoanNumber_{{i}}" name="addInsuredLoanNumber"
                                                            class="form-control" placeholder="City"
                                                            formControlName="addInsuredLoanNumber"
                                                            placeholder="Loan Number (optional)" autocomplete="off">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="text-right mb-3">
                                                    <a style="color: #115740;" title="Add Another Interest"
                                                        (click)="addInterest()">Add Another Interest</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-lg-12 text-center">
                                        <span class="text-lg-right float-lg-right">
                                            <button type="submit" name="addUnitbtn" id="addUnitbtn"
                                                class="innerSubButton" title="Add Another Unit"
                                                (click)="validateAndAddVehicle()">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                Add Another Unit
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-4" *ngIf="isEndorsementPolicyEffectivePriorToToday">
                        <div class="row mb-1">
                            <div class="col-lg-3 pt-4">
                                <label>Upload No Loss Letter</label>
                                <span style="color: red;"> *</span>
                            </div>
                            <div class="col-lg-9">
                                <a class="drag_and_drop" (click)="fileUpload()" name="fileUpload" id="fileUpload"
                                    [class.fileUpload_error_cls]="isSubmitted && lossLetterDocuments.length == 0">
                                    <h3 class="mb-0">Drag & Drop Files Here</h3>
                                    <p class="mb-0">or</p>
                                    <h6>Browse Files</h6>
                                </a>
                                <p-fileUpload class="UploadCls" id="lossLetter" name="lossLetter"
                                    (onSelect)="onUpload($event)" (click)="clickUpload($event)"
                                    [accept]="fileNetAllowedFileExtensions" multiple="multiple" maxFileSize="15728640"
                                    invalidFileTypeMessageSummary="{0}: Invalid file type.">
                                </p-fileUpload>
                                <div class="row" *ngFor="let item of lossLetterDocuments; let i = index;">
                                    <div class="col-lg-12 mt-3">
                                        <div class="upload_file_icon mr-3">
                                            <i class="fa fa-file-o" aria-hidden="true"></i>
                                        </div>
                                        <div class="upload_file_details">
                                            <div class="upload_file_name">{{ item?.fileName }}</div>
                                            <span class="row text-right float-right upload_size_details"><b
                                                    class="color_dark">({{item?.filesize}})</b>
                                                <i class="fa fa-times-circle-o ml-2 fa-lg color_green pointerCur"
                                                    title="DeleteFile" (click)="deleteFile(item, i)"
                                                    aria-hidden="true"></i>
                                            </span><br>
                                            <p-progressBar [value]="item?.progressValue"></p-progressBar>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-2">
                                    <div class="small color_grey">The combined file size limit is 15 MB.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-4" *ngIf="vehicleDetailsList?.currentItem?.uploadedDocuments?.enabled">
                        <endorsement-file-upload-form #edsFileUploadForm [questionGroup]="endorsementQuestionGroup"></endorsement-file-upload-form>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12">
                    <div class="col-mg-6 p-0 mt-3">
                        <span class="text-lg-left float-lg-left" style="margin-left: 250px;">
                            <modify-endorsement-type [endorsement]="endorsement"></modify-endorsement-type>
                        </span>
                    </div>
                    <endorsement-footer 
                        [parentPage]="this"
                        (clickedViewApplication)="bindAnswersToQuestions()">
                    </endorsement-footer>
                </div>
            </div>
        </form>
    </div>
</div>
<p-toast position="center" key="delete" (onClose)="onReject('delete')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnonRejectYesNo"
                        id="btnonRejectYesID" (click)="confirmRemove('delete', message.data)">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        Yes
                    </button>
                </div>
                <div class="p-col-6">
                    <button type="button" class="warnBtnerror" title="No" name="btnVonRejectNo" id="btnVonRejectNoID"
                        (click)="onReject('delete')">
                        <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                        No
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>