<radiobutton-question
    *ngIf="excludeUimBi?.enabled && showExcludeUimBiQuestion"
    #isExcludeUimBiRadiobutton
    [question]="excludeUimBi"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<radiobutton-question
    *ngIf="excludeUimBiPrd?.enabled && showExcludeUimBiPrdQuestion"
    #isExcludeUimBiRadiobutton
    [question]="excludeUimBiPrd"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>


<dropdown-question
    *ngIf="showUimBiPrd"
    #uimBiPrdDropdown
    label="Underinsured Motorist - Combined Bodily Injury / Property Damage Limit"
    class="uim-bi-prd"
    [question]="uimBiPrd"
    [requiredAmtOrDecline]="true"
    [disabled]="excludeUimBiPrd.enabled && excludeUimBiPrd?.answer == 'No'"
    [isFormSubmitted]="isFormSubmitted"
    [supressErrorMessage]="showUimBi"
    (changed)="uimBiPrdChanged()">
</dropdown-question>

<dropdown-question
    *ngIf="showUimBiPrdDed"
    #uimBiPrdDedDropdown
    label="Underinsured Motorist - Combined Bodily Injury / Property Damage Deductible"
    [question]="uimBiPrdDed"
    [disabled]="isUimBiPrdDedDisabled"
    [requiredAmtOrDecline]="uimBiPrdDed.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<dropdown-question
    *ngIf="showUimBi"
    #uimBiDropdown
    label="Underinsured Motorist - Bodily Injury"
    class="uim-bi"
    [question]="uimBi"
    [disabled]="excludeUimBi.enabled && excludeUimBi?.answer == 'No'"
    [requiredAmtOrDecline]="uimBi.required"
    [isFormSubmitted]="isFormSubmitted"
    [supressErrorMessage]="showUimBiPrd"
    (changed)="onUimBiChanged()">
</dropdown-question>
<div class="row">
    <div class="col-lg-6"></div>
    <div class="col-lg-6">
     <span class="error-message" *ngIf="umAndUimMustMatch">
        {{validationMessage}}
     </span>
    </div>
 </div>

<dropdown-question
    *ngIf="showUimPrd"
    #uimPrdDropdown
    label="Underinsured Motorist - Property Damage Limit"
    class="uim-prd"
    [question]="uimPrd"
    [disabled]="isUimPrdDisabled"
    [requiredAmtOrDecline]="uimPrd.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>
<dropdown-question
    *ngIf="showUimPrdDed"
    #uimPrdDedDropdown 
    label="Underinsured Motorist - Property Damage Deductible"
    class="uim-prd-ded"
    [question]="uimPrdDed"
    [disabled]="isUimPrdDedDisabled"
    [requiredAmtOrDecline]="uimPrdDed.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>