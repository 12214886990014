import { Component, Input, OnInit } from '@angular/core';
import { Endorsement } from 'src/app/modal/Endorsements/Endorsement.Model';

@Component({
  selector: 'title-bar',
  templateUrl: './title-bar.component.html'
})
export class TitleBarComponent implements OnInit {
  @Input() endorsement: Endorsement;

  constructor() { }

  ngOnInit(): void {
  }
}
