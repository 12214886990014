import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { emptyModal } from '../../../modal/DAO';
import { DateService } from "../../../services/date.service";
import { QuestionAnswer, Questionnaire, ResponseContainer } from "../../../modal/questionnaire.models";
import _ from 'lodash';

export class LiabilityAndPhysicalDamageFormGroup extends FormGroup {
    private debounceTimeout: ReturnType<typeof setTimeout>;
    public lossInfoGrid: Array<LossRecord> = [];
    public initialLossInfoGrid: Array<LossRecord> = [];
    public minLossHistoryDate: Date;
    public maxLossHistoryDate: Date;

    constructor(readonly fb: FormBuilder = new FormBuilder()) {
        super(fb.group({
        }).controls); 
    }

    public initializeFormControls(apiResponse: ResponseContainer) {
        this.calculateMinMaxLossHistoryDate(apiResponse);
        this.initializeFrom(apiResponse);
        this.buildForm();
        this.subscribeToValueChanges();
    }

    private calculateMinMaxLossHistoryDate(apiResponse: ResponseContainer): void {
        if (!!apiResponse?.accountInfoQsnr) {
            let policyEffectiveDate: QuestionAnswer = apiResponse.accountInfoQsnr.findQuestion("policyEffectiveDate");
            if (policyEffectiveDate != null) {
                let dateService: DateService = new DateService();
                let effectiveDate: Date = dateService.getCorrectDate(policyEffectiveDate.answer);
                this.minLossHistoryDate = new Date(effectiveDate);
                this.minLossHistoryDate.setFullYear(effectiveDate.getFullYear() - 4);
                this.maxLossHistoryDate = effectiveDate;
            }
        }
    }

    private initializeFrom(apiResponse: ResponseContainer): void {
        const lossQuestionnaire: Questionnaire = apiResponse.losssHistoryQuestionnaire;
        if (lossQuestionnaire.questionAnswer.length == 0) return;

        this.lossInfoGrid = [];
        for (let i = 1; i <= 4; i++) {
            let tempProp: LossRecord = new LossRecord().initializeFrom(apiResponse, i);
            this.lossInfoGrid.push(tempProp);
        }
        this.initialLossInfoGrid = _.cloneDeep(this.lossInfoGrid);
    }

    private buildForm(): void {
        for (let i = 0; i < this.lossInfoGrid.length; i++) {
            const lossRecord = this.lossInfoGrid[i];
            this.addControl(`effectiveDate_${i + 1}`, new FormControl(lossRecord.liabeffectiveDate.answer, this.getValidator(lossRecord.liabeffectiveDate)));
            this.addControl(`expirationDate_${i + 1}`, new FormControl(lossRecord.liabexpirationDate.answer, this.getValidator(lossRecord.liabexpirationDate)));
            this.addControl(`liabHistoricalPowerUnits_${i + 1}`, new FormControl(lossRecord.liabHistoricalPowerUnits.answer, this.getValidator(lossRecord.liabHistoricalPowerUnits)));
            this.addControl(`physicalDamagePUCount_${i + 1}`, new FormControl(lossRecord.physicalDamagePUCount.answer, this.getValidator(lossRecord.physicalDamagePUCount)));
        }
    }

    private getValidator(control: emptyModal): Validators | null {
        if (control.rowIndex == 4 && (control.key == 'liabHistoricalPowerUnits' || control.key == 'physicalDamagePUCount')) {
            return null;
        }
        return Validators.required;
    }

    private subscribeToValueChanges(): void {
        for (let i = 0; i < this.lossInfoGrid.length; i++) {
            const lossRecord = this.lossInfoGrid[i];
            const effectiveDateControl = this.get(`effectiveDate_${i + 1}`) as FormControl;
            const expirationDateControl = this.get(`expirationDate_${i + 1}`) as FormControl;
            const liabHistoricalPowerUnitControl = this.get(`liabHistoricalPowerUnits_${i + 1}`) as FormControl;
            const physicalDamagePUCountControl = this.get(`physicalDamagePUCount_${i + 1}`) as FormControl;

            effectiveDateControl.valueChanges.subscribe((value: string) => {
                lossRecord.liabeffectiveDate.answer = value;
            });

            expirationDateControl.valueChanges.subscribe((value: string) => {
                lossRecord.liabexpirationDate.answer = value;
            });

            liabHistoricalPowerUnitControl.valueChanges.subscribe((value: string) => {
                lossRecord.liabHistoricalPowerUnits.answer = value;
                if (i == 3)
                    this.updateValidators(liabHistoricalPowerUnitControl, physicalDamagePUCountControl);
            });

            physicalDamagePUCountControl.valueChanges.subscribe((value: string) => {
                lossRecord.physicalDamagePUCount.answer = value;
                if (i == 3)
                    this.updateValidators(liabHistoricalPowerUnitControl, physicalDamagePUCountControl);
            });
        }
    }

    private updateValidators(liabHistoricalPowerUnitControl: FormControl, physicalDamagePUCountControl: FormControl): void {
        // If one of the fields is filled, make the other field mandatory
        if (liabHistoricalPowerUnitControl.value !== '' || physicalDamagePUCountControl.value !== '') {
            liabHistoricalPowerUnitControl.setValidators(Validators.required);
            physicalDamagePUCountControl.setValidators(Validators.required);
        } else {
            // If both fields are empty, reset validators to null
            liabHistoricalPowerUnitControl.setValidators(null);
            physicalDamagePUCountControl.setValidators(null);
        }
        this.debounce(() => {
            liabHistoricalPowerUnitControl.updateValueAndValidity();
            physicalDamagePUCountControl.updateValueAndValidity();
        });
    }

    private debounce(fn: () => void, delay: number = 0): void {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(fn, delay);
    }

    public toQuestionAnswers(): Array<QuestionAnswer> {
        let questionAnswers: Array<QuestionAnswer> = [];
        this.lossInfoGrid.forEach(item => {
            Object.keys(item).forEach(property => {
                if (item[property] instanceof Object) {
                    questionAnswers.push(item[property]);
                }
            });
        });
        return questionAnswers;
    }
}

export class LossRecord {
    public liabeffectiveDate: emptyModal;
    public liabexpirationDate: emptyModal;
    public liabHistoricalPowerUnits: emptyModal;
    public physicalDamagePUCount: emptyModal;
    public liabNumberOfClaims: emptyModal;

    constructor() {
        this.liabeffectiveDate = new emptyModal();
        this.liabexpirationDate = new emptyModal();
        this.liabHistoricalPowerUnits = new emptyModal();
        this.physicalDamagePUCount = new emptyModal();
        this.liabNumberOfClaims = new emptyModal();
    }

    initializeFrom(apiResponse: ResponseContainer, i: number): LossRecord {
        const lossQuestionnaire: Questionnaire = apiResponse.losssHistoryQuestionnaire;

        let policyEffectiveDate: QuestionAnswer = apiResponse.accountInfoQsnr.findQuestion("policyEffectiveDate");
        let dateService: DateService = new DateService();

        let effectiveDate = lossQuestionnaire.findQuestionByKeyAndRowIndex('liabEffectiveDate', i);
        this.liabeffectiveDate = emptyModal.initializeFrom(effectiveDate || lossQuestionnaire.findQuestionByKeyAndRowIndex('liabEffectiveDate', 1));
        this.liabeffectiveDate.type = "Date";
        if (effectiveDate == null)
            this.liabeffectiveDate.rowIndex = i;
        if (!this.liabeffectiveDate.answer) {
            const policyEffectiveDateAsDate: Date = dateService.getCorrectDate(policyEffectiveDate.answer);
            policyEffectiveDateAsDate.setFullYear(policyEffectiveDateAsDate.getFullYear() - i);
            this.liabeffectiveDate.answer = policyEffectiveDateAsDate;
        }
        if (!!this.liabeffectiveDate.answer && typeof (this.liabeffectiveDate.answer) != 'object') {
            this.liabeffectiveDate.answer = dateService.getCorrectDate(this.liabeffectiveDate.answer);
        }

        let expirationDate = lossQuestionnaire.findQuestionByKeyAndRowIndex('liabExpirationDate', i);
        this.liabexpirationDate = emptyModal.initializeFrom(expirationDate || lossQuestionnaire.findQuestionByKeyAndRowIndex('liabExpirationDate', 1));
        this.liabexpirationDate.type = "Date";
        if (expirationDate == null)
            this.liabexpirationDate.rowIndex = i;
        if (!this.liabexpirationDate.answer) {
            const expirationDateAsDate: Date = dateService.getCorrectDate(this.liabeffectiveDate.answer);
            expirationDateAsDate.setFullYear(expirationDateAsDate.getFullYear() + 1);
            this.liabexpirationDate.answer = expirationDateAsDate;
        }
        if (!!this.liabexpirationDate.answer && typeof (this.liabexpirationDate.answer) != 'object') {
            this.liabexpirationDate.answer = dateService.getCorrectDate(this.liabexpirationDate.answer);
        }

        let liabHistoricalPowerUnit = lossQuestionnaire.findQuestionByKeyAndRowIndex('liabHistoricalPowerUnits', i);
        this.liabHistoricalPowerUnits = emptyModal.initializeFrom(liabHistoricalPowerUnit || lossQuestionnaire.findQuestionByKeyAndRowIndex('liabHistoricalPowerUnits', 1));
        if (liabHistoricalPowerUnit == null)
            this.liabHistoricalPowerUnits.rowIndex = i;

        let physicalDamagePUCount = lossQuestionnaire.findQuestionByKeyAndRowIndex('physicalDamagePUCount', i);
        this.physicalDamagePUCount = emptyModal.initializeFrom(physicalDamagePUCount || lossQuestionnaire.findQuestionByKeyAndRowIndex('physicalDamagePUCount', 1));
        if (physicalDamagePUCount == null)
            this.physicalDamagePUCount.rowIndex = i;

        let liabNumberOfClaims = lossQuestionnaire.findQuestionByKeyAndRowIndex('liabNumberOfClaims', i);
        this.liabNumberOfClaims = emptyModal.initializeFrom(liabNumberOfClaims || lossQuestionnaire.findQuestionByKeyAndRowIndex('liabNumberOfClaims', 1));
        if (liabNumberOfClaims == null)
            this.liabNumberOfClaims.rowIndex = i;

        return this;
    }
}