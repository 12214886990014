import { DateService } from "src/app/services/date.service";
import { emptyModal } from "../DAO";
import { QuestionAnswer } from "../questionnaire.models";

export class Driver {
    public firstName: emptyModal = new emptyModal();
    public middleName: emptyModal = new emptyModal();
    public lastName: emptyModal = new emptyModal();
    public dob: emptyModal = new emptyModal();
    public licenseStateCode: emptyModal = new emptyModal();
    public licensePermitNumber: emptyModal = new emptyModal();
    public originalLicenseDate: emptyModal = new emptyModal();
    public dateOfHire: emptyModal = new emptyModal();
    public suffix: emptyModal = new emptyModal();
    public uploadedDocuments: emptyModal = new emptyModal();
    public yearsOfExperience: emptyModal = new emptyModal();

    static instantiateArrayFrom(questionAnswers: Array<QuestionAnswer>): Array<Driver> {
        let driverList: Array<Driver> = [];
        if (!!!questionAnswers || questionAnswers.length === 0) {
            return driverList;
        }

        let numberOfDriver: number = questionAnswers.filter(x => x.key == "firstName").length;
        for (let i = 1; i <= numberOfDriver; i++) {
            let filteredQuestionAnswers: Array<QuestionAnswer> = questionAnswers.filter(x => x.rowIndex == i);
            let tempDriver: Driver = this.instantiateFromSingleQuestionAnswerSet(filteredQuestionAnswers);
            driverList.push(tempDriver);
        }
        return driverList;
    }

    static instantiateFromSingleQuestionAnswerSet(questionAnswers: Array<QuestionAnswer>): Driver {
        let tempDriver: Driver = new Driver();
        if (!questionAnswers || questionAnswers.length === 0) {
            return tempDriver;
        }

        let ds = new DateService();
        Object.keys(tempDriver).forEach(property => {
            let tempquestionAnswer: QuestionAnswer = questionAnswers.find(x => x.key == property);
            if (!!tempquestionAnswer) {
                let questionAnswer = emptyModal.initializeFrom(tempquestionAnswer);
                if (questionAnswer.answer == null || questionAnswer.answer == undefined) questionAnswer.answer = '';
                if (property == 'dob' || property == 'originalLicenseDate' || property == 'dateOfHire') {
                    questionAnswer.answer = ds.getCorrectDate(questionAnswer.answer);
                }
                tempDriver[property] = questionAnswer;
            }
        });
        return tempDriver;
    }

    static createNewInstanceFromQuestionAnswers(questionAnswers: Array<QuestionAnswer>): Driver {
        let firstQuestionAnswerSet: Array<QuestionAnswer> = questionAnswers.filter(x => x.rowIndex == 1);
        let tempDriver: Driver = this.instantiateFromSingleQuestionAnswerSet(firstQuestionAnswerSet);
        Object.keys(tempDriver).forEach(property => {
            tempDriver[property].answer = "";
        });
        return tempDriver;
    }

    public toQuestionAnswers(rowIndex: number = 0): Array<QuestionAnswer> {
        let questionAnswers: Array<QuestionAnswer> = [];
        Object.keys(this).forEach(property => {
            if (!(this[property] instanceof Object) || !!!this[property].key) {
                return;
            }
            // setting driver's year of experience
            if (this[property].key == 'yearsOfExperience') {
                let ds = new DateService();
                if (!isNaN(ds.getCorrectDate(this.originalLicenseDate.answer).getTime())) {
                    this[property].answer = (new Date().getFullYear() - ds.getCorrectDate(this.originalLicenseDate.answer).getFullYear()).toString();
                }
            }
            this[property].rowIndex = rowIndex;
            questionAnswers.push(this[property]);
        });
        return questionAnswers;
    }
}