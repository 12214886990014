import { AbstractControl, NG_VALIDATORS } from '@angular/forms'
import { Directive } from '@angular/core';

@Directive({
  selector: '[positiveIntegersOnly]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PositiveIntegersOnlyDirective, multi: true }
  ]
})
export class PositiveIntegersOnlyDirective {
  constructor() { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    let value = c.value;    
    if (!!!Number(value) || !!!Number.isSafeInteger(Number(value)) || Number(value) < 0) {
        return { 'positiveIntegersOnly': true, 'errmsg': 'Please enter positive number without decimals' }        
    }
    return null;
  }
}
