import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthClientConfig, AuthConfig, AuthConfigService } from '@auth0/auth0-angular';

@Injectable()
export class AppConfig {

    public config: Object = null;    

    constructor(private httpClient: HttpClient, public authClientConfig: AuthClientConfig) {        
    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        return this.config[key];
    }

    public getConfigFromObject(object: string, prop: string) {
        if(!!this.config[object])
            return this.config[object][prop];
        return null;
    }

    /**
     * Use to get the data found in the first file (environment file)
     */
    public get environment():string {
        return this.getConfig("env");
    }

    public get auth0Enabled() {
        return this.getConfigFromObject("auth0","enabled");
    }

    public get serviceUrlKong() {
        return this.getConfigFromObject("auth0","serviceUrlKong");
    }

    public load() {
        return new Promise((resolve, reject) => {

                let request:any = null;
                let salt = (new Date()).getTime();//use salt to stop httpclient caches
                request = this.httpClient.get('./configurations/appsettings.json?'+salt);                   
                if (request) {
                    request
                        .map(
                             res =>
                              res )
                        .catch((error: any) => {
                            console.error('Error reading configurations/appsetting.json file');
                            resolve(error);
                            return Observable.throw(error.json().error || 'Server error');
                        })
                        .subscribe((responseData) => {
                            this.config = responseData;
                            this.authClientConfig.set({ 
                                clientId: responseData.auth0.clientId, 
                                domain: responseData.auth0.domain,
                                authorizationParams: responseData.auth0.authorizationParams
                                });
                            resolve(true);
                        });
                } else {
                    console.error('environmentconfig file "environment.json" is not valid');
                    resolve(true);
                }
            });
    }
}
