import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'any'
})

export class StateSettings {
    public getStateSpecificValidationMessage: Array<{ state: string, key: string, errorMessage: string }> =
        [
            { state: 'OH', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limits must be selected' },
            { state: 'OH', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limits must be selected or rejected' },
            { state: 'OH', key: 'sgPayroll', errorMessage: 'The Stop Gap Payroll amount must be greater than or equal to 36,900' },
            { state: 'GA', key: 'atFaultLiab', errorMessage: 'At-Fault Liability Limit Type must be selected' },
            { state: 'GA', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury must be selected' },
            { state: 'GA', key: 'umBiDed', errorMessage: 'Uninsured Motorist - Bodily Injury deductible must be selected ' },
            { state: 'GA', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected ' },
            { state: 'GA', key: 'umPdPrd', errorMessage: 'Uninsured Motorist - Combined Bodily Injury /Property Damage Limit must be selected' },
            { state: 'GA', key: 'umPdPrdDed', errorMessage: 'Uninsured Motorist - Combined Bodily Injury /Property Damage Deductible Limit must be selected ' },
            { state: 'KS', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'KS', key: 'addedPIP', errorMessage: 'Added Personal Injury Protection limit must be selected' },
            { state: 'KY', key: 'tortLimits', errorMessage: 'This option is not valid when Tort Limitation is rejected' },
            { state: 'KY', key: 'um/uim', errorMessage: 'UM BI and UIM BI limits must match' },
            { state: 'KY', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'KY', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected or rejected' },
            { state: 'KY', key: 'addedPIP', errorMessage: 'Added Personal Injury Protection limit must be selected' },
            { state: 'ME', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'MO', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'MO', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected or rejected' },
            { state: 'WI', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'WI', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected or rejected' },
            { state: 'NE', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'NE', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'UT', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'UT', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'UT', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'UT', key: 'addedPIP', errorMessage: 'Added Personal Injury Protection limit must be selected' },
            { state: 'UT', key: 'hiredAutoUimBi', errorMessage: 'Hired Auto Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'OK', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'MD', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'VA', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'VA', key: 'medExpenseBenefits', errorMessage: 'Medical Expense Benefits must be selected or rejected' },
            { state: 'VA', key: 'incomeLoss', errorMessage: 'Income Loss Benefits must be selected or rejected' },
            { state: 'FL', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'FL', key: 'addedPIP', errorMessage: 'Added Personal Injury Protection limit must be selected' },
            { state: 'FL', key: 'namedIndividual', errorMessage: 'Please enter the Named Individual details' },
            { state: 'FL', key: 'hiredAutoUmBi', errorMessage: 'Hired Auto Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'ID', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'ID', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'NM', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'LA', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'WV', key: 'uimPrd', errorMessage: 'Underinsured Motorist - Property Damage Limit must be selected' },
            { state: 'WV', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'WV', key: 'uimBiUimPd', errorMessage: 'Either Underinsured Motorist - Combined Bodily Injury/Property Damage limit or Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'SD', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'SD', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'ND', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'ND', key: 'uimBi', errorMessage: 'Underinsured Motorist - Bodily Injury limit must be selected' },
            { state: 'ND', key: 'sgPayroll', errorMessage: 'The Stop Gap Payroll amount must be greater than or equal to 47,100' },
            { state: 'ND', key: 'addedPIP', errorMessage: 'Added Personal Injury Protection limit must be selected' },
            { state: 'TX', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'NC', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'AL', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'OR', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
            { state: 'DE', key: 'addedPIP', errorMessage: 'Added Personal Injury Protection limit must be selected' },
            { state: 'DE', key: 'umPdLimit', errorMessage: 'Uninsured Motorist - Property Damage Limit must be selected' },
            { state: 'RI', key: 'umBiLimit', errorMessage: 'Uninsured Motorist - Bodily Injury limit must be selected' },
        ];
    public commonValidationMessages: Array<{ key: string, errorMessage: string }> =
        [
            { key: 'annualCostOfHire', errorMessage: 'Annual cost of hire starts at 5000 dollars' },
            { key: 'namedIndividual', errorMessage: 'Please enter the Named Individual details in the text box' },
            { key: 'umBiUmPd', errorMessage: 'Either Uninsured Motorist - Combined Bodily Injury/Property Damage limit or Uninsured Motorist - Bodily Injury limit must be selected' },
            { key: 'trailerDamageLimit', errorMessage: 'Maximum value per trailer for the physical damage limit cannot be less than $25,000 or more than $150,000' },
            { key: 'nonOwnedAutoLiabEmployeesCount', errorMessage: 'Total number of employees must be at-least 25' }
        ];
    public StateQuestionOptions: Array<{ code: string, helpText: string, label: string , sequence:number, value:string}> =
    [
      { "code": "AL", "helpText": "", "label": "AL", "sequence": 1, "value": "AL" },
      { "code": "AK", "helpText": "", "label": "AK", "sequence": 2, "value": "AK" },
      { "code": "AZ", "helpText": "", "label": "AZ", "sequence": 3, "value": "AZ" },
      { "code": "AR", "helpText": "", "label": "AR", "sequence": 4, "value": "AR" },
      { "code": "CA", "helpText": "", "label": "CA", "sequence": 5, "value": "CA" },
      { "code": "CO", "helpText": "", "label": "CO", "sequence": 6, "value": "CO" },
      { "code": "CT", "helpText": "", "label": "CT", "sequence": 7, "value": "CT" },
      { "code": "DC", "helpText": "", "label": "DC", "sequence": 8, "value": "DC" },
      { "code": "DE", "helpText": "", "label": "DE", "sequence": 9, "value": "DE" },
      { "code": "FL", "helpText": "", "label": "FL", "sequence": 10, "value": "FL" },
      { "code": "GA", "helpText": "", "label": "GA", "sequence": 11, "value": "GA" },
      { "code": "HI", "helpText": "", "label": "HI", "sequence": 12, "value": "HI" },
      { "code": "ID", "helpText": "", "label": "ID", "sequence": 13, "value": "ID" },
      { "code": "IL", "helpText": "", "label": "IL", "sequence": 14, "value": "IL" },
      { "code": "IN", "helpText": "", "label": "IN", "sequence": 15, "value": "IN" },
      { "code": "IA", "helpText": "", "label": "IA", "sequence": 16, "value": "IA" },
      { "code": "KS", "helpText": "", "label": "KS", "sequence": 17, "value": "KS" },
      { "code": "KY", "helpText": "", "label": "KY", "sequence": 18, "value": "KY" },
      { "code": "LA", "helpText": "", "label": "LA", "sequence": 19, "value": "LA" },
      { "code": "ME", "helpText": "", "label": "ME", "sequence": 20, "value": "ME" },
      { "code": "MD", "helpText": "", "label": "MD", "sequence": 21, "value": "MD" },
      { "code": "MA", "helpText": "", "label": "MA", "sequence": 22, "value": "MA" },
      { "code": "MI", "helpText": "", "label": "MI", "sequence": 23, "value": "MI" },
      { "code": "MN", "helpText": "", "label": "MN", "sequence": 24, "value": "MN" },
      { "code": "MS", "helpText": "", "label": "MS", "sequence": 25, "value": "MS" },
      { "code": "MO", "helpText": "", "label": "MO", "sequence": 26, "value": "MO" },
      { "code": "MT", "helpText": "", "label": "MT", "sequence": 27, "value": "MT" },
      { "code": "NE", "helpText": "", "label": "NE", "sequence": 28, "value": "NE" },
      { "code": "NV", "helpText": "", "label": "NV", "sequence": 29, "value": "NV" },
      { "code": "NH", "helpText": "", "label": "NH", "sequence": 30, "value": "NH" },
      { "code": "NJ", "helpText": "", "label": "NJ", "sequence": 31, "value": "NJ" },
      { "code": "NM", "helpText": "", "label": "NM", "sequence": 32, "value": "NM" },
      { "code": "NY", "helpText": "", "label": "NY", "sequence": 33, "value": "NY" },
      { "code": "NC", "helpText": "", "label": "NC", "sequence": 34, "value": "NC" },
      { "code": "ND", "helpText": "", "label": "ND", "sequence": 35, "value": "ND" },
      { "code": "OH", "helpText": "", "label": "OH", "sequence": 36, "value": "OH" },
      { "code": "OK", "helpText": "", "label": "OK", "sequence": 37, "value": "OK" },
      { "code": "OR", "helpText": "", "label": "OR", "sequence": 38, "value": "OR" },
      { "code": "PA", "helpText": "", "label": "PA", "sequence": 39, "value": "PA" },
      { "code": "RI", "helpText": "", "label": "RI", "sequence": 40, "value": "RI" },
      { "code": "SC", "helpText": "", "label": "SC", "sequence": 41, "value": "SC" },
      { "code": "SD", "helpText": "", "label": "SD", "sequence": 42, "value": "SD" },
      { "code": "TN", "helpText": "", "label": "TN", "sequence": 43, "value": "TN" },
      { "code": "TX", "helpText": "", "label": "TX", "sequence": 44, "value": "TX" },
      { "code": "UT", "helpText": "", "label": "UT", "sequence": 45, "value": "UT" },
      { "code": "VT", "helpText": "", "label": "VT", "sequence": 46, "value": "VT" },
      { "code": "VA", "helpText": "", "label": "VA", "sequence": 47, "value": "VA" },
      { "code": "WA", "helpText": "", "label": "WA", "sequence": 48, "value": "WA" },
      { "code": "WV", "helpText": "", "label": "WV", "sequence": 49, "value": "WV" },
      { "code": "WI", "helpText": "", "label": "WI", "sequence": 50, "value": "WI" },
      { "code": "WY", "helpText": "", "label": "WY", "sequence": 51, "value": "WY" }
    ];
}
