<form [formGroup]="lossInfoGridFormGroup">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <p-table class="table llh_table" [rows]="15" [value]="lossInfoGridFormGroup.lossInfoGrid">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center">Prior Policy Period Effective Date</th>
                                <th class="text-center">Prior Policy Period Expiration Date</th>
                                <th class="text-center">Historical Power Units - Auto Liability</th>
                                <th>Historical Power Units - Auto Physical Damage</th>
                            </tr>
                        </ng-template>
                        <ng-template let-i=rowIndex pTemplate="body">
                            <tr>
                                <td>
                                    <p-calendar dateFormat="mm/dd/yy" [showIcon]="true"
                                        inputId="effectiveDate_{{i + 1}}" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2000:2050"
                                        [class.validate_error_cls_dp]="isSubmitted && lossInfoGridFormGroup.get('effectiveDate_' + (i + 1))?.invalid"
                                        placeholder="MM / DD / YYYY" id="effectiveDate_{{i + 1}}"
                                        appendTo="body" formControlName="effectiveDate_{{i + 1}}"
                                        [minDate]="lossInfoGridFormGroup.minLossHistoryDate" [maxDate]="lossInfoGridFormGroup.maxLossHistoryDate">
                                    </p-calendar>
                                </td>
                                <td>
                                    <p-calendar dateFormat="mm/dd/yy" [showIcon]="true"
                                        inputId="expirationDate_{{i + 1}}" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="2000:2050"
                                        [class.validate_error_cls_dp]="isSubmitted && lossInfoGridFormGroup.get('expirationDate_' + (i + 1))?.invalid"
                                        placeholder="MM / DD / YYYY"
                                        id="expirationDate_{{i + 1}}" appendTo="body"
                                        formControlName="expirationDate_{{i + 1}}" [minDate]="lossInfoGridFormGroup.minLossHistoryDate"
                                        [maxDate]="lossInfoGridFormGroup.maxLossHistoryDate">
                                    </p-calendar>
                                </td>
                                <td>
                                    <input class="form-control"
                                        [class.validate_error_cls]="isSubmitted && lossInfoGridFormGroup.get('liabHistoricalPowerUnits_' + (i + 1))?.invalid"
                                        placeholder="Enter power unit count" type="text"
                                        id="liabHistoricalPowerUnits_{{i + 1}}"
                                        formControlName="liabHistoricalPowerUnits_{{i + 1}}"
                                        (keypress)="numbersOnly($event)" [numbersOnly]>
                                </td>
                                <td>
                                    <input class="form-control"
                                        [class.validate_error_cls]="isSubmitted && lossInfoGridFormGroup.get('physicalDamagePUCount_' + (i + 1))?.invalid"
                                        placeholder="Enter power unit count" type="text"
                                        id="physicalDamagePUCount_{{i + 1}}"
                                        formControlName="physicalDamagePUCount_{{i + 1}}"
                                        (keypress)="numbersOnly($event)" [numbersOnly]>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</form>