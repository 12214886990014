import { Component, Input, OnInit } from "@angular/core";
import { LiabilityAndPhysicalDamageFormGroup } from "./LiabilityAndPhysicalDamageFormGroup";
import { ServiceLayer } from "src/app/services/serviceLayer";

@Component({
    selector: 'liability-and-physical-damage',
    templateUrl: './liability-and-physical-damage.component.html'
})

export class LiabilityAndPhysicalDamageComponent implements OnInit {
    @Input() lossInfoGridFormGroup: LiabilityAndPhysicalDamageFormGroup;
    @Input() isSubmitted: boolean;

    constructor(private serviceLayer: ServiceLayer) { }

    public ngOnInit(): void {
    }

    public numbersOnly(event: Event): boolean {
        return this.serviceLayer.numbersOnly(event, 'loss');
    }
}