import { NG_VALIDATORS, AbstractControl } from '@angular/forms'
import { Directive, Input } from '@angular/core';
import _ from 'lodash';
import { StateDriverLicenseSettings } from '../modal/state.driversLicense.models';

@Directive({
  selector: '[licenseNumberValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: LicenseNumberDirective, multi: true }
  ]
})
export class LicenseNumberDirective {
  constructor(
    private stateDriverLicenseSettings: StateDriverLicenseSettings) { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    if (!!!c.value.licenseStateCode || !!!c.value.licensePermitNumber) {
      return null;
    }
    let xpressionData = this.stateDriverLicenseSettings.DriverLicenseQuestionOptions.filter(d => d.code == c.value.licenseStateCode);
    if (!!xpressionData && xpressionData.length > 0) {
      let pattern = new RegExp(xpressionData[0].expression);
      if (!pattern.test(c.value.licensePermitNumber)) {
        let errormsg: string = 'License number format is invalid for the state ' + c.value.licenseStateCode;
        return { 'invalidLicenseNumber': true, 'errmsg': errormsg }
      }
    }
    return null;
  }
}
