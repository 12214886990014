<p-toast position="center" key="sessionTimeout" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1; padding: 3px;">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <div [innerHTML]="message.summary">{{message.summary}}</div><br />

                <div class="p-grid p-fluid">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <button type="button" class="warnBtnSuccess" title="OK" name="btnok" id="btnokID"
                            (click)="respondedToSessionTimeout()">
                            <i class="fa fa-thumbs-up" aria-hidden="true" tooltip="OK"></i>
                            OK
                        </button>
                    </div>
                    <div class="col-sm-3"></div>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>