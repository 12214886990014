<div class="container">
    <div class="vehiclescrn main_content mt-2">
        <form #driverForm="ngForm" style="width:100%">
            <div class="row mt-0">
                <div class="col-lg-3 col-md-4">
                    <listbox header="Driver's List" listName="Driver"
                        [labelFormat]="'{firstName.answer} {middleName.answer} {lastName.answer}'" [itemList]="driverModel.driverList"
                        [activeItemIndex]="driverModel?.driverIndex" [maxItemLimit]="30"
                        [blankDataValidationProperties]="['firstName.answer', 'lastName.answer']" (addItemButtonClicked)="addDriver(driverForm)"
                        (removeItemButtonClicked)="onRemoveButtonClicked($event)" (itemClicked)="onItemClick(driverForm, $event)">
                    </listbox>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <span class="text-lg-right float-lg-right">
                        <button type="button" class="btnCancelSub" name="btncancelmySubmissionName"
                            id="btncancelmySubmissionID" title="Cancel my Submission"
                            (click)="cancelMySubmissionPopUp()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Cancel my Submission
                        </button>
                    </span>
                    <span class="text-lg-left float-lg-left" style="padding-left: 5px;">
                        <button type="button" name="btnSampleDLDownload" id="btnSampleDLDownloadID"
                            class="innerSubButton" title="Download Sample Driver Template" data-toggle="modal"
                            (click)="downloadSampleDriverTemplate('SampleDriverTemplate', 'BSB_DriverUploadTemplate')">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Download Sample Driver Template
                        </button>
                    </span>
                    
                    <div class="clearfix"></div>

                    <div class="vehicle_details1 mt-4" *ngIf="driverModel?.driverPage == 1">
                        <div class="card">
                            <div class="card-header">
                                <h4 *ngIf="driverModel?.currentDriver?.firstName?.answer" class="mb-1">
                                    <b>{{driverModel?.currentDriver?.firstName?.answer}}
                                        {{driverModel?.currentDriver?.middleName?.answer}}
                                        {{driverModel?.currentDriver?.lastName?.answer}}</b>
                                </h4>
                                <h4 *ngIf="!driverModel?.currentDriver?.firstName?.answer" class="mb-1"><b>New
                                        Driver</b>
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="row mb-1" *ngIf="!driverModel?.driverDefault?.firstName?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblFirstName" id="lblFirstNameID">First Name:</label>
                                        <span *ngIf="driverModel?.driverDefault?.firstName?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="First Name"
                                            [class.validate_error_cls]="(driverForm.submitted || dao?.isSubmitForm || !firstName.pristine) && !firstName.valid"
                                            class="form-control"
                                            [(ngModel)]="driverModel.currentDriver.firstName.answer" type="text"
                                            name="txtfirstName" id="txtfirstNameID" #firstName="ngModel"
                                            [alphaCharactersOnly]
                                            [required]="driverModel?.driverDefault?.firstName?.required">
                                        <span class="validationred"
                                            *ngIf="(driverForm.submitted || !firstName.pristine) && firstName.errors?.alphaCharactersOnly">
                                             Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="!driverModel?.driverDefault?.middleName?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblMiddle" id="lblMiddleID">Middle:</label>
                                        <span *ngIf="driverModel?.driverDefault?.middleName?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="Middle Name"
                                            [class.validate_error_cls]="(driverForm.submitted || dao?.isSubmitForm || !middleName.pristine) && !middleName.valid"
                                            class="form-control"
                                            [(ngModel)]="driverModel.currentDriver.middleName.answer" type="text"
                                            name="txtmiddleName" id="txtmiddleNameID" #middleName="ngModel"
                                            [alphaCharactersOnly]
                                            [required]="driverModel?.driverDefault?.middleName?.required">
                                            <span class="validationred"
                                            *ngIf="(driverForm.submitted || !middleName.pristine) && middleName.errors?.alphaCharactersOnly">
                                             Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-1" *ngIf="!driverModel?.driverDefault?.lastName?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblLast" id="lblLastID">Last:</label>
                                        <span *ngIf="driverModel?.driverDefault?.lastName?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="Last Name"
                                            [class.validate_error_cls]="(driverForm.submitted || dao?.isSubmitForm || !lastName.pristine) && !lastName.valid"
                                            class="form-control"
                                            [(ngModel)]="driverModel.currentDriver.lastName.answer" type="text"
                                            name="txtlastName" id="txtLastID" #lastName="ngModel"
                                            [alphaCharactersOnly]
                                            [required]="driverModel?.driverDefault?.lastName?.required">
                                            <span class="validationred"
                                            *ngIf="(driverForm.submitted || !lastName.pristine) && lastName.errors?.alphaCharactersOnly">
                                             Special characters and numerics are not allowed
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-1" *ngIf="!driverModel?.driverDefault?.suffix?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblSuffix" id="lblSuffixID">Suffix:</label>
                                        <span *ngIf="driverModel?.driverDefault?.suffix?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-dropdown placeholder="Select Suffix"
                                            [options]="nameSuffixDropdownValues"
                                            name="ddsuffix" id="ddsuffixID"
                                            [(ngModel)]="driverModel.currentDriver.suffix.answer" #suffix="ngModel"
                                            [required]="driverModel?.driverDefault?.suffix?.required">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="!driverModel?.driverDefault?.dob?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lbldob" id="lbldobID">Date Of Birth:</label>
                                        <span *ngIf="driverModel?.driverDefault?.dob?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon1"
                                            [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:2050"
                                            [class.validate_error_cls_dp]="(driverForm.submitted || dao?.isSubmitForm) && !dob.valid"
                                            placeholder="MM / DD / YYYY"
                                            [(ngModel)]="driverModel.currentDriver.dob.answer" name="clddob"
                                            id="clddobID" #dob="ngModel"
                                            [required]="driverModel?.driverDefault?.dob?.required"
                                            (onSelect)="selectDob()" (onBlur)="selectDob()" [maxDate]="yesterday">
                                        </p-calendar>
                                    </div>
                                </div>
                                <div class="row mb-1"
                                    *ngIf="!driverModel?.driverDefault?.licenseStateCode?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblDriversLicenseState" id="lblDriversLicenseStateID">Drivers
                                            License State:</label>
                                        <span *ngIf="driverModel?.driverDefault?.licenseStateCode?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-dropdown
                                            [class.validateDrodown]="(driverForm.submitted || dao?.isSubmitForm) && !licenseStateCode?.valid"
                                            placeholder="Select Drivers License State"
                                            [options]="stateSettings.StateQuestionOptions"
                                            name="licenseStateCode" id="ddDriversLicenseStateID"
                                            [(ngModel)]="driverModel.currentDriver.licenseStateCode.answer"
                                            #licenseStateCode="ngModel"
                                            [required]="driverModel?.driverDefault?.licenseStateCode?.required">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="row mb-1"
                                    *ngIf="!driverModel?.driverDefault?.licensePermitNumber?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblDriversLicenseNumber" id="lblDriversLicenseNumberID">Drivers
                                            License Number:</label>
                                        <span *ngIf="driverModel?.driverDefault?.licensePermitNumber?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <input placeholder="Driver License Number"
                                            [class.validate_error_cls]="(driverForm.submitted || dao?.isSubmitForm) && !licensePermitNumber.valid"
                                            class="form-control"
                                            [(ngModel)]="driverModel.currentDriver.licensePermitNumber.answer"
                                            type="text" name="txtlicensePermitNumber" id="txtDriversLicenseNumberID"
                                            #licensePermitNumber="ngModel"
                                            [required]="driverModel?.driverDefault?.licensePermitNumber?.required">
                                    </div>
                                </div>
                                <div class="row mb-1"
                                    *ngIf="!driverModel?.driverDefault?.originalLicenseDate?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblOriginalDateLicensed" id="lblOriginalDateLicensedID">Original
                                            Date Licensed:</label>
                                        <span *ngIf="driverModel?.driverDefault?.originalLicenseDate?.required"
                                            style="color: red;">*</span>
                                        <span class="business_owner_icon"><i class="fa fa-question-circle"
                                                pTooltip="Original date licensed is the date the driver was first licensed to the drive with operators license (not CDL)"
                                                aria-hidden="true"></i></span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon2"
                                            [monthNavigator]="true" [yearNavigator]="true"
                                            yearRange="1900:2050"
                                            [class.validate_error_cls_dp]="(driverForm.submitted || dao?.isSubmitForm) && !originalLicenseDate.valid"
                                            placeholder="MM / DD / YYYY"
                                            [minDate]="driverModel?.minOriginalLicenseDate"
                                            [(ngModel)]="driverModel.currentDriver.originalLicenseDate.answer"
                                            name="originalLicenseDate" id="cldOriginalDateLicensedID"
                                            #originalLicenseDate="ngModel"
                                            [required]="driverModel?.driverDefault?.originalLicenseDate?.required">
                                        </p-calendar>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="!driverModel?.driverDefault?.dateOfHire?.IsHidden">
                                    <div class="col-lg-6">
                                        <label name="lblDateofHire" id="lblDateofHireID">Date of Hire:</label>
                                        <span *ngIf="driverModel?.driverDefault?.dateOfHire?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <p-calendar dateFormat="mm/dd/yy" [showIcon]="true" inputId="calendarIcon3"
                                            [monthNavigator]="true" [yearNavigator]="true"
                                            yearRange="1900:2050"
                                            [class.validate_error_cls_dp]="(driverForm.submitted || dao?.isSubmitForm) && !dateOfHire.valid"
                                            placeholder="MM / DD / YYYY"
                                            [(ngModel)]="driverModel.currentDriver.dateOfHire.answer"
                                            name="dateOfHire" #dateOfHire="ngModel" id="cldDateofHire"
                                            [required]="driverModel?.driverDefault?.dateOfHire?.required">
                                        </p-calendar>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-lg-9 text-lg-right" style="padding: 0px;">
                                        <button type="button" name="btnDVUpload" id="btnDVUploadID"
                                            class="innerSubButton" title="Upload Driver List" data-toggle="modal"
                                            (click)="fileUpload(driverForm, $event)" type="file"
                                            *ngIf="isValidToShowDriverAddButton">
                                            <i class="fa fa-upload" aria-hidden="true"></i>
                                            Upload Driver List
                                        </button>
                                        <p-fileUpload name="demo[]" id="demoID[]" class=" UploadCls driver-upload"
                                            (onSelect)="confirmUpload($event)" (click)="clickUpload(driverForm,$event)"
                                            accept=".xlsx, .xls" multiple="multiple" maxFileSize="15728640"
                                            showButtons="false" invalidFileTypeMessageSummary="{0}: Invalid file type.">
                                        </p-fileUpload>
                                    </div>

                                    <div class="col-lg-3">
                                        <span class="text-lg-right float-lg-right">
                                            <button type="submit" name="btnDVNext" id="btnDVNextID"
                                                class="innerSubButton" title="Add New Driver" data-toggle="modal"
                                                (click)="addDriver(driverForm)"
                                                *ngIf="isValidToShowDriverAddButton">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                Add New Driver
                                            </button>
                                        </span>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-lg-4" *ngIf="driverModel.driverList.length > 1 && driverModel.driverIndex != 0">
                                        <span class="text-lg-right float-lg-left">
                                            <button type="submit" name="btnPreviousDriver" id="btnPreviousDriverID"
                                                class="innerSubButton" title="Previous Driver" data-toggle="modal"
                                                (click)="clickedAdjacentDriver(driverForm, driverModel.driverIndex,'PreviousDriver')">
                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                Previous Driver
                                            </button>
                                        </span>
                                    </div>

                                    <div class="{{driverModel.driverList.length > 1 && driverModel.driverIndex != 0 ? 'col-lg-8' : 'col-lg-12'}}" *ngIf="driverModel.driverList.length > 1 && driverModel.driverIndex != this.driverModel.driverList.length - 1">
                                        <span class="text-lg-left float-lg-right">
                                            <button type="submit" name="btnNextDriver" id="btnNextDriverID"
                                                class="innerSubButton" title="Next Driver" data-toggle="modal"
                                                (click)="clickedAdjacentDriver(driverForm, driverModel.driverIndex,'NextDriver')">
                                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                Next Driver
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12 text-center">
                    <div class="col-lg-12 p-0 mt-3">
                        <button type="button" class="btnerror float-left" title="Back" name="btnDVBack" id="btnDVBackID"
                            (click)="onBack('vehicles')">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            Back
                        </button>
                        <span class="text-lg-right float-lg-right">
                            <button type="submit" name="btnDVSave" id="btnsaveContinueID"
                                (click)="saveAndContinue(driverForm)"
                                class="btnsuccess" title="Save & Continue" data-toggle="modal">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Save & Continue
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-toast key="myKey1" position="top-center"></p-toast>
    <p-toast position="center" key="c" (onClose)="onReject('c')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" name="btnDVCancelSubPopUpYes" id="btnDVCancelsubPopUpYesID" pButton
                            (click)="CancelmySubmission()" label="Yes" class="warnPBtnSuccess"></button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" pButton name="btnDVCancelSubPopUpNo" id="btnDVCancelsubPopUpNoID"
                            (click)="onReject('c')" label="No" class="warnPBtnError"></button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="remove" (onClose)="onReject('remove')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" name="btnDVconfirmRemoveYes"
                            id="btnDVconfirmRemoveYesID" title="Yes"
                            (click)="confirmRemove();messageService.clear('remove');">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnDVconfirmRemoveNo"
                            id="btnDVconfirmRemoveID" (click)="onReject('remove')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="ok" (onClose)="onReject('ok')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1; padding: 3px;;">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <div [innerHTML]="message.summary">{{message.summary}}</div><br />

                    <div class="p-grid p-fluid">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <button type="button" class="warnBtnSuccess" title="OK" name="btnok" id="btnokID"
                                (click)="onReject('ok')">
                                <i class="fa fa-thumbs-up" aria-hidden="true" tooltip="OK"></i>
                                OK
                            </button>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>