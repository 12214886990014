import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService, User } from '@rss/ng-auth-service';
import { Component, OnDestroy } from '@angular/core';
import { IAuthenticatedUserInfo, JwtTokenUserInfo } from '../../modal/user.models'

@Component({
    template: '',
})
export class AuthenticatedUserComponent implements OnDestroy {

    protected unsubscriber = new Subject();
    private user: User;
    private userLoaded = new BehaviorSubject<boolean>(false);

    constructor(protected authenticationService: AuthenticationService) {
        this.listenForAuthenticationStateChanges();
    }

    public ngOnDestroy(): void {
        this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    protected onUserDataLoaded$(): Observable<boolean> {
        return this.userLoaded.asObservable();
    }

    private listenForAuthenticationStateChanges(): void {
        this.authenticationService.user$.pipe(takeUntil(this.unsubscriber)).subscribe((user: User) => {
            if (user) {
                if (!this.user || this.user.email !== user.email) {
                    this.user = user;
                    this.userLoaded.next(true);
                }
            }
            else {
                this.user = null;
                this.userLoaded.next(false);
            }
        });
    }

    public get userAgencyCode(): string | null {
        let userDetails: IAuthenticatedUserInfo = JwtTokenUserInfo.getUserDetails(this.authenticationService.jwt);
        return userDetails?.agencycode;
    }

    public get userInfo(): User | null {
        return this.user;
    }

    public get userEmail(): string | null {
        return this.user?.email;
    }

    public get userFullName(): string {
        return `${this.user?.given_name} ${this.user?.family_name}`.trim();
    }

    public get isAdmin(): boolean {
        return !!this.user?.role?.find(r => r === 'admin');
    }

    public get isInternalUser(): boolean {
        if (this.user?.winaccountname?.includes('\\')) {
            const nameArray = this.user.winaccountname.replace('\\', '//').split('//');
            return nameArray[0].toUpperCase() === 'WRBTS';
        }
        return false;
    }
}