import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { emptyModal } from 'src/app/modal/DAO';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes, PortalStatus } from 'src/app/modal/Endorsements/Endorsement.Model';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { CommonService } from 'src/app/services/commonService';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { DocumentModel } from 'src/app/modal/DocumentModel';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { Subscription } from 'rxjs';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'other-endorsements',
  templateUrl: './otherEndorsements.component.html'
})
export class OtherEndorsementsComponent implements OnInit, OnDestroy, IBasePage {

  public otherEndorsementsForm: FormGroup;
  private endorsementQuestionGroup: EndorsementQuestionGroup;
  private menuClickSubscription: Subscription;
  private readonly toastValidation: string = 'Please fill the required fields highlighted in red';
  public fileNetAllowedFileExtensions = Constants.FileNetAllowedFileExtensions.toString();
  public isSubmitted: boolean = false;
  public endorsement: Endorsement = new Endorsement();
  public otherEndorsement: OtherEndorsement = new OtherEndorsement();
  private menuClickContext: MenuClickContext;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private fileuploadService: FileuploadService,
    private commonService: CommonService,
    public router: Router,
    private endorsementService: EndorsementService,
    private documentService: DocumentService
  ) {
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
  }

  ngOnInit(): void {
    this.initializeData();
    this.menuClickSubscription = this.endorsementService.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
  }

  private async getQuestionAnswers(): Promise<Questionnaire> {
    let payload = { "urlData": [this.endorsement.endorsementId, EndorsementTypes.otherEndorsements] };
    return this.commonService.get('endorsement', 'getQuestionAnswers', payload).toPromise()
      .then(questionAnswer => {
        const questionnaire: Questionnaire = new Questionnaire();
        questionnaire.key = EndorsementTypes.otherEndorsements;
        questionnaire.questionAnswer = questionAnswer
        return questionnaire;
      })
      .catch(error => Promise.reject(error));
  }

  public async initializeData(): Promise<void> {
    await this.endorsementService.reloadQuestionGroups(this.endorsement);
    this.endorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.otherEndorsements);
    this.getQuestionAnswers().then(async questionnaire => {
      if (!!questionnaire.questionAnswer && questionnaire.questionAnswer.length > 0) {
        this.endorsementQuestionGroup.questionAnswers = questionnaire.questionAnswer;
        this.otherEndorsement.instantiateFrom(questionnaire);
        this.buildReactiveForm();
      }
    }).catch(error => {
      console.error(error);
    });
  }

  private buildReactiveForm(): void {
    if (!!!this.otherEndorsement) return;

    const fileControls = this.otherEndorsement.files.map(file => {
      return this.formBuilder.group({
        fileName: [file.fileName],
        filesize: [file.filesize],
        guid: [file.guid]
      });
    });

    this.otherEndorsementsForm = this.formBuilder.group({
      notes: [this.otherEndorsement.notes.answer, this.otherEndorsement.notes.required ? Validators.required : null],
      files: this.formBuilder.array(fileControls)
    });

    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    this.otherEndorsementsForm.valueChanges.subscribe((formValue) => {
      if (!!this.otherEndorsement) {
        this.otherEndorsement.notes.answer = formValue.notes;

        const filesFormArray = this.otherEndorsementsForm.get('files') as FormArray;
        filesFormArray.valueChanges.subscribe((files: any[]) => {
          this.otherEndorsement.files = [];
          files.forEach(file => {
            this.otherEndorsement.files.push({
              fileName: file.fileName,
              filesize: file.filesize,
              guid: file.guid
            });
          });
        });
      }
    });
  }

  public menuClicked(menuClickContext: MenuClickContext): void {
    this.menuClickContext = menuClickContext;
    if (menuClickContext != null && menuClickContext.page != EndorsementTypes.otherEndorsements) {
      if (!this.otherEndorsementsForm) {
        this.menuClickContext.page = EndorsementTypes.otherEndorsements;
        return;
      }
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = this.otherEndorsementsForm.touched;
      if (this.endorsementService.isMenuClickHeaderPage(menuClickContext)) {
        this.menuClickContext.page = EndorsementTypes.otherEndorsements;
        return;
      }
      this.endorsementService.handleUnsavedChangesOrNavigateToUrl(menuClickContext, this.endorsement);
    }
  }

  public isPageValid(): boolean {
    return this.otherEndorsementsForm.valid;
  }

  public onRollback(currentTempage: string): void { }

  public onBack(page: string): void { }

  public saveAndContinue(type = ""): void {
    this.isSubmitted = true;
    if (this.isPageValid()) {
      this.postQuestionAnswers().then(isSuccess => {
        if (isSuccess) {
          this.isSubmitted = false;
          let currrentEndorsementQuestionGroup : EndorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.otherEndorsements);
          currrentEndorsementQuestionGroup.hasAnswers = true;
          currrentEndorsementQuestionGroup.isValidPage = true;
          this.endorsement.userIsAt = '';
          if (type === "finalSubmit") {
              this.endorsementService.updateStatusToSubmitted(this.endorsement.endorsementId);
          }
          else if (type === "draft") {
            this.router.navigate(['/home'], { state: { data: 'endorsement' } });
          }
        }
      }).catch(error => {
        console.error(error);
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Other Endorsements', detail: error });
      })
    }
    else
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Other Endorsements', detail: this.toastValidation });
  }

  private async postQuestionAnswers(): Promise<boolean> {
    this.bindAnswersToQuestions();
    let payload = { "payloadData": this.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'postQuestionAnswers', payload).toPromise()
      .then(result => result)
      .catch(error => Promise.reject(error));
  }

  public bindAnswersToQuestions(): void {
    let questionAnswers: QuestionAnswer[] = this.otherEndorsement.toQuestionAnswers(this.endorsementQuestionGroup.questionAnswers);
    this.endorsementQuestionGroup.questionAnswers = questionAnswers;
  }

  public onUpload(event: any): void {
    let response = this.fileuploadService.onUploadingFiles(event.files, this.otherEndorsement.files, 'endorsement', this.endorsementQuestionGroup.endorsementId.toString());
    if (!!response) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Other Endorsements', detail: !!response ? response : this.toastValidation });
    }
  }

  public clickUpload(event: any): void {
    if (event && event.target && event.target.className == 'p-fileupload-content') {
      this.fileUpload();
    }
  }

  public fileUpload(): void {
    const fileInput = document.querySelector('.p-fileupload-choose') as HTMLElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  public deleteFile(item: any, index: number): void {
    const delData = { guid: item?.id ?? item?.guid, index: index }
    const strData = JSON.stringify(delData);
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: ' Do you want to delete ?', data: strData });
  }

  public confirmDelete(delData: string): void {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
    this.deletingDocuments(delData);
  }

  private deletingDocuments(delData: string): void {
    let jsonObject = JSON.parse(delData);
    let payload = { "urlData": [jsonObject.guid] }
    this.commonService.get('delete', 'FileUpload', payload).subscribe(
      (event: any) => {
        let endorsementFilesArray = this.otherEndorsement.files;
        endorsementFilesArray.splice(jsonObject.index, 1);
      },
      (error) => {
        console.error('HTTP error deleting endorsement file', error);
      }
    );
  }

  public onReject(type: string): void {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
  }

  ngOnDestroy(): void {
    if (!!this.menuClickSubscription) {
      this.menuClickSubscription.unsubscribe();
    }
  }
}

class OtherEndorsement {
  public notes: emptyModal = new emptyModal();
  public files: Array<DocumentModel> = [];

  public instantiateFrom(questionnaire: Questionnaire): void {
    this.notes = emptyModal.initializeFrom(questionnaire.findQuestion('notes'));
    this.buildUploadedFiles(questionnaire.questionAnswer)
  }

  private buildUploadedFiles(questionAnswers: QuestionAnswer[]): void {
    let uploadedFiles = questionAnswers.filter(x => x.key == 'uploadedDocuments' && !!x.answer);
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach(questionAnswer => {
        let document: DocumentModel = JSON.parse(questionAnswer.answer);
        this.files.push(document);
      })
    }
  }

  public toQuestionAnswers(questionAnswers: QuestionAnswer[]): QuestionAnswer[] {
    let tempQuestionAnswers: QuestionAnswer[] = [];
    tempQuestionAnswers.push(this.notes);
    let uploadedFilesQuestionsAnswer = questionAnswers.find(x => x.key == "uploadedDocuments");
    if (this.files.length > 0) {
      this.files.forEach((file, index) => {
        let newQuestionAnswer = emptyModal.initializeFrom(uploadedFilesQuestionsAnswer);
        newQuestionAnswer.answer = JSON.stringify(file);
        newQuestionAnswer.key = 'uploadedDocuments';
        newQuestionAnswer.rowIndex = index + 1;
        newQuestionAnswer.group = 'otherEndorsements';
        tempQuestionAnswers.push(newQuestionAnswer);
      });
    }
    return tempQuestionAnswers;
  }
}