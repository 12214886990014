import { AuthService, IdToken, User } from '@auth0/auth0-angular';
import { SubscribableOrPromise } from 'rxjs';

export interface IAuthenticatedUserInfo {
    email: string;
    given_name: string;
    family_name: string;
    role: Array<string>;
    agencycode: string;
    company: string[];
    isinternal: boolean;
}

export class JwtTokenUserInfo{

    public static getUserDetails(jwtToken : string): IAuthenticatedUserInfo | null {
        if (jwtToken != null) {
            let [_, payload] = jwtToken.split('.');
            if (!!payload) {
                let userInfo: IAuthenticatedUserInfo = JSON.parse(atob(payload));
                return userInfo
            }
        }
        return null;
    }
}

export class Auth0User extends User {
  constructor(user: User) {
    super();
    for (const property in user) {
      this[property] = user[property];
    }
  }
  public agencyCode: string;
  public isAdmin: boolean;
  public accessToken: string;
  public idToken: import("@auth0/auth0-spa-js").IdToken | null | undefined;
  public expireAt: number;
  public get isInternal(): boolean {
    return !!this.email?.includes('berkleysmallbusiness.com') || !!this.email?.includes('wrberkley.com');
  }
}
