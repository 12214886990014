import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AdditionalInsured } from 'src/app/modal/Endorsements/AdditionalInsured.Model';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { CommonService } from 'src/app/services/commonService';
import { dropDownOptions, emptyModal } from 'src/app/modal/DAO';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StateSettings } from 'src/app/modal/state.models';
import { MessageService } from 'primeng/api';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { ListViewModel } from '../../modal/Endorsements/ListView.Model'
import { Router } from '@angular/router';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { Subscription } from 'rxjs';
import { FileUploadFormComponent } from '../file-upload-form/file-upload-form.component';
import _ from 'lodash';

@Component({
  selector: 'delete-additional-insured',
  templateUrl: './delete-additional-insured.component.html',
  styleUrls: ['./delete-additional-insured.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class DeleteAdditionalInsuredComponent implements OnInit, OnDestroy, IBasePage {
  @ViewChild('fileUploadForm') fileUploadForm: FileUploadFormComponent;

  private readonly toastValidationMessage: string = 'Please fill required fields highlighted in red';
  private menuClickSubscription: Subscription;
  private menuClickContext: MenuClickContext;
  public lineOfBusinessOptions: dropDownOptions[] = Constants.LineOfBusinessOptions;
  public additionalInsuredList: ListViewModel<AdditionalInsured>;
  public endorsementQuestionGroup: EndorsementQuestionGroup;
  public additionalInsuredFrm: FormGroup;
  public isSubmitted: boolean = false;
  public endorsement: Endorsement = new Endorsement();

  constructor(
    public stateSettings: StateSettings,
    private commonService: CommonService,
    private messageService: MessageService,
    public router: Router,
    private fb: FormBuilder,
    private endorsementService: EndorsementService
  ) {
    this.additionalInsuredFrm = this.fb.group({});
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
  }

  ngOnInit(): void {
    this.initializeData();
    this.menuClickSubscription = this.endorsementService.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
  }

  // Initialize the Data
  public async initializeData(): Promise<void> {
    await this.endorsementService.reloadQuestionGroups(this.endorsement);
    this.endorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.DeleteAdditionalInsured);
    this.getQuestionAnswers().then(questionAnswers => {
      if (!!questionAnswers && questionAnswers.length > 0) {
        this.additionalInsuredList = new ListViewModel<AdditionalInsured>(
          AdditionalInsured.instantiateArrayFrom(questionAnswers),
          this.beforeAdd,
          this.afterAdd,
          this.afterDelete,
          this.initializeNewItem
        );
        this.endorsementQuestionGroup.questionAnswers = questionAnswers;
        this.buildReactiveForm();
      }
    }).catch(error => {
      console.error(error);
    });
  }

  // Build reactive form
  public buildReactiveForm(): void {
    let currentItem: AdditionalInsured = this.additionalInsuredList.currentItem;
    if (!!!currentItem) return;

    this.additionalInsuredFrm = this.fb.group({
      name: [currentItem.name.answer, currentItem.name.required ? Validators.required : null],
      lineOfBusiness: [currentItem.lineOfBusiness, Validators.required],
      address: this.fb.group({
        street: [currentItem.address.street, Validators.required],
        city: [currentItem.address.city, Validators.required],
        state: [currentItem.address.state, Validators.required],
        postalCode: [currentItem.address.postalCode, Validators.required],
      })
    });
    this.bindFormValueToCurrentAdditionalInsured();
  }

  // Bind form value to currentAdditionalInsured
  private bindFormValueToCurrentAdditionalInsured(): void {
    this.additionalInsuredFrm.valueChanges.subscribe((formValue) => {
      let currentItem: AdditionalInsured = this.additionalInsuredList.currentItem;
      if (!!currentItem) {
        currentItem.name.answer = formValue.name;
        currentItem.lineOfBusiness = formValue.lineOfBusiness;
        currentItem.address = formValue.address;
      }
    });
  }

  // Check if page is valid
  public isPageValid(): boolean {
    return this.additionalInsuredFrm.valid &&
      (!this.additionalInsuredList.currentItem.uploadedDocuments.required || (this.additionalInsuredList.currentItem.uploadedDocuments.required && this.fileUploadForm.uploadedDocuments.length > 0));
  }

  public beforeAdd = (): boolean => {
    this.isSubmitted = true;
    if (!this.isPageValid()) {
      this.openErrorToast(this.toastValidationMessage);
      return false;
    }
    return true;
  }

  public afterAdd = (): void => {
    this.isSubmitted = false;
    this.additionalInsuredFrm.reset();
  }

  public afterDelete = (): void => {
    this.isSubmitted = false;
    this.buildReactiveForm();
  }

  public initializeNewItem = (): AdditionalInsured => {
    return AdditionalInsured.createNewInstanceFromQuestionAnswers(this.endorsementQuestionGroup.questionAnswers);
  }

  public addItem(): void {
    this.additionalInsuredList.add();
  }

  // Handle click on item
  public clickedItem(index: number): void {
    if (!this.isPageValid() && index != this.additionalInsuredList.currentPosition) {
      this.isSubmitted = true;
      this.openErrorToast(this.toastValidationMessage);
      return;
    }
    this.additionalInsuredList.currentPosition = index;
    this.isSubmitted = false;
    this.buildReactiveForm();
  }

  // Save data and continue
  public saveAndContinue(type = ""): void {
    this.isSubmitted = true;
    if (this.isPageValid()) {
      this.postAdditionalInsuredDetails().then(isSuccess => {
        if (isSuccess) {
          this.isSubmitted = false;
          let currrentEndorsementQuestionGroup : EndorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.DeleteAdditionalInsured);
          currrentEndorsementQuestionGroup.hasAnswers = true;
          currrentEndorsementQuestionGroup.isValidPage = true;
          this.endorsement.userIsAt = '';
          if (type === "finalSubmit") {
              this.endorsementService.updateStatusToSubmitted(this.endorsement.endorsementId);
          }
          else if (type === "draft") {
            this.router.navigate(['/home'], { state: { data: 'endorsement' } });
          }
          // if menuClickContext is current page or not set, get and update next menuClickContext
          if (!this.menuClickContext || this.menuClickContext?.page === EndorsementTypes.DeleteAdditionalInsured) {
            this.endorsementService.menuClickBehavior.next(this.endorsementService.getNextMenuClickContext(EndorsementTypes.DeleteAdditionalInsured));
          }
        }
      }).catch(error => {
        console.error(error);
        this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Additional Insured', detail: error });
      });
    } else {
      this.openErrorToast(this.toastValidationMessage);
    }
  }

  // Open error toast
  private openErrorToast(body: string = ""): void {
    this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Delete Additional Insured', detail: body });
  }

  // Get Delete Insured QuestionAnswer from API
  private getQuestionAnswers(): Promise<QuestionAnswer[]> {
    let payload = { "urlData": [this.endorsement.endorsementId, EndorsementTypes.DeleteAdditionalInsured] };
    return this.commonService.get('endorsement', 'getQuestionAnswers', payload).toPromise()
      .then(result => {
        return result
      })
      .catch(error => Promise.reject(error));
  }

  // Save Data to Table
  private postAdditionalInsuredDetails(): Promise<boolean> {
    this.bindAnswersToQuestions();
    let payload = { "payloadData": this.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'postQuestionAnswers', payload).toPromise()
      .then(result => {
        return result;
      })
      .catch(error => Promise.reject(error));
  }

  public bindAnswersToQuestions(): void {
    let questionAnswers: QuestionAnswer[] = [];
    this.additionalInsuredList.items.forEach((additionalInsured, i) => {
      questionAnswers.push(...additionalInsured.toQuestionAnswers(i + 1, EndorsementTypes.DeleteAdditionalInsured))
    });
    questionAnswers.push(...this.fileUploadForm.convertFileDocumentsToQuestionAnswer());
    this.endorsementQuestionGroup.questionAnswers = questionAnswers;
  }

  public menuClicked(menuClickContext: MenuClickContext): void {
    this.menuClickContext = menuClickContext;
    if (menuClickContext != null && menuClickContext.page != EndorsementTypes.DeleteAdditionalInsured) {
      if (!this.additionalInsuredFrm || !this.fileUploadForm) {
        this.menuClickContext.page = EndorsementTypes.DeleteAdditionalInsured;
        return;
      }
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = this.additionalInsuredFrm.touched || !_.isEqual(this.additionalInsuredList.initialItems, this.additionalInsuredList.items) || this.fileUploadForm.isFormDataChanged();
      if (this.endorsementService.isMenuClickHeaderPage(menuClickContext)) {
        this.menuClickContext.page = EndorsementTypes.DeleteAdditionalInsured;
        return;
      }
      if (menuClickContext.currentPageIsValid && menuClickContext.currentPageDataChanged) {
        this.saveAndContinue();
      }
      this.endorsementService.handleUnsavedChangesOrNavigateToUrl(menuClickContext, this.endorsement);
    }
  }

  public onRollback(currentTempage: string): void {
    // Rollback is not needed
  }

  public onBack(page: string): void {
    //There is no back button
  }

  ngOnDestroy(): void {
    if (!!this.menuClickSubscription) {
      this.menuClickSubscription.unsubscribe();
    }
  }
}
