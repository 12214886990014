<div class="row mb-1"
*ngIf="sgEmployeresliab?.enabled">
    <div class="col-lg-12">
        <label><b><u>Stop- Gap Employers Liability</u></b></label>
    </div>
</div>
<!-- Stop Gap Employers Liability -->
<radiobutton-question
    *ngIf="sgEmployeresliab.enabled"
    #sgEmployeresliabRadiobutton
    [question]="sgEmployeresliab"
    (clicked)="sgEmployeresliabSelection()"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>
<div *ngIf="sgEmployeresliab.answer == 'Yes'">
    <!-- Stop Gap Payroll -->
    <textbox-question
        *ngIf="sgPayroll.enabled"
        #sgPayrollTextbox
        [question]="sgPayroll"
        [minAmt]="sgPayroll.minimum"
        [isFormSubmitted]="isFormSubmitted">
    </textbox-question>
    <!-- Bodily Injury By Accident Each Accident -->
    <dropdown-question
        *ngIf="sgBIAccidentEach.enabled"
        #sgBIAccidentEachDropdown
        [question]="sgBIAccidentEach"
        [disabled]="true"
        [isFormSubmitted]="isFormSubmitted">
    </dropdown-question>
    <!-- Bodily Injury By Disease Aggregate Limit -->
    <dropdown-question
        *ngIf="sgBIDiseaseLimit.enabled"
        #sgBIDiseaseLimitDropdown
        [question]="sgBIDiseaseLimit"
        [disabled]="true"
        [isFormSubmitted]="isFormSubmitted">
    </dropdown-question>
    <!-- Bodily Injury By Disease Each Employee -->
    <dropdown-question
        *ngIf="sgBIDiseaseEach.enabled"
        #sgBIDiseaseEachDropdown
        [question]="sgBIDiseaseEach"
        [disabled]="true"
        [isFormSubmitted]="isFormSubmitted">
    </dropdown-question>
</div>
