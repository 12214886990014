import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'any'
})
export class DateService {

    constructor() {

    }

    public isValidDate(date: any): boolean {
        const tempDate = new Date(date);
        return !isNaN(tempDate.getTime());
    }

    public getDateString(date: any, seperator: string = '/'): string {
        if (!this.isValidDate(date)) {
            return '';
        }
        const tempDate = new Date(date);
        const dayNumer = tempDate.getDate();
        const monthNumber = tempDate.getMonth() + 1;

        const day = this.addLeadingZero(dayNumer);
        const month = this.addLeadingZero(monthNumber);
        const year = tempDate.getFullYear();

        return `${month}${seperator}${day}${seperator}${year}`;
    }

    public getDateTimeString(date: any, seperator: string = '/'): string {
        if (!this.isValidDate(date)) {
            return '';
        }
        const tempDate = new Date(date);
        const dayNumer = tempDate.getDate();
        const monthNumber = tempDate.getMonth() + 1;

        const sDay = this.addLeadingZero(dayNumer);
        const sMonth = this.addLeadingZero(monthNumber);
        const sYear = tempDate.getFullYear();
        let intHour = tempDate.getHours();
        const sMinute = this.addLeadingZero(tempDate.getMinutes());
        let sAMPM = "AM";

        if (intHour > 12) {
            sAMPM = "PM";
            intHour = intHour - 12;
        }
        else if (intHour === 0) {
            intHour = 12;
        }
        let sHour:string = this.addLeadingZero(intHour);
        return sMonth + seperator + sDay + seperator + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
    }


    private addLeadingZero(value: number): string {
        if (value < 10) {
            return `0${value}`
        }

        return `${value}`;
    } 

    // ===== Get Date ===========
    public getCorrectDate(date: string | Date): Date | null {
        let correctDate: Date = null;
        if(!!!date){
            return null;
        }
        if (typeof (date) == 'string' && date.split('-').length > 2) {
            date = date.split("T")[0];
            let year = Number(date.split('-')[0]);
            let month = Number(date.split('-')[1]);
            let day = Number(date.split('-')[2]);
            if (year != undefined && !isNaN(year) && month != undefined && !isNaN(month) && day != undefined && !isNaN(day)) {
                correctDate = new Date(year, month - 1, day);
            }else{
                let parsedDate = new Date(date);
                if(!isNaN(parsedDate.getTime())){
                    return parsedDate;
                }else{
                    throw new Error(`Cannot convert ${date} to a Date type.`);
                }       
            }
        }
        else{
            correctDate = new Date(new Date(date).setHours(0,0,0,0));
        }
        return correctDate;
    }
}