import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';
import { CommonService } from '../services/commonService';
import { Constants } from 'src/app/modal/ProjConstants.models';

@Injectable({
  providedIn: 'any'
})

export class FileuploadService {

  constructor(private commonService: CommonService, public appComponent: AppComponent) { }

  public onUploadingFiles(uploadedFiles, totalFiles, docType, referenceNumber: string = "", afterUploaded = null) {
    if (!!uploadedFiles && uploadedFiles.length > 0) {
      // Validate all files
      let validationMsg = this.validateFile(uploadedFiles, totalFiles);
      if (!!validationMsg) return validationMsg;
      // upload All Files
      let promises = new Array<Promise<any>>();
      for (var i = 0; i < uploadedFiles.length; i++) {        
        let fileToUpload = uploadedFiles[i];
        //save file to API
        let p = this.postFilesToAPI(fileToUpload, docType, referenceNumber);
        p.then((event: any) => {
            if (!!event.invalidFormatMessageFromServer) {
              this.appComponent.addToast('error', 'File-Upload', event.invalidFormatMessageFromServer);             
            } else {
                fileToUpload.filesize = this.bytesToSize(fileToUpload.size);
                fileToUpload.fileName = event.fileName;
                fileToUpload.filePath = event.filePath;
                fileToUpload.guid = event.guid;
                totalFiles.push(fileToUpload);
            }
          })
        promises.push(p);        
      };
      Promise.all(promises).then(
        data => {
            if (!!afterUploaded)
                afterUploaded();
        }
      );
      return;  
    }
  }

  private validateFile(uploadedfile, totalfiles): string {
    let errorMessage = "";
    for (var i = 0; i < uploadedfile.length; i++) {
      errorMessage = this.validateFileName(uploadedfile[i].name);
      if (!!!errorMessage) {
        errorMessage = this.validateTotalFileSize(uploadedfile[i], totalfiles);
      }
      if (!!errorMessage)
        return errorMessage;
    }
    return errorMessage;
  }

  // Validate File Name
  private validateFileName(fileName: string): string {

    let errorMessage = "";

    if (!!fileName) {
      let numberOfDot = fileName.split(".").length - 1;
      let fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.'); // file name without extension
      let fileExtension = "." + fileName.split('.').pop()?.toLowerCase() ?? "";
      if (numberOfDot > 1) {
        errorMessage = errorMessage + "File name cannot contain more than one period. Please update the file name and upload again.\n";
      }
      if (!Constants.FileNetAllowedFileExtensions.includes(fileExtension)) {
        errorMessage = errorMessage + "File cannot be uploaded as the file type is invalid. Please update the file type and upload again.\n";
      }
      if (fileNameWithoutExtension.length >= 100) {
        errorMessage = errorMessage + "File name must be less than 100 characters. Please update the file name and upload again.";
      }
    }
    return errorMessage;
  }

  // validate file size
  private validateTotalFileSize(uploadedfile, totalfiles): string {
    if (!!uploadedfile) {
      var totalFilesize = uploadedfile.size;
      if (!!totalfiles && totalfiles.length > 0) {
        totalfiles.forEach(u => {
          totalFilesize = totalFilesize + u.size;
        });
      }
      if (totalFilesize > 15728640) {
        return 'File size exceeded the limit';
      }
    }
    return "";
  }

  /// upload file to api 
  public postFilesToAPI(uploadedfile, docType, referenceNumber: string = ""): Promise<any> {
    if (!!uploadedfile) {
      let fileToUpload = uploadedfile;
      fileToUpload.progressValue = 0;
      let formData = new FormData();
      formData.append('Files', fileToUpload, fileToUpload.name);
      formData.append('DocSource', docType);
      let payload = { "urlData": [referenceNumber], "payloadData": formData };
      let interval = setInterval(() => {
        if (fileToUpload.progressValue <= 85) {
          fileToUpload.progressValue = fileToUpload.progressValue + Math.floor(Math.random() * 10) + 1;
          if (fileToUpload.progressValue >= 85) {
            clearInterval(interval);
            fileToUpload.progressValue = 100;
          }
        }
        else {
          clearInterval(interval);
          fileToUpload.progressValue = 100;
        }
      }, 120);             
      return this.commonService.post('upload', 'FileUpload', payload).toPromise();      
    }
  }

  // ========= Byte to size =====================
  public bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = (Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i) * 100) / 100 + ' ' + sizes[i];
  }
  // ================ Get File Details ============
  public async getFileDetails(guid: string, transactionId: string): Promise<any> {
    let payload = { "urlData": [guid, transactionId] };
    var clientData: any = await this.commonService.get('loss-history', 'getFileDetails', payload).toPromise();
    return clientData;
  }
}
