import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListboxComponent } from './listbox/listbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { Auth0LoginComponent } from './auth0-login/auth0-login.component';

@NgModule({
  declarations: [
    ListboxComponent,
    Auth0LoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
    ToastModule
  ],
  exports: [ListboxComponent, Auth0LoginComponent]
})
export class SharedComponentsModule { }
