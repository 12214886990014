import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceLayer } from '../../../services/serviceLayer';
import { DAO, emptyModal, modalValues } from '../../../modal/DAO';
import * as $ from "jquery";
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { Constants } from 'src/app/modal/ProjConstants.models';
import _ from 'lodash';
declare var require: any;
declare var jQuery: any;
import { CommonService } from 'src/app/services/commonService';
import { ResponseContainer } from 'src/app/modal/questionnaire.models';
import { HttpResults, workInProgress } from 'src/app/modal/routing.models';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-dot',
  templateUrl: './dot.component.html'
})
export class DotComponent implements OnInit {
  @ViewChild('dotForm') dotForm: NgForm;
  constructor(public serviceLayer: ServiceLayer,
    public dao: DAO,
    public dateService : DateService,
    private messageService: MessageService, private commonService: CommonService) { }

  public IsSubmit: boolean = false;
  public IsSubmitFleet: boolean = false;
  public PrimaryDOTMsg: string = '';
  public fleetSizeOptions = Constants.FleetSizeOptions;
  public retailerSubCode: string = "";
  public dotNumber: string = "";
  public isShowFleet: boolean = false;
  public powerUnitsSize: string = "1";
  public dotEffectiveDate: emptyModal = new emptyModal();
  public dotExpirationDate: emptyModal = new emptyModal();
  public mindotEffectiveDate: Date = new Date();
  public maxdotExpirationDate: Date = new Date();
  private isDOTExistsCheckClearance: boolean = false;
  public isIncumbentAgent: emptyModal = new emptyModal();

  // ========= Page Load =====================
  ngOnInit(): void {
    setTimeout(() => {
      (<any>$('#PrimaryDOTID')).focus();
    }, 100);
  }
  // ========= Submit the DOT =====================
  public async SubmitDOT() {
    if (this.dotForm.invalid) {
      return;
    }
    this.dotNumber = this.dotNumber.trim();
    if (!!this.dotNumber) {
      if (this.dotNumber.length > 8) {
        this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: 'DOT must be 8 or less characters.' });
      } else {
        let isExistsDOT: HttpResults = await this.checkIsExistsDOT(parseInt(this.dotNumber));
        if (isExistsDOT.isSuccess == false) {
          if (isExistsDOT.responseText == 'Validate') {
            this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: 'DOT must be 8 or less characters.' });
          } else {
            if (isExistsDOT.responseText != 'DOT Exists') {
              this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: isExistsDOT.responseText });
            } else {
              this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: 'This DOT is already ' + isExistsDOT.responseText + '. Please create a new submission.' });
              setTimeout(() => {
                window.location.href = '/home'
              }, 3000);
            }

          }

        }
        else if (isExistsDOT.responseText != "DOT Exists" && !this.isDOTExistsCheckClearance) {
          let dotList : ResponseContainer = await this.getSubmissionModel();
          var getRetailerSubCodeQuestionAnswer = dotList.dotQsnr.findQuestion('retailerSubCode');
          this.dotEffectiveDate = emptyModal.initializeFrom(dotList.dotQsnr.findQuestion('dotEffectiveDate'));
          this.dotExpirationDate = emptyModal.initializeFrom(dotList.dotQsnr.findQuestion('dotExpirationDate'));
          this.mindotEffectiveDate = this.dateService.getCorrectDate(this.dotEffectiveDate.minimum);
          this.maxdotExpirationDate = this.dateService.getCorrectDate(this.dotEffectiveDate.maximum);
          if (!!getRetailerSubCodeQuestionAnswer)
            getRetailerSubCodeQuestionAnswer.answer = this.retailerSubCode;
          var getretailerSubCodeParam = !!this.retailerSubCode ? this.retailerSubCode : null;
          await this.getPrimaryDOT(dotList, getretailerSubCodeParam);
          if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
            this.isShowFleet = false;
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'responseMsg', sticky: true, severity: 'warn', summary: 'DOT #', data: this.dao.DOTLookupStatusMsg[0] });
          }
        }
      }
    }
    else {
      this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: 'Please fill required fields highlighted in red.' });
    }
  }

   //To update the policy expiration date based on the selected policy effective date.
   public updatedotExpirationDate() : void {
    this.dotExpirationDate.answer = this.dotEffectiveDate?.answer ? new Date(new Date(this.dotEffectiveDate?.answer).setFullYear(new Date(this.dotEffectiveDate?.answer).getFullYear() + 1)) : null;
  }

  // ========= Continue DOT =====================
  public continueDOT(): void {
    if (this.dotForm.valid) {
      if (this.powerUnitsSize != '10+') {
        let dotNumberValue = parseInt(this.dotNumber)
        if (!isNaN(dotNumberValue) && !!this.dao.SubmissionList && this.dao.SubmissionList.length > 0 
                && this.dao.SubmissionList.filter(c => c.dotNumber == dotNumberValue && c.status =="Incomplete").length > 0) {
          //Very unlike to hit here. If so, check again from DB again and redirect to incomplete submission
          this.checkIsExistsDOT(parseInt(this.dotNumber)).then(
            (resp: HttpResults) =>
            {
                if (resp.isSuccess && resp.responseText != "DOT Exists")
                {
                    this.lookupdotClicked();
                }
                else
                {
                    this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: 'Error: ' + resp.responseText + ". Please contact BSB Customer Service. "});
                }
            });           
        }
        else {
          this.lookupdotClicked();
        }
      }
      else {
        window.location.href = "https://www.berkleyprimetrans.com/agents/submit-your-business/";
      }
    }
    else {
      this.messageService.add({ key: 'RequiredMsg', severity: 'error', summary: 'DOT #', detail: 'Please fill required fields highlighted in red.' });
    }
  }

  //To apply mask to the policy effective date input
   private dateControlChange() : void {
    jQuery("#dotEffectiveDatecalendarIconInput").inputmask("mm/dd/yyyy", {
      isComplete: () => {        
        const value = jQuery("#dotEffectiveDatecalendarIconInput").val();
        const numericNewEffectiveDate = new Date(value).getTime();        
          if (isNaN(numericNewEffectiveDate) == false) {            
            if (!!!this.dotEffectiveDate.answer || new Date(this.dotEffectiveDate.answer).getTime() != numericNewEffectiveDate) {
              this.dotEffectiveDate.answer = new Date(numericNewEffectiveDate);
            }
          }      
      }, onKeyValidation: function (key, result) {
        $("div").remove(".p-datepicker");
      }
    });
    jQuery("#dotEffectiveDatecalendarIconInput").blur(() => {
      if (new Date(this.dotEffectiveDate?.answer) > new Date(this.dotEffectiveDate?.maximum)) {
        this.dotEffectiveDate.answer = new Date(this.dotEffectiveDate.maximum);
        this.updatedotExpirationDate();
      }
    })
  }

  // ========= Look up click =====================
  public async lookupdotClicked(): Promise<void> {
    let agencyCode : string = "";
    let referenceNumber : string = "";
    let priorSubmissionDate: string = "";
    if(!!this.dao.DOTList.dotQsnr){
      agencyCode = this.dao.DOTList.dotQsnr?.findQuestion("agencyCode")?.answer;
      referenceNumber= this.dao.DOTList.dotQsnr?.findQuestion("number")?.answer;
      priorSubmissionDate= this.dao.DOTList.dotQsnr?.findQuestion("priorSubmissionDate")?.answer;
    }
    this.dao.DOTList = await this.serviceLayer.getDOTNumber();
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("accountDot", this.dotNumber);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("fleetSize", this.powerUnitsSize);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("retailerSubCode", this.retailerSubCode);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("agencyCode", agencyCode);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("number", referenceNumber);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("priorSubmissionDate", priorSubmissionDate);
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("dotEffectiveDate", this.dateService.getDateString(this.dotEffectiveDate.answer));
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("dotExpirationDate", this.dateService.getDateString(this.dotExpirationDate.answer));
    this.dao.DOTList.dotQsnr.addOrUpdateQuestion("isIncumbentAgent", this.isIncumbentAgent.answer);
    await this.serviceLayer.getDOTDetails();
    if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'cab_invalid', sticky: true, severity: 'warn', data: this.dao.DOTLookupStatusMsg });
    }
    else if (this.dao.isDOTValid) {
      this.dao.PolicyTemplate = 'AccountInfo'
    }
    this.serviceLayer.setActiveMenu();
  }

  public resetPrimaryDOT(type: string): void {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
    this.isShowFleet = false;
    this.IsSubmit = false;
    this.IsSubmitFleet = false;
    this.PrimaryDOTMsg = '';
    this.dotNumber = "";
    this.powerUnitsSize = '';
  }

  // ========= Cancel =====================
  public cancel(): void {
    this.messageService.clear();
    this.messageService.add({ key: 'Success', severity: 'success', summary: 'DOT #', detail: 'Thank you for considering Berkley Small Business Solutions for your small trucking needs. Looking forward to working with you on the next submission!' });
    setTimeout(() => {
      window.location.href = '/home'
    }, 1000);
    document.body.style.pointerEvents = "visible";
    this.isShowFleet = false;
    this.powerUnitsSize = '';
  }
  public submit(): void {
    this.isShowFleet = true;
    setTimeout(() => {
      this.dateControlChange()
    }, 500)
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
  }
  // ========= Numbers only =====================
  public numbersOnly(e) {
    return this.serviceLayer.numbersOnly(e, 'dot');
  }
  keyPress(event) {
    var charCode = event.keyCode;
    // Charcodes -> Uppercase Alphabets(65-90), Lowercase Alphabets(97-122), Numerics(48-57), WhiteSpaces(32), Hyphen(45), Pound(35)
    return (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode > 47 && charCode < 58) || (charCode == 35 || charCode == 45 || charCode == 32)
  }

  // ========= Get Submission Model =====================
  public async getSubmissionModel(): Promise<ResponseContainer> {
    let payload = { "urlData": [this.dao.userCompany, 'CHECK_SUBMISSION'] }
    var clientData: any = await this.commonService.get('Clearance', 'CheckSubmissionModel', payload).toPromise();
    let dotList : ResponseContainer = ResponseContainer.fromJSON(clientData);
    return dotList;
  }

  // ============= Get Primary DOT Detais
  public async getPrimaryDOT(dotList : ResponseContainer, retailerSubCode: string): Promise<void> {
    let response : { agencyCode: string, label: string, value: number } = await this.createReferenceNo(retailerSubCode);
    if (!!response.label != null) {
      if (!!dotList.dotQsnr) {
        dotList.dotQsnr.addOrUpdateQuestion("accountDot", this.dotNumber);
        dotList.dotQsnr.addOrUpdateQuestion("fleetSize", this.powerUnitsSize);
        dotList.dotQsnr.addOrUpdateQuestion("number", response.label);
        dotList.dotQsnr.addOrUpdateQuestion("agencyCode", response.agencyCode);
        dotList.dotQsnr.addOrUpdateQuestion("retailerSubCode", this.retailerSubCode);
        dotList.dotQsnr.addOrUpdateQuestion("isIncumbentAgent", this.isIncumbentAgent.answer);
      }
      let payload = { "urlData": [this.dao.userCompany, this.dao.userMail, 'CHECK_SUBMISSION'], "payloadData": dotList }
      let jsonPathRef = require('jsonpath');
      this.isShowFleet = false;
      await this.commonService.post('DOT', 'Submission', payload).toPromise().then(async (data: any) => {
        let notifyData = jsonPathRef.query(data, '$..extendedStatus[?(@.extendedStatusCode !="SUCCESS")].extendedStatusCode');
        if (!!data) {
          this.dao.DOTList = ResponseContainer.fromJSON(data);;
          this.dao.finalSubmitPage = data.finalSubmitPage;
          this.dao.navigatePage = this.dao.finalSubmitPage;
          this.serviceLayer.checkEvaluatequestionaire('Dot')
          if (notifyData != null && notifyData.length > 0 && notifyData.filter(n => n == 'Decline').length == 0) {
            this.isShowFleet = true;
            setTimeout(() => {
              this.dateControlChange()
            },500)
          }
        }
      });
    }

  }

  public async createReferenceNo(retailerSubCode: string): Promise<{ agencyCode: string, label: string, value: number }> {
    let payload = { "urlData": [this.dotNumber, this.dao.userMail, this.dao.userCompany, retailerSubCode] }
    const result: unknown = await this.commonService.get('dot', 'getReferenceNo', payload).toPromise();
    return result as { agencyCode: string, label: string, value: number };
  }

  // ========= Get DOT Exists =====================
  public async checkIsExistsDOT(accountDot: number): Promise<HttpResults | null> {
    let payload = { "urlData": [accountDot, this.dao.userMail, this.dao.userAgencyCode] }
    let result = null;
    await this.IsExistsDOT(payload).then(
        async (data: HttpResults) => {
            this.isDOTExistsCheckClearance = false;
            if (!!data) {
                if (data.responseText == "DOT Exists") {
                    if (!! data?.userData) {
                        let myIncompleteWip: workInProgress = data.userData as workInProgress;
                        if(!!myIncompleteWip.referenceNumber && myIncompleteWip.status == "Incomplete")
                        {
                            this.dao.ExistsDOTSubmitData = {};
                            this.dao.ExistsDOTSubmitData.data = myIncompleteWip;
                            await this.serviceLayer.bindDatafromWIP(this.dao.ExistsDOTSubmitData);
                            this.isDOTExistsCheckClearance = true;
                        }
                    }
                }
            }            
            result = data;
        }
    )
    .catch(err => this.popupGeneralError(err));
    return result;
  }
  // ====== Get DOT Exists ====
  public async IsExistsDOT(payload): Promise<HttpResults | null> {
    var result: any = await this.commonService.get('dot', 'checkIsExistsDOT', payload).toPromise();
    return result as HttpResults;
  }

  private popupGeneralError(err)
  {
    this.messageService.add({ key: 'declinePopup', sticky: true, severity: 'error', summary: 'Your submission could not be transmitted at this time.  Please try again later or contact Berkley Small Business Solutions at help@berkleysmallbusiness.com for additional support.' });
    console.error(err);
  }
}
