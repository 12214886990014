import { emptyModal } from "../DAO";
import { Address } from "../address.models";
import { QuestionAnswer, QuestionAnswerHelper, Questionnaire } from "../questionnaire.models";
import { AdditionalInsured } from "./AdditionalInsured.Model";
import { EndorsementTypes } from "./Endorsement.Model";

export class AddVehicleModal {
    public vehicleType: emptyModal = new emptyModal();
    public vehicleYear: emptyModal = new emptyModal();
    public vehicleMake: emptyModal = new emptyModal();
    public vehicleModel: emptyModal = new emptyModal();
    public vehicleVin: emptyModal = new emptyModal();
    public vehicleStatedAmount: emptyModal = new emptyModal();
    public vehicleCustomPartsParent: emptyModal = new emptyModal();
    public vehicleCustomAmount: emptyModal = new emptyModal();
    public vehicleCustomDesc: emptyModal = new emptyModal();
    public vehicleAddInsuredOrLossPayee: emptyModal = new emptyModal();
    public AdditionalInsuredList: Array<AdditionalInsured> = [];
    public uploadedDocuments: emptyModal = new emptyModal();

    static initializeArrayFrom(questionAnswers: QuestionAnswer[]): AddVehicleModal[] {
        let vehicleDetailsArray: AddVehicleModal[] = [];
        if (!!!questionAnswers || questionAnswers.length === 0) {
            return vehicleDetailsArray;
        }

        let allGroups: Array<string> = [...new Set(questionAnswers.map(x => x.group))];
        let vehicleGroups: Array<string> = allGroups.filter(group => group.startsWith('VEH'));

        if (vehicleGroups.length === 0) {
            vehicleGroups = allGroups;
        }

        vehicleGroups.forEach(group => {
            let filteredQuestionAnswers: Array<QuestionAnswer> = questionAnswers.filter(x => x.group == group);
            if (filteredQuestionAnswers.length > 0) {
                let tempVehicleDetail: AddVehicleModal = this.instantiateFromSingleQuestionAnswerSet(filteredQuestionAnswers);
                vehicleDetailsArray.push(tempVehicleDetail);
            }
        });
        return vehicleDetailsArray;
    }

    static instantiateFromSingleQuestionAnswerSet(questionAnswers: Array<QuestionAnswer>): AddVehicleModal {
        let tempVehicleDetails: AddVehicleModal = new AddVehicleModal();
        if (!questionAnswers || questionAnswers.length === 0) {
            return tempVehicleDetails;
        }

        Object.keys(tempVehicleDetails).forEach(property => {
            if (tempVehicleDetails[property] instanceof emptyModal) {
                let questionAnswer = emptyModal.initializeFrom(questionAnswers.find(x => x.key == property));
                if (!!questionAnswer) {
                    if (questionAnswer.answer == undefined || questionAnswer.answer == null) questionAnswer.answer = '';
                    QuestionAnswerHelper.useDefaultValue.apply(questionAnswer);
                    tempVehicleDetails[property] = questionAnswer;
                }
            }
            else if (property == 'AdditionalInsuredList') {
                tempVehicleDetails.AdditionalInsuredList = [];
                let additionalInsuredList : AdditionalInsured[] =  AdditionalInsured.instantiateArrayFrom(questionAnswers)
                tempVehicleDetails.AdditionalInsuredList = tempVehicleDetails.AdditionalInsuredList.concat(additionalInsuredList);
            }
        });
        return tempVehicleDetails;
    }

    static createNewInstanceFromQuestionAnswers(questionAnswers: QuestionAnswer[]): AddVehicleModal {
        let vehicleDetails: AddVehicleModal = new AddVehicleModal();
        if (!!!questionAnswers || questionAnswers.length === 0) {
            return vehicleDetails;
        }

        let vehicleGroups: Array<string> = [...new Set(questionAnswers.map(x => x.group))];
        if (vehicleGroups.length === 0) return vehicleDetails;

        let firstQuestionAnswerSet: Array<QuestionAnswer> = questionAnswers.filter(x => x.group == vehicleGroups[0]);
        vehicleDetails = this.instantiateFromSingleQuestionAnswerSet(firstQuestionAnswerSet);

        Object.keys(vehicleDetails).forEach(property => {
            let detail = vehicleDetails[property];
            if (detail instanceof emptyModal) {
                detail.answer = "";
            } else if (property == "AdditionalInsuredList") {
                let tempInsured = detail[0];
                tempInsured.address = new Address();
                tempInsured.addInsuredName.answer = "";
                tempInsured.addInsuredInterestType.answer = "";
                tempInsured.addInsuredLoanNumber.answer = "";
                vehicleDetails[property] = [tempInsured];
            }
        })
        return vehicleDetails;
    }
    
    public toQuestionAnswers(rowIndex: number = 0): Array<QuestionAnswer> {
        let questionnaire: Questionnaire = new Questionnaire();
        questionnaire.key = EndorsementTypes.AddVehicle;
        questionnaire.questionAnswer = [];

        let vehGroup = `VEH-${rowIndex}`;
        Object.keys(this).forEach(property => {
            let detail = this[property];
            if (detail instanceof emptyModal && !!detail.key) {
                detail.rowIndex = rowIndex;
                detail.group = vehGroup;
                questionnaire.questionAnswer.push(detail);
            }
            else if (property == 'AdditionalInsuredList') {
                this.AdditionalInsuredList.forEach((interest, index) => {
                    let interestArray: QuestionAnswer[] = interest.toQuestionAnswers(index + 1, EndorsementTypes.AddVehicle, vehGroup);
                    questionnaire.questionAnswer = questionnaire.questionAnswer.concat(interestArray);
                });
            }
        });
        return questionnaire.questionAnswer;
    }
}

export class DocumentModel{
    public fileName: string
    public filesize: string
    public guid: string;
}