import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dropDownPipe'
})
export class DropDownPipe implements PipeTransform {
  transform(type: string, bannerTypeQuestionOptions: any[]): string {
    const foundItem = bannerTypeQuestionOptions.find(x => x.value === type);
    return foundItem ? foundItem.label : '';
  }
}