<div class="container">
    <div class="main_content pt-3 mt-2" *ngIf="!!otherEndorsementsForm && otherEndorsementsForm.controls">
        <div class="row mb-3">
            <div class="col-12">
                <span class="text-lg-right float-lg-right">
                    <cancel-endorsement [endorsementId]="endorsement.endorsementId"></cancel-endorsement>
                </span>
            </div>
        </div>
        <form [formGroup]="otherEndorsementsForm">
            <div class="card">
                <div class="card-body">
                    <h5 class="float-left">Other</h5>
                    <span style="color: red;">*</span>
                    <textarea class="form-control"
                        [class.validate_error_cls]="isSubmitted && otherEndorsementsForm.get('notes').invalid" rows="5"
                        formControlName="notes" Name="notes" id="notes"></textarea>
                    <div class="col-lg-12 mt-2">
                        <div class="row">
                            <div class="col-md-5 p-0">
                                <label>Please upload supporting documents for this endorsement request</label>
                            </div>
                            <div class="col-md-7 p-0">
                                <a class="drag_and_drop" (click)="fileUpload()" name="fileUpload" id="fileUploadID">
                                    <h3 class="mb-0">Drag & Drop Files Here</h3>
                                    <p class="mb-0">or</p>
                                    <h6>Browse Files</h6>
                                </a>
                                <p-fileUpload class="UploadCls" formArrayName="files" id="files"
                                    (onSelect)="onUpload($event)" (click)="clickUpload($event)"
                                    [accept]="fileNetAllowedFileExtensions" multiple="multiple" maxFileSize="15728640"
                                    invalidFileTypeMessageSummary="{0}: Invalid file type.">
                                </p-fileUpload>
                                <div class="row" *ngFor="let item of otherEndorsement.files; let i = index;">
                                    <div class="col-lg-12 mt-3">
                                        <div class="upload_file_icon mr-3">
                                            <i class="fa fa-file-o" aria-hidden="true"></i>
                                        </div>
                                        <div class="upload_file_details">
                                            <div class="upload_file_name">{{ item?.fileName }}</div>
                                            <span class="row text-right float-right upload_size_details"><b
                                                    class="color_dark">({{item?.filesize}})</b>
                                                <i class="fa fa-times-circle-o ml-2 fa-lg color_green pointerCur"
                                                    title="DeleteFile" (click)="deleteFile(item, i)"
                                                    aria-hidden="true"></i>
                                            </span><br>
                                            <p-progressBar [value]="item?.progressValue"></p-progressBar>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-2">
                                    <div class="small color_grey">The combined file size limit is 15 MB.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12">
                    <div class="col-mg-6 p-0 mt-3">
                        <span class="text-lg-left float-lg-left">
                            <modify-endorsement-type [endorsement]="endorsement"></modify-endorsement-type>
                        </span>
                    </div>
                    <endorsement-footer 
                        [parentPage]="this"
                        (clickedViewApplication)="bindAnswersToQuestions()">
                    </endorsement-footer>
                </div>
            </div>
        </form>
    </div>
    <p-toast position="center" key="remove" (onClose)="onReject('remove')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnonRejectYesNo"
                            id="btnonRejectYesID" (click)="confirmDelete(message.data)">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnVonRejectNo"
                            id="btnVonRejectNoID" (click)="onReject('remove')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>