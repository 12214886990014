import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm, NgModel, NgModelGroup} from '@angular/forms';
import { DAO } from 'src/app/modal/DAO';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
import { ProductService } from 'src/app/services/product.service';

interface PrimeNGDropDownEvent {
    value: string;
    originalEvent: PointerEvent;
}

@Component({
    selector: 'dropdown-question',
    templateUrl: './dropdown-question.component.html',
    styleUrls: ['./dropdown-question.component.css'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'row mb-1' },
    viewProviders: [{
        provide: ControlContainer,
        deps: [[Optional, NgModelGroup]],
        useFactory: (ngModelGroup: NgModelGroup) => ngModelGroup,
      },]
})
export class DropdownQuestionComponent implements OnInit {

    @ViewChild('dropdownModel', { static: true }) dropdownModel: NgModel;

    @Input() question: QuestionAnswer;
    @Input() isFormSubmitted: boolean;
    @Input() name: string;
    @Input() disabled: boolean;
    @Input() label: string;
    @Input() requiredAmtOrDecline: boolean;
    @Input() supressErrorMessage: boolean;

    @Output() changed = new EventEmitter();

    public questionnaire: Questionnaire;
    public validationMessage: string = "";

    constructor(private dao: DAO, private productService: ProductService) { }

    ngOnInit(): void {
        this.loadValidationMessage();
    }
    public get labelText(): string {
        return this.label ?? this.question.questionLabel;
    }

    public get showError(): boolean {
        return (this.isFormSubmitted || !this.dropdownModel?.pristine) && this.dropdownModel.invalid;
    }

    public onSelectionChanged(event: PrimeNGDropDownEvent): void {
        this.changed.emit();
    }
    private loadValidationMessage(): void {
        this.validationMessage = this.productService.getValidationMessage(this.question.key) ?? "";
    }
}
