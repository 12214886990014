 <!-- At-Fault Liability Limit -->
 <dropdown-question
    *ngIf="atFaultLiab.enabled"
    #atFaultLiabDropdown
    [question]="atFaultLiab"
    [requiredAmtOrDecline]="atFaultLiab.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Uninsured motorist - Combined Bodily Injury / Property Damage Limit -->
<dropdown-question
    *ngIf="showUmPdPrd"
    #umPdPrdDropdown
    label="Uninsured motorist - Combined Bodily Injury / Property Damage Limit"
    [question]="umPdPrd"
    [isFormSubmitted]="productForm.submitted"
    [supressErrorMessage]="!!productForm.form?.controls.commercialAutoGroup?.controls['umBiLimit']"
    [requiredAmtOrDecline]="umPdPrd.required"
    (changed)="onUmPdPrdChanged()">
</dropdown-question>

<!-- Uninsured motorist - Combined Bodily Injury / Property Damage Deductible -->
<dropdown-question
    *ngIf="showUmPdPrdDed"
    #umPdPrdDedDropdown
    label="Uninsured motorist - Combined Bodily Injury / Property Damage Deductible"
    [question]="umPdPrdDed"
    [disabled]="isUmPdPrdDedDisabled"
    [requiredAmtOrDecline]="umPdPrdDed.required"
    [isFormSubmitted]="productForm.submitted">
</dropdown-question>

<!-- Uninsured motorist - Bodily Injury Limit -->
<dropdown-question
    *ngIf="showUmBiLimit"
    #umBiLimitDropdown
    label="Uninsured motorist - Bodily Injury"
    [question]="umBiLimit"
    [isFormSubmitted]="isFormSubmitted"
    [supressErrorMessage]="!!productForm.form.controls?.commercialAutoGroup?.controls['umPdPrd']"
    [requiredAmtOrDecline]="umBiLimit.required"
    (changed)="onUmBiLimitChanged()">
</dropdown-question>

<!-- Uninsured motorist - Bodily Injury Deductible -->
<dropdown-question
    *ngIf="showUmBiDed"
    #umBiDedDropdown
    [question]="umBiDed"
    [disabled]="isUmBiDedDisabled"
    [requiredAmtOrDecline]="umBiDed.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<!-- Uninsured motorist - Property Damage Limit -->
<dropdown-question
    *ngIf="showUmPdLimit"
    #umPdLimitDropdown
    label="Uninsured motorist - Property Damage Limit"
    [question]="umPdLimit"
    [disabled]="isUmPdLimitDisabled"
    [requiredAmtOrDecline]="umPdLimit.required"
    [isFormSubmitted]="isFormSubmitted"
    (changed)="onUmPdLimitChanged()">
</dropdown-question>

<!-- Uninsured motorist - Property Damage Deductible -->
<dropdown-question
    *ngIf="showUmPdDed"
    #umPdDedDropdown
    label="Uninsured motorist - Property Damage Deductible"
    [question]="umPdDed"
    [disabled]="isUmPdDedDisabled"
    [requiredAmtOrDecline]="umPdDed.required"
    [isFormSubmitted]="isFormSubmitted">
</dropdown-question>

<radiobutton-question
    *ngIf="umStacked?.enabled"
    #umStackedRadiobutton
    [question]="umStacked"
    [radioButtonLabel1]="'Stacked'"
    [radioButtonLabel2]="'Non-Stacked'"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>

<radiobutton-question
    *ngIf="umEconomicLoss?.enabled && showUmPdLimit"
    #uumEconomicLossRadiobutton
    [question]="umEconomicLoss"
    [isFormSubmitted]="isFormSubmitted">
</radiobutton-question>