import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { CommonService } from 'src/app/services/commonService';
import { EndorsementService } from 'src/app/services/endorsement.service';

@Component({
  selector: 'menu-icon-bar',
  templateUrl: './menu-icon-bar.component.html',
  styleUrls: ['./menu-icon-bar.component.css']
})

export class MenuIconBarComponent implements OnInit {

  @Input() endorsement: Endorsement;
  private menuItems: MenuItem[] = [
    { icon1Class: 'fa fa-user fa-stack-2x', icon2Class: 'fa fa-plus fa-stack-1x pb-3 ml-3', title: 'Add Driver', code: EndorsementTypes.AddDriver, hasAnswers: false },
    { icon1Class: 'fa fa-user fa-stack-2x', icon2Class: 'fa fa-times fa-stack-1x pb-3 ml-3', title: 'Delete Driver', code: EndorsementTypes.DeleteDriver, hasAnswers: false },
    { icon1Class: 'fa fa-truck fa-stack-2x', icon2Class: 'fa fa-plus fa-stack-1x pb-3 ml-4', title: 'Add Vehicle', code: EndorsementTypes.AddVehicle, hasAnswers: false },
    { icon1Class: 'fa fa-truck fa-stack-2x', icon2Class: 'fa fa-times fa-stack-1x pb-3 ml-4', title: 'Delete Vehicle', code: EndorsementTypes.DeleteVehicle, hasAnswers: false },
    { icon1Class: 'fa fa-address-card fa-stack-2x', icon2Class: 'fa fa-plus fa-stack-1x pb-3 ml-4', title: 'Add Additional Insured', code: EndorsementTypes.AddAdditionalInsured, hasAnswers: false },
    { icon1Class: 'fa fa-address-card fa-stack-2x', icon2Class: 'fa fa-times fa-stack-1x pb-3 ml-4', title: 'Delete Additional Insured', code: EndorsementTypes.DeleteAdditionalInsured, hasAnswers: false },
    { icon1Class: 'fa fa-clone fa-stack-2x', icon2Class: '', title: 'Other', code: EndorsementTypes.otherEndorsements, hasAnswers: false }
  ];
  public selectedMenuItems: MenuItem[] = [];
  private endorsementSubscription: Subscription;

  constructor(
    public router: Router,
    public commonService: CommonService,
    private endorsementService: EndorsementService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.endorsementSubscription = this.endorsementService.endorsement.subscribe(() => {
      this.loadEndorsementMenuItems();
    });
  }

  public hasAnswers(menuCode: string): boolean {
    if (!!!menuCode) return;
    return !!this.endorsement.selectedEndorsementGroups.find(x => x.groupKey === menuCode).hasAnswers ?? false;
  }

  public isValidPage(menuCode: string): boolean {
    if (!!!menuCode) return;
    return this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == menuCode)?.isValidPage !== false;
  }

  public loadEndorsementMenuItems(): void {
    this.endorsementService.getEndorsementQuestionGroups(this.endorsement.endorsementId).then(endorsementQuestionGroups => {
      if (!!endorsementQuestionGroups) {
        this.endorsement.selectedEndorsementGroups = endorsementQuestionGroups;

        this.selectedMenuItems = this.menuItems
          .filter(item => this.endorsement.selectedEndorsementGroups.find(x => x.groupKey.includes(item.code) && x.selected))
          .map(filteredItem => ({
            ...filteredItem,
            code: filteredItem.code,
            icon1Class: filteredItem.icon1Class,
            icon2Class: filteredItem.icon2Class,
            title: filteredItem.title,
            hasAnswers: this.endorsement.selectedEndorsementGroups.find(code => code.groupKey === filteredItem.code)?.hasAnswers || false
          }));
        this.endorsementService.selectedMenuItems = this.selectedMenuItems;

        let lastUrlPart = this.router.url.split('/').pop();
        const foundGroup = this.selectedMenuItems.some(item => lastUrlPart === item.code);

        if (this.selectedMenuItems.length > 0 && !foundGroup) {
          let route = Constants.EndorsementBaseRoute;
          if (!!this.endorsement.userIsAt && lastUrlPart == 'endorsements') {
            route += this.endorsement.userIsAt;
          }
          else {
            route += this.selectedMenuItems[0]?.code;
          }
          this.router.navigateByUrl(route, { state: { endorsement: this.endorsement } });
        }
      }
    }).catch(error => {
      console.error(error);
    })
  }

  public menuClick(menuCode: string): void {
    const menuClickContext: MenuClickContext = new MenuClickContext();
    menuClickContext.currentPageIsValid = true;
    menuClickContext.page = menuCode;
    this.endorsementService.menuClickBehavior.next(menuClickContext);
  }

  public onCancelChanges(type: string) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
  }

  public onOkChanges(targetUrl: string) {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
    this.router.navigateByUrl(targetUrl, { state: { endorsement: this.endorsement } });
  }

  public ngOnDestroy(): void {
    if (!!this.endorsementSubscription) {
      this.endorsementSubscription.unsubscribe();
      this.endorsementSubscription = null;
    }
  }
}

export interface MenuItem {
  icon1Class: string;
  icon2Class: string;
  title: string;
  code: EndorsementTypes;
  hasAnswers: boolean;
}
