import { Validator, NG_VALIDATORS, FormControl, NgForm} from '@angular/forms'
import { Directive, OnInit, forwardRef, Input  } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
@Directive({
  selector: '[selectEitherOrOption]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SelectEitherOrOptionDirective, multi: true }
  ]
})
export class SelectEitherOrOptionDirective {

  
  @Input("selectEitherOrOption") isCombinedCoveragePresent: boolean;

  constructor(private productService: ProductService) { }
  ngOnInit() {
  }
 
  validate(c: NgForm) {
    let accountStateModel =  this.productService.getAccountStateAnswer();
    if (!this.isCombinedCoveragePresent)
      return null;
    let validationMessage = "";

    // UMBI and UMPdPrd Validation
    let umBiLimit = c.value?.commercialAutoGroup?.umBiLimit;
    let umPdPrd = c.value?.commercialAutoGroup?.umPdPrd
    if ((umBiLimit == '' || umBiLimit == 'CLICK') && (umPdPrd == '' || umPdPrd == 'CLICK'))
    {
      validationMessage = validationMessage + this.productService.getValidationMessage("umBiUmPd");
    }

    // UIMBI and UIMPdPrd Validation
    if (!!accountStateModel && accountStateModel == 'WV') {
      let uimBi = c.value?.commercialAutoGroup?.uimBi;
      let uimPdPrd = c.value?.commercialAutoGroup?.uimBiPrd;
      if ((uimBi == '' || uimBi == 'CLICK') && (uimPdPrd == '' || uimPdPrd == 'CLICK'))
      {
        if(validationMessage){
          validationMessage = validationMessage + " \n ";
        }
        validationMessage = validationMessage + this.productService.getValidationMessage("uimBiUimPd");
      }      
    }

    if(!!validationMessage){
      return { 'invalidCombinedCoverages': true, 'errmsg':  validationMessage}
    }else{
      return null;
    }
  }
}
