import { emptyModal } from "../DAO";
import { Address } from "../address.models";
import { QuestionAnswer, Questionnaire } from "../questionnaire.models";
import { EndorsementTypes } from "./Endorsement.Model";

export class AdditionalInsured {
    private _lineOfBusiness: emptyModal = new emptyModal();
    public name: emptyModal = new emptyModal();
    public address: Address = new Address();
    public addInsuredInterestType: emptyModal = new emptyModal();
    public addInsuredName: emptyModal = new emptyModal();
    public addInsuredLoanNumber: emptyModal = new emptyModal();
    public uploadedDocuments: emptyModal = new emptyModal();

    get lineOfBusiness(): Array<LineOfBusinessTypes> {
        if (!!!this._lineOfBusiness?.answer) return [];
        return this._lineOfBusiness.answer.split(",") as Array<LineOfBusinessTypes>;
    }

    set lineOfBusiness(value) {
        if (value instanceof Array) {
            this._lineOfBusiness.answer = value.join(',');
        }
        else {
            this._lineOfBusiness.answer = value;
        }
    }
    

    static instantiateArrayFrom(questionAnswers: Array<QuestionAnswer>): Array<AdditionalInsured> {
        let additionalInsuredArray: Array<AdditionalInsured> = [];
        if (!!!questionAnswers || questionAnswers.length === 0) {
            return additionalInsuredArray;
        }

        let currentKey: string = questionAnswers.find(x => x.key === "lineOfBusiness")?.key || 'addInsuredInterestType';

        let numberOfAdditionalInsured: number = questionAnswers.filter(x => x.key == currentKey).length;
        for (let i = 1; i <= numberOfAdditionalInsured; i++) {
            let filteredQuestionAnswers: Array<QuestionAnswer> = questionAnswers.filter(x => x.rowIndex == i);
            let tempNamedInsured: AdditionalInsured = this.instantiateFromSingleQuestionAnswerSet(filteredQuestionAnswers);
            additionalInsuredArray.push(tempNamedInsured);
        }
        return additionalInsuredArray;
    }


    static instantiateFromSingleQuestionAnswerSet(questionAnswers: Array<QuestionAnswer>): AdditionalInsured {
        let tempAdditionalInsured: AdditionalInsured = new AdditionalInsured();
        if (!questionAnswers || questionAnswers.length === 0) {
            return tempAdditionalInsured;
        }

        let tempQuestionnaire: Questionnaire = new Questionnaire();
        tempQuestionnaire.questionAnswer = questionAnswers;
        tempAdditionalInsured.name = emptyModal.initializeFrom(tempQuestionnaire.findQuestion("name"));
        tempAdditionalInsured._lineOfBusiness = emptyModal.initializeFrom(tempQuestionnaire.findQuestion("lineOfBusiness"));
        tempAdditionalInsured.addInsuredName = emptyModal.initializeFrom(tempQuestionnaire.findQuestion("addInsuredName"));
        tempAdditionalInsured.addInsuredInterestType = emptyModal.initializeFrom(tempQuestionnaire.findQuestion("addInsuredInterestType"));
        tempAdditionalInsured.addInsuredLoanNumber = emptyModal.initializeFrom(tempQuestionnaire.findQuestion("addInsuredLoanNumber"));
        tempAdditionalInsured.uploadedDocuments = emptyModal.initializeFrom(tempQuestionnaire.findQuestion("uploadedDocuments"));
        tempAdditionalInsured.address.street = questionAnswers.find(x => x.key == "street")?.answer;
        tempAdditionalInsured.address.city = questionAnswers.find(x => x.key == "city")?.answer;
        tempAdditionalInsured.address.state = questionAnswers.find(x => x.key == "state")?.answer;
        tempAdditionalInsured.address.postalCode = questionAnswers.find(x => x.key == "postalCode")?.answer;

        if (!!!tempAdditionalInsured.name.answer) tempAdditionalInsured.name.answer = "";
        return tempAdditionalInsured;
    }

    static createNewInstanceFromQuestionAnswers(questionAnswers: Array<QuestionAnswer>): AdditionalInsured {
        let firstQuestionAnswerSet: Array<QuestionAnswer> = questionAnswers.filter(x => x.rowIndex == 1);
        let tempAdditionalInsured: AdditionalInsured = this.instantiateFromSingleQuestionAnswerSet(firstQuestionAnswerSet);
        tempAdditionalInsured.address = new Address();
        tempAdditionalInsured.lineOfBusiness = [];
        tempAdditionalInsured.name.answer = "";
        tempAdditionalInsured.addInsuredName.answer = "";
        tempAdditionalInsured.addInsuredLoanNumber.answer = "";
        tempAdditionalInsured.addInsuredInterestType.answer = "";
        return tempAdditionalInsured;
    }

    public toQuestionAnswers(rowIndex: number = 0, endorsementType: EndorsementTypes, group?: string): Array<QuestionAnswer> {
        let questionnaire: Questionnaire = new Questionnaire();
        questionnaire.key = endorsementType;
        questionnaire.questionAnswer = [];

        if (!!this.addInsuredInterestType.key)
            questionnaire.questionAnswer.push(this.addInsuredInterestType);
        if (!!this.addInsuredName.key)
            questionnaire.questionAnswer.push(this.addInsuredName);
        if (!!this.addInsuredLoanNumber.key)
            questionnaire.questionAnswer.push(this.addInsuredLoanNumber);
        if (!!this.name.key){
            this.name.rowIndex = rowIndex;
            questionnaire.questionAnswer.push(this.name);
        }
        if (!!this._lineOfBusiness.key){
            questionnaire.questionAnswer.push(this._lineOfBusiness);
            this._lineOfBusiness.rowIndex = rowIndex;
        }

        questionnaire.upsertQuestionAnswer("street", this.address.street, rowIndex);
        questionnaire.upsertQuestionAnswer("city", this.address.city, rowIndex);
        questionnaire.upsertQuestionAnswer("state", this.address.state, rowIndex);
        questionnaire.upsertQuestionAnswer("postalCode", this.address.postalCode, rowIndex);

        if (!!group) {
            questionnaire.questionAnswer.forEach(x => {
                x.rowIndex = rowIndex;
                x.group = group || "";
            });
        }
        return questionnaire.questionAnswer;
    }
}


export enum LineOfBusinessTypes {
    CommercialAuto = 'CommercialAuto',
    GeneralLiability = 'GeneralLiability',
    MotorTruckCargo = 'MotorTruckCargo'
}