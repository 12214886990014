import { Validator, FormGroup, AbstractControl, NgModelGroup, NG_VALIDATORS, FormControl } from '@angular/forms'
import { Directive, OnInit, forwardRef, Input } from '@angular/core';

@Directive({
  selector: '[totalHundredControls]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: TotalHundredValidatorDirective, multi: true }
  ]
})
export class TotalHundredValidatorDirective {

  @Input("totalHundredControls") totalHundred: string;
  constructor() { }

  ngOnInit() {
  }

  validate(c: AbstractControl) {
    if (this.totalHundred == undefined || this.totalHundred == "")
      return null;
    let total = 0;
    Object.keys(c.value).forEach(key => {
      if (key.toLowerCase().indexOf(this.totalHundred.toLowerCase()) >= 0 && !isNaN(c.value[key]))
        total += Number(c.value[key]);
    });
    if (total != 100)
      return { 'totalHundred': true, 'errmsg': 'totalHundred is false' }
    else
      return null;
  }

}
