import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/commonService';

@Component({
  selector: 'cancel-endorsement',
  templateUrl: './cancel-endorsement.component.html',
  styleUrls: ['./cancel-endorsement.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CancelEndorsementComponent {
  @Input() endorsementId: number;

  constructor(
    public router: Router,
    private commonService: CommonService
  ) { }

  public confirmCancelEndorsement(): void {
    this.cancelEndorsement().then(result => {
      if (typeof result === 'number' && result > 0) {
        this.router.navigate(['/home'], { state: { data: 'endorsement' } });
      }
    }).catch(error => {
      console.error(error);
    })
  }

  private cancelEndorsement(): Promise<number> {
    let payload = { "urlData": [this.endorsementId, 'Cancelled'] };
    return this.commonService.put('endorsement', 'updateEndorsementStatus', payload).toPromise()
      .then(result => {
        return result
      })
      .catch(error => Promise.reject(error));
  }
}