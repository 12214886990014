import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { emptyModal } from 'src/app/modal/DAO';
import { DocumentModel } from 'src/app/modal/DocumentModel';
import { EndorsementQuestionGroup } from 'src/app/modal/Endorsements/Endorsement.Model';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { CommonService } from 'src/app/services/commonService';
import { FileuploadService } from 'src/app/services/fileupload.service';
import _ from 'lodash';

@Component({
  selector: 'endorsement-file-upload-form',
  templateUrl: './file-upload-form.component.html',
  styleUrls: ['./file-upload-form.component.css']
})
export class FileUploadFormComponent implements OnInit {
  @Input() questionGroup: EndorsementQuestionGroup;
  @Input() questionKey: string = 'uploadedDocuments';
  @Input() toastSummary: string = 'Endorsements';
  @Input() toastValidation: string = 'Please fill the required fields highlighted in red';
  public fileNetAllowedFileExtensions = Constants.FileNetAllowedFileExtensions.toString();
  public uploadedDocuments: Array<DocumentModel> = [];
  public initialUploadedDocuments: Array<DocumentModel> = [];
  
  constructor(
    private messageService: MessageService,
    private commonService: CommonService,
    private fileuploadService: FileuploadService
  ) {}

  ngOnInit(): void {
    this.buildFileDocuments();
  }

  onUpload(event: any): void {
    let response = this.fileuploadService.onUploadingFiles(event.files, this.uploadedDocuments, 'endorsement', this.questionGroup.endorsementId.toString());
    if (!!response) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: this.toastSummary, detail: !!response ? response : this.toastValidation });
    }
  }

  clickUpload(event: any): void {
    if (event && event.target && event.target.className == 'p-fileupload-content') {
      this.fileUpload();
    }
  }

  fileUpload(): void {
    const fileInput = document.querySelector('#edsLossLetter .p-fileupload-choose') as HTMLElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  deleteFile(item: any, index: number): void {
    const delData = { guid: item?.id ?? item?.guid, index: index };
    const strData = JSON.stringify(delData);
    document.body.style.pointerEvents = 'none';
    this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: ' Do you want to delete ?', data: strData });
  }

  confirmDelete(delData: string): void {
    this.messageService.clear();
    document.body.style.pointerEvents = 'visible';
    this.deletingDocuments(delData);
  }

  convertFileDocumentsToQuestionAnswer(): QuestionAnswer[] {
    let questionAnswers: QuestionAnswer[] = [];
    let fileDocumentsQuestionsAnswer = this.questionGroup.questionAnswers.find((x) => x.key == this.questionKey);
    
    this.uploadedDocuments.forEach((file, index) => {
      let newQuestionAnswer = emptyModal.initializeFrom(fileDocumentsQuestionsAnswer);
      newQuestionAnswer.answer = JSON.stringify(file);
      newQuestionAnswer.key = this.questionKey;
      newQuestionAnswer.rowIndex = index + 1;
      newQuestionAnswer.group = 'VEH-1';
      questionAnswers.push(newQuestionAnswer);
    });
    return questionAnswers;
  }

  onReject(type: string): void {
    this.messageService.clear(type);
    document.body.style.pointerEvents = 'visible';
  }

  private deletingDocuments(delData: string): void {
    let jsonObject = JSON.parse(delData);
    let payload = { urlData: [jsonObject.guid] };
    this.commonService.get('delete', 'FileUpload', payload).subscribe(() => {
        this.uploadedDocuments.splice(jsonObject.index, 1);
      },
      (error) => {
        console.error('HTTP error deleting endorsement file', error);
      }
    );
  }

  private buildFileDocuments() {
    let fileDocument = this.questionGroup.questionAnswers.filter((x) => x.key == this.questionKey && !!x.answer);
    if (fileDocument.length === 0) {
      return;
    }

    fileDocument.forEach((questionAnswer) => {
      let document: DocumentModel = JSON.parse(questionAnswer.answer);
      this.uploadedDocuments.push(document);
    });
    this.initialUploadedDocuments = _.cloneDeep(this.uploadedDocuments);
  }

  isFormDataChanged(): boolean {
    return !_.isEqual(this.initialUploadedDocuments, this.uploadedDocuments)
  }
}