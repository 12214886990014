<div class="container wider-container">
    <div class="main_content">
        <form [formGroup]="lossInfoFG" [style.width]="'100%'" (ngSubmit)="saveAndContinue()">
            <div class="row">
                <div class="col-lg-12  m-2">
                    <span class="text-lg-right float-lg-right">
                        <button type="button" class="btnCancelSub" name="cancelMySubmission" id="cancelMySubmission"
                            title="Cancel my Submission" (click)="cancelMySubmissionPopUp()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Cancel my Submission
                        </button>
                    </span>
                    <h3>Loss History Information</h3>
                </div>
                <liability-and-physical-damage
                    [lossInfoGridFormGroup]="liabPhysicalFG" [isSubmitted]="isSubmitted">
                </liability-and-physical-damage>
                <!-- Large Loss Starts -->
                <div class="col-lg-12" *ngIf="largeLossInformation.isLargeLossIncurred.enabled">
                    <div class="row">
                        <div class="col-lg-9">
                            <label
                                [class.validationred]="isSubmitted && lossInfoFG.get('isLargeLossIncurred')?.invalid"
                                id="lblIsLargeLossIncurred">{{largeLossInformation.isLargeLossIncurred.questionLabel}}
                                <span style="color: red;" *ngIf="largeLossInformation.isLargeLossIncurred.required">
                                    *</span></label>
                        </div>
                        <div class="col-lg-3">
                            <p-radioButton label="Yes" name="isLargeLossIncurred" id="isLargeLossIncurredYes"
                                value="Yes" class="radio_button" formControlName="isLargeLossIncurred"
                                (onClick)="largeLossIncurredChange()">
                            </p-radioButton>
                            <p-radioButton label="No" formControlName="isLargeLossIncurred" name="isLargeLossIncurred"
                                id="isLargeLossIncurredNo" class="radio_button" value="No"
                                (onClick)="largeLossIncurredChange()">
                            </p-radioButton>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="lossInfoFG.value?.isLargeLossIncurred == 'Yes'">
                    <div class="row">
                        <div class="col-lg-9">
                            <label id="lblLargeLossCount">{{largeLossInformation.largeLossCount.questionLabel}}
                                <span style="color: red;" *ngIf="largeLossInformation.largeLossCount.required">
                                    *</span></label>
                        </div>
                        <div class="col-lg-3">
                            <p-dropdown
                                [class.validateDrodown]="isSubmitted && lossInfoFG.get('largeLossCount')?.invalid"
                                formControlName="largeLossCount" id="largeLossCount"
                                [requiredAmtOrDecline]="largeLossInformation.largeLossCount.required"
                                [options]="largeLossInformation.largeLossCount.questionOptions"
                                (onChange)="largeLossCountChange()">
                            </p-dropdown>
                            <span class="validationred"
                                *ngIf="isSubmitted && lossInfoFG.get('largeLossCount')?.hasError('requiredAmtOrDecline')">
                                Auto Liability Large Loss must be Selected
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 mt-2" *ngIf="largeLossInformation.lastFourYearsLosses.enabled">
                    <div class="row">
                        <div class="col-lg-9">
                            <label
                                [class.validationred]="isSubmitted && lossInfoFG.get('lastFourYearsLosses')?.invalid"
                                id="lblHasLastFourYearLosses">{{largeLossInformation.lastFourYearsLosses.questionLabel}}
                                <span style="color: red;" *ngIf="largeLossInformation.lastFourYearsLosses.required">
                                    *</span></label>
                        </div>
                        <div class="col-lg-3">
                            <p-radioButton label="Yes" name="lastFourYearsLosses" id="islastFourYearsLossesYes"
                                value="Yes" class="radio_button" formControlName="lastFourYearsLosses" (onClick)="lastFourYearsLosseChange()">
                            </p-radioButton>
                            <p-radioButton label="No" formControlName="lastFourYearsLosses" name="lastFourYearsLosses"
                                id="islastFourYearsLossesNo" class="radio_button" value="No" (onClick)="lastFourYearsLosseChange()">
                            </p-radioButton>
                        </div>
                    </div>
                </div>
                <div *ngIf="lossInfoFG.value?.lastFourYearsLosses == 'Yes'">
                    <four-year-loss-history [fourYearLossHistoryFormGroup]="fourYearLossHistoryFG" [isSubmitted]="isSubmitted"></four-year-loss-history>
                    <span class="m-3" style="color:#d9a300;" *ngIf="isSubmitted && fourYearLossHistoryFG.lossHistoryFormArray.length == 0 || fourYearLossHistoryFG.newLossHistory?.hasTouched">Please click on "Add Loss" button to save the loss detail.</span>
                    <four-year-loss-history [fourYearLossHistoryFormGroup]="fourYearLossHistoryFG" [isEdit]="true" *ngIf="fourYearLossHistoryFG?.lossHistoryFormArray?.length >0" (resetLastFourYearsLosses)="resetLastFourYearsLosses($event)"></four-year-loss-history>
                </div>
                <!-- Large Loss Ends -->
                <div class="col-lg-12  m-2">
                    <file-upload-form [fileUploadFormGroup]="fileUploadFG" [isSubmitted]="isSubmitted"></file-upload-form>
                </div>

                <div class="col-md-12 px-2" *ngIf="dao.PolicyTemplate == 'losshistory'">
                    <div class="col-lg-12 mt-3">
                        <span class="uplod text-lg-left mr-3">
                            <button type="button" name="onBack" id="onBack" class="btnerror" title="Back"
                                (click)="onBack('uw')">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                Back
                            </button>
                        </span>
                        <span class="text-lg-right float-lg-right">
                            <button type="submit" name="saveAndContinue" id="btnsaveContinueID" class="btnsuccess"
                                title="Save & Continue" data-toggle="modal">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Save & Continue
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-toast position="center" key="CancelMySubmission" (onClose)="onReject('CancelMySubmission')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnLHCancelsubYes"
                            id="btnLHCancelSubYesID" (click)="CancelmySubmission()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnLHPopcancelsubNo"
                            id="btnLHPopcancelsubNoID" (click)="onReject('CancelMySubmission')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast key="RequiredMsg" position="top-center"></p-toast>
</div>
