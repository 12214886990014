import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { dropDownOptions, emptyModal } from 'src/app/modal/DAO';
import { Endorsement, EndorsementQuestionGroup, EndorsementTypes } from 'src/app/modal/Endorsements/Endorsement.Model';
import { Constants } from 'src/app/modal/ProjConstants.models';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { StateSettings } from 'src/app/modal/state.models';
import { CommonService } from 'src/app/services/commonService';
import { AddVehicleModal, DocumentModel } from 'src/app/modal/Endorsements/AddVehicleModel';
import { ListViewModel } from 'src/app/modal/Endorsements/ListView.Model';
import { MessageService } from 'primeng/api';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { FileuploadService } from 'src/app/services/fileupload.service';
import { VehicleFormGroup } from 'src/app/modal/Endorsements/vehicle-form-group'
import { Address } from 'src/app/modal/address.models';
import { AdditionalInsured } from 'src/app/modal/Endorsements/AdditionalInsured.Model';
import { AdditionalInsuredFormGroup } from 'src/app/modal/Endorsements/Addittional-Insured-Form-Group';
import { EndorsementService } from 'src/app/services/endorsement.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { FileUploadFormComponent } from '../file-upload-form/file-upload-form.component';

@Component({
  selector: 'add-vehicle',
  templateUrl: './add-vehicle.component.html'
})

export class AddVehicleComponent implements OnInit, OnDestroy, IBasePage {

  @ViewChild('edsFileUploadForm') edsFileUploadForm: FileUploadFormComponent;

  readonly toastValidationMessage: string = 'Please answer the required questions.';
  public endorsementQuestionGroup: EndorsementQuestionGroup;
  public vehicleTypes: Array<dropDownOptions> = Constants.VehicleTypesDropDown;
  public interestTypes: Array<dropDownOptions> = Constants.InterestTypesDropDown;
  public fileNetAllowedFileExtensions = Constants.FileNetAllowedFileExtensions.toString();
  public addVehicleForm: FormGroup;
  public isSubmitted: boolean = false;
  public isSaveAndSubmit: boolean = false;
  private interestListIndex: number;
  private menuClickSubscription: Subscription;
  private menuClickContext: MenuClickContext;
  public endorsement: Endorsement = new Endorsement();
  public vehicleDetailsList: ListViewModel<AddVehicleModal>;
  public lossLetterDocuments: Array<DocumentModel> = [];
  public initialLossLetterDocuments: Array<DocumentModel> = [];
  public isEndorsementPolicyEffectivePriorToToday: boolean = false;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private fileuploadService: FileuploadService,
    private commonService: CommonService,
    public stateSettings: StateSettings,
    public router: Router,
    private endorsementService: EndorsementService
  ) {
    this.addVehicleForm = this.formBuilder.group({});
    this.endorsement = this.router.getCurrentNavigation()?.extras?.state?.endorsement;
    if (!!this.endorsement && this.endorsement.IsEndorsementPolicyEffectivePriorToToday == null) {
      this.endorsement = new Endorsement(this.endorsement);
    }
  }

  ngOnInit(): void {
    this.initializeData();
    this.menuClickSubscription = this.endorsementService.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
  }

  // Initialize component data
  public async initializeData(): Promise<void> {
    await this.endorsementService.reloadQuestionGroups(this.endorsement);
    this.endorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.AddVehicle);
    this.getQuestionAnswers().then(questionAnswers => {
      if (!!questionAnswers && questionAnswers.length > 0) {
        this.endorsementQuestionGroup.questionAnswers = questionAnswers;
        this.vehicleDetailsList = new ListViewModel<AddVehicleModal>(
          AddVehicleModal.initializeArrayFrom(questionAnswers),
          this.beforeAdd,
          this.afterAdd,
          this.afterDelete,
          this.initializeNewItem
        );
        this.buildReactiveForm();
        this.buildLossLetterDocuments(this.endorsementQuestionGroup.questionAnswers);
      }
    }).catch(error => {
      console.error(error);
    })
  }

  // Get question answers from API
  private getQuestionAnswers(): Promise<QuestionAnswer[]> {
    let payload = { "urlData": [this.endorsement.endorsementId, EndorsementTypes.AddVehicle] };
    return this.commonService.get('endorsement', 'getQuestionAnswers', payload).toPromise()
      .then(result => { return result })
      .catch(error => Promise.reject(error));
  }

  public remove(i: number): void {
    this.interestListIndex = i;
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'delete', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
  }

  public confirmRemove(type: string, fileDoc: string): void {
    if (!!fileDoc) {
      this.deletingDocuments(fileDoc);
    }
    else {
      let additionalInsuredArray = this.addVehicleForm.get('additionalInsuredList') as FormArray;
      additionalInsuredArray.removeAt(this.interestListIndex);
      this.vehicleDetailsList.currentItem.AdditionalInsuredList.splice(this.interestListIndex, 1);
    }
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
  }

  public onReject(type: string): void {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
  }

  public validateAndAddVehicle(): void {
    this.isSubmitted = true;
    let vinErrorMsg: string = this.isValidVinNumber();
    if (this.isPageValid()) {
      this.isSubmitted = false;
      this.addItem()
      this.isSubmitted = false;
    }
    else {
      let errorMsg: string = !!vinErrorMsg ? `${this.toastValidationMessage} \n ${vinErrorMsg}` : this.toastValidationMessage;
      this.openErrorToast(errorMsg);
    }
  }

  private isValidVinNumber(): string {
    if (!this.isTrailer && this.vehicleDetailsList.currentItem.vehicleVin?.answer?.length > 0) {
      if (this.vehicleDetailsList.currentItem.vehicleYear?.answer < 1981
        && (this.vehicleDetailsList.currentItem.vehicleVin?.answer?.length > 13 || this.vehicleDetailsList.currentItem.vehicleVin?.answer?.length < 5)) {
        return ' Vehicle VIN # must be minimum of 5 and maximum of 13 characters.';
      }
      else if (this.vehicleDetailsList.currentItem.vehicleYear?.answer >= 1981 && this.vehicleDetailsList.currentItem.vehicleVin?.answer?.length != 17) {
        return ' Vehicle VIN # must be equal to 17 characters.';
      }
    }
    return '';
  }

  private verifyDuplicateVinNumber(): boolean {
    let result: boolean = true;
    this.vehicleDetailsList.items.forEach(vehicle => {
      vehicle["isValid"] = true;
    });

    this.vehicleDetailsList.items.forEach((vehicle, i) => {
      this.vehicleDetailsList.items.slice(i + 1).forEach(otherVehicle => {
        if (!!vehicle.vehicleVin.answer && !!otherVehicle.vehicleVin.answer && vehicle.vehicleVin.answer === otherVehicle.vehicleVin.answer) {
          vehicle["isValid"] = false;
          otherVehicle["isValid"] = false;
          result = false;
        }
      });
    });
    return result;
  }

  // Save and continue
  //  Validate the uploaded loss runs only when the "Save and Continue" button is clicked.
  //  Checking isValidLossRuns in isPageValid() would cause the validation message
  //  to appear when the "Add Another Unit" button is clicked, which is not our requirement.
  public saveAndContinue(type = ""): void {
    this.isSubmitted = true;
    let vinErrorMsg: string = this.isValidVinNumber();
    let isValidLossRuns = this.isEndorsementPolicyEffectivePriorToToday ? this.lossLetterDocuments.length > 0 : true;
    if (this.isPageValid() && isValidLossRuns) {
      if (this.verifyDuplicateVinNumber()) {
        this.postVehicleDetails().then(isSuccess => {
          if (isSuccess) {
            this.isSubmitted = false;
            let currrentEndorsementQuestionGroup : EndorsementQuestionGroup = this.endorsement.selectedEndorsementGroups.find(x => x.groupKey == EndorsementTypes.AddVehicle);
            currrentEndorsementQuestionGroup.hasAnswers = true;
            currrentEndorsementQuestionGroup.isValidPage = true;
            this.endorsement.userIsAt = '';
            if (type === "finalSubmit") {
                this.endorsementService.updateStatusToSubmitted(this.endorsement.endorsementId);
            }
            else if (type === "draft") {
              this.router.navigate(['/home'], { state: { data: 'endorsement' } });
            }
            // if menuClickContext is current page or not set, get and update next menuClickContext
            if (!this.menuClickContext || this.menuClickContext?.page === EndorsementTypes.AddVehicle) {
              this.endorsementService.menuClickBehavior.next(this.endorsementService.getNextMenuClickContext(EndorsementTypes.AddVehicle));
            }
          }
        }).catch(error => {
          console.error(error);
          this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Add Vehicle', detail: error });
        });
      }
      else {
        this.openErrorToast('Duplicate VIN numbers have been identified, please correct the vehicles highlighted in red');
      }
    }
    else {
      let errorMsg: string = !!vinErrorMsg ? `${this.toastValidationMessage} \n ${vinErrorMsg}` : this.toastValidationMessage;
      this.openErrorToast(errorMsg);
    }
  }

  public clickedItem(index: number): void {
    let vinErrorMsg: string = this.isValidVinNumber();
    if ((!this.addVehicleForm.valid || !!vinErrorMsg) && index != this.vehicleDetailsList.currentPosition) {
      this.isSubmitted = true;
      let errorMsg: string = !!vinErrorMsg ? `${this.toastValidationMessage} \n ${vinErrorMsg}` : this.toastValidationMessage;
      this.openErrorToast(errorMsg);
      return;
    }
    this.vehicleDetailsList.currentPosition = index;
    this.isSubmitted = false;
    this.buildReactiveForm();
  }

  // Build reactive form
  public buildReactiveForm(): void {
    this.addVehicleForm = new VehicleFormGroup(this.vehicleDetailsList.currentItem);
  }

  public addInterest(): void {
    let newAdditinalInsured = new AdditionalInsured();

    Object.keys(newAdditinalInsured).forEach(interest => {
      if (newAdditinalInsured[interest] instanceof emptyModal) {
        let questionAnswer: QuestionAnswer = this.endorsementQuestionGroup.questionAnswers.find(x => x.key == interest);
        newAdditinalInsured[interest] = emptyModal.initializeFrom(questionAnswer);
        newAdditinalInsured[interest].answer = '';
      }
    });

    newAdditinalInsured.address = new Address();
    this.vehicleDetailsList.currentItem.AdditionalInsuredList.push(newAdditinalInsured);
    let additionalInsuredList = this.addVehicleForm.get('additionalInsuredList') as FormArray
    additionalInsuredList.clear();
    this.vehicleDetailsList.currentItem.AdditionalInsuredList.map(insuredList => additionalInsuredList.push(new AdditionalInsuredFormGroup(insuredList, this.formBuilder)));
  }

  public beforeAdd = (): boolean => {
    this.isSubmitted = true;
    if (!this.isPageValid()) {
      this.openErrorToast(this.toastValidationMessage);
      return false;
    }
    return true;
  }

  public afterAdd = (): void => {
    this.isSubmitted = false;
    this.addVehicleForm = new VehicleFormGroup(this.vehicleDetailsList.currentItem);
  }

  public afterDelete = (): void => {
    this.isSubmitted = false;
    this.buildReactiveForm();
  }

  // Initialize new item
  public initializeNewItem = (): AddVehicleModal => {
    return AddVehicleModal.createNewInstanceFromQuestionAnswers(this.endorsementQuestionGroup.questionAnswers);
  }

  public addItem(): void {
    this.vehicleDetailsList.add();
  }

  private buildLossLetterDocuments(questionAnswers: QuestionAnswer[]) {
    let lossLetters = questionAnswers.filter(x => x.key == "lossLetter" && !!x.answer);
    if (lossLetters.length > 0) {
      lossLetters.forEach(questionAnswer => {
        let document: DocumentModel = JSON.parse(questionAnswer.answer);
        this.lossLetterDocuments.push(document);
      })
    }
    this.initialLossLetterDocuments = _.cloneDeep(this.lossLetterDocuments);
    this.isEndorsementPolicyEffectivePriorToToday = this.endorsement.IsEndorsementPolicyEffectivePriorToToday();
  }

  // Convert loss documents to question answer
  private convertLossDocumentsToQuestionAnswer(): QuestionAnswer[] {
    let questionAnswers: QuestionAnswer[] = []
    let lossLetterQuestionsAnswer = this.endorsementQuestionGroup.questionAnswers.find(x => x.key == "lossLetter");
    if (this.lossLetterDocuments.length > 0) {
      this.lossLetterDocuments.forEach((file, index) => {
        let newQuestionAnswer = emptyModal.initializeFrom(lossLetterQuestionsAnswer);
        newQuestionAnswer.answer = JSON.stringify(file);
        newQuestionAnswer.rowIndex = index + 1;
        newQuestionAnswer.group = 'VEH-1';
        questionAnswers.push(newQuestionAnswer);

      })
    }
    return questionAnswers;
  }

  // On click File upload button
  public fileUpload(): void {
    let fileInput = document.querySelector('.p-fileupload-choose') as HTMLElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  // On select any files
  public onUpload(event: any): void {
    let response = this.fileuploadService.onUploadingFiles(event.files, this.lossLetterDocuments, 'endorsement', this.endorsementQuestionGroup.endorsementId.toString());
    if (!!response) {
      this.messageService.add({ key: 'toast', severity: 'error', summary: 'Add Vehicle', detail: !!response ? response : this.toastValidationMessage });
    }
  }

  // On click file upload
  public clickUpload(event: any): void {
    if (event && event.target && event.target.className == 'p-fileupload-content') {
      this.fileUpload();
    }
  }

  public deleteFile(item: any, index: number): void {
    let delData = { guid: item?.id ?? item?.guid, index: index }
    let strData = JSON.stringify(delData);
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'delete', sticky: true, severity: 'warn', summary: ' Do you want to delete ?', data: strData });
  }

  private deletingDocuments(delData: string): void {
    let jsonObject = JSON.parse(delData);
    let payload = { "urlData": [jsonObject.guid] }
    this.commonService.get('delete', 'FileUpload', payload).subscribe(
      (event: any) => {
        this.lossLetterDocuments.splice(jsonObject.index, 1);
      },
      (error) => {
        console.error('HTTP error deleting endorsement file', error);
      }
    );
  }

  private postVehicleDetails(): Promise<boolean> {
    this.bindAnswersToQuestions();
    let payload = { "payloadData": this.endorsementQuestionGroup }
    return this.commonService.post('endorsement', 'postQuestionAnswers', payload).toPromise()
      .then(result => {
        return result;
      })
      .catch(error => Promise.reject(error));
  }


  public bindAnswersToQuestions(): void {
    let questionAnswers: QuestionAnswer[] = [];
    this.vehicleDetailsList.items.forEach((vehicle, i) => {
      let vehicleQuestionAnswers = vehicle.toQuestionAnswers(i + 1);
      questionAnswers.push(...vehicleQuestionAnswers)
    });
    questionAnswers.push(...this.convertLossDocumentsToQuestionAnswer());
    questionAnswers.push(...this.edsFileUploadForm.convertFileDocumentsToQuestionAnswer());
    this.endorsementQuestionGroup.questionAnswers = questionAnswers;
  }

  private openErrorToast(body: string): void {
    this.messageService.add({ key: 'toast', severity: 'error', summary: 'Endorsements - Add Vehicle', detail: body });
  }

  // Function to check if the page is valid
  // While validating any other fields except addVehicleForm,
  // Make sure it will work correctly according to our requirements.
  public isPageValid(): boolean {
    return this.addVehicleForm.valid
      && (!this.vehicleDetailsList.currentItem.uploadedDocuments.required || (this.vehicleDetailsList.currentItem.uploadedDocuments.required && this.edsFileUploadForm.uploadedDocuments.length > 0));
  }

  public menuClicked(menuClickContext: MenuClickContext): void {
    this.menuClickContext = menuClickContext;
    if (menuClickContext != null && menuClickContext.page != EndorsementTypes.AddVehicle) {
      if (!this.addVehicleForm || !this.edsFileUploadForm || !this.lossLetterDocuments) {
        this.menuClickContext.page = EndorsementTypes.AddVehicle;
        return;
      }
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = this.addVehicleForm.touched
        || !_.isEqual(this.vehicleDetailsList.initialItems, this.vehicleDetailsList.items)
        || this.edsFileUploadForm.isFormDataChanged()
        || !_.isEqual(this.initialLossLetterDocuments, this.lossLetterDocuments);
      if (this.endorsementService.isMenuClickHeaderPage(menuClickContext)) {
        this.menuClickContext.page = EndorsementTypes.AddVehicle;
        return;
      }
      if (menuClickContext.currentPageIsValid && menuClickContext.currentPageDataChanged) {
        this.saveAndContinue();
      }
      this.endorsementService.handleUnsavedChangesOrNavigateToUrl(menuClickContext, this.endorsement);
    }
  }

  public onRollback(currentTempage: string): void {
    // Rollback is not needed
  }

  public onBack(page: string): void {
    //There is no back button
  }

  ngOnDestroy(): void {
    if (!!this.menuClickSubscription) {
      this.menuClickSubscription.unsubscribe();
    }
  }

  get isTrailer(): boolean {
    return this.vehicleDetailsList.currentItem?.vehicleType?.answer == 'Trailer';
  }
}