<div class="container">

    <form #productForm="ngForm" style="width:100%"
    [selectEitherOrOption]="[(!!productForm?.controls?.commercialAutoGroup?.controls['umBiLimit'] && !!productForm?.controls?.commercialAutoGroup?.controls['umPdPrd'] ? true : false)
    || (!!productForm?.controls?.commercialAutoGroup?.controls['uimBi'] && !!productForm?.controls?.commercialAutoGroup?.controls['uimBiPrd'] ? true : false)]"
    (ngSubmit)="submitForm(productForm)">
        <div class="main_content mt-2">
            <div class="row mt-0">
                <div class="col-lg-6 text-lg-left float-lg-left p-0">
                    <div class="mt-0 col-lg-12 productheader">Policy Coverage </div>
                    <div class="mt-0 col-lg-12 productsubheader">Please select applicable coverages and limits.</div>
                </div>
                <div class="col-lg-6 text-lg-right float-lg-right">
                    <span class="text-lg-right float-lg-right">
                        <button type="button" class="btnCancelSub" name="btnLHCancelSub" id="btnLHCancelSubID"
                            title="Cancel my Submission" (click)="cancelMySubmissionPopUp()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Cancel my Submission
                        </button>
                    </span>
                </div>
                <div class="col-lg-12 mt-3">
                    <p-tabView styleClass="tabview-custom" (onChange)="setselectedIndex($event)"
                        [(activeIndex)]="activeTab">
                        <p-tabPanel header="Commercial Auto" [selected]="activeTab == 0" ngModelGroup="commercialAutoGroup" #commercialAutoGroup="ngModelGroup" >
                            <ng-template pTemplate="header">
                                <span [class.validationred] = "((productForm.submitted || dao?.isSubmitForm) && !productForm.controls?.commercialAutoGroup?.valid)">
                                    <i class="fa fa-automobile mr-2"></i>
                                    <span>Commercial Auto</span>
                                </span>
                            </ng-template>

                            <default-coverage 
                                *ngIf="questionnaire"
                                [questionnaire]="questionnaire"
                                [isFormSubmitted]="productForm.submitted">
                            </default-coverage>

                            <div class="row mb-1" *ngIf="!umReject || (umReject && umReject?.answer=='No')">
                                <div class="col-lg-12">
                                    <label><b><u>Uninsured Motorist/Underinsured Motorist</u></b></label>
                                </div>
                            </div>
                            <uninsured-motorist 
                                *ngIf="questionnaire && (!umReject || (umReject && umReject?.answer=='No'))"
                                [questionnaire]="questionnaire"
                                [isFormSubmitted]="productForm.submitted"
                                [umBiLimitQuestionBehaviore] ="umBiLimitQuestionBehaviore"
                                [umPdPrdQuestionBehaviore] ="umPdPrdQuestionBehaviore"
                                [productForm]=productForm
                                >
                            </uninsured-motorist>

                            <underinsured-motorist 
                                *ngIf="questionnaire" 
                                [questionnaire]="questionnaire"
                                [isFormSubmitted]="productForm.submitted"
                                [umBiLimit]="productForm.value?.commercialAutoGroup?.umBiLimit"
                                [umPdPrd]="productForm.value?.commercialAutoGroup?.umPdPrd"
                                [umBiLimitQuestionBehaviore] ="umBiLimitQuestionBehaviore"
                                [umPdPrdQuestionBehaviore] ="umPdPrdQuestionBehaviore"
                                [uimBiQuestionBehaviore]="uimBiQuestionBehaviore"
                                (uimBiChanged)="UnderinsuredRuleCheck()"
                                [productForm]=productForm>
                            </underinsured-motorist>

                            <div class="row mb-1"
                                *ngIf="dao.ProductModel.productDetails.nfPipLimits.enabled || dao.ProductModel.productDetails.nfPipDed.enabled">
                                <div class="col-lg-12">
                                    <label><b><u>No-Fault Coverages</u></b></label>
                                </div>
                            </div>
                            <div class="row mb-1" *ngIf="dao.ProductModel.productDetails.nfPipLimits.enabled">
                                <div class="col-lg-6">
                                    <label name="lblnfPipLimits"
                                        id="lblnfPipLimitsID">{{dao.ProductModel.productDetails.nfPipLimits.questionLabel}}
                                    </label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.nfPipLimits?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <p-dropdown
                                        [class.validateDrodown]="(productForm.submitted || dao?.isSubmitForm || !nfPipLimits.pristine) && nfPipLimits?.invalid"
                                        placeholder="{{dao?.ProductModel?.productDetails?.nfPipLimits?.questionHelpText ? '':dao?.ProductModel?.productDetails?.nfPipLimits?.questionHelpText}}"
                                        #nfPipLimits="ngModel" name="nfPipLimits" id="nfPipLimitsID"
                                        [(ngModel)]="dao.ProductModel.productDetails.nfPipLimits.answer"
                                        [requiredAmtOrDecline]="dao?.ProductModel?.productDetails?.nfPipLimits?.required"
                                        [options]="dao?.ProductModel?.productDetails?.nfPipLimits?.questionOptions"
                                        (onChange)="dao.ProductModel.productDetails?.pdlBuyback?.enabled ? nfLimitOnChange() : '' "
                                        [disabled]="(accountState == 'TX' || accountState == 'DE') ? isNFPipLimitDisabled : !this.dao.ProductModel.productDetails.pdlBuyback.enabled && !!this.dao.ProductModel.productDetails.nfPipLimits.answer">

                                    </p-dropdown>
                                    <span class="validationred"
                                        *ngIf="(productForm.submitted || !nfPipLimits.pristine) && nfPipLimits.errors?.requiredAmtOrDecline">
                                        {{dao.ProductModel.productDetails.nfPipLimits.questionLabel}} is required
                                    </span>
                                </div>
                            </div>

                            <!-- MI State No Fault Starts -->
                            <!-- NF_250kWithPIPME_Child 1 -->
                            <div
                                *ngIf="dao.ProductModel.productDetails.nfPipLimits.answer == '250kWithPIPME' && !hideNFChild">
                                <div class="col-lg-12 row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child1?.questionLabel}}
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child1?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="NF_250kWithPIPME_Child1Name"
                                                id="NF_250kWithPIPME_Child1YesID" value="Yes" class="radio_button"
                                                inputId="Yes" #NF_250kWithPIPME_Child1="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_250kWithPIPME_Child1.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child1?.required"
                                                (click)="checkNFIsNotQualify('250kWithPIPME','NF_250kWithPIPME_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_250kWithPIPME_Child1.pristine) && NF_250kWithPIPME_Child1.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="nf250kChild1">Yes</label>
                                            <p-radioButton name="NF_250kWithPIPME_Child1Name"
                                                id="NF_250kWithPIPME_Child1ID" class="radio_button" value="No"
                                                inputId="No" #NF_250kWithPIPME_Child1="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_250kWithPIPME_Child1.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child1?.required"
                                                (click)="checkNFIsNotQualify('250kWithPIPME','NF_250kWithPIPME_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_250kWithPIPME_Child1.pristine) && NF_250kWithPIPME_Child1.invalid">
                                            </p-radioButton>
                                            <label for="nf250kChild1" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- NF_250kWithPIPME_Child 2 -->
                                <div class="col-lg-12 row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child2?.questionLabel}}
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child2?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="NF_250kWithPIPME_Child2Name"
                                                id="NF_250kWithPIPME_Child2YesID" value="Yes" class="radio_button"
                                                inputId="Yes" #NF_250kWithPIPME_Child2="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_250kWithPIPME_Child2.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child2?.required"
                                                (click)="checkNFIsNotQualify('250kWithPIPME','NF_250kWithPIPME_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_250kWithPIPME_Child2.pristine) && NF_250kWithPIPME_Child2.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="nf250kChild2">Yes</label>
                                            <p-radioButton name="NF_250kWithPIPME_Child2Name"
                                                id="NF_250kWithPIPME_Child2ID" class="radio_button" value="No"
                                                inputId="No" #NF_250kWithPIPME_Child2="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_250kWithPIPME_Child2.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_250kWithPIPME_Child2?.required"
                                                (click)="checkNFIsNotQualify('250kWithPIPME','NF_250kWithPIPME_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_250kWithPIPME_Child2.pristine) && NF_250kWithPIPME_Child2.invalid">
                                            </p-radioButton>
                                            <label for="nf250kChild2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- NF_50K_Child1 -->
                            <div *ngIf="dao.ProductModel.productDetails.nfPipLimits.answer == '50,000' && !hideNFChild">
                                <div class="col-lg-12 row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.NF_50K_Child1?.questionLabel}}
                                            <span *ngIf="dao?.ProductModel?.productDetails?.NF_50K_Child1?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="NF_50K_Child1Name" id="NF_50K_Child1YesID" value="Yes"
                                                class="radio_button" inputId="Yes" #NF_50K_Child1="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_50K_Child1.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_50K_Child1?.required"
                                                (click)="checkNFIsNotQualify('50,000','NF_50K_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_50K_Child1.pristine) && NF_50K_Child1.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="nf50kChild1">Yes</label>
                                            <p-radioButton name="NF_50K_Child1Name" id="NF_50K_Child1ID"
                                                class="radio_button" value="No" inputId="No" #NF_50K_Child1="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_50K_Child1.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_50K_Child1?.required"
                                                (click)="checkNFIsNotQualify('50,000','NF_50K_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_50K_Child1.pristine) && NF_50K_Child1.invalid">
                                            </p-radioButton>
                                            <label for="nf50kChild1" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- NF_50K_Child2 -->
                                <div class="col-lg-12 row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.NF_50K_Child2?.questionLabel}}
                                            <span *ngIf="dao?.ProductModel?.productDetails?.NF_50K_Child2?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="NF_50K_Child2Name" id="NF_50K_Child2YesID" value="Yes"
                                                class="radio_button" inputId="Yes" #NF_50K_Child2="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_50K_Child2.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_50K_Child2?.required"
                                                (click)="checkNFIsNotQualify('50,000','NF_50K_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_50K_Child2.pristine) && NF_50K_Child2.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="nf50kChild2">Yes</label>
                                            <p-radioButton name="NF_50K_Child22Name" id="NF_50K_Child2ID"
                                                class="radio_button" value="No" inputId="No" #NF_50K_Child2="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_50K_Child2.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_50K_Child2?.required"
                                                (click)="checkNFIsNotQualify('50,000','NF_50K_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_50K_Child2.pristine) && NF_50K_Child2.invalid">
                                            </p-radioButton>
                                            <label for="nf50kChild2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- NF_PIPMedOptOut_Child1 -->
                            <div
                                *ngIf="dao.ProductModel.productDetails.nfPipLimits.answer == 'PIPMedOptOut' && !hideNFChild">
                                <div class="col-lg-12 row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child1?.questionLabel}}
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child1?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="NF_PIPMedOptOut_Child1Name"
                                                id="NF_PIPMedOptOut_Child1YesID" value="Yes" class="radio_button"
                                                inputId="Yes" #NF_PIPMedOptOut_Child1="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_PIPMedOptOut_Child1.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child1?.required"
                                                (click)="checkNFIsNotQualify('PIPMedOptOut','NF_PIPMedOptOut_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_PIPMedOptOut_Child1.pristine) && NF_PIPMedOptOut_Child1.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="NF_PIPMedOptOut_Child1">Yes</label>
                                            <p-radioButton name="NF_PIPMedOptOut_Child1Name"
                                                id="NF_PIPMedOptOut_Child1ID" class="radio_button" value="No"
                                                inputId="No" #NF_PIPMedOptOut_Child1="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_PIPMedOptOut_Child1.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child1?.required"
                                                (click)="checkNFIsNotQualify('PIPMedOptOut','NF_PIPMedOptOut_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_PIPMedOptOut_Child1.pristine) && NF_PIPMedOptOut_Child1.invalid">
                                            </p-radioButton>
                                            <label for="NF_PIPMedOptOut_Child1" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- NF_PIPMedOptOut_Child2 -->
                                <div class="col-lg-12 row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child2?.questionLabel}}
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child2?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="NF_PIPMedOptOut_Child2Name"
                                                id="NF_PIPMedOptOut_Child2YesID" value="Yes" class="radio_button"
                                                inputId="Yes" #NF_PIPMedOptOut_Child2="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_PIPMedOptOut_Child2.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child2?.required"
                                                (click)="checkNFIsNotQualify('PIPMedOptOut','NF_PIPMedOptOut_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_PIPMedOptOut_Child2.pristine) && NF_PIPMedOptOut_Child2.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="NF_PIPMedOptOut_Child2">Yes</label>
                                            <p-radioButton name="NF_PIPMedOptOut_Child2Name"
                                                id="NF_PIPMedOptOut_Child2ID" class="radio_button" value="No"
                                                inputId="No" #NF_PIPMedOptOut_Child2="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.NF_PIPMedOptOut_Child2.answer"
                                                [required]="dao?.ProductModel?.productDetails?.NF_PIPMedOptOut_Child2?.required"
                                                (click)="checkNFIsNotQualify('PIPMedOptOut','NF_PIPMedOptOut_Child')"
                                                [class.radioButtonValidator]="(productForm.submitted || !NF_PIPMedOptOut_Child2.pristine) && NF_PIPMedOptOut_Child2.invalid">
                                            </p-radioButton>
                                            <label for="NF_PIPMedOptOut_Child2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- NF Not Qualify Error Message-->
                            <div class="row mb-1" *ngIf="IsShowNFNotQualifyErrorMsg">
                                <label
                                    class="col-lg-12 color_red">{{dao?.ProductModel?.productDetails?.NF_NotQualify?.questionLabel}}</label>
                            </div>
                            <div class="row mb-1"
                                *ngIf="dao.ProductModel.productDetails.nfPipDed.enabled && 
                        (!dao.ProductModel.productDetails?.pdlBuyback?.enabled || (dao.ProductModel.productDetails?.pdlBuyback?.enabled && accountLegalEntityAnswer == 'INDIVIDUAL'))">
                                <div class="col-lg-6">
                                    <label name="lblnfPipDed"
                                        id="lblnfPipDedID">{{dao.ProductModel.productDetails.nfPipDed.questionLabel}}
                                    </label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.nfPipDed?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <p-dropdown
                                        [class.validateDrodown]="(productForm.submitted || dao?.isSubmitForm || !nfPipDed.pristine) && nfPipDed?.invalid"
                                        placeholder="{{dao?.ProductModel?.productDetails?.nfPipDed?.questionHelpText ? '':dao?.ProductModel?.productDetails?.nfPipDed?.questionHelpText}}"
                                        #nfPipDed="ngModel" name="nfPipDed" id="nfPipDedID"
                                        [(ngModel)]="dao.ProductModel.productDetails.nfPipDed.answer"
                                        [requiredAmtOrDecline]="dao?.ProductModel?.productDetails?.nfPipDed?.required"
                                        [options]="dao?.ProductModel?.productDetails?.nfPipDed?.questionOptions"
                                        [disabled]="accountState == 'DE' ? dao.ProductModel.productDetails.nfPipDed.questionOptions?.length == 1 : false"
                                        (onChange)="onChangeNfPipDed(dao.ProductModel.productDetails.nfPipDed.answer)">

                                    </p-dropdown>
                                    <span class="validationred"
                                        *ngIf="(productForm.submitted || !nfPipDed.pristine) && nfPipDed.errors?.requiredAmtOrDecline">
                                        Personal Injury Protection Deductibles is required
                                    </span>
                                </div>
                            </div>

                            <no-fault *ngIf="questionnaire" [questionnaire]="questionnaire"
                                [nfPipDed]="dao?.ProductModel?.productDetails?.nfPipDed?.answer"
                                [isFormSubmitted]="productForm.submitted">
                            </no-fault>
                            <broadened-pip *ngIf="questionnaire" [questionnaire]="questionnaire"
                                [isFormSubmitted]="productForm.submitted"
                                [broadenedPIPDeductibleValue]="dao?.ProductModel?.productDetails?.nfPipDed?.answer">
                            </broadened-pip>
                            <div *ngFor="let named of dao?.AddAnotherNamedIndividual; let i= index;">
                                <div class="row mb-1"
                                    *ngIf="dao?.ProductModel?.productDetails?.namedIndividual?.enabled && !named.isDeleted">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.namedIndividual?.questionLabel}}</label>
                                        <span *ngIf="dao?.ProductModel?.productDetails?.namedIndividual?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-5">
                                        <input type="text"
                                            class="{{(productForm.submitted || dao?.isSubmitForm) && (!namedIndividual?.valid || named.Name == null) ? 'form-control validate_error_cls' : 'form-control'}}"
                                            [required]="dao?.ProductModel?.productDetails?.namedIndividual?.required"
                                            name="namedIndividual{{i}}" #namedIndividual="ngModel"
                                            id="namedIndividualID_{{i}}" [(ngModel)]="named.Name">
                                        <span class="validationred"
                                            *ngIf="(productForm.submitted || dao?.isSubmitForm) && (!namedIndividual?.valid || named.Name == null)">
                                            {{namedIndividualValidationMessage}}
                                        </span>
                                    </div>
                                    <div class="col-lg-1">
                                        <span *ngIf="i != 0"><i class="fa fa-trash delete_icon" title="delete"
                                                (click)="removeProduct(i,'namedIndividual',named.Name)"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1" style="margin-right: 1px;">
                                <div class="col-lg-12 text-right"
                                    *ngIf="dao?.ProductModel?.productDetails?.namedIndividual?.enabled && getNamedIndividualCount">
                                    <a style="color: #115740;" (click)="AddNamedIndividual()">Add Another Named
                                        Individual</a>
                                </div>
                            </div>
                            <div *ngIf="nfRateBasis?.enabled && dao.ProductModel.productDetails.namedIndividual?.enabled">
                                <dropdown-question
                                    class="row mb-1"
                                    #nfRateBasisDropdown
                                    [question]="nfRateBasis"
                                    [disabled]="true"
                                    [isFormSubmitted]="isFormSubmitted">
                                </dropdown-question>
                            </div>
                            <div class="row mb-1" *ngIf="dao.ProductModel.productDetails.pdlBuyback.enabled">
                                <!-- Property Liability Buyback -->
                                <div class="col-lg-12 row mb-1"
                                    *ngIf="dao.ProductModel.productDetails.pdlBuyback.enabled">
                                    <div class="col-lg-6">
                                        <label class="float-left" name="lblpdlBuyBack"
                                            id="lblpdlBuyBackID">{{dao.ProductModel.productDetails.pdlBuyback.questionLabel}}
                                        </label>
                                        <span *ngIf="dao?.ProductModel?.productDetails?.pdlBuyback?.required"
                                            style="color: red;">*</span>

                                    </div>
                                    <div class="col-lg-6 p-field-checkbox" style="left: 15px">
                                        <p-checkbox
                                            [class.invalid_div_cls]="(productForm.submitted || dao?.isSubmitForm || !pdlBuyback.pristine) && pdlBuyback.invalid"
                                            name="pdlBuyback" id="pdlBuybackID" type="checkbox" binary="true"
                                            [(ngModel)]="dao.ProductModel.productDetails.pdlBuyback.answer"
                                            #pdlBuyback="ngModel">
                                        </p-checkbox>
                                        <span class="validationred"
                                            *ngIf="(productForm.submitted || !pdlBuyback.pristine) && pdlBuyback.errors?.checkboxValidator">
                                            {{dao.ProductModel.productDetails.pdlBuyback.questionLabel}} is required
                                        </span>
                                    </div>
                                </div>
                                <!-- Worker's Compensation -->
                                <div class="col-lg-12 row mb-1"
                                    *ngIf="dao.ProductModel.productDetails.workersCompensation.enabled">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.workersCompensation?.questionLabel}}
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.workersCompensation?.required"
                                                style="color: red;">*</span>
                                        </label>
                                    </div>
                                    <div class="col-lg-6" style="left: 15px">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="workersCompensationName" id="workersCompensationYesID"
                                                value="Yes" class="radio_button" inputId="Yes"
                                                #workersCompensation="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.workersCompensation.answer"
                                                [required]="dao?.ProductModel?.productDetails?.workersCompensation?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !workersCompensation.pristine) && workersCompensation.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="city1">Yes</label>
                                            <p-radioButton name="workersCompensationName" id="workersCompensationID"
                                                class="radio_button" value="No" inputId="No"
                                                #workersCompensation="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.workersCompensation.answer"
                                                [required]="dao?.ProductModel?.productDetails?.workersCompensation?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !workersCompensation.pristine) && workersCompensation.invalid">
                                            </p-radioButton>
                                            <label for="city2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- NF property protection-->
                            <div class="row mb-1" *ngIf="dao.ProductModel.productDetails.propertyProtection.enabled">
                                <div class="col-lg-6">
                                    <label name="lblpropertyProtection"
                                        id="lblumPdPrdID">{{dao.ProductModel.productDetails.propertyProtection.questionLabel}}
                                    </label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.propertyProtection?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <p-dropdown
                                        [class.validateDrodown]="(productForm.submitted || dao?.isSubmitForm || !propertyProtection.pristine) && propertyProtection?.invalid"
                                        #propertyProtection="ngModel" name="propertyProtection"
                                        id="propertyProtectionID"
                                        [(ngModel)]="dao.ProductModel.productDetails.propertyProtection.answer"
                                        [requiredAmtOrDecline]="dao?.ProductModel?.productDetails?.propertyProtection?.required"
                                        [options]="dao?.ProductModel?.productDetails?.propertyProtection?.questionOptions">
                                    </p-dropdown>
                                    <span class="validationred"
                                        *ngIf="(productForm.submitted || !propertyProtection.pristine) && propertyProtection.errors?.requiredAmtOrDecline">
                                        {{dao.ProductModel.productDetails.propertyProtection.questionLabel}} is required
                                    </span>
                                </div>
                            </div>

                            <div class="row mb-1" *ngIf="dao.ProductModel.productDetails.mcca.enabled">
                                <div class="col-lg-6">
                                    <label name="lblmcca"
                                        id="lblmccaID">{{dao.ProductModel.productDetails.mcca.questionLabel}}
                                    </label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.mcca?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6 p-field-checkbox">
                                    <p-checkbox name="mcca" id="mccaID" type="checkbox" binary="true"
                                        [(ngModel)]="dao.ProductModel.productDetails.mcca.answer"
                                        [checkboxValidator]="dao?.ProductModel?.productDetails?.mcca?.required"
                                        [class.invalid_div_cls]="(productForm.submitted || !mcca.pristine) && mcca.invalid"
                                        #mcca="ngModel">
                                    </p-checkbox>
                                    <span class="validationred"
                                        *ngIf="(productForm.submitted || !mcca.pristine) && mcca.errors?.checkboxValidator">
                                        {{dao.ProductModel.productDetails.mcca.questionLabel}} is required
                                    </span>
                                </div>
                            </div>
                            <!-- MI State No Fault Ends -->
                            <app-municipal-tax *ngIf="questionnaire" name="municipaltaxca"
                                [questionnaire]="questionnaire" [isFormSubmitted]="productForm.submitted">
                            </app-municipal-tax>
                            <app-trailer-interchange *ngIf="!!questionnaire" [accountState]="accountState"
                                [questionnaire]="questionnaire" [isFormSubmitted]="productForm.submitted">
                            </app-trailer-interchange>

                            <div class="row mb-1">
                                <div class="col-md-12">
                                    <hr />
                                </div>
                            </div>

                            <div class=' insurableinterestproduct'>
                                <div class="row mb-1" style="margin: 1px;">
                                    <div class="col-lg-12" style="margin-top: 15px;">
                                        <label><b><u>Optional Coverages</u></b></label>
                                    </div>
                                </div>
                                <div class="row mb-1" style="margin: 1px;">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.insurableInterestParent?.questionLabel}}</label>
                                        <span
                                            *ngIf="dao?.ProductModel?.productDetails?.insurableInterestParent?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="insurableInterestParentName"
                                                id="insurableInterestParentNameYesID" value="Yes" class="radio_button"
                                                inputId="Yes" #addInsuredCAYesNo="ngModel"
                                                (onClick)="dao.AddAnotherInsuredForCommercialAuto = [];AddAnotherInsuredForCommercialAuto();"
                                                [(ngModel)]="dao.ProductModel.productDetails.insurableInterestParent.answer"
                                                [required]="dao?.ProductModel?.productDetails?.insurableInterestParent?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addInsuredCAYesNo.pristine) && addInsuredCAYesNo.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="city1">Yes</label>
                                            <p-radioButton name="insurableInterestParentName"
                                                id="insurableInterestParentNameNoID" class="radio_button" value="No"
                                                inputId="No" #addInsuredCAYesNo="ngModel"
                                                (onClick)="dao.AddAnotherInsuredForCommercialAuto = [];AddAnotherInsuredForCommercialAuto();"
                                                [(ngModel)]="dao.ProductModel.productDetails.insurableInterestParent.answer"
                                                [required]="dao?.ProductModel?.productDetails?.insurableInterestParent?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addInsuredCAYesNo.pristine) && addInsuredCAYesNo.invalid">
                                            </p-radioButton>
                                            <label for="city2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let ins of dao?.AddAnotherInsuredForCommercialAuto; let i= index;">
                                    <div class="row mb-1" style="margin: 1px;"
                                        *ngIf="dao.ProductModel.productDetails.insurableInterestParent.answer=='Yes'">
                                        <div class="col-lg-6">
                                            <label>Enter Name of Person or Organization</label>
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.insurableInterestName?.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6 row" style="display: flex;">
                                            <input type="text" style="width: 90%;margin-bottom: 5px;"
                                                class="{{(productForm.submitted || dao?.isSubmitForm) && (!insurableInterestName?.valid || ins.Name == null) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                required name="insurableInterestName_{{i}}"
                                                #insurableInterestName="ngModel" id="insurableInterestNameID_{{i}}"
                                                placeholder="{{dao?.ProductModel?.productDetails?.insurableInterestName?.questionHelpText?dao?.ProductModel?.productDetails?.insurableInterestName?.questionHelpText:''}}"
                                                [(ngModel)]="ins.Name">
                                            <span *ngIf="i != 0"><i class="fa fa-trash delete_icon ml-3" title="delete"
                                                    (click)="removeProduct(i,'insuredForCommercial',ins.Name)"></i></span>
                                            <input type="text"
                                                class="{{(productForm.submitted || dao?.isSubmitForm) && (!insurableInterestStreet?.valid || ins.Street == null) ? 'form-control mb-1 validate_error_cls' : 'form-control mb-1'}}"
                                                required name="insurableInterestStreet_{{i}}"
                                                #insurableInterestStreet="ngModel" id="insurableInterestStreetID_{{i}}"
                                                placeholder="{{dao?.ProductModel?.productDetails?.insurableInterestStreet?.questionHelpText ?dao?.ProductModel?.productDetails?.insurableInterestStreet?.questionHelpText:'Street Address'}}"
                                                [(ngModel)]="ins.Street"
                                                maxlength="40">
                                            <div class="row mb-1">
                                                <div class="col-lg-4 col-md-4 col-4">
                                                    <input type="text"
                                                        class="{{(productForm.submitted || dao?.isSubmitForm) && (!insurableInterestCity?.valid || ins.City == null) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                        required name="insurableInterestCity_{{i}}"
                                                        #insurableInterestCity="ngModel"
                                                        placeholder="{{dao?.ProductModel?.productDetails?.insurableInterestCity?.questionHelpText ?dao?.ProductModel?.productDetails?.insurableInterestCity?.questionHelpText:'City'}}"
                                                        [(ngModel)]="ins.City">
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-4">
                                                    <div
                                                        class="{{(productForm.submitted || dao?.isSubmitForm) && !addInsuredState?.valid ? 'validateDrodown' : ''}}">
                                                        <p-dropdown placeholder="Select State"
                                                            [options]="stateSettings.StateQuestionOptions"
                                                            required name="addInsuredState_{{i}}"
                                                            id="addInsuredStateID_{{i}}" [(ngModel)]="ins.State"
                                                            #addInsuredState="ngModel">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-4">
                                                    <div
                                                        class="{{(productForm.submitted || dao?.isSubmitForm) && !insurableInterestPostalCode?.valid ? 'validate_error_cls' : ''}}">
                                                        <p-inputMask [(ngModel)]="ins.PostalCode" mask="99999" required
                                                            name="insurableInterestPostalCode_{{i}}"
                                                            #insurableInterestPostalCode="ngModel"
                                                            id="insurableInterestPostalCode_{{i}}"
                                                            placeholder="{{dao?.ProductModel?.productDetails?.insurableInterestPostalCode?.questionHelpText ?dao?.ProductModel?.productDetails?.insurableInterestPostalCode?.questionHelpText:'Zip'}}">
                                                        </p-inputMask>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1" style="margin-right: 1px;">
                                    <div class="col-lg-12 text-right"
                                        *ngIf="dao.ProductModel.productDetails.insurableInterestParent.answer=='Yes'">
                                        <a style="color: #115740;" (click)="AddAnotherInsuredForCommercialAuto()">Add
                                            Additional Insured</a>
                                    </div>
                                </div>

                                <div class="row mb-1" style="margin: 1px;">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.autoWavierOfSubrogation?.questionLabel}}</label>
                                        <span
                                            *ngIf="dao?.ProductModel?.productDetails?.autoWavierOfSubrogation?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="autoWavierOfSubrogationName" id="SubrogationradioYesID"
                                                value="Yes" class="radio_button" inputId="Yes"
                                                #addWaiverCAYesNo="ngModel"
                                                (onClick)="dao.AddAnotherWaiverForCommercialAuto = [];AddAnotherWaiverForCommercialAuto();"
                                                [(ngModel)]="dao.ProductModel.productDetails.autoWavierOfSubrogation.answer"
                                                [required]="dao?.ProductModel?.productDetails?.autoWavierOfSubrogation?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addWaiverCAYesNo.pristine) && addWaiverCAYesNo.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="city1">Yes</label>
                                            <p-radioButton name="autoWavierOfSubrogationName" id="SubrogationradioNoID"
                                                class="radio_button" value="No" inputId="No" #addWaiverCAYesNo="ngModel"
                                                (onClick)="dao.AddAnotherWaiverForCommercialAuto = [];AddAnotherWaiverForCommercialAuto();"
                                                [(ngModel)]="dao.ProductModel.productDetails.autoWavierOfSubrogation.answer"
                                                [required]="dao?.ProductModel?.productDetails?.autoWavierOfSubrogation?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addWaiverCAYesNo.pristine) && addWaiverCAYesNo.invalid">
                                            </p-radioButton>
                                            <label for="city2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let wav of dao?.AddAnotherWaiverForCommercialAuto; let i= index;">
                                    <div class="row mb-1" style="margin: 1px;"
                                        *ngIf="dao.ProductModel.productDetails.autoWavierOfSubrogation.answer =='Yes'">
                                        <div class="col-lg-6">
                                            <label>{{dao?.ProductModel?.productDetails?.autoWavierOfSubrogationName?.questionLabel}}</label>
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.autoWavierOfSubrogationName?.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6" style="display: flex;padding: 0;">
                                            <input type="text" style="width: 90%;"
                                                class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.autoWavierOfSubrogationName?.required && (!autoWavierOfSubrogationName?.valid || wav.Name == null) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                [required]="dao?.ProductModel?.productDetails?.autoWavierOfSubrogationName?.required"
                                                name="autoWavierOfSubrogationName{{i}}"
                                                #autoWavierOfSubrogationName="ngModel"
                                                id="autoWavierOfSubrogationNameID_{{i}}"
                                                placeholder="{{dao?.ProductModel?.productDetails?.autoWavierOfSubrogationName?.questionHelpText?'':dao?.ProductModel?.productDetails?.autoWavierOfSubrogationName?.questionHelpText}}"
                                                [(ngModel)]="wav.Name">
                                            <div class="col-lg-1">
                                                <span *ngIf="i != 0"><i class="fa fa-trash delete_icon" title="delete"
                                                        (click)="removeProduct(i,'waiverForCommercial',wav.Name)"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1" style="margin-right: 1px;">
                                    <div class="col-lg-12 text-right"
                                        *ngIf="dao.ProductModel.productDetails.autoWavierOfSubrogation.answer =='Yes'">
                                        <a style="color: #115740;" (click)="AddAnotherWaiverForCommercialAuto()">Add
                                            Another Waiver</a>
                                    </div>
                                </div>
                                <!-- Pollution Broad form  -->
                                <div class="row mb-1" style="margin: 1px;"
                                    *ngIf="dao?.ProductModel?.productDetails?.Q_pollutionBroadForm?.enabled">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.Q_pollutionBroadForm?.questionLabel}}</label>
                                        <span *ngIf="dao?.ProductModel?.productDetails?.Q_pollutionBroadForm?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="pollutionBroadFormName"
                                                id="pollutionBroadFormradioYesID" value="Yes" class="radio_button"
                                                inputId="Yes" #pollutionBroadFormYesNo="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.Q_pollutionBroadForm.answer"
                                                [required]="dao?.ProductModel?.productDetails?.Q_pollutionBroadForm?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !pollutionBroadFormYesNo.pristine) && pollutionBroadFormYesNo.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl">Yes</label>
                                            <p-radioButton name="pollutionBroadFormName"
                                                id="pollutionBroadFormradioNoID" class="radio_button" value="No"
                                                inputId="No" #pollutionBroadFormYesNo="ngModel"
                                                [(ngModel)]="dao.ProductModel.productDetails.Q_pollutionBroadForm.answer"
                                                [required]="dao?.ProductModel?.productDetails?.Q_pollutionBroadForm?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !pollutionBroadFormYesNo.pristine) && pollutionBroadFormYesNo.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <optional-coverage 
                                    *ngIf="questionnaire" 
                                    [questionnaire]="questionnaire"
                                    [isFormSubmitted]="productForm.submitted"
                                    [umBiLimitQuestionBehaviore] ="umBiLimitQuestionBehaviore"
                                    [uimBiQuestionBehaviore] ="uimBiQuestionBehaviore">
                                </optional-coverage>
                                <div class="row mb-1" style="margin: 7px;"></div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Commercial General Liability" [selected]="activeTab == 1"
                            *ngIf="dao.ProductModel.productDetails.glIncluded.answer == 'Yes'" ngModelGroup="generalLiabilityGroup" #generalLiabilityGroup="ngModelGroup">
                            <ng-template pTemplate="header">
                                <span [class.validationred] = "((productForm.submitted || dao?.isSubmitForm) && !productForm.controls?.generalLiabilityGroup?.valid)">
                                    <i class="pi pi-file mr-2"></i>
                                    <span>Commercial General Liability</span>
                                </span>
                            </ng-template>
                            <div class="row mb-1">
                                <div class="col-lg-12">
                                    <label><b><u>General Liability</u></b></label>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label>Each Occurrence</label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.eachOccurrenceLImit?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;"
                                        [required]="dao?.ProductModel?.productDetails?.eachOccurrenceLImit?.required && dao.ProductModel.productDetails.glIncluded.answer == 'Yes'"
                                        name="eachOccurrenceLImit" #eachOccurrenceLImit="ngModel" disabled
                                        id="eachOccurrenceLImitID"
                                        placeholder="{{dao?.ProductModel?.productDetails?.eachOccurrenceLImit?.questionHelpText ?'':dao?.ProductModel?.productDetails?.eachOccurrenceLImit?.questionHelpText}}"
                                        [(ngModel)]="dao.ProductModel.productDetails.eachOccurrenceLImit.answer">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label>Personal/Advertising Injury</label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.persAdvInj?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;"
                                        [required]="dao?.ProductModel?.productDetails?.persAdvInj?.required && dao.ProductModel.productDetails.persAdvInj.answer == 'Yes'"
                                        name="persAdvInj" #persAdvInj="ngModel" id="persAdvInjID"
                                        placeholder="{{dao?.ProductModel?.productDetails?.persAdvInj?.questionHelpText ? '':dao?.ProductModel?.productDetails?.persAdvInj?.questionHelpText}}"
                                        [(ngModel)]="dao.ProductModel.productDetails.persAdvInj.answer"
                                        [minFractionDigits]="2" inputId="locale-us" mode="decimal" locale="en-US"
                                        [value]="" disabled>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label>General Aggregate</label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.aggregateLimit?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;"
                                        [required]="dao?.ProductModel?.productDetails?.aggregateLimit?.required && dao.ProductModel.productDetails.aggregateLimit.answer == 'Yes'"
                                        name="aggregateLimit" #aggregateLimit="ngModel" id="aggregateLimitID"
                                        placeholder="{{dao?.ProductModel?.productDetails?.aggregateLimit?.questionHelpText ?'':dao?.ProductModel?.productDetails?.aggregateLimit?.questionHelpText}}"
                                        [(ngModel)]="dao.ProductModel.productDetails.aggregateLimit.answer"
                                        [minFractionDigits]="2" inputId="locale-us" mode="decimal" locale="en-US"
                                        [value]="" disabled>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label name="lblmedExpense" id="lblmedExpenseID">Medical Expense</label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.medExpense?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;"
                                        [required]="dao?.ProductModel?.productDetails?.medExpense?.required && dao.ProductModel.productDetails.medExpense.answer == 'Yes'"
                                        name="medExpense" #medExpense="ngModel" id="medExpenseID"
                                        placeholder="{{dao?.ProductModel?.productDetails?.medExpense?.questionHelpText?'':dao?.ProductModel?.productDetails?.medExpense?.questionHelpText}}"
                                        [(ngModel)]="dao.ProductModel.productDetails.medExpense.answer"
                                        [minFractionDigits]="2" inputId="locale-us" mode="decimal" locale="en-US"
                                        [value]="" disabled>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label name="lblprmsRent" id="lblprmsRentID">Premises Rented to You</label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.prmsRent?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;"
                                        [required]="dao?.ProductModel?.productDetails?.prmsRent?.required && dao.ProductModel.productDetails.prmsRent.answer == 'Yes'"
                                        name="prmsRent" #prmsRent="ngModel" id="prmsRentID"
                                        placeholder="{{dao?.ProductModel?.productDetails?.prmsRent?.questionHelpText ?'':dao?.ProductModel?.productDetails?.prmsRent?.questionHelpText}}"
                                        [(ngModel)]="dao.ProductModel.productDetails.prmsRent.answer"
                                        [minFractionDigits]="2" inputId="locale-us" mode="decimal" locale="en-US"
                                        [value]="" disabled>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label name="lblempBenefitsLiab" id="lblempBenefitsLiabID">Employee Benefits
                                        Liability
                                    </label>
                                    <span *ngIf="dao?.ProductModel?.productDetails?.empBenefitsLiab?.required"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <p-dropdown
                                        [class.validateDrodown]="(productForm.submitted || dao?.isSubmitForm || !empBenefitsLiab.pristine) && empBenefitsLiab?.invalid"
                                        placeholder="{{dao?.ProductModel?.productDetails?.empBenefitsLiab?.questionHelpText?'':dao?.ProductModel?.productDetails?.empBenefitsLiab?.questionHelpText}}"
                                        #empBenefitsLiab="ngModel" name="empBenefitsLiab" id="empBenefitsLiabID"
                                        [(ngModel)]="dao.ProductModel.productDetails.empBenefitsLiab.answer"
                                        [requiredAmtOrDecline]="dao?.ProductModel?.productDetails?.empBenefitsLiab?.required && dao.ProductModel.productDetails.empBenefitsLiab.answer == 'Yes'"
                                        [options]="dao?.ProductModel?.productDetails?.empBenefitsLiab?.questionOptions">
                                    </p-dropdown>
                                    <span class="validationred"
                                        *ngIf="(productForm.submitted || !empBenefitsLiab.pristine) && empBenefitsLiab.errors?.requiredAmtOrDecline">
                                        Employee Benefit Liability is required
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-1"
                                *ngIf="dao?.ProductModel?.productDetails?.empBenefitsLiab?.answer != 'DECLINE'">
                                <div class="col-lg-6">
                                    <label name="lblnumberOfEmmployees"
                                        id="lblnumberOfEmmployeesID">{{dao?.ProductModel?.productDetails?.empBenefitsLiabNumberOfEmp?.questionLabel}}</label>
                                    <span
                                        *ngIf="dao?.ProductModel?.productDetails?.empBenefitsLiab?.answer != 'DECLINE'"
                                        style="color: red;">*</span>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        [class.validate_error_cls]="(productForm.submitted || dao?.isSubmitForm || !empBenefitsLiabNumberOfEmp.pristine) && empBenefitsLiabNumberOfEmp?.invalid"
                                        style="width: 25%;"
                                        [required]="dao?.ProductModel?.productDetails?.empBenefitsLiab?.answer != 'DECLINE'"
                                        [minAmt]="dao.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.minimum"
                                        name="empBenefitsLiabNumberOfEmp" #empBenefitsLiabNumberOfEmp="ngModel"
                                        id="empBenefitsLiabNumberOfEmpID"
                                        placeholder="{{dao?.ProductModel?.productDetails?.empBenefitsLiabNumberOfEmp?.questionHelpText ?'':dao?.ProductModel?.productDetails?.empBenefitsLiabNumberOfEmp?.questionHelpText}}"
                                        [(ngModel)]="dao.ProductModel.productDetails.empBenefitsLiabNumberOfEmp.answer"
                                        maxlength="3" (keypress)="numbersOnly($event)">
                                    <span class="validationred"
                                        *ngIf="(productForm.submitted || !empBenefitsLiabNumberOfEmp.pristine) && empBenefitsLiabNumberOfEmp.errors?.minAmt">
                                        Number of Employees must be greater than 0
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="fireSurcharge?.enabled">
                                <dropdown-question
                                    class="row mb-1"
                                    #fireSurchargeDropdown
                                    [question]="fireSurcharge"
                                    [disabled]="true"
                                    [isFormSubmitted]="isFormSubmitted">
                                </dropdown-question>
                            </div>
                            <div class="row mb-1">
                                <div class="col-md-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-12"
                                    *ngFor="let Loc of dao?.AddProductAnotherLocation; let i= index;">
                                    <div class="row mb-1">
                                        <div class="col-lg-6">
                                            <label name="lblpremiseLocation" id="lblpremiseLocationID">Select the
                                                Location of Premises </label>
                                            <span *ngIf="dao?.ProductModel?.productDetails?.premiseLocation?.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6 locdrop">
                                            <p-dropdown
                                                class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.premiseLocation?.required && (!premiseLocation?.valid || Loc?.premiseLocation == 'CLICK') && dao.ProductModel.productDetails.glIncluded.answer == 'Yes' ? 'validateDrodown' : ''}}"
                                                placeholder="{{dao?.ProductModel?.productDetails?.premiseLocation?.questionHelpText ?'':dao?.ProductModel?.productDetails?.premiseLocation?.questionHelpText}}"
                                                #premiseLocation="ngModel" name="premiseLocation_{{i}}"
                                                id="premiseLocationID_{{i}}" [(ngModel)]="Loc.premiseLocation"
                                                [requiredAmtOrDecline]="true"
                                                [required]="dao?.ProductModel?.productDetails?.premiseLocation?.required"
                                                [options]="Loc.premiseList"
                                                (onChange)="checkDifferentLocationInProduct(Loc.premiseLocation,i)">
                                            </p-dropdown>
                                            <span *ngIf="i != 0"><i class="fa fa-trash delete_icon ml-3" title="delete"
                                                    (click)="removeProduct(i,'location',Loc.premisePostalCode)"></i></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 float-right"
                                        *ngIf="Loc.premiseLocation && Loc.premiseLocation != 'CLICK'">
                                        <input type="text"
                                            class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.premiseStreet?.required && (!premiseStreet?.valid || Loc.premiseStreet == null) ? 'form-control mb-1 validate_error_cls' : 'form-control mb-1'}}"
                                            style="width: 103%;"
                                            [required]="dao?.ProductModel?.productDetails?.premiseStreet?.required"
                                            name="premiseStreet_{{i}}" #premiseStreet="ngModel" id="premiseStreetID"
                                            placeholder="{{dao?.ProductModel?.productDetails?.premiseStreet?.questionHelpText?dao?.ProductModel?.productDetails?.premiseStreet?.questionHelpText:'Street Address'}}"
                                            [(ngModel)]="Loc.premiseStreet"
                                            maxlength="40" 
                                            [readonly]="Loc.premiseLocation != 'newAddressAsPremise'"
                                            (keypress)="ValidateAddress($event,Loc.premiseStreet,'premiseStreet',i)">

                                        <div class="row mb-1 m-0">
                                            <div class="col-lg-4 col-md-4 col-4 p-0">
                                                <input type="text"
                                                    class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.premiseCity?.required && (!premiseCity?.valid || Loc.premiseCity == null) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                    [required]="dao?.ProductModel?.productDetails?.premiseCity?.required"
                                                    name="premiseCity_{{i}}" #premiseCity="ngModel"
                                                    id="premiseCityID_{{i}}"
                                                    placeholder="{{dao?.ProductModel?.productDetails?.premiseCity?.questionHelpText?dao?.ProductModel?.productDetails?.premiseCity?.questionHelpText:'City'}}"
                                                    [(ngModel)]="Loc.premiseCity"
                                                    [readonly]="Loc.premiseLocation != 'newAddressAsPremise'">
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-4">
                                                <input type="text"
                                                    class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.premiseState?.required && (!premiseState?.valid || Loc.premiseState == null) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                    [required]="dao?.ProductModel?.productDetails?.premiseState?.required"
                                                    name="premiseState_{{i}}" #premiseState="ngModel"
                                                    id="premiseStateID_{{i}}" readonly
                                                    placeholder="{{dao?.ProductModel?.productDetails?.premiseState?.questionHelpText?dao?.ProductModel?.productDetails?.premiseState?.questionHelpText:'ST'}}"
                                                    [(ngModel)]="Loc.premiseState">
                                            </div>
                                            <div
                                                class="col-lg-4 col-md-4 col-4 p-0 {{Loc.premiseLocation != 'newAddressAsPremise' ? 'readonlyMask' : ''}}">
                                                <div
                                                    class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.premisePostalCode?.required && !premisePostalCode?.valid ? 'validate_error_cls' : ''}}">
                                                    <p-inputMask [(ngModel)]="Loc.premisePostalCode" mask="99999"
                                                        [required]="dao?.ProductModel?.productDetails?.premisePostalCode?.required"
                                                        name="premisePostalCode_{{i}}" #premisePostalCode="ngModel"
                                                        id="premisePostalCodeID_{{i}}"
                                                        placeholder="{{dao?.ProductModel?.productDetails?.premisePostalCode?.questionHelpText?dao?.ProductModel?.productDetails?.premisePostalCode?.questionHelpText:'Zip'}}"
                                                        [readonly]="Loc.premiseLocation != 'newAddressAsPremise'"
                                                        autocomplete="none"></p-inputMask>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 row mb-1 p-0">
                                        <div class="col-lg-6" style="padding-right: 0px;">
                                            <label>{{dao?.ProductModel?.productDetails?.payrollAmount?.questionLabel}}
                                                <span *ngIf="dao?.ProductModel?.productDetails?.payrollAmount?.required"
                                                    style="color: red;">*</span>
                                                <span class="question_icon">
                                                    <i class="fa fa-question-circle ml-2" [escape]="false"
                                                        tooltipStyleClass="tooltippayrollclss"
                                                        [pTooltip]="dao?.ProductModel?.productDetails?.payrollAmount?.questionHelpText"
                                                        aria-hidden="true"></i>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="col-lg-6 p-custominput">
                                            <p-inputNumber #payrollAmount="ngModel" id="payrollAmountID_{{i}}"
                                                class="payrollcls"
                                                [class.invalid]="(productForm.submitted || dao?.isSubmitForm || !payrollAmount.pristine) && payrollAmount?.invalid"
                                                [name]="'payrollAmount_' + i"
                                                [placeholder]="dao?.ProductModel?.productDetails?.payrollAmount?.questionHelpText?'':dao?.ProductModel?.productDetails?.payrollAmount?.questionHelpText"
                                                [(ngModel)]="Loc.payrollAmount"
                                                [minAmt]="dao.ProductModel.productDetails.payrollAmount.minimum">
                                            </p-inputNumber>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-lg-12 text-right">
                                    <a style="color: #115740;" (click)="AddProductLocation()">Add Another Location</a>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-md-12">
                                        <hr />
                                    </div>
                                </div>
                            </div>

                            <app-municipal-tax *ngIf="questionnaire" name="municipaltaxgl"
                                [questionnaire]="questionnaire" defaultDisable="true"
                                [isFormSubmitted]="productForm.submitted">
                            </app-municipal-tax>

                            <div class="row mb-1">
                                <div class="col-md-12">
                                    <hr />
                                </div>
                            </div>

                            <div class=' insurableinterestgl'>
                                <div class="row mb-1">
                                    <div class="col-lg-12">
                                        <label><b><u>Optional Coverages</u></b></label>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.additionalInsuredIncludedParent?.questionLabel}}</label>
                                        <span
                                            *ngIf="dao?.ProductModel?.productDetails?.additionalInsuredIncludedParent?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="additionalInsuredIncludedParentName"
                                                id="additionalInsuredIncludedParentNameYesID" value="Yes"
                                                class="radio_button" inputId="Yes" #addInsuredGLYesNo="ngModel"
                                                (onClick)="dao.AddAnotherInsured = [];AddAnotherInsured();"
                                                [(ngModel)]="dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer"
                                                [required]="dao?.ProductModel?.productDetails?.additionalInsuredIncludedParent?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addInsuredGLYesNo.pristine) && addInsuredGLYesNo.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="city1">Yes</label>
                                            <p-radioButton name="additionalInsuredIncludedParentName"
                                                id="additionalInsuredIncludedParentNameNoID" class="radio_button"
                                                value="No" inputId="No" #addInsuredGLYesNo="ngModel"
                                                (onClick)="dao.AddAnotherInsured = [];AddAnotherInsured();"
                                                [(ngModel)]="dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer"
                                                [required]="dao?.ProductModel?.productDetails?.additionalInsuredIncludedParent?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addInsuredGLYesNo.pristine) && addInsuredGLYesNo.invalid">
                                            </p-radioButton>
                                            <label for="city2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let ins of dao?.AddAnotherInsured; let i= index;">
                                    <div class="row mb-1"
                                        *ngIf="dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer=='Yes'">
                                        <div class="col-lg-6">
                                            <label>Enter Name of Person or Organization</label>
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.additionalInsuredName?.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6" style="display: flex;">
                                            <input type="text" style="width: 90%;"
                                                class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.additionalInsuredName?.required && (!additionalInsuredName?.valid || ins.Name == null) && dao.ProductModel?.productDetails?.glIncluded?.answer == 'Yes' ? 'form-control validate_error_cls' : 'form-control'}}"
                                                [required]="dao?.ProductModel?.productDetails?.additionalInsuredName?.required"
                                                name="additionalInsuredName_{{i}}" #additionalInsuredName="ngModel"
                                                id="additionalInsuredNameID_{{i}}"
                                                placeholder="{{dao?.ProductModel?.productDetails?.additionalInsuredName?.questionHelpText?'':dao?.ProductModel?.productDetails?.additionalInsuredName?.questionHelpText}}"
                                                [(ngModel)]="ins.Name">
                                            <span *ngIf="i != 0"><i class="fa fa-trash delete_icon ml-3" title="delete"
                                                    (click)="removeProduct(i,'insured',ins.Name)"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-12 text-right"
                                        *ngIf="dao.ProductModel.productDetails.additionalInsuredIncludedParent.answer=='Yes'">
                                        <a style="color: #115740;" (click)="AddAnotherInsured()">Add Additional
                                            Insured</a>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-6">
                                        <label>{{dao?.ProductModel?.productDetails?.glWavierOfSubrogation?.questionLabel}}</label>
                                        <span *ngIf="dao?.ProductModel?.productDetails?.glWavierOfSubrogation?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="glWavierOfSubrogationName" id="SubrogationradioYesID"
                                                value="Yes" class="radio_button" inputId="Yes"
                                                #addWaiverGLYesNo="ngModel"
                                                (onClick)="dao.AddAnotherWaiver = [];AddAnotherWaiver();"
                                                [(ngModel)]="dao.ProductModel.productDetails.glWavierOfSubrogation.answer"
                                                [required]="dao?.ProductModel?.productDetails?.glWavierOfSubrogation?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addWaiverGLYesNo.pristine) && addWaiverGLYesNo.invalid">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="city1">Yes</label>
                                            <p-radioButton name="glWavierOfSubrogationName" id="SubrogationradioNoID"
                                                class="radio_button" value="No" inputId="No" #addWaiverGLYesNo="ngModel"
                                                (onClick)="dao.AddAnotherWaiver = [];AddAnotherWaiver();"
                                                [(ngModel)]="dao.ProductModel.productDetails.glWavierOfSubrogation.answer"
                                                [required]="dao?.ProductModel?.productDetails?.glWavierOfSubrogation?.required"
                                                [class.radioButtonValidator]="(productForm.submitted || !addWaiverGLYesNo.pristine) && addWaiverGLYesNo.invalid">
                                            </p-radioButton>
                                            <label for="city2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let wav of dao?.AddAnotherWaiver; let i= index;">
                                    <div class="row mb-1"
                                        *ngIf="dao.ProductModel.productDetails.glWavierOfSubrogation.answer =='Yes'">
                                        <div class="col-lg-6">
                                            <label>{{dao?.ProductModel?.productDetails?.glWavierOfSubrogationName?.questionLabel}}</label>
                                            <span
                                                *ngIf="dao?.ProductModel?.productDetails?.glWavierOfSubrogationName?.required"
                                                style="color: red;">*</span>
                                        </div>
                                        <div class="col-lg-6" style="display: flex;">
                                            <input type="text" style="width: 90%;"
                                                class="{{(productForm.submitted || dao?.isSubmitForm) && dao?.ProductModel?.productDetails?.glWavierOfSubrogationName?.required && (!wavierOfSubrogationName?.valid || wav.Name == null) && dao.ProductModel.productDetails.glIncluded.answer == 'Yes' ? 'form-control validate_error_cls' : 'form-control'}}"
                                                [required]="dao?.ProductModel?.productDetails?.glWavierOfSubrogationName?.required"
                                                name="glWavierOfSubrogationName{{i}}" #wavierOfSubrogationName="ngModel"
                                                id="glWavierOfSubrogationNameID_{{i}}"
                                                placeholder="{{dao?.ProductModel?.productDetails?.glWavierOfSubrogationName?.questionHelpText?'':dao?.ProductModel?.productDetails?.glWavierOfSubrogationName?.questionHelpText}}"
                                                [(ngModel)]="wav.Name">
                                            <div class="col-lg-1">
                                                <span *ngIf="i != 0"><i class="fa fa-trash delete_icon" title="delete"
                                                        (click)="removeProduct(i,'waiver',wav.Name)"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-lg-12 text-right"
                                        *ngIf="dao.ProductModel.productDetails.glWavierOfSubrogation.answer =='Yes'">
                                        <a style="color: #115740;" (click)="AddAnotherWaiver()">Add Another Waiver</a>
                                    </div>
                                </div>
                            </div>
                            <!-- This block is specific to 'OH' state -->
                            <stop-GapEmployersLiability *ngIf="questionnaire" [questionnaire]="questionnaire"
                                [isFormSubmitted]="productForm.submitted">
                            </stop-GapEmployersLiability>
                        </p-tabPanel>
                        <!--cargo starts-->
                        <p-tabPanel header="Motor Truck Cargo" [selected]="activeTab == 2"
                            *ngIf="dao?.ProductModel?.IsCargoShow && dao.ProductModel.productDetails.cargoIncluded.answer == 'Yes'" ngModelGroup="motorCargGroup" #motorCargGroup="ngModelGroup" >
                            <ng-template pTemplate="header">
                                <span [class.validationred]="((productForm.submitted || dao?.isSubmitForm) && !productForm.controls?.motorCargGroup?.valid)">
                                    <i class="pi pi-file mr-2"></i>
                                    <span>Motor Truck Cargo</span>
                                </span>
                            </ng-template>
                            <div class="row mb-1">
                                <div class="col-lg-12">
                                    <label><b><u>Property Covered</u></b></label>
                                </div>
                            </div>
                            <div class="row mb-1"  *ngIf="!!cargoPropertyLimit">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">{{cargoPropertyLimit.questionLabel}}</label>
                                </div>
                                <div class="col-lg-6">
                                    <p-dropdown #propertyLimit="ngModel" name="propertyLimit" id="propertyLimit"
                                    [(ngModel)]="cargoPropertyLimit.answer"
                                    [options]="cargoPropertyLimit.questionOptions"
                                    (onChange)="changingPropertyLimitDropdown()">
                                </p-dropdown>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-12">
                                    <label><b><u>Deductible</u></b></label>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">Deductible Amount</label>
                                </div>
                                <div class="col-lg-6">
                                    <p-dropdown #flatDeductibles="ngModel" name="flatDeductibles" id="flatDeductiblesID"
                                        [(ngModel)]="dao.ProductModel.productDetails.flatDeductible.answer"
                                        [options]="dao?.ProductModel?.productDetails?.flatDeductible?.questionOptions">
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-12">
                                    <label><b><u>Supplemental Coverages</u></b></label>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">Additional Debris Removal</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;" name="additionalDebris"
                                        #additionalDebris="ngModel" disabled id="additionalDebrisID"
                                        [(ngModel)]="dao.ProductModel.productDetails.additionalDebris.answer">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">Defense Cost</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;" name="defenseCost"
                                        #defenseCost="ngModel" disabled id="defenseCostID"
                                        [(ngModel)]="dao.ProductModel.productDetails.defenseCost.answer">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">Freight Charges</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;" name="freightCharges"
                                        #freightCharges="ngModel" disabled id="freightChargesID"
                                        [(ngModel)]="dao.ProductModel.productDetails.freightCost.answer">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">Pollution Cleanup and Removal</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;" name="pollution"
                                        #pollution="ngModel" disabled id="pollutionID"
                                        [(ngModel)]="dao.ProductModel.productDetails.pollutionCleanupAndRemoval.answer">
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-lg-6">
                                    <label style="margin-left: 20px;">Newly Acquired Terminals</label>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control"
                                        style="cursor: not-allowed; opacity:  0.5 !important;" name="newlyAcquired"
                                        #newlyAcquired="ngModel" disabled id="newlyAcquiredID"
                                        [(ngModel)]="dao.ProductModel.productDetails.newlyAcquiredTerminals.answer">
                                </div>
                            </div>

                            <app-municipal-tax *ngIf="questionnaire" name="municipaltaxcargo"
                                [questionnaire]="questionnaire" defaultDisable="true"
                                [isFormSubmitted]="productForm.submitted">
                            </app-municipal-tax>

                            <hr />
                            <div class=' insurableinterestproduct'>
                                <div class="row mb-1" style="margin: 1px;">
                                    <div class="col-lg-12" style="margin-top: 15px;">
                                        <label><b><u>Optional Coverages</u></b></label>
                                    </div>
                                </div>
                                <div class="row mb-1" style="margin: 1px;">
                                    <div class="col-lg-6">
                                        <label>Are any designated shipper or Loss Payee to be listed?</label>
                                        <span
                                            *ngIf="dao?.ProductModel?.productDetails?.cargoNameInsuredParent?.required"
                                            style="color: red;">*</span>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="p-field-radiobutton">
                                            <p-radioButton name="cargoNameInsuredParent"
                                                id="cargoNameInsuredParentYesID" value="Yes" class="radio_button"
                                                inputId="Yes" required
                                                (onClick)="onCargoNameInsuredClick()"
                                                [(ngModel)]="dao.ProductModel.productDetails.cargoNameInsuredParent.answer"
                                                [class.radioButtonValidator]="productForm.submitted && dao.ProductModel.productDetails.cargoNameInsuredParent.answer == '' ? 'validationred' : ''">
                                            </p-radioButton>
                                            <label class="radio_button_lbl" for="city1">Yes</label>
                                            <p-radioButton name="cargoNameInsuredParent" id="cargoNameInsuredParentNoID"
                                                value="No" class="radio_button" inputId="No" required
                                                (onClick)="dao.AddAnotherInsuredForCargo = []; AddAnotherInsuredForCargo();"
                                                [(ngModel)]="dao.ProductModel.productDetails.cargoNameInsuredParent.answer"
                                                [class.radioButtonValidator]="productForm.submitted && dao.ProductModel.productDetails.cargoNameInsuredParent.answer == '' ? 'validationred' : ''">
                                            </p-radioButton>
                                            <label for="city2" class="radio_button_lbl">No</label>
                                        </div>
                                    </div>
                                </div>
                                <dropdown-question
                                    class="row mb-1"
                                    style="margin: 1px;"
                                    #cargoShipperLimitDropdown
                                    *ngIf="dao.ProductModel.productDetails.cargoNameInsuredParent.answer=='Yes' && !!cargoShipperLimit"
                                    [question]="cargoShipperLimit"
                                    [isFormSubmitted]="isFormSubmitted">
                                </dropdown-question>
                                <div ngModelGroup="cargo" #childgroup="ngModelGroup">
                                    <div *ngFor="let ins of dao?.AddAnotherInsuredForCargo; let i= index;">
                                        <div class="row mb-1" style="margin: 1px;"
                                            *ngIf="dao.ProductModel.productDetails.cargoNameInsuredParent.answer=='Yes'">
                                            <div class="col-lg-6">
                                                <label>Enter Name of Person or Organization</label>
                                                <span
                                                    *ngIf="dao?.ProductModel?.productDetails?.additionalInsuredName?.required"
                                                    style="color: red;">*</span>
                                            </div>
                                            <div class="col-lg-6 row" style="display: flex;padding-left: 30px;">
                                                <input type="text" style="width: 90%;margin-bottom: 5px;"
                                                    class="{{(productForm.submitted || dao?.isSubmitForm || isAddBtnClicked) && (!insuredName.valid) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                    name="insuredName_{{i}}" required #insuredName="ngModel"
                                                    id="insuredName_{{i}}ID" placeholder="Insured Name"
                                                    [(ngModel)]="ins.Name">
                                                <span *ngIf="i != 0">
                                                    <i class="fa fa-trash delete_icon ml-3" title="delete"
                                                        (click)="removeProduct(i,'insuredForCargo',ins.Name)"></i>
                                                </span>

                                                <input type="text" style="width: 100%;margin-bottom: 5px;"
                                                    class="{{(productForm.submitted || dao?.isSubmitForm || isAddBtnClicked) && (!insuredStreet.valid) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                    name="insuredStreet_{{i}}" required #insuredStreet="ngModel"
                                                    id="insuredStreet_{{i}}ID" placeholder="Street Address"
                                                    [(ngModel)]="ins.Street"
                                                    maxlength="40">

                                                <div class="row mb-1">
                                                    <div class="col-lg-4 col-md-4 col-4">
                                                        <input type="text"
                                                            class="{{(productForm.submitted || dao?.isSubmitForm || isAddBtnClicked) && (!insuredCity.valid) ? 'form-control validate_error_cls' : 'form-control'}}"
                                                            name="insuredCity_{{i}}" required #insuredCity="ngModel"
                                                            id="insuredCity_{{i}}ID" placeholder="City"
                                                            [(ngModel)]="ins.City">
                                                    </div><br />
                                                    <div class="col-lg-4 col-md-4 col-4">
                                                        <div
                                                            class="{{(productForm.submitted || dao?.isSubmitForm || isAddBtnClicked) && (!insuredState.valid)  ? 'validateDrodown' : ''}}">
                                                            <p-dropdown placeholder="Select State"
                                                                [options]="stateSettings.StateQuestionOptions"
                                                                name="insuredState{{i}}" required
                                                                id="insuredState{{i}}ID" [(ngModel)]="ins.State"
                                                                #insuredState="ngModel">
                                                            </p-dropdown>
                                                        </div>
                                                    </div><br />
                                                    <div class="col-lg-4 col-md-4 col-4">
                                                        <div
                                                            class="{{(productForm.submitted || dao?.isSubmitForm || isAddBtnClicked) && (!insuredPostalCode.valid)  ? 'validate_error_cls' : ''}}">
                                                            <p-inputMask [(ngModel)]="ins.PostalCode" mask="99999"
                                                                name="insuredPostalCode_{{i}}" required
                                                                #insuredPostalCode="ngModel"
                                                                id="insuredPostalCode_{{i}}ID" placeholder="Zip">
                                                            </p-inputMask>
                                                        </div><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1" style="margin-right: 1px;">
                                        <div class="col-lg-12 text-right"
                                            *ngIf="dao.ProductModel.productDetails.cargoNameInsuredParent.answer=='Yes' &&  dao.AddAnotherInsuredForCargo.length < 2">
                                            <a style="color: #115740;"
                                                (click)="isAddBtnClicked = true; childgroup.valid && AddAnotherInsuredForCargo()">
                                                Add Additional Insured
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                        <!--cargo ends-->
                    </p-tabView>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12 text-center">
                    <div class="col-lg-12 p-0 mt-3">
                        <span class="text-lg-right float-lg-right">
                            <button *ngIf="dao.ProductModel.IsGLShow" class="btnsuccess float-right"
                                style="margin-left: 15px; padding: 0px 10px !important;"
                                title="{{dao.ProductModel.IsGLShow && dao.ProductModel.productDetails.glIncluded.answer != 'Yes' ? 'Add General Liability' : 'Remove General Liability'}}"
                                name="AddGL" id="AddGLID" (click)="IsShowGLTab();" type="button">
                                <i class="pi pi-file" aria-hidden="true"></i>
                                <label name="GeneralLiabilityButton" id="GeneralLiabilityButtonID"
                                    *ngIf="dao.ProductModel.IsGLShow && dao.ProductModel.productDetails.glIncluded.answer != 'Yes'">
                                    Add General Liability
                                </label>
                                <label name="GeneralLiabilityButton" id="GeneralLiabilityButtonID"
                                    *ngIf="dao.ProductModel.IsGLShow && dao.ProductModel.productDetails.glIncluded.answer == 'Yes'">
                                    Remove General Liability
                                </label>
                            </button>
                            <button
                                *ngIf="dao.ProductModel.IsCargoShow && ((!!cargoIndicator?.required && cargoIndicator?.answer == 'Yes'))"
                                class="btnsuccess float-right" style="padding: 0px 10px !important;"
                                title="{{dao.ProductModel.IsCargoShow && dao.ProductModel.productDetails.cargoIncluded.answer != 'Yes' ? 'Add Motor Truck Cargo' : 'Remove Motor Truck Cargo'}}"
                                name="AddCargo" id="AddCargoID" (click)="IsShowCargoTab();" type="button">
                                <i class="pi pi-file" aria-hidden="true"></i>
                                <label name="CargoButton" id="CargoButtonID"
                                    *ngIf="dao.ProductModel.IsCargoShow && dao.ProductModel.productDetails.cargoIncluded.answer != 'Yes'">
                                    Add Motor Truck Cargo
                                </label>
                                <label name="CargoButton" id="CargoButtonID"
                                    *ngIf="dao.ProductModel.IsCargoShow && dao.ProductModel.productDetails.cargoIncluded.answer == 'Yes'">
                                    Remove Motor Truck Cargo
                                </label>
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-7"></div>
                <div class="col-lg-5 mb-1 unitaddcls" *ngIf="!dao.ProductModel.IsGLShow">
                    <div class="col-lg-12 text-center">
                        <div class="col-lg-12 p-0 mt-3">
                            <span class="text-lg-right float-lg-right">
                                <label class="color_red font_16" name="IneligibleGeneralLiabilityLabel"
                                    id="IneligibleGeneralLiabilityLabelID">
                                    General Liability Coverage Not Available
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7"></div>
                <div class="col-lg-5 mb-1 unitaddcls"
                    *ngIf="!dao.ProductModel.IsCargoShow && ((cargoIndicator.required && cargoIndicator.answer == 'Yes') || !cargoIndicator.required)">
                    <div class="col-lg-12 text-center">
                        <div class="col-lg-12 p-0 mt-3">
                            <span class="text-lg-right float-lg-right">
                                <label class="color_red font_16" name="IneligibleMotorTruckCargoLabel"
                                    id="IneligibleMotorTruckCargoLabelID">
                                    Motor Truck Cargo Coverage Not Available
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12 text-center">
                    <div class="col-lg-12 p-0 mt-3">
                        <button type="button" class="btnerror float-left" title="Back" (click)="onBack('driver')">
                            <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            Back
                        </button>
                        <span class="text-lg-right float-lg-right">
                            <button type="submit" class="btnsuccess" id="btnsaveContinueID" title="Continue"
                                data-toggle="modal">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Save & Continue
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<p-toast key="productToastKey" position="top-center"></p-toast>
<p-toast position="center" key="remove" (onClose)="onReject('remove')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton pButton name="btnDVconfirmRemoveYes" id="btnDVconfirmRemoveYesID"
                        (click)="confirmRemove();messageService.clear('remove');" label="Yes"
                        class="warnPBtnError"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton pButton name="btnDVconfirmRemoveNo" id="btnDVconfirmRemoveID"
                        (click)="onReject('remove')" label="No" class="warnPBtnSuccess"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" key="CancelMySubmission" (onClose)="onReject('CancelMySubmission')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" name="btnDVCancelSubPopUpYes" id="btnDVCancelsubPopUpYesID" pButton
                        (click)="CancelmySubmission()" label="Yes" class="warnPBtnError"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton name="btnDVCancelSubPopUpNo" id="btnDVCancelsubPopUpNoID"
                        (click)="onReject('CancelMySubmission')" label="No" class="warnPBtnSuccess"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="RemoveGL" (onClose)="onReject('RemoveGL')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton pButton name="GLconfirmRemoveYes" id="GlconfirmRemoveYesID"
                        (click)="IsShowGLTabForProduct();messageService.clear('RemoveGL');" label="Yes"
                        class="warnPBtnError"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton pButton name="GLconfirmRemoveNo" id="GlconfirmRemoveNoID"
                        (click)="onReject('RemoveGL')" label="No" class="warnPBtnSuccess"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<p-toast position="center" key="RemoveCargo" (onClose)="onReject('RemoveCargo')" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="p-flex p-flex-column" style="flex: 1">
            <div class="p-text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <button type="button" pButton pButton name="CargoconfirmRemoveYes" id="CargoconfirmRemoveYesID"
                        (click)="IsShowCargoTabForProduct();messageService.clear('RemoveCargo');" label="Yes"
                        class="warnPBtnError"></button>
                </div>
                <div class="p-col-6">
                    <button type="button" pButton pButton name="CargoconfirmRemoveNo" id="CargoconfirmRemoveNoID"
                        (click)="onReject('RemoveCargo')" label="No" class="warnPBtnSuccess"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
