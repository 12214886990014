import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-session-management',
    templateUrl: './session-management.component.html'
})
export class SessionManagementComponent implements OnInit, OnDestroy {

    private interval: any;
    private readonly timeToWarn: number = 5 * 60 * 1000;

    constructor(private messageService: MessageService) { }

    ngOnInit(): void {
        this.interval = setInterval(() => this.CheckUserSession(), 1000);
    }


    async CheckUserSession() {
        let timeRemaining = await this.calculateTimeOutDistance();
        if (!!!sessionStorage.getItem('session-warning-popup'))
            sessionStorage.setItem('session-warning-popup', 'false');
        if (timeRemaining.Distance <= 0) {
            clearInterval(this.interval);
            this.messageService.clear('sessionTimeout');
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'sessionTimeout', sticky: true, severity: 'warn', summary: 'Your session has expired. Please login again to resume your work.' });
            return;
        }
        else if (timeRemaining.Distance <= this.timeToWarn && sessionStorage.getItem('session-warning-popup') == 'false') {
            sessionStorage.setItem('session-warning-popup', 'true');
            this.messageService.clear('sessionTimeout');
            let timeLeftForExpiration = timeRemaining.Min + 1;
            document.body.style.pointerEvents = "none";
            this.messageService.add({
                key: 'sessionTimeout',
                sticky: true,
                severity: 'warn',
                summary: 'Your session will expire in ' + timeLeftForExpiration + ' minutes, any unsaved data will be lost.  Please save your data, log out from the portal after ' + timeLeftForExpiration + ' minutes and login again to resume working.'
            });
        }
    }

    async respondedToSessionTimeout() {
        this.messageService.clear('sessionTimeout');
        document.body.style.pointerEvents = "visible";
        let timeRemaining = await this.calculateTimeOutDistance();
        if (timeRemaining.Distance <= 0) {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                //set expiration on cookie
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
            //also clear session storage
            sessionStorage.clear();
            window.location.href = '/';
        }
    }

    // This method is written for Testing purpose to know the session expiration time
    async calculateTimeOutDistance() {
        var maxExpirationTime: number = this.ExpirationAt;
        var currentTime: number = Date.now();
        var distance: number = maxExpirationTime - currentTime;
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        return {
            Distance: distance,
            Hrs: hours,
            Min: minutes,
            Sec: seconds
        }
    }

    get ExpirationAt(): number {
        return parseInt(sessionStorage.getItem("salte.auth.expiration"));
    }

    public ngOnDestroy(): void {
        if (!!!this.interval)
            clearInterval(this.interval);
    }
}
