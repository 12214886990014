import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DAO } from 'src/app/modal/DAO';
import { QuestionAnswer, Questionnaire } from 'src/app/modal/questionnaire.models';
import _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ProductService } from 'src/app/services/product.service';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'any'
})

@Component({
    selector: 'broadened-pip',
    templateUrl: './broadened-pip.component.html',
    styleUrls: ['./broadened-pip.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BroadenedPIPComponent implements OnInit {

    @Input() questionnaire: Questionnaire;
    @Input() isFormSubmitted: boolean;
    @Input() broadenedPIPDeductibleValue: string;

    public broadenedPIPYesNo: QuestionAnswer;

    public broadenedPIP: QuestionAnswer; //Broadened PIP
    public showBroadenedPIP: boolean = false;
    public isBroadenedPipDisabled: boolean = false;

    public broadenedPIPDeductible: QuestionAnswer; // Broadened PIP Deductible
    public showBroadenedPIPDeductible: boolean = false;
    public isBroadenedPipDeductibleDisabled: boolean = false;

    constructor(private dao: DAO, private messageService: MessageService, private productService: ProductService) {

    }

    public ngOnInit(): void {
        this.loadQuestionAnswers();
        if (this.broadenedPIPYesNo.enabled) {
            this.broadenedPipSelection();
            this.broadenedPIP.answer = this.broadenedPIP.answer != 'CLICK' ? this.broadenedPIP.answer : this.broadenedPIP?.questionDefault[0]?.code;
        }
        this.dao.ProductModel.productDetails.namedIndividual.enabled = this.broadenedPIPYesNo.answer == 'Yes' && this.broadenedPIPYesNo.enabled;
    }
    public broadenedPipSelection(): void {
        this.dao.ProductModel.productDetails.namedIndividual.enabled = false;
        this.showBroadenedPIP = false;
        this.showBroadenedPIPDeductible = false;
        this.isBroadenedPipDeductibleDisabled = false;
        this.isBroadenedPipDisabled = false;
        if (this.broadenedPIPYesNo.answer == 'Yes') {
            this.showBroadenedPIP = this.questionnaire.questionAnswer.some(x => x.key === 'nfBrd');
            this.showBroadenedPIPDeductible = this.questionnaire.questionAnswer.some(x => x.key === 'nfBrdDed');
            this.dao.ProductModel.productDetails.namedIndividual.enabled = true;
            if (this.dao?.ProductModel?.productDetails?.nfPipDed?.answer) {
                this.broadenedPIPDeductible.answer = this.dao.ProductModel.productDetails.nfPipDed.answer
                this.isBroadenedPipDeductibleDisabled = true;
                this.isBroadenedPipDisabled = true;
            }
        }
        else {
            if (this.broadenedPIP.questionOptions.some(x => x.code == 'CLICK'))
                this.broadenedPIP.answer = 'CLICK';
            this.dao.AddAnotherNamedIndividual = [];
            this.productService.AddNamedIndividual();
        }
    }

    private loadQuestionAnswers(): void {
        var defaultQuestionAnswer = this.getDefaultQuestionAnswer();

        this.broadenedPIPYesNo = this.questionnaire.questionAnswer.find(x => x.key === 'Q_nfBrd') ?? defaultQuestionAnswer;
        this.broadenedPIP = this.questionnaire.questionAnswer.find(x => x.key === 'nfBrd') ?? defaultQuestionAnswer;
        this.broadenedPIPDeductible = this.questionnaire.questionAnswer.find(x => x.key === 'nfBrdDed') ?? defaultQuestionAnswer;

    }

    private getDefaultQuestionAnswer(): QuestionAnswer {
        return {
            answer: null,
            enabled: false,
            required: false,
            group: null,
            hidden: false,
            key: null,
            isVehicleUpdatedbyVEH1: false,
            minimum: null,
            name: null,
            type: '',
            rowIndex: 0,
            questionOptions: []
        }
    }
}