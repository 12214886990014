import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'any'
})
export class UserInputService {

    constructor() {

    }

    public isCharCodeAlphaOnly(charCode: number): boolean {
        // Charcodes -> WhiteSpaces(32), SingleQuote(39), Uppercase Alphabets(65-90), Lowercase Alphabets(97-122)
        return (charCode == 32 || charCode == 39 || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123));
    }

    public valueContainsNonAlphaCharacters(value: string): boolean {
        let result: boolean = false;

        if (!value || !value.length) {
            return false;
        }

        for (var i = 0; i < value.length; i++) {
            var charCode = value.charCodeAt(i);
            if (!this.isCharCodeAlphaOnly(charCode)) {
                result = true;
                break;
            }
        }

        return result;
    }

    public isCharCodeAlphaNumericOnly(charCode: number): boolean {
        // Charcodes -> WhiteSpaces(32), SingleQuote(39), Hyphen(45), Uppercase Alphabets(65-90), Lowercase Alphabets(97-122), Numerics(48-57)
        return (charCode == 32 || charCode == 45 || charCode == 39 || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || (charCode > 47 && charCode < 58));
    }

    public valueContainsNonAlphaNumericCharacters(value: string): boolean {
        let result: boolean = false;

        if (!value || !value.length) {
            return false;
        }

        for (var i = 0; i < value.length; i++) {
            var charCode = value.charCodeAt(i);
            if (!this.isCharCodeAlphaNumericOnly(charCode)) {
                result = true;
                break;
            }
        }

        return result;
    }
} 